.box {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;
}

.infoBox {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;

  @media (width <= 960px) {
    grid-template-columns: 1fr;
    gap: 24px;
  }

  .circle {
    place-self: center center;
  }
}

.mealsTable {
  display: flex;
  flex-direction: column;
  gap: 6px;
  justify-content: space-between;
}

.value {
  font: var(--title-36-bold);
}

.percent {
  font: var(--title-16-bold);
}
