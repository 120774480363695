.linearProgress {
  &:global(.MuiLinearProgress-root) {
    height: 12px;
    background-color: var(--background-50-dark);
    border-radius: 8px;
  }

  :global(.MuiLinearProgress-bar) {
    border-radius: inherit;
  }
}
