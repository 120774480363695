.food-list {
  max-width: 1376px;
  width: 100%;
  margin: 32px auto;

  &__submit {
    visibility: hidden;
    position: absolute;
  }

  .quick-search {
    margin-right: 10px;
  }

  .view-only-mode {
    padding: 0 16px;
    margin-left: 10px;
    border-left: 1px solid rgba(120, 128, 162, 0.2);

    md-icon {
      opacity: 0.7;
    }
  }

  .filter-list-input {
    margin-top: 40px;

    md-icon {
      margin-right: -30px;
      min-height: 16px;
      height: 16px;
    }

    md-icon[md-svg-icon="close"] {
      width: 24px;
      height: 24px;
      min-width: 24px;
      min-height: 24px;
      cursor: pointer;
    }

    input {
      background: transparent;
      padding-left: 34px;
    }
  }

  .title-page {
    font-weight: 900;
    color: #000000;
    font-size: 28px;
    letter-spacing: -.67px;
    line-height: 45px;
    margin: 0;
  }

  .title-text {
    margin: 32px 26px 16px 26px;
  }

  a {
    font-size: 14px;
    text-decoration: none;
    padding: 6px 0;
    opacity: 1;
    cursor: default;

    &:hover {
      opacity: 1;
    }

    &.disabled {
      pointer-events: none;

      md-switch {
        pointer-events: auto;
      }
    }
  }

  .list {
    margin-top: 20px;
    min-height: 100px;

    .custom-tag-clmn-title {
      text-align: center;
      padding: 0;
    }

    &-item {
      box-shadow: 0 7px 22px rgba(96, 105, 157, 0.07);
      background-color: #ffffff;
      margin-bottom: 10px;
      min-height: 74px;
      padding-left: 17px;

      &.editable {
        &:hover {
          box-shadow: 0 4px 15px rgba(96, 105, 157, 0.22);

          a {
            cursor: pointer;
          }
        }
      }

      &:first-child {
        box-shadow: none;

        .list-item__text {
          color: var(--natural-600);
        }
      }

      md-checkbox[disabled] {
        .md-label {
          font-size: 16px;
          white-space: nowrap;
          color: var(--main-black);
        }

        &.md-checked .md-icon {
          background-color: var(--main-black)
        }
      }

      &.non-active {
        opacity: 0.5;
      }

      .tag {
        font-weight: 400;
        width: auto;
        border-radius: 16px;
        color: var(--white);
        font-size: 14px;
        font-weight: 700;
        letter-spacing: -.34px;
        padding: 3px 8px 3px 8px;
        height: min-content;
        margin: 5px 5px 5px 0;
        border: 1px solid #c9cbd4;
        background: #c9cbd4;

        &-Protein {
          border-color: var(--proteins-color);
          background: var(--proteins-color);
        }

        &-Carbohydrate {
          border-color: var(--carbs-color);
          background: var(--carbs-color);
        }

        &-Fat {
          border-color: var(--fats-color);
          background: var(--fats-color);
        }

        &-Condiments {
          border-color: var(--condiments-color);
          background: var(--condiments-color);
        }

        &-Fruit, &-Veggie {
          border-color: var(--veggies-color);
          background: var(--veggies-color);
        }

        @media (max-width: 1200px) {
          margin: 5px auto 5px auto;
        }
      }

      &__text {
        font-weight: 700;
        font-size: 16px;
        margin: 0;
        color: #000000;
        opacity: 1;
        padding-right: 24px;
      }

      p[class^="icon-"] {
        &:before {
          content: '•';
          margin-right: 7px;
          font-size: 20px;
        }
      }

      .icon-protein {
        &:before {
          color: var(--proteins-color);
        }
      }

      .icon-carbs {
        &:before {
          color: var(--carbs-color);
        }
      }

      .icon-fats {
        &:before {
          color: var(--fats-color);
        }
      }

      .icon-sugar {
        &:before {
          color: var(--sugar-color);
        }
      }

      .icon-fiber {
        &:before {
          color: var(--fiber-color);
        }
      }

      .icon-veggie {
        &:before {
          color: var(--veggies-color);
        }
      }

      .icon-sodium {
        &:before {
          color: var(--sodium-color);
        }
      }

      .icon-calorie {
        &:before {
          color: var(--calories-color);
        }
      }
    }
  }

  .filter-list-input {
    margin-top: 0;

    input {
      padding-left: 12px;
    }

    &.food-search {
      margin-top: 40px;

      input {
        padding-left: 34px;
      }
    }
  }

  md-select .md-select-value {
    padding-right: 0;
  }
}
