.add-video {
  max-width: 1376px;
  width: 100%;
  margin: 32px auto;

  .title-page {
    font-weight: 900;
    color: #000000;
    font-size: 28px;
    letter-spacing: -.67px;
    line-height: 45px;
    margin: 0;
  }

  &__btn-save {
    margin-left: 10px;
  }

  .list {
    margin-top: 20px;

    &-item {
      box-shadow: 0 7px 22px rgba(96, 105, 157, 0.1);
      border-radius: 3px;
      margin: 10px 10px 30px 10px;
      padding: 33px 26px 33px;
      &:last-child{
        margin-bottom: 10px;
      }
      &__index {
        font-size: 16px;
        font-weight: 700;
        letter-spacing: -.38px;
        color: var(--main-black);
        margin-right: 22px;
      }

      &__image {
        position: relative;
        cursor: pointer;
        width: 129px;
        height: 92px;

        img {
          width: 100%;
          height: 100%;
        }

        &.active {
          &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            z-index: 1;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: rgba(255, 255, 255, .2) url("../../../assets/images/material-icons/play-arrow.svg") no-repeat center;
          }
        }
      }

      md-checkbox {
        margin-left: 15px;
      }
    }
  }
}
