.termsLabel {
  width: 100%;
  max-width: 500px;
  font: var(--body-14-medium);
  color: var(--main-black);
}

.link {
  color: var(--primary-500) !important;
  text-decoration: none;
}