.score-table {
  margin: 32px auto;

  &-width-wrapper {
    max-width: 1376px;
    width: 100%;
    margin: auto;
  }

  .title-page {
    font-weight: 900;
    color: #000000;
    font-size: 28px;
    letter-spacing: -.67px;
    line-height: 45px;
    margin: 0;
  }

  button.outline {
    margin-right: 10px;
  }

  md-switch {
    margin-left: auto;
    width: fit-content;
  }

  .list {
    &-item {
      margin-top: 6px;
      border-radius: 7px;
      background: #ffffff;
      box-shadow: 0 7px 22px rgba(96, 105, 157, .1);
      padding: 12px 12px 12px 52px;
      position: relative;
      min-height: 70px;
      &.header {
        background: transparent;
        box-shadow: none;
        border-radius: 0;
        max-width: 1376px;
        width: 100%;
        margin: auto;
        padding: 25px 12px 25px 52px;

        .list-item__text {
          color: var(--natural-600);
          font-size: 14px;
          font-weight: 700;
          letter-spacing: -.34px;

        }

        .filter-list-input {
          margin: 0 10px;
        }
        .list-item__actions {
          button {
            display: none;
            min-width: 105px;
            &.outline {
              margin-right: 10px;
            }
          }
        }
      }

      &__text {
        color: #000000;
        font-weight: 700;
        margin: 0 10px;
      }

      &__actions {
        margin: 0 10px;
      }

      input {
        background: transparent;
        border: none;
        border-bottom: 1px solid rgba(0, 0, 0, .12);
        color: #000000;
        letter-spacing: -.38px;
        font-weight: 700;
        font-size: 16px;
        margin: 0 10px;
        padding-bottom: 1px;

        &:disabled {
          border-color: transparent;
        }

        &:focus {
          border-bottom: 2px solid var(--primary-500) !important;
          padding-bottom: 0;
        }

        &.value-with-warning {
          color: #ff6c6b;
        }

        &.value-with-error {
          border-bottom: 2px solid #ff6c6b;
          padding-bottom: 0;
        }
      }

      &.deleted {
        background: rgba(255, 255, 255, .3);

        input {
          color: var(--natural-600);
        }

        .list-item__text {
          color: var(--natural-600);
        }

        md-icon[md-svg-icon="trash"] {
          display: block;
        }
      }

      &.edited {
        md-icon[md-svg-icon="edit-option"] {
          display: block;
        }
      }

      &.new {
        &:before {
          content: 'NEW';
          color: var(--white);
          font-weight: 900;
          font-size: 8px;
          letter-spacing: -.2px;
          background: #58ca8c;
          border-radius: 20px;
          padding: 1px 6px;
          position: absolute;
          left: 12px;
          top: 0;
          bottom: 0;
          margin: auto;
          max-height: 13px;
        }
      }

      md-icon[md-svg-icon="trash"] {
        width: 30px;
        min-height: 14px;
        height: 14px;
        position: absolute;
        left: 12px;
        top: 0;
        bottom: 0;
        margin: auto;
        z-index: 2;
        background: #ffffff;
        display: none;
      }

      md-icon[md-svg-icon="edit-option"] {
        width: 30px;
        min-height: 14px;
        height: 14px;
        position: absolute;
        left: 12px;
        top: 0;
        bottom: 0;
        margin: auto;
        z-index: 1;
        background: #ffffff;
        display: none;
      }

      button.clear {
        margin: 0 10px;
      }

      .menu-wrapper {
        margin: 0 10px;
      }
    }

    .fixed-header {
      position: sticky;
      top: 0;
      left: 0;
      right: 0;
      box-shadow: 0 3px 7px rgba(0, 0, 0, .09);
      background-color: var(--app-background);
      z-index: 5;
      .header{
        .list-item__actions {
          button {
            display: block;
          }
        }
      }
    }
  }
}
