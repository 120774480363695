.title {
  margin-bottom: 8px;
  font: var(--body-14-bold);
}

.list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.item {
  display: flex;
  gap: 8px;
}
