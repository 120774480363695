.waringMessage {
  display: flex;
  gap: 5px;
  align-items: center;
  padding: 5px 50px;
  font: var(--body-14-bold);
  color: var(--info-300);
  background: var(--info-50);

  .link {
    display: flex;
    gap: 5px;
    align-items: center;
    margin-left: 40px;
    color: var(--info-300) !important;
    text-underline-offset: 4px;
    white-space: nowrap;
  }
}
