.testBox {
  margin-bottom: 16px;

  .resultBox {
    padding: 28px 32px 10px;

    .resultRow {
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 25px;
      }
    }
  }
}
