.invoice-calendar {
  font: var(--title-16-bold);

  .container {
    width: 490px;
    padding: 0;
    margin: 0 0 0 5px;
    border-radius: 8px;
    background: #fff;
  }

  .tabs {
    border-radius: 8px;
  }

  md-tabs-wrapper {
    margin: 20px 20px 0;
  }

  md-pagination-wrapper {
    width: 100%;
  }

  .md-tab {
    width: 50%;
    padding-top: 0;
    border-radius: 8px;
    background: none;
    font: var(--title-22-bold);
    color: var(--natural-400);

    &.md-active {
      color: var(--main-black);

      ~ md-ink-bar {
        background-color: #000;
      }
    }
  }

  md-content {
    background-color: var(--white);
  }

  .list {
    max-height: 460px;
    overflow-y: scroll;

    &__item {
      margin: 0 20px 7px 20px;
      position: relative;
      box-shadow: 0 7px 22px rgba(96, 105, 157, 0.1);
      border-radius: 7px;
      background-color: var(--white);
      overflow: hidden;

      &:first-of-type {
        margin-top: 20px;
      }

      &:last-of-type {
        margin-bottom: 20px;
      }
    }
  }

  .item {
    $grey: #D8D8E6;

    &__dash {
      color: $grey;
      padding: 0 5px;
    }

    &__total {
      font: var(--title-16-regular);
      color: var(--natural-500);
    }

    &__arrow {
      color: $grey;
    }
  }

  .item.selected {
    color: var(--white);
    background: var(--info-300);

    .item__dash {
      color: var(--white);
    }

    .item__total {
      color: var(--white);
    }
  }
}