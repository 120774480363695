.statusBox {
  display: inline-flex;
  gap: 8px;
  align-items: center;

  p {
    font: var(--body-14-regular);
  }
}

// label position
.align-center {
  align-items: center;
}

.align-start {
  align-items: start;
}

.align-end {
  align-items: end;
}
