.tableBox {
  height: calc(100vh - 154px);
  overflow: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }

  .timeRow {
    display: grid;
    grid-template-columns: 1.4fr 8.1fr;
    padding-left: 10px;
    font: var(--body-14-bold);
    border-bottom: 1px solid var(--natural-200);

    &:nth-child(even) {
      background-color: var(--background-50-light);
    }
  }
}
