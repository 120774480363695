.gridRow {
  display: grid;
  grid-auto-flow: column;
  min-width: 100%;
  padding: 12px 20px;
  margin-bottom: 8px;
  font: var(--body-14-regular);
  color: var(--main-black);
}

.header {
  font: var(--body-14-bold);
}

.filter {
  padding: 15px 20px;
}

.row {
  min-height: 94px;
}

.subRow {
  min-height: 56px;
  margin-bottom: 0;
  border-radius: 0;
  box-shadow: none;
}

.unstyled {
  padding: 0;
  margin-bottom: 0;
  overflow: visible;
  border-radius: 0;
  box-shadow: none;
}

.highlight {
  padding-left: 16px;
  border-left: 4px solid var(--primary-500);
}
