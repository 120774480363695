.customer-setup-step-4 {
  md-radio-group {
    md-radio-button {
      margin: 20px 0;

      &:not(:first-child) {
        margin-left: 50px;
      }
    }
  }

  .input-placeholder {
    font-size: 14px;
    font-weight: 700;
    letter-spacing: -.34px;
    color: #000000;
    opacity: .5;
    margin: 0;
    padding-top: 23px;
    white-space: nowrap;
  }

  md-input-container.date {
    &:after {
      content: '';
      background: url("../../../assets/images/vectors/calendar.svg");
      width: 18px;
      height: 19px;
      margin-left: -16px;
      margin-top: 4px;
      position: absolute;
      right: 5px;
      top: 5px;
    }
  }

  .goal-targets {
    font-size: 14px;
    font-weight: 700;
    letter-spacing: -.34px;
    color: #000000;
    margin: 0;
    padding-top: 23px;

    &__label {
      opacity: .5;
    }
    &__text{
      font-size: 16px;
      padding: 0 2px;
    }
  }

  .table {
    &-item {
      &__label{
        font-weight: 700;
        letter-spacing: -.34px;
        opacity: .5;
        color: #000000;
        margin-bottom: 30px;
      }
      &__text {
        font-weight: 700;
        letter-spacing: -.34px;
        color: #000000;
        margin-bottom: 30px;
      }
    }
  }
}
