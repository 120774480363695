.dayRow {
  position: sticky;
  top: 0;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  padding: 17px 60px 14px 100px;
  font: var(--title-16-bold);
  text-transform: capitalize;
  background-color: var(--white);
  border-bottom: 1px solid var(--natural-300);
}
