.container {
  display: flex;
  place-content: center space-between;
  width: 100%;
  margin-bottom: 34px;
}

.typography {
  font: var(--title-28-black);
}
