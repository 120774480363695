.titleControls {
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  justify-content: end;
  max-width: 450px;
}

.filterInput {
  max-width: 230px;
}