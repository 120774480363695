.coach-dashboard {
  max-width: 1376px;
  width: 100%;
  margin: 32px auto;

  .under-construction {
    text-align: center;
    padding: 30px 0;
    color: #000000;
    font-weight: 700;
    letter-spacing: 0.09px;

    &__description {
      margin-top: 20px;
    }
  }

  .app-card {
    &:first-child {
      margin-right: 20px;
    }
  }

  .list {
    &-item {
      padding-top: 14px;
      padding-bottom: 26px;
      border-bottom: 1px solid transparentize(#7880a2, .8);

      &:first-child {
        .list-item__text {
          color: var(--natural-600);
        }
      }

      &__text {
        color: var(--main-black);
        font-size: 14px;
        font-weight: 700;
        letter-spacing: -0.34px;
        margin: 0;

        &:first-child {
          color: var(--natural-600);
        }
      }

    }
  }
}
