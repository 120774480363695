.rangeBox {
  position: relative;
}

.slider {
  position: absolute;
  bottom: 0;
  left: 8px;
  z-index: 10;
  width: calc(100% - 16px);
  height: 1px;
  padding: 0;
}
