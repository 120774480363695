.view-video {
  position: relative;
  cursor: pointer;
  opacity: .8;
  margin: 10px 32px 10px 0;
  min-width: 241px;
  width: 241px;

  img {
    width: 100%;
    height: 176px;
    border-radius: 7px;
  }

  &:before {
    content: '';
    position: absolute;
    margin: auto;
    z-index: 1;
    border-radius: 50%;
    background: rgba(255, 255, 255, .2) url("../../../assets/images/material-icons/play-arrow.svg") no-repeat center;
    height: 56px;
    width: 56px;
    left: 0;
    right: 0;
    top: 60px;
  }

  &:hover {
    opacity: 1;
  }

  &__text {
    color: var(--natural-600);
    font-size: 14px;
    font-weight: 700;
    margin-top: 8px;
  }
}

.video-help-tab {

  .under-construction {
    text-align: center;
    padding: 30px 0;
    color: #000000;
    font-weight: 700;
    letter-spacing: 0.09px;

    &__description {
      margin-top: 20px;
    }
  }
}
