.inbodyKeyTable {
  :global(.tableGridLayout) {
    grid-template-columns: repeat(3, 1fr) 50px;
    gap: 24px;
    place-items: center left;
    background-color: transparent;

    &:not(:last-child) {
      border-bottom: 1px solid var(--natural-300);
    }
  }
}
