.row {
  display: grid;
  grid-template-columns: subgrid;
  grid-column: 1 /-1;
  place-items: center end;
  font: var(--body-14-medium);
  color: var(--natural-600);
}

.percentageCell {
  justify-self: end;
}

.mealNameCell {
  justify-self: start;
  color: var(--main-black);
}

.progressCell {
  justify-self: stretch;
  padding: 0 16px;
}

.mealCountCell {
  justify-self: end;

  .trackedCount {
    color: var(--main-black);
  }
}
