.releases {
  max-width: 1048px;
  margin: 0 auto 25px;

  .header {
    padding: 48px 0 18px;
    font: var(--title-28-black);
  }

  .app-card {
    padding: 20px 32px 32px;
    margin-bottom: 12px;
    border-radius: 16px;

    &:first-of-type {
      .header__release {
        color: var(--success-300);
      }
    }

    .header {
      padding: 0 0 16px 0;

      &__date {
        font: var(--title-18-bold);
      }

      &__release {
        font: var(--title-16-bold);
        color: var(--primary-500);
      }
    }
  }

  .body {
    font: var(--body-14-regular);
    line-height: 21px;
    color: var(--natural-600);

    &__list {
      padding: 16px 0 0 15px;
    }
  }
}
