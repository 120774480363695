.formRow {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 30px;

  .supBox {
    p {
      font: var(--title-16-bold);
    }

    span {
      font: var(--body-12-medium);
      color: var(--natural-400);
    }
  }
}
