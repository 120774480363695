.compareScanTable {
  border-top: 1px solid var(--natural-300);
  border-bottom: 1px solid var(--natural-300);

  :global(.tableGridLayout) {
    grid-template-columns: 184px repeat(3, 1fr);
    gap: 24px;
    place-items: center left;

    &:not(:last-child) {
      border-bottom: 1px solid var(--natural-300);
    }
  }
}
