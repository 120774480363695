.cell {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  min-height: 80px;
  padding: 8px;
  border: 1px solid var(--natural-300);
  border-radius: 8px;

  &:hover,
  &:has(:global(.Mui-checked)) {
    border: 1px solid var(--primary-500);
  }

  .name {
    font: var(--title-16-bold);
  }
}
