.dollar-icon {
  color: var(--main-black);
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.38px;
  margin-right: -9px;
}

.suggested-meal-plan-input {
  margin: 0 0 4px 0;

  input {
    width: 80%;
  }
}

.meal-plan-input {
  margin: 0 0 0 8px;
}

.full-row-input {
  margin: 5px 0 0 0;
  width: 100%;
}

.edit-meal-plan-input {
  margin: -2px 0 4px 0 !important;

  input {
    width: 80%;
  }
}

md-input-container {
  width: 208px;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.38px;
  line-height: 24px;
  margin: 15px 15px 5px;

  input[disabled] {
    border: none;
  }

  &[data-label] {
    &:after {
      content: attr(data-label);
      position: absolute;
      right: 0;
      top: 4px;
      color: var(--natural-600);
    }
  }

  &.meal-time-input {
    margin: -10px 0 10px 0;
    width: 100px;
  }

  &.small-input {
    width: 100px;
  }

  &.full-input {
    width: 100%;
  }

  &.full-input-for-modal {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  &.product-field {
    width: 67%;
  }

  &.md-input-has-value, &.md-input-focused {
    label {
      color: #666666 !important;
      font-size: 14px;
    }

    md-select {
      .md-select-placeholder {
        color: #666666 !important;
      }
    }
  }

  label {
    color: rgba(0, 0, 0, 0.4);
    margin: 0;
  }

  &.md-icon-left.md-icon-right {
    padding-right: 0;
    padding-left: 0;

    md-icon {
      margin-left: 0;
    }

  }

  &.md-icon-left {
    label {
      left: 0;
    }

    .md-select-value {
      padding-left: 20px;

    }
  }

  &.md-icon-right {
    padding-right: 0;

    input {
      padding-right: 24px;

      &[type="text"] + .md-errors-spacer + md-icon[md-svg-icon="eye-outline"],
      &[type="text"] + .md-errors-spacer + md-icon[md-svg-icon="eye-outline-close"],
      &[type="password"] + .md-errors-spacer + md-icon[md-svg-icon="eye-outline"],
      &[type="password"] + .md-errors-spacer + md-icon[md-svg-icon="eye-outline-close"] {
        width: 25px;
        padding: 0 2px 0 4px;
        border-left: 1px solid transparentize(#898989, .8);
        cursor: pointer;
      }
    }

    md-icon {
      color: var(--primary-500);
      width: 20px;
      min-width: 20px;
      min-height: 20px;
      height: 20px;
      top: 7px;
    }
  }

  md-select {
    .md-select-icon {
      display: none !important;
    }

    & + md-icon.md-select-icon {
      width: 12px;
      min-width: 12px;
      min-height: 12px;
      height: 12px;
      top: 0;
      bottom: 0;
      margin: 12px auto auto !important;
    }

    &[aria-expanded="true"] {
      & + md-icon.md-select-icon {
        transform: scale(-1);
      }
    }
  }

  & + md-icon[md-svg-icon="trash"] {
    min-width: 14px;
    width: 14px;
    min-height: 14px;
    height: 14px;
    margin-left: -14px;
    cursor: pointer;
  }

  .order-block {
    md-icon {
      margin-top: 2px;
      min-width: 11px;
      width: 11px;
      min-height: 11px;
      height: 11px;
      cursor: pointer;

      &:active {
        color: #000000;
      }
    }
  }

  &.title-dropdown {
    width: auto;
    padding: 0 20px 0 0;
    margin-top: 0;

    &.large {

      md-select {
        md-select-value {
          border-bottom: 2px dashed var(--warning-600) !important;
        }

        .md-text {
          font-size: 28px;
          line-height: 36px;
        }

        & + md-icon.md-select-icon {
          min-width: 20px;
          min-height: 20px;
          margin: 12px -5px !important;
        }
      }
    }

    md-select-value {
      padding: 0;
      border-bottom: 1px dashed var(--warning-600) !important;

      & > span:not(.md-select-icon) .md-text {
        color: var(--warning-600);
      }
    }

    md-select + md-icon.md-select-icon {
      margin: 10px 4px auto !important;
    }
  }
}

fieldset[disabled] {
  md-input-container {
    .md-input {
      color: inherit;
      background-image: none;
    }
  }
}

md-select-menu {
  border-radius: 7px;
  padding: 5px;
  background: var(--white);

  md-content {
    md-option {
      margin: 0 5px;
      padding: 0 10px;
      color: #000000;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: -0.34px;
      line-height: 38px;
      background-color: var(--white) !important;
      border-bottom: 1px solid #f1f1f1;
      height: 41px;

      .md-text {
        width: 100%;
      }

      &:not([disabled]):hover {
        color: var(--primary-500);
        border-radius: 3px;
        background-color: rgba(var(--default-rgb), 0.1) !important;
      }

    }
  }

  ::-webkit-scrollbar {
    width: 4px;
    height: 529px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 3px;
    background-color: #e7e7e7;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--primary-500);
    border-radius: 3px;
    width: 4px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: var(--warning-600);
  }
}

md-select .md-select-value {
  padding-right: 18px;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  display: none;
}

input[type="number"]#servingSize::-webkit-outer-spin-button,
input[type="number"]#servingSize::-webkit-inner-spin-button {
  display: block;
}

.filter-list-input {
  input, md-select {
    font-size: 14px;
    font-weight: 700;
    letter-spacing: -0.34px;
    color: #000000;
    border-radius: 3px;
    border: 1px solid rgba(164, 166, 177, 0.3);
    padding: 1px 14px;
    height: 42px;
    width: 100%;
    background: #ffffff;

    &:disabled {
      opacity: .5;
    }
  }

  md-select {
    margin: 0;
    width: 100%;
  }

  md-select::placeholder {
    color: #999999;
  }

  md-select:focus {
    border: 1px solid var(--primary-500);
  }

  .md-select-icon {
    color: var(--main-black);
  }

  .md-select-value {
    border: none !important;
  }

  .md-select:not([disabled]):focus .md-select-value {
    border: none !important;
  }

  input::placeholder {
    color: #999999;
  }

  input:focus {
    border: 1px solid var(--primary-500);
  }

  &.with-sort {
    align-items: center;

    input {
      width: calc(100% - 20px);
    }

    md-icon {
      min-width: 10px;
      min-height: 10px;
      width: 10px;
      height: 10px;
      cursor: pointer;
      fill: var(--storm_grey);
      opacity: 0.5;
      margin: 5px;
    }

    md-icon[md-svg-icon="close"] {
      min-height: 24px !important;
      min-width: 24px !important;
    }

    .sorted-by {
      opacity: 1;
      fill: var(--main-black);
    }
  }

  .order-up, .order-down {
    cursor: pointer;
    width: 6px;
    height: 3px;
    margin-left: 6px;
    margin-top: -5px;
    padding: 5px 10px 5px 10px;
    background: url("../../assets/images/vectors/order_up.svg") no-repeat center;

    &.disabled {
      cursor: initial;
      opacity: 0.3
    }
  }

  md-select {
    &:before {
      content: attr(data-label);
      @include textRegular;
      color: var(--natural-600);
      font-size: 12px;
      background-color: var(--app-background);
      opacity: 1;
      z-index: 2;
      position: absolute;
      padding: 0 5px;
      margin-top: -10px;
    }
  }
}

.date-select {
  md-input-container {
    width: fit-content;
    margin: 0;
    padding: 0;

    &.md-icon-right md-icon {
      top: 16px !important;
    }

    .md-icon-right > md-icon:last-of-type {
      right: 8px;
    }
  }

  md-icon {
    fill: var(--main-black);
    margin: 18px 2px 0 0;
    min-width: 16px;
    min-height: 16px;
    width: 16px;
    height: 16px;
  }

  md-select {
    margin: 12px 6px 0 6px;
    border-radius: 3px;
    border: 1px solid var(--datepicker-border);

    .md-select-icon {
      display: none !important;
    }
  }

  .md-select-value {
    padding: 0 30px 0 10px;
    border: none !important;
  }
}

@mixin no-message {
    width: 100%;
    margin: 0;

    .md-errors-spacer {
      display: none;
    }

    input {
      color: inherit;
      background: none;
    }
}

.no-message {
  @include no-message;
}

.price {
  @include no-message;
  position: relative;

  &__dollar {
    position: absolute;
    top: 5px;
  }

  input {
    padding-left: 15px;
  }
}
