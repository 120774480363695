.container {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1140px;
  min-height: calc(100vh - var(--header-height) - var(--footer-desktop-height));
  margin: 0 auto;

  @media screen and (width <= 768px) {
    padding: 30px 0;
  }
}

.paper {
  display: flex;
  gap: 100px;
  align-items: center;
  padding: 40px 0;
  border-radius: 16px;
  @media screen and (width <= 1200px) {
    gap: 40px;
  }
  @media screen and (width <= 768px) {
    width: 100%;
    padding: 5% 0;
  }
}

.form {
  max-width: 370px;
  margin-left: 75px;
  font: var(--body-14-medium);

  @media screen and (width <= 1200px) {
    margin-left: 40px;
  }
  @media screen and (width <= 768px) {
    width: 100%;
    min-width: auto;
    margin: 0 5%;
  }
}

.title {
  margin-bottom: 8px;
  font: var(--title-28-bold);
}

.description {
  margin-bottom: 32px;
  font: var(--title-16-medium);
  color: var(--natural-700);
}

.input {
  @media screen and (width <= 768px) {
    margin: 40px 0;
  }
}

.button {
  margin: 24px 0 12px;
}

.linkWrapper {
  display: flex;
  justify-content: center;
}

.image {
  min-width: 50%;

  @media screen and (width <= 768px) {
    display: none;
  }
}
