.supplement-setup-step-1 {
  &__submit {
    display: block;
    margin: 0 0 0 auto;
  }
}

md-option[aria-label="supplement-gender"] {
  text-transform: capitalize;
}
