.user-profile {
  padding: 25px 0;

  &-actions {
    margin-top: 16px;
    margin-left: auto;
  }

  .profile-fields-header, .user-profile-fields {
    padding: 0 25px;
  }

  .user-profile-fields {
    padding-top: 25px;
  }

  &-fields {
    border-top: 1px solid rgba(120, 128, 162, 0.2);
    padding-top: 20px;
  }

  .profile-fields-header {
    padding-bottom: 20px;
    min-height: 100px;
  }

  .delete {
    margin-right: 12px;
  }
}
