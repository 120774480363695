.school-fees {
  .app-card {
    &-body {
      padding: 26px 32px 0;
    }
  }

  .table {
    &__row {
      padding-bottom: 33px;

      &_header {
        font: 500 14px/24px Lato;
        color: #8B8B94;
      }
    }
  }

  .fee {
    &__type {
      font: var(--title-16-black);
    }

    &__description {
      md-input-container {
        input {
          font: 400 16px/24px "SF UI Display", sans-serif;
        }
      }
    }
  }

  .col {
    margin-right: 28px;
  }
}
