.sign-in-auth0 {
  &-form-wrapper {
    max-width: 924px;
    width: 95%;
    height: 526px;
    box-shadow: 0 7px 22px rgba(96, 105, 157, 0.1);
    border-radius: 7px;
    background-color: var(--white);
    margin: calc((100vh - 596px) / 2) auto;
    padding: 54px 41px 54px 87px;
    @media screen and (max-width: 930px) {
      padding: 54px 20px 54px 20px;
      margin-top: 95px;
    }
    @media (max-height: 700px) {
      margin-top: 115px;
    }
    button.clear {
      padding: 0;
      height: auto;
      border-bottom: 1px solid;
      border-radius: 0;
    }

    .mfa-explanation {
      color: var(--natural-600);
      font-size: 14px;
      font-weight: 700;
      letter-spacing: -0.34px;
      margin-top: -30px;
      opacity: 0.8;
    }
  }

  &-form {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 300px;
    height: 400px;

    &__title {
      position: absolute;
      top:0;
      color: var(--main-black);
      opacity: .8;
      font-size: 28px;
      font-weight: 900;
      letter-spacing: -.67px;
    }

    md-input-container {
      margin: 18px 0 4px;
    }

    &__email {
      margin-bottom: 4px;

      input {
        padding-right: 40px;
        background: url("../../../assets/images/vectors/user_email_login.svg") no-repeat right;
      }
    }

    &__password {
      margin-bottom: 0;

      md-icon {
        cursor: pointer;
      }

      .md-errors-spacer {
        min-height: auto;
      }
    }

    &__link {
      color: var(--primary-500) !important;
      margin-left: auto;
      margin-top: 5px;
      margin-bottom: 72px;
    }

    &__submit {
      width: 100%;
      height: 45px;
      border-radius: 4px;
      background-color: var(--primary-500);
      color: var(--white);
      font-size: 16px;
      font-weight: 700;
      letter-spacing: -0.38px;
      text-transform: capitalize;
      margin-left: 0;

      &:not([disabled]):hover {
        opacity: 0.8;
        background-color: var(--primary-500);
      }
    }
  }

  &-image {
    max-width: 435px;
    width: 50%;
    margin-top: 6px;
    margin-left: 20px;
  }
}
