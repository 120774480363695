.box {
  display: inline-flex;
  gap: 16px;
  align-items: center;
  justify-content: end;
  max-width: 343px; // size for M-size mobile
  height: 42px;
  overflow: hidden; // crop entity logo when bigger than 343px
}

.entityLogo {
  object-fit: cover;
  height: 100%;
}

.separatorLine {
  width: 1px;
  height: 20px;
  background-color: var(--natural-700);
}

.description {
  font: var(--body-12-medium);
  color: var(--natural-400);
}

.logo {
  display: inline-flex;
  gap: 4px;
  font: var(--title-16-bold);
  color: var(--white);

  .primary {
    font: var(--title-16-bold);
    color: var(--primary-500);
  }
}
