.box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  background-color: var(--white);

  h6 {
    font: var(--title-28-black);
    color: var(--primary-500);
  }

  button {
    width: auto;
  }
}
