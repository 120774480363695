.calendar {
  :global {
    .MuiDateCalendar-root {
      width: auto;
      max-height: none;
      padding: 0 32px;
      margin: 0;
    }

    .MuiPickersCalendarHeader-root {
      min-height: 0;
      max-height: 57px;
      padding: 11px 20px 11px 34px;
      margin: 0 -32px;
      border-bottom: 1px solid var(--natural-300);

      .MuiPickersCalendarHeader-labelContainer {
        max-height: 30px;
        font: var(--title-16-bold);
        cursor: auto;
      }
    }

    .MuiPickersFadeTransitionGroup-root {
      .MuiPickersSlideTransition-root {
        min-height: 290px;
      }

      .MuiDayCalendar-weekDayLabel,
      .MuiPickersDay-root {
        width: 43px;
        height: 43px;
        margin: 2.5px;
      }

      .MuiDayCalendar-weekDayLabel {
        padding-top: 20px;
      }

      .MuiPickersDay-root {
        border-radius: 4px;
      }

      .MuiDayCalendar-weekContainer {
        margin: 0;
      }
    }
  }
}
