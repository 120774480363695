.filtersBox {
  padding: 20px;
  margin-bottom: 12px;

  form {
    display: grid;
    grid-template-columns: auto 1fr 150px;
    align-items: center;

    .checkbox {
      margin-right: 20px;

      svg {
        fill: var(--natural-300);
      }

      &:global(.Mui-checked) svg {
        fill: var(--main-black);
      }
    }

    .inputBox {
      display: grid;
      flex-grow: 1;
      grid-auto-columns: 1fr;
      grid-auto-flow: column;
      gap: 20px;
    }

    .submitBtn {
      justify-self: end;
      width: 120px;
    }
  }
}
