.menuItem {
  gap: 4px;

  svg:first-child {
    font-size: 16px;
    color: var(--natural-700);
  }

  &:hover svg:first-child {
    color: var(--primary-500);
  }
}
