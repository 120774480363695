.video-tab-content {
  padding: 40px 40px;

  &.dashboard {
    padding: 0;
  }

  .white-shape-shadow {
    &:first-child {
      margin-top: 30px;
    }

    padding: 20px 20px 0 20px;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 30px;
    }

    @media all and (max-width: 1386px) {
      width: 100%;
    }
  }

  .video-list-title {
    position: relative;
    color:var(--main-black);
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.38px;
    padding-bottom: 20px;

    &:after {
      content: '';
      position: absolute;
      width: calc(100% + 40px);
      height: 1.5px;
      bottom: 0;
      left: -20px;
      background-color: var(--cloud);
    }
  }

  .video-panel {
    margin-top: 20px;
    flex-wrap: wrap;

    .preview {
      max-width: 240px;
      width: 100%;
      height: auto;
      border-radius: 7px;
      opacity: 0.8;
      cursor: pointer;
      margin-bottom: -56px;

      &:hover {
        opacity: 1;
      }

      @media all and (max-width: 1085px) {
        max-width: 200px;
      }

      img {
        border-radius: 7px;
      }

      .play-arrow-icon {
        position: relative;
        top: -115px;
        left: 93px;
        width: 56px;
        height: 56px;
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0.2);

        md-icon {
          margin-top: 30%;
          margin-left: 40%;
        }
      }
    }

    .video-container {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }

    .video-title {
      color: var(--natural-600);
      font-size: 14px;
      font-weight: 700;
      margin-left: 0;
      margin-top: 8px;
      margin-bottom: 20px;
      max-width: 240px;
      max-height: 20px;
      word-break: break-all;

      @media all and (max-width: 1085px) {
        max-width: 200px;
      }
    }
  }
}

.video-container-popup {
  max-width: 951px;
  width: 100%;
  height: auto;
  max-height: 648px;
  padding-top: 30px;

  .video-title-popup {
    max-width: 864px;
    width: 100%;
    margin: 0 auto;
  }

  .layout-align-end-stretch {
    max-width: 901px;
    width: 100%;
  }
}
