.title {
  margin-bottom: 4px;
  font: var(--body-14-medium);
}

.box {
  display: inline-flex;
  gap: 10px;
  align-items: center;
}

.value {
  font: var(--title-28-bold);
}

.icon {
  font-size: 10px;
}
