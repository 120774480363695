.checkContainer {
  display: flex;
  align-items: center;
}

.checkBox {
  margin-right: 15px;
}

.checkBoxText {
  margin-right: 7px;
  font: var(--title-16-medium);
}

.checkBoxLink {
  font: var(--title-16-bold);
}
