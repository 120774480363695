.inputBox {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 16px;
}

.paymentDetailsBox {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
