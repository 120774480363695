.customer-setup-step-2 {
  .app-card {
    &-header {
      border-width: 1px;

      &__title {
        font-weight: 700;

        &.grey {
          opacity: .5;
          margin-left: 15px;
        }
      }
    }
  }

  .input-placeholder {
    font-size: 14px;
    font-weight: 700;
    letter-spacing: -.34px;
    color: #000000;
    opacity: .5;
    margin: 0;
    padding-top: 10px;
  }

  .border-left {
    border-left: 1px solid rgba(120, 128, 162, 0.2);
  }

  .input-container-workouts {
    padding-bottom: 0;
    margin-bottom: 0;
    margin-top: 0;
  }
}
