.container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font: var(--body-12-medium);
  color: var(--natural-500);
}

.privacyLinks {
  display: flex;
  gap: 8px;
  color: var(--natural-500);
  text-decoration: none;
}

.link {
  font: var(--body-12-medium);
  color: var(--natural-500) !important;
  text-decoration: none;
}