.pre-approval-setup {
  max-width: 1360px;
  margin: 32px auto;
  &__buttons-group {
    margin-top: 25px;
  }

  .title-page {
    font-family: Lato-Black, sans-serif;
    color: #000000;
    font-size: 28px;
    letter-spacing: -.67px;
    line-height: 45px;
    margin: 0;
  }

  .app-card-body {
    padding: 0 5px 15px;
    clear: both;
  }

  md-input-container {
    width: 190px;
  }

  .header {
    margin-bottom: 20px;
    &__info {
      display: flex;
      align-items: center;
      .icon {
        margin: 0 5px 0 0;
        width: 16px;
        height: 16px;
        min-width: auto;
        min-height: auto;
      }
      .text {
        margin: 0;
        color: #666;
        font: bold 12px / 14px Lato, sans-serif;
        letter-spacing: -0.28px;
      }
    }
  }
  .card {
    margin: 0 0 8px 20px;
    &__index {
      margin-left: -20px;
      display: inline-block;
    }
    &__delete-btn {
      float: right;
      margin: 15px 10px 0 0;
      align-items: center;
      height: auto;
    }
  }
  .button--wide {
    text-align: center;
    margin-left: 10px;
    min-width: 165px;
  }

  &__subscriptions {
    md-select-menu {
      width: 230px;

      md-option {
        height: auto;
        padding: 10px;

        .md-text {
          line-height: 21px;
          white-space: initial;
        }
      }
    }
  }
}
