.users-fees-card {
  .table {
    @include feesTable;
  }

  md-input-container.no-message {
    width: auto;
    margin: 0 15px;
  }
}
