:root {
    --app-background: #FBFBFB;
    --waikawa_gray: #626993;
    --lynch: #7880a2;
    --aluminium: #a4a6b1;
    --cloud: #ecedf2;
    --grey: #b0b4c9;
    --grizzly: #bbbcc5;
    --white: #ffffff;
    --shadow: #2e3556;
    --slate_grey: #8a8c9a;
    --santas_gray: #9196B3;
    --athens_gray: #E4E6EC;
    --storm_grey: #6C7188;
    --light_storm_grey: #7A7F93;
    --default-rgb: 255, 153, 0;
    --main-button-border-rgb: 0, 0, 0;
    --main-border-button-hover: rgba(127, 127, 127, 0.1);
    --block-button-border: rgba(178, 178, 178, 0.5);
    --label-text: #999999;
    --scrollbar-track: #e7e7e7;
    --tabs-background-rgb: 234, 234, 234;
    --tabs-text-rgb: 0, 0, 0;

    --datepicker-border: rgba(164, 166, 177, 0.3);
    --filter-tag: #f8f8f8;
    --tab-icons: #898989;
    --note-border: rgba(151, 151, 151, 0.3);
    --select-item-background: rgba(255, 154, 21, 0.1);
    --left-panel-border: #efefef;
    --selected-field-area: #e5e6e9;
    --non-active-tab-background: #f6f6f6;
    --coach-tab-icon-background: #f4f4f4;
    --header-avatar-background: #e5e5e5;
    --profile-avatar-background: rgba(229, 229, 229, 0.9);
    --link-btns-rgb: 0, 0, 0;
    --note-tag-background: rgba(153, 153, 153, 0.1);
    --corporate-tag-rgb: 255, 153, 0;
    --main-black: #090B0E;

     /* new colors  */
     /* same as in PALETTE_COLOR, if change also change PALETTE_COLOR */
    --primary-800: #F17A09;
    --primary-700: #F57D00;
    --primary-600: #FB8D00;
    --primary-500: #FF9900;
    --primary-400: #FFA722;
    --primary-300: #FFB84D;
    --primary-200: #FFCC80;

    --info-600: #004BC3;
    --info-500: #0053CE;
    --info-400: #286CD9;
    --info-300: #5E86DF;
    --info-200: #91A9E8;
    --info-100: #BECAF1;
    --info-50: #F0F3FF;

    --success-600: #00A351;
    --success-500: #00B25B;
    --success-400: #1BBE72;
    --success-300: #58CA8C;
    --success-200: #8ED8AC;
    --success-100: #BBE8CD;
    --success-50: #E3F6EB;

    --warning-600: #FC9A13;
    --warning-500: #FFA617;
    --warning-400: #FFB332;
    --warning-300: #FFC256;
    --warning-200: #FFD387;
    --warning-100: #FEE4B6;
    --warning-50: #FFF5E2;

    --error-600: #DB2C2C;
    --error-500: #E9362D;
    --error-400: #E64948;
    --error-300: #F66464;
    --error-200: #FC9290;
    --error-100: #FFC9CE;
    --error-50: #FFE9ED;

    --natural-700: #5A5A65;
    --natural-600: #6E6E79;
    --natural-500: #9696A2;
    --natural-400: #B6B6C2;
    --natural-300: #DADAE6;
    --natural-200: #E9E9F6;
    --natural-100: #F2F2FE;

    --violet-600: #9860DB;
    --violet-500: #A270DF;
    --violet-400: #AD80E2;
    --violet-300: #B790E6;
    --violet-200: #C1A0E9;
    --violet-100: #CBB0ED;
    --violet-50: #D6BFF1;

    --background-50-dark: #F2F2F7;
    --background-50-light: #F8F8FA;

    --shades-900: #090B0E;
    --shades-50: #FFFFFF;

    /* shadow */
    --shadow-main-1: 0px 6px 21px rgba(56, 63, 82, 0.22);
    --panel-shadow: 0 7px 22px rgba(96, 105, 157, 0.1);
    --tab-shadow: 2px 2px 2px 0px rgba(96, 105, 157, 0.10);
    --toast-shadow: -2px 2px 25px 0px rgba(57, 63, 80, 0.12);

    /* meals variable (remove to a separate folder after webpack implement) */
    --proteins-color: var(--info-300);
    --carbs-color: var(--error-300);
    --fats-color: var(--warning-300);
    --sugar-color: var(--violet-600);
    --fiber-color: var(--success-500);
    --sodium-color: var(--natural-700);
    --calories-color: var(--success-200);
    --veggies-color: var(--warning-600);
    --water-color: var(--info-200);
    --condiments-color: #a49800;

    /* fonts */
    --title-62-black: 900 62px/78px 'Lato', sans-serif;
    --title-48-black: 900 48px/64px 'Lato', sans-serif;
    --title-36-bold: 700 36px/42px 'Lato', sans-serif;
    --title-28-black: 900 28px/34px 'Lato', sans-serif; /* used in angular, new pattern use --title-28-bold */
    --title-28-bold: 700 28px/34px 'Lato', sans-serif;
    --title-22-black: 900 22px/32px 'Lato', sans-serif;
    --title-22-bold: 700 22px/32px 'Lato', sans-serif;
    --title-18-black: 900 18px/26px 'Lato', sans-serif;
    --title-18-bold: 700 18px/26px 'Lato', sans-serif;
    --title-18-medium: 500 18px/26px 'Lato', sans-serif;
    --title-16-black: 900 16px/24px 'Lato', sans-serif;
    --title-16-bold: 700 16px/24px 'Lato', sans-serif;
    --title-16-medium: 500 16px/24px 'Lato', sans-serif;
    --title-16-regular: 400 16px/24px 'Lato', sans-serif;
    --body-14-bold: 700 14px/16px 'Lato', sans-serif;
    --body-14-medium: 500 14px/16px 'Lato', sans-serif;
    --body-14-regular: 400 14px/16px 'Lato', sans-serif;
    --body-12-bold: 700 12px/14px 'Lato', sans-serif;
    --body-12-medium: 500 12px/14px 'Lato', sans-serif;
    --body-10-regular: 400 10px/12px 'Lato', sans-serif;

    /* sizes */
    --header-height: 74px;
    --footer-desktop-height: 35px;
    --footer-mobile-height: 59px;

    /* icons */
    --download-file: url('../assets/images/vectors/download-file-black.svg');
    --logo-url: url('../assets/images/logo-lifebase.svg');
    --logo-width: 120px;
    --logo-height: 39px;
    --printer-profile: url('../assets/images/vectors/printer-black.svg');
    --overview-icon: url('../assets/images/vectors/overview_dark.svg');
    --overview-active-icon: url('../assets/images/vectors/overview_active_dark.svg');
    --customer-profile-icon: url('../assets/images/vectors/customer_profile_dark.svg');
    --customer-profile-active-icon: url('../assets/images/vectors/customer_profile_active_dark.svg');
    --micro-macro-plan-icon: url('../assets/images/vectors/micro_macro_plan_dark.svg');
    --micro-macro-plan-active-icon: url('../assets/images/vectors/micro_macro_plan_active_dark.svg');
    --meal-time-icon: url('../assets/images/vectors/meal_time_dark.svg');
    --meal-time-active-icon: url('../assets/images/vectors/meal_time_active_dark.svg');
    --meal-reports-icon: url('../assets/images/vectors/meal_reports_dark.svg');
    --meal-reports-active-icon: url('../assets/images/vectors/meal_reports_active_dark.svg');
    --inbody-indicators-icon: url('../assets/images/vectors/inbody_indicators_dark.svg');
    --inbody-indicators-active-icon: url('../assets/images/vectors/inbody_indicators_active_dark.svg');
    --goal-icon: url('../assets/images/vectors/goal_dark.svg');
    --goal-active-icon: url('../assets/images/vectors/goal_active_dark.svg');
    --no-food: url('../assets/images/material-icons/no-food.svg');
    --no-meal-report: url('../assets/images/vectors/no_meal_report_dark.svg');
    --no-compliance-board: url('../assets/images/vectors/no-compliance-board-dark.svg');
    --no-goal: url('../assets/images/vectors/goal-empty-dark.svg');
    --no-product: url('../assets/images/vectors/empty-product-dark.svg');
    --datepicker-calendar: url('../assets/images/vectors/calendar-black.svg');
    --send-message-gif: url('../assets/images/send-message-dark.gif');
    --send-message-img: url('../assets/images/send-message-dark.svg');
    --setup-meal-plan: url('../assets/images/vectors/setup-meal-plan-dark.svg');
}
