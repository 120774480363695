.form {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 0 16px;
  margin: auto;

  @media (width <= 768px) {
    padding: 40px 16px;
  }
}

.formElements {
  max-width: 452px;
}

.terms {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.applePayButton {
  margin-top: 16px;
}

.divider {
  font: var(--body-14-medium);
  color: var(--natural-300);

  &:first-child {
    display: none;
  }
}
