.user-profile-tab {
  .app-card {
    padding: 25px;
    margin-bottom: 0;

    .sub-title {
      margin: 50px 15px 25px;
      color: var(--main-black);
      font-weight: 900;
      font-size: 22px;
      letter-spacing: -0.53px;
    }

    button[type="submit"] {
      margin-top: 52px;
    }
  }

  .inputs-wrapper {
    border-bottom: 1px solid rgba(120, 128, 162, 0.2);
    padding-bottom: 20px;
  }

  .switch-container {
    margin-top: 20px;
    margin-right: 50px;
    margin-left: 15px;

    &__label {
      font-weight: 900;
      letter-spacing: -.48px;
      font-weight: 900;
      color: var(--main-black);
      font-size: 18px;
      margin: 0 32px 0 0;
    }
  }

  .subscriptions {
    padding: 25px;

    &__title {
      letter-spacing: -.34px;
      font-size: 14px;
      font-weight: 700;
      color: var(--natural-600);

      &:not(:first-child) {
        margin-top: 50px;
      }
    }

    .list {
      &-item {
        margin-right: 20px;
        margin-top: 20px;

        .subscription-card {
          width: 320px;
          background-image: linear-gradient(-80deg, #000000 0, #A0A0A0 100%);
          box-shadow: 0 7px 22px rgba(96, 105, 157, .1);
          border-radius: 7px;
          padding: 18px;

          &__text {
            font-size: 16px;
            font-weight: 700;
            letter-spacing: -.38px;
            color: #ffffff;
            &:last-child{
              white-space: nowrap;
            }
            &.light {
              letter-spacing: -.34px;
              font-size: 14px;
              opacity: .5;
              margin: 0;
            }
          }

          &.blue {
            background-image: linear-gradient(-80deg, #E17745 0, #FFBA53 100%);
          }

          &.expired {
            background-image: linear-gradient(-84deg, #828da3 0, #51596a 100%);
          }
        }

        &__notify-text {
          font-size: 10px;
          font-weight: 700;
          letter-spacing: -.24px;
          color: var(--light_storm_grey);
          margin-top: 5px;
        }
      }
    }

    &-actions {
      margin-top: 50px;

      button {
        margin-left: 10px;
      }
    }

  }
}
