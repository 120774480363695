.container {
  padding: 0;
}

.titleContainer {
  padding: 16px 25px;
}

.line {
  width: 100%;
  height: 1px;
  background-color: var(--background-50-dark);
}

.title {
  font: var(--title-16-black);
}

.bodyContainer {
  padding: 32px 32px 36px;
}
