.box {
  margin-bottom: 20px;

  .formBox {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 50px;
    padding: 50px;
  }
}
