.leaderboard-page {
  max-width: 1376px;
  width: 100%;
  margin: 32px auto;

  .title-page {
    font-weight: 900;
    color: #000000;
    font-size: 28px;
    letter-spacing: -.67px;
    line-height: 45px;
    margin: 0;
  }

  button.outline {
    margin-left: 10px;
  }

  .list {
    margin-top: 14px;

    &-item {
      background-color: #fefefe;
      min-height: 80px;
      padding: 0 25px;

      &:not(:first-child) {
        margin-top: 8px;

        &:before {
          content: attr(data-index);
          font-weight: 900;
          min-width: 19px;
          line-height: 19px;
          height: 19px;
          border-radius: 50%;
          background-color: #ffca1d;
          color: #fff;
          font-size: 10px;
          letter-spacing: -.24px;
          text-align: center;
          margin-right: 28.5px;
          margin-left: 3.5px;
        }

        .list-item__text {
        }
      }

      &:first-child {

        .list-item__text {
          color: var(--natural-600);
        }

        .first-text {
          width: 19px;
          margin-right: 28.5px;
          margin-left: 3.5px;
          text-align: center;
        }
      }

      &.total-first-place {
        &:before {
          content: '';
          min-width: 26px;
          height: 20px;
          border-radius: 0;
          background: url("../../../assets/images/vectors/leaderboard_crown.svg") no-repeat left;
          margin-right: 25px;
          margin-left: 0;
        }
      }

      &__text {
        font-weight: 700;
        letter-spacing: -.34px;
        font-size: 14px;
        color: #000000;
        margin: 0;
        margin-right: 10px;
      }

      .filter-list-input {
        margin-right: 10px;
      }

      .with-sort {
        margin-right: 10px;
      }
    }
  }

  .with-sort {
    .list-item__text {
      margin-right: 5px;
    }

    md-icon {
      min-width: 10px;
      width: 10px;
      min-height: 10px;
      height: 10px;
      margin: 5px;
      cursor: pointer;
    }
  }
}
