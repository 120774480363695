.doubleInputBox {
  position: relative;
  padding-bottom: 24px;

  .label {
    height: 16px;
    font: var(--body-12-medium);
    color: var(--natural-600);
    letter-spacing: -0.336px;
  }

  .inputsRow {
    position: relative;
    display: flex;
    justify-content: space-between;
    font: var(--title-16-bold);
    color: var(--main-black);
    cursor: text;

    .inputsContainer {
      flex-grow: 1;
    }

    &::after {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      content: '';
      border-bottom: 1px solid var(--natural-300);
    }

    &:focus-within::after {
      border-bottom: 2px solid var(--primary-500);
    }

    div {
      max-width: calc(50% - 1em);
    }

    input {
      display: inline-block;
      max-width: 100%;
      padding: 4px 0;
      background-color: transparent;
      border: none;
    }

    input[type='number'] {
      margin-right: -16px;
      appearance: textfield;
    }

    span {
      color: var(--natural-400);
    }

    .endAdornment {
      align-self: center;
      font: var(--title-16-regular);
      color: var(--natural-400);
    }
  }

  .errorMessage {
    position: absolute;
    bottom: 0;
    left: 0;
    padding-bottom: 3px;
    font: var(--body-12-medium);
    color: var(--error-300);
    letter-spacing: -0.336px;
    opacity: 0;
    transition: 0.3s;
  }

  &[data-error='true'] {
    .label {
      color: var(--error-300);
    }

    .inputsRow::after {
      border-bottom-color: var(--error-300);
    }

    .errorMessage {
      opacity: 1;
    }

    &:focus-within {
      .label {
        color: var(--natural-600);
      }

      .inputsRow::after {
        border-bottom-color: var(--error-300);
      }
    }
  }
}
