.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.btnContainer {
  display: flex;
  gap: 12px;
}
