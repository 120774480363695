.customer-setup-step-5 {
  md-input-container {
    input[disabled] {
      border: none;
      color: #000000;
    }
  }

  .table-payment-detail {
    &__label {
      color: var(--natural-600);
      font-size: 14px;
      font-weight: 700;
      letter-spacing: -.34px;
      margin-top: 18px;
      min-height: 20px;

      &.total {
        font-weight: 900;
        font-size: 16px;
        color: var(--natural-600);
        margin-bottom: 0;
      }
    }

    &__text {
      color: var(--main-black);
      font-size: 16px;
      font-weight: 700;
      letter-spacing: -.38px;
      margin-top: 18px;

      &.total {
        font-weight: 900;
        font-size: 30px;
        color: var(--main-black);
        letter-spacing: -.72px;
        margin: 0;
      }
    }
  }

  &__submit {
    display: block;
    margin-right: 0;
    margin-left: auto;
  }

  .title-page {
    margin-bottom: 20px;
  }

  .app-card {
    &.with-error {
      position: relative;

      &:after {
        content: '';
        border-bottom: #f87d7d solid 2px;
        position: absolute;
        left: 4px;
        bottom: 0;
        right: 4px;
      }
    }

    &.inactive {
      opacity: 0.5;
    }
  }

  md-checkbox {
    span {
      opacity: 0.75;
    }
  }

  .error-message {
    color: #f87d7d;
    font-size: 11.5px;
    margin: 0 0 10px;
    font-weight: 400;
    padding-left: 30px;

    md-icon {
      color: #f87d7d;
      min-height: 14px;
      height: 14px;
      min-width: 14px;
      width: 14px;
      margin-left: -25px;
      margin-right: 11px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .info {
    margin-top: -12px;

    md-icon {
      margin: 0 15px 0 0;
      min-width: 16px;
      width: 16px;
      min-height: 16px;
      height: 16px;
    }

    &__text {
      color: #666666;
      font-size: 12px;
      font-weight: 300;
      line-height: 20px;
      letter-spacing: .17px;
      width: 589px;
      margin: 0;
    }
  }

  .outline {
    margin-right: 12px;
  }
}

