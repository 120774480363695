.table {
  th {
    padding: 8px 12px;
    font: var(--body-14-bold);
    color: var(--shades-900);
    border-top: 1px solid var(--natural-200);
    border-bottom: 1px solid var(--natural-200);
  }

  td {
    padding: 16px 12px;
    font: var(--body-14-bold);
    color: var(--shades-900);
    border-bottom: 1px solid var(--natural-200);
  }
}

.emptyContainer {
  width: 100%;
  margin: 0 auto;
}
