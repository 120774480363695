.entity-setup {
  p {
    margin: 0;
  }

  padding-bottom: 20px;
  max-width: 1053px;
  margin: 32px auto;

  .title-page {
    font-weight: 900;
    color: #000000;
    font-size: 28px;
    letter-spacing: -.67px;
    line-height: 45px;
    margin: 0;
  }

  .steps {
    padding: 18px;
    &-item {
      cursor: initial;
      &:not(:last-child) {
        &:after {
          width: 165px;
        }
      }
    }
  }
}
