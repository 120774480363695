.fileBox {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  .uploadBtn {
    input {
      display: none;
    }
  }
}
