.customerRow {
  display: grid;
  grid-template-columns: auto 1fr 150px;
  align-items: center;
  height: 96px;
  padding: 0 20px;
  margin-bottom: 8px;
  font: var(--body-14-regular);
  opacity: 0.7;

  &.checked {
    opacity: 1;
  }

  .checkbox {
    margin-right: 20px;

    svg {
      fill: var(--natural-300);
    }

    &:global(.Mui-checked) svg {
      fill: var(--main-black);
    }
  }

  .infoBox {
    display: grid;
    flex-grow: 1;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    gap: 20px;
    place-items: center center;
  }
}
