.videoBox {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 24px;
  padding: 24px 20px;

  .video {
    .imgBox {
      position: relative;
      aspect-ratio: 16/9;
      margin-bottom: 8px;
      overflow: hidden;
      cursor: pointer;
      border-radius: 8px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .hoverBg {
        position: absolute;
        inset: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgb(9 11 14 / 20%);
        opacity: 0;
        transition: opacity 0.3s;

        .playIconBox {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 52px;
          height: 52px;
          background-color: rgb(255 255 255 / 40%);
          border-radius: 50%;
        }
      }

      &:hover .hoverBg {
        opacity: 1;
      }
    }

    p {
      font: var(--body-14-bold);
    }
  }
}
