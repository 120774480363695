.cell {
  display: flex;
  align-items: center;
  padding: 0 22px;
  overflow: hidden;
  border-right: 1px solid var(--natural-200);

  &:last-of-type {
    border-right: none;
  }

  &.start {
    justify-content: flex-start;
  }

  &.center {
    justify-content: center;
  }

  &.end {
    justify-content: flex-end;
  }

  &.between {
    justify-content: space-between;
  }
}
