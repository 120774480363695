.labelBox {
  gap: 8px;
  margin: 0;

  :global(&:not(.Mui-disabled):hover .MuiSvgIcon-root) {
    fill: 'currentColor';
  }

  input[type='radio'],
  input[type='checkbox'] {
    // fix bootstrap style
    margin: 0;
  }

  :global .MuiFormControlLabel-label {
    align-self: center; // fix if icon bigger than label
    font: inherit; // font inherit from font props

    &.Mui-disabled {
      color: var(--natural-600);
    }
  }
}

// label font
.inherit {
  font: inherit;
}

.body-14-medium {
  font: var(--body-14-medium);
}

// label position
.align-center {
  align-items: center;
}

.align-start {
  align-items: start;
}

.align-end {
  align-items: end;
}
