.suggested-scenario-dialog {
  width: 500px;

  .close_icon {
    margin-right: 12px;
  }

  .meal-plan-title {
    font-size: 22px;
    font-weight: 700;
  }

  .meal-plan-data {
    font-size: 16px;
    font-weight: 700;

    &.with-margin {
      margin-left: 5px;
    }

    &.scenario {
      font-size: 18px;
    }
  }

  .scan-time {
    padding-left: 32px;
    background: url("../../assets/images/material-icons/meal-time-tab-active.svg") no-repeat left 8px;
    background-size: 20px
  }

  .meal-plan-popup {
    font-weight: 300;
    color: var(--main-black);
    font-size: 16px;
    line-height: 25px;
    opacity: 0.8;
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: 24px;
  }
}
