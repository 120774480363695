.operator-setup {
  max-width: 1053px;
  width: 100%;
  margin: 32px auto;

  .title-page {
    font-weight: 900;
    color: #000000;
    font-size: 28px;
    letter-spacing: -.67px;
    line-height: 45px;
    margin: 0 0 20px;
  }

  .search-input {
    font-weight: 700;
    letter-spacing: -0.34px;
    font-size: 15px;
    width: 100%;
    height: 42px;
    border-radius: 3px;
    border: 1px solid var(--cloud);
    padding-left: 41px;
    padding-right: 10px;
    background: url("../../../assets/images/vectors/search_icon.svg") no-repeat 15px;
    background-size: 17px 17px;
  }

  .info-message {
    font-weight: 700;
    color: #696c7e;
    font-size: 15px;
    letter-spacing: -0.34px;
    margin: 20px 0;
  }

  .dnd-container {
    padding: 0;
    max-height: 460px;

    overflow: auto;

    &::-webkit-scrollbar {
      width: 4px;
      height: 529px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 3px;
      background-color: #DADCE5;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--primary-500);
      border-radius: 3px;
      width: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: var(--warning-600);
    }

    .entity-card {
      padding: 15px;
      cursor: grab;
      border-radius: 3px;
      box-shadow: 0 7px 22px rgba(96, 105, 157, 0.1);
      height: 80px;
      margin: 10px;

      &__name {
        font-size: 16px;
        font-weight: 700;
        letter-spacing: -0.38px;
        margin: 0;
      }

      &__text {
        letter-spacing: -0.34px;
        color: var(--grey);
        margin: 0;
      }
    }
  }

  md-icon[md-svg-icon="back-forth-arrows"] {
    margin: auto 10px;
    opacity: .2;
  }

  md-icon[md-svg-icon="drag-drop-icon"] {
    margin: 0;
    opacity: .3;
  }
}


