.background {
  // display: flex; need to fix issue with margin
  display: flex;
  flex-direction: row;
  min-height: calc(100vh - var(--header-height));
  background: linear-gradient(0deg, transparent 70%, var(--main-black) 30%);

  .container {
    max-width: 713px;
    padding: 0 32px;
  }
}
