.pricesContainer {
  display: flex;
  flex-direction: row;
  gap: 8px;
  font: var(--body-14-bold);
  color: var(--main-black);
}

.oldPrice {
  color: var(--natural-600);
  text-decoration: line-through;
}

.priceBox {
  display: inline-flex;
  gap: 4px;
  align-items: center;
}

.description {
  font: var(--body-14-regular);
}
