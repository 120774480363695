.recipeInfoCell {
  display: inline-flex;
  gap: 8px;
  align-items: center;
}

.description {
  font: var(--body-12-medium);
  color: var(--natural-600);
}

.box {
  margin-bottom: 8px;

  &:has(.recipeRow:hover) {
    box-shadow: var(--shadow-main-1);
  }
}

.recipeRow {
  min-height: 94px;
  padding: 12px 20px;
  font: var(--body-14-regular);
  color: var(--main-black);

  &:hover {
    cursor: pointer;
  }
}
