.details-text, .light-text {
  @include textRegular;
  color: var(--main-black);
  font-size: 1em;
  font-weight: 700;
  letter-spacing: -0.34px;
}

.light-text {
  opacity: 0.5;
  font-weight: 400;
}

.first {
  margin-right: 8px;
}

.filter-field-container {
  width: 32%;
  margin-bottom: 16px;
}

.btn-green {
  font-size: 16px;
  width: 165px;
}

.clmn-row-container {
  max-width: 686px;

  button {
    @media all and (max-width: 920px) {
      display: block;
      width: 210px !important;
      margin: 0 auto !important;
      margin-top: 20px !important;
    }
  }
}

@media (max-width: 760px) {
  .flex-colmn {
    flex-direction: column;
    align-items: flex-start !important;
    padding-left: 5px;
  }
}

@mixin md-card {
  border-radius: 7px;
  margin: 0 0 55px 0;
  box-shadow: 0 7px 22px rgba(96, 105, 157, 0.1);
  width: calc(50% - 24px);
  md-card-title {
    padding: 13px 28px;
    border-bottom: 2px solid rgba(120, 128, 162, 0.2);

    &-text {
      color: var(--main-black);
      flex: 0 0 auto;
      @include textLatoBlack;
      font-size: 16px;
      font-weight: 900;
      letter-spacing: -0.38px;
      margin-bottom: 2px;
      margin-right: 10px;
    }
  }
  md-card-content {
    height: 300px;
  }
  @media screen and (max-width: 1290px) {
    width: 90%;
    margin: 0 auto 20px;
  }
}

.vertical-tab-panel {
  display: flex;
  flex-direction: column;

  input {
    display: none;

  }

  input:checked + &__button {
    background-color: #ffffff;
    border: 1px solid var(--natural-100);
    border-left: 5px solid var(--main-black);
    padding-left: 43px;
    color: var(--main-black);
  }

  &__button {
    background-color: var(--non-active-tab-background);
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    padding: 5px 10px 5px 48px;
    width: 197px;
    min-height: 70px;
    z-index: 75;
    color: var(--natural-600);
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.38px;
  }
}

.workout-type-count-selected {
  @include textLight;
  font-weight: 700;
  border-radius: 4px;
  background-color: var(--main-black);
  color: #fff;
  font-size: 12px;
  margin-left: 10px;
  padding: 0 3px;
  letter-spacing: 1px;
}

.quick-access {
  &-item {
    font-size: 16px;
    color: var(--santas_gray);
    cursor: pointer;
    font-weight: 700;
    letter-spacing: -0.38px;
    margin-bottom: 20px;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 280px;
    @include textRegular;

    input[type=radio] {
      -webkit-appearance: none;
      margin: 0 15px 0 0;
      cursor: pointer;
      height: 23px;
      width: 23px;
      vertical-align: bottom;
      border: 2px solid #6d8eee;
      background: transparent;
      border-radius: 50%;

      &:checked {
        border: 7px solid #6d8eee;
      }
    }

    &__text {
      line-height: 23px;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 230px;
      display: inline-block;
      vertical-align: middle;
    }

    &__button-remove {
      color: var(--primary-500);
      background-color: transparent;
      border: none;
      border-bottom: 1px solid;
      padding: 0;
      margin: 0 10px;
    }
  }
}

md-dialog[aria-label="Quick Access Name"], md-dialog[aria-label="Overwrite filter"] {
  width: 447px;

  md-dialog-content {
    padding: 24px 48px 0;

    .md-title {
      @include textRegular;
      color: var(--main-black);
      font-size: 22px;
      font-weight: 700;
      line-height: 30px;
      width: 282px;
      text-align: center;
      margin: auto;
    }

    .md-dialog-content-body {
      p {
        @include textRegular;
        color: var(--grey);
        font-size: 14px;
        font-weight: 700;
        letter-spacing: -0.34px;
        margin: 25px 2px 0;
      }

    }

    md-input-container {
      margin-left: 0;
    }
  }

  md-dialog-actions {
    justify-content: space-around;
    padding: 20px 40px 40px;

    .md-button {
      margin: 0;
      border: none;
      min-width: 130px;
      padding: 0 10px;
      height: 45px;
      border-radius: 4px;

      font-size: 16px;
      font-weight: 700;
      letter-spacing: -0.38px;
    }

    .md-cancel-button.md-primary {
      color: #000000;
      background: transparent;
      border: 2px solid rgba(178, 178, 178, 0.5);

      &:hover {
        background: rgba(127, 127, 127, 0.1);
        opacity: 1;
      }
    }

    .md-confirm-button.md-primary {
      background-color: #ff9900 !important;
      color: #ffffff;

      &:hover {
        opacity: 0.8;
      }

      &:disabled {
        opacity: 0.5;
      }
    }
  }
}
