md-backdrop {
  position: fixed;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
  .btn-blue, .title_page {
    font-weight: 500 !important;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 1) {
  .btn-blue, .title_page {
    font-weight: 500 !important;
  }
}

@media (max-width: 820px) {
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
    opacity: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield !important;
  }
}

.md-input-focused {
  label {
    margin-bottom: -3px;
  }
}

.md-input-has-value {
  label {
    margin-bottom: -3px;
  }
}

ul[dnd-list] > li.dndPlaceholder {
  border-radius: 3px;
  background-color: #f6f8f9;
}

ul[dnd-list] > li.dndDraggingSource {
  border-radius: 3px;
  background-color: #f6f8f9;
}

ul[dnd-list] > li.dndDragging {
  border-left: 6px solid #000000;
}

.btn-clmn-row-container {
  display: flex;
  justify-content: center;
  @media (max-width: 920px) {
    flex-direction: column;
  }
}

.md-open-menu-container.md-active, .md-open-menu-container {
  min-width: 146px;
  box-shadow: 0 2px 6px rgba(57, 72, 101, 0.3);
  border-radius: 7px;
  background-color: var(--white);
}

md-icon[md-svg-icon="arrow-age"] {
  min-width: 12px;
  width: 12px;
  margin: 0 12px;
  fill: var(--main-black);

  &.disabled {
    opacity: 0.3;
  }
}

.price-icon {
  @include textPurp;
  margin-top: 4px;
}

.list-group {
  position: absolute;
  z-index: 100;
  width: 200px;
  box-shadow: 0 7px 22px rgba(96, 105, 157, 0.1);
  border-radius: 8px;
  margin-top: 45px;
  padding: 16px 7px 16px;
  background-color: white;

  &.dashboard {
    position: initial;
    z-index: 0;
    border: 0.5px solid #e2e3e6;
    box-shadow: none;
    margin-top: 0;
    padding: 4px 4px 0 0;
  }

  ul {
    padding: 0 3px 0 7px;
    max-height: 232px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 4px;
      height: 529px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 3px;
      background-color: #e7e7e7;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--primary-500);
      border-radius: 3px;
      width: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: var(--warning-600);
    }
  }

  .list-group-item {
    cursor: pointer;
    margin-right: 5px;
    border-left: none;
    border-right: none;
    border-bottom: none;
    border-top: 0.5px solid #e2e3e6;
  }

  .list-group-item:first-child {
    border-top: none;
  }

  .list-group-item:hover {
    color: var(--white) !important;
    background-color: var(--primary-500) !important;
    border-radius: 4px;

    &.dashboard {
      color: var(--main-black) !important;
      background-color: white !important;
    }
  }
}

.compliance-coach-tooltip {
  position: absolute;
  filter: drop-shadow(0 7px 22px rgba(96, 105, 157, 0.2));
  margin-left: -16px;
  margin-top: -140px;

  span {
    position: fixed;
    min-width: 190px;
    width: auto;
    background-color: white;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.6);
    font-weight: 900;
    padding: 50px 8px;
    text-align: left;
    -webkit-clip-path: polygon(53% 35%, 81% 35%, 100% 35%, 100% 70%, 36% 70%, 31% 74%, 26% 70%, 0% 70%, 0% 35%, 21% 35%);
    clip-path: polygon(53% 35%, 81% 35%, 100% 35%, 100% 70%, 36% 70%, 31% 74%, 26% 70%, 0% 70%, 0% 35%, 21% 35%);
  }
}

.compliance-score-with-star {
  @include textBlack;
  font-size: 16px !important;
  font-weight: 900 !important;
  width: 200px;
  height: 21px;
  padding-left: 30px;
  margin-left: 5px;
  margin-top: 10px;
  background: url("../assets/images/vectors/favorite_active.svg") no-repeat left center;

  &.scans {
    width: auto;
    height: auto;
    margin-top: 0;
  }
}

.score-details-container {
  @include textRegular;
  opacity: 0.8;
  font-weight: 400;
  margin-top: 16px;
  margin-right: 36px;
  text-align: left;

  .score-row {
    border-bottom: 1px solid rgba(29, 31, 40, 0.2);
  }

  .score-row:last-child {
    border-bottom: none;
  }

  .score {
    font-weight: 700;
  }
}

.sign-scans {
  min-width: 18px;
  height: 18px;
  margin-left: 16px;
  margin-top: 2px;
  cursor: pointer;

  &.plus {
    background: url("../assets/images/material-icons/plus.svg") no-repeat center;
  }

  &.minus {
    background: url("../assets/images/material-icons/minus.svg") no-repeat center;
  }
}

.max-content {
  width: intrinsic; /* Safari/WebKit uses a non-standard name */
  width: -moz-max-content; /* Firefox/Gecko */
  width: -webkit-max-content; /* Chrome */
  display: -ms-grid;
  -ms-grid-columns: max-content;
}

.md-dense > .md-button:not(.md-dense-disabled), .md-dense :not(.md-dense-disabled) .md-button:not(.md-dense-disabled) {
  @include textRegular;
  background-color: transparent !important;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: -0.34px;
}

.margin-r-30 {
  margin-right: 30px !important;
  @media screen and (max-width: 1290px) {
    margin-right: 0;
  }
}

.md-open-menu-container.md-active > md-menu-content > * {
  height: 38px;
}

.media-max-width-90 {
  @media all and (max-width: 1290px) {
    width: 90%;
    margin: 0 auto 20px auto;
  }
}

.food-row .max-width-80 {
  @media all and (max-width: 950px) {
    max-width: 80%;
  }
}

.video_wrapper {
  max-width: 850px;
  height: 469px;
  width: 100%;
  margin: auto;
  position: relative;
  background-color: #000;
}

.videoIframe {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
}

.bootstrap {
  .tags {
    background-color: var(--white);
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 1px solid rgba(171, 174, 187, 0.5);
    border-radius: 0;
    box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.075);
    transition: none;

    .tag-item {
      @include textLight;
      color: var(--white);
      font-size: 14px;
      font-weight: 700;
      letter-spacing: -0.34px;
      border-radius: 12px;
      border: 1px solid #C9CBD4;
      background: #C9CBD4;

      &.proteins-tag {
        border-color: var(--protein-color);
        background: var(--proteins-color);
      }

      &.carbs-tag {
        border-color: var(--carbs-color);
        background: var(--carbs-color);
      }

      &.fats-tag {
        border-color: var(--fats-color);
        background: var(--fats-color);
      }

      &.fibers-tag {
        border-color: var(--fiber-color);
        background: var(--fiber-color);
      }

      &.fruits-tag {
        border-color: var(--veggies-color);
        background: var(--veggies-color);
      }

      &.condiments-tag {
        border-color: var(--condiments-color);
        background: var(--condiments-color);
      }
    }
  }

  .tags.focused {
    border-color: transparent;
    border-bottom: 1px solid #abaebb;
    box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.075);
  }
}

tags-input.ng-invalid .tags {
  box-shadow: 0 0 0 0 rgba(255, 0, 0, .6);
  border-color: transparent;
  border-bottom: 1px solid rgb(221, 44, 0);
}

.bootstrap .tags .tag-item.selected {
  background: #ffffff;
  border: 1px solid currentColor;
  color: #C9CBD4;

  .remove-button {
    border-left: 1px solid currentColor;
    color: #C9CBD4 !important;
  }

  &.no-tag {
    display: none;
  }

  &.proteins-tag {
    color: var(--proteins-color);

    .remove-button {
      color: var(--proteins-color) !important;
    }
  }

  &.carbs-tag {
    color: var(--carbs-color);

    .remove-button {
      color: var(--carbs-color) !important;
    }
  }

  &.fats-tag {
    color: var(--fats-color);

    .remove-button {
      color: var(--fats-color) !important;
    }
  }

  &.fibers-tag {
    color: var(--fiber-color);

    .remove-button {
      color: var(--fiber-color) !important;
    }
  }

  &.fruits-tag {
    color: var(--veggies-color);

    .remove-button {
      color: var(--veggies-color) !important;
    }
  }

  &.condiments-tag {
    color: var(--condiments-color);

    .remove-button {
      color: var(--condiments-color) !important;
    }
  }
}

.bootstrap .tags .tag-item .remove-button {
  color: var(--white) !important;
  text-decoration: none;
  padding-left: 6px;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
}

.bootstrap {
  .autocomplete {
    ::-webkit-scrollbar {
      width: 4px;
      height: 529px;
    }

    ::-webkit-scrollbar-track {
      border-radius: 3px;
      background-color: #e7e7e7;
    }

    ::-webkit-scrollbar-thumb {
      background-color: var(--primary-500);
      border-radius: 3px;
      width: 4px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background-color: var(--warning-600);
    }

    .suggestion-item {
      @include textRegular;
      color: var(--main-black);
      font-weight: 700;
      letter-spacing: -0.34px;
      background-color: white;
      height: 36px;

      img {
        border-radius: 50%;
      }

      em {
        background-color: var(--select-item-background);
        padding-top: 3px;
        padding-bottom: 3px;
        border-radius: 2px;
        color: var(--main-black);
      }
    }

    .suggestion-item.selected {
      @include textRegular;
      color: var(--main-black);
      font-weight: 700;
      letter-spacing: -0.34px;
      background-color: var(--select-item-background);
      border-radius: 2px;
      padding-right: 8px;

      em {
        background-color: var(--select-item-background);
        color: var(--primary-500);
      }
    }
  }
}

.input-label {
  @include textRegular;
  letter-spacing: -0.38px;
  font-size: 15px;
  font-weight: 500;
  color: var(--label-text);
}

.card-space {
  box-shadow: 0 7px 22px rgba(96, 105, 157, 0.1);
  border-radius: 7px;
  margin-top: 10px;
  padding: 12px 18px;

  .separ-card {
    border-bottom: 1px solid #ffffff;
    opacity: 0.2;
    margin-bottom: 12px;
  }

  &.card, &.subscription-plan {
    background-image: linear-gradient(-80deg, #87bcf4 0%, #3084ee 100%);
    margin-right: 21px;

    .settings-button {
      margin: 1px 5px 115px 240px;
      min-width: 1%;
      width: 25px;
      background: url("../assets/images/vectors/dots_settings.svg") no-repeat center;
    }

    .hologram {
      margin-top: -32px;
      margin-left: 225px;
      height: 21px;
      width: 27px;
      background: url("../assets/images/vectors/card_hologram.svg") no-repeat center;
    }

    .card-label {
      margin-top: -125px;
      margin-left: 18px;
      height: 18px;
      width: 55px;
      background: url("../assets/images/vectors/visa-label.svg") no-repeat center;
    }
  }

  &.subscription-plan {
    max-width: 350px;
    height: auto;
  }

  &.add-card-button {
    @include textRegular;
    margin: 10px 0 0 0;
    font-size: 16px;
    font-weight: 700;
    color: var(--info-300);
    letter-spacing: -0.38px;
    border: 1px solid var(--info-300);
    text-transform: capitalize;
    opacity: 0.9;
    width: 270px;
    height: 151px;
    @media screen and (max-width: 850px) {
      width: 180px;
    }
  }

  .white-text {
    @include textPurp;
    color: white !important;
    padding-top: 8px;
  }

  .white-text-transparent {
    @include textLightInCircle;
    font-size: 14px !important;
    color: white;
    opacity: 0.5;

    &.bright {
      font-size: 14px !important;
      opacity: 0.8 !important;
    }
  }

  .white-text-card {
    @include textRegular;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: -0.34px;
    color: white;
  }

  .white-text-transparent-card {
    @include textRegular;
    margin-top: 20px;
    margin-left: 23px;
    font-size: 9px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: -0.22px;
    opacity: 0.5;
    color: white;
  }

  .current-plan {
    padding-right: 12px;
    min-height: 40px;
    @media screen and (max-width: 900px) {
      margin-right: 30px;
    }
  }

  .unknown-numbers {
    background: url("../assets/images/vectors/card_number_part.svg") no-repeat center;
    margin-right: 16px;
    height: 19px;
    width: 35px;
  }
}

.notify-text {
  color: #f87d7d;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: -0.24px;
}

.custom-tag {
  @include textLight;
  width: auto;
  height: 26px;
  border-radius: 12px;
  color: var(--white);
  font-size: 14px;
  font-weight: 700;
  letter-spacing: -0.34px;
  border: 1px solid #C9CBD4;
  background: #C9CBD4;
  margin-right: 12px;
  padding: 3px 8px 3px 8px;

  &.protein {
    border-color: var(--proteins-color);
    background: var(--proteins-color);
  }

  &.carb {
    border-color: var(--carbs-color);
    background: var(--carbs-color);
  }

  &.fat {
    border-color: var(--fats-color);
    background: var(--fats-color);
  }

  &.fiber {
    border-color: var(--fiber-color);
    background: var(--fiber-color);
  }

  &.fruit {
    border-color: var(--veggies-color);
    background: var(--veggies-color);
  }

  &.condiment {
    border-color: var(--condiments-color);
    background: var(--condiments-color);
  }
}

.select-entity-container {
  position: absolute;
  z-index: 10;
  width: 310px;
  max-height: 530px;
  box-shadow: 0 7px 22px rgba(96, 105, 157, 0.15);
  border-radius: 4px;
  background-color: var(--white);
  padding: 10px 10px 10px 14px;

  .entities-list {
    @include textRegular;
    color: var(--main-black) !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    overflow-x: hidden;
    overflow-y: scroll;
    height: 430px;
    cursor: pointer;
  }

  .entity-row {
    padding: 13px 10px 20px 10px;
    margin-right: 5px;

    &.first {
      background-image: linear-gradient(95deg, #faf8fa 0%, #ffffff 100%);
      font-weight: 900 !important;
    }
  }

  .first-letter {
    opacity: 0.3;
  }

  .entity-row:hover {
    background-image: none;
    background-color: var(--warning-600);
    border-radius: 2px;
    color: white;
  }

  ::-webkit-scrollbar {
    width: 4px;
    height: 495px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 3px;
    background-color: var(--scrollbar-track);
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--primary-500);
    border-radius: 3px;
    width: 4px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: var(--warning-600);
  }
}

.entities-container {
  md-icon {
    fill: var(--primary-500);
    min-height: 36px;
    min-width: 36px;
    margin-top: 5px;
  }
}

.md-calendar-date-selection-indicator {
  border-radius: 3px;
}

.md-calendar-selected-date .md-calendar-date-selection-indicator {
  color: #ffffff !important;
}

html, body, .app-body {
  background-color: var(--app-background);
  height: 100%;
  font-family: Lato, Roboto, "Helvetica Neue", sans-serif !important;

  md-content.app-container {
    overflow: visible;
    width: 100%;
    padding: 0 32px;
    flex-grow: 1;

    @media (width < 768px) {
      padding: 0 16px;
    }
  }

  &.mobile {
    background-color: var(--white) !important;
  }

  &.for-select-in-header {
    position: static !important;
  }
}

.shape_white_shadow_3px {
  background-color: var(--white);
  border-radius: 3px;
  box-shadow: 0 7px 22px rgba(96, 105, 157, 0.1);
}

.shape_white_shadow_7px {
  background-color: var(--white);
  border-radius: 7px;
  box-shadow: 0 7px 22px rgba(96, 105, 157, 0.1);

  &.terms-and-cond {
    width: 80%;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 32px;
    padding: 32px;
  }

  .title-terms-and-cond {
    @include textLatoBlack();
    color: var(--main-black);
    font-size: 20px;
    font-weight: 900;
    letter-spacing: -0.38px;
    margin-bottom: 12px;
    margin-top: 8px;
  }

  .subtitle-terms-and-cond {
    @include textLatoBlack();
    color: var(--main-black);
    font-size: 16px;
    font-weight: 900;
    letter-spacing: -0.38px;
    margin-top: 8px;
  }

  .bold-paragraph-text {
    @include textLatoBlack();
    color: var(--main-black);
    font-size: 14px;
    font-weight: 900;
    letter-spacing: -0.38px;

    &.paragraph {
      padding-left: 16px;
      background: url("../assets/images/vectors/blue-fill-oval.svg") no-repeat 0 7px;
      margin-bottom: 12px;
    }
  }

  .maintext-terms-and-cond {
    @include textRegular;
    color: var(--main-black);
    opacity: 0.75;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: -0.34px;
    line-height: 24px;
    margin-bottom: 14px;

    &.subparagraph {
      margin-left: 22px;
      padding-left: 16px;
      background: url("../assets/images/vectors/blue-empty-oval.svg") no-repeat 0 7px;
      margin-bottom: 0;
    }
  }

  .paragraph-container {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .btn-group {
    position: absolute;
    top: 12px;
    right: 29px;

    .btn {
      @include textPurp;
      font-size: 12px;
      font-weight: 700;
      letter-spacing: -0.29px;
      color: var(--waikawa_gray);
      background-color: var(--white);
      opacity: 0.7;
      border: 1px solid var(--waikawa_gray);

      &.active {
        color: var(--white);
        background-color: var(--info-300);
        border: 1px solid var(--info-300);
      }

    }
  }
}

.date-panel {
  justify-content: flex-start;
  height: 28px;
  margin-top: 15px;
  margin-left: 20px;
  margin-right: 20px;
  @media screen and (max-width: 830px) {
    margin-left: 0;
    margin-right: 0;
  }

  .md-button {
    min-width: 25px;
    min-height: 25px;
    border-radius: 50%;
  }

  .nav-btn {
    width: 25px;
    height: 25px;
    margin-top: 2px;
    cursor: pointer;
  }

  .left {
    width: min-content;
    border-radius: 0 100px 100px 0;
    background-image: linear-gradient(to left, #efefef 0%, #ffffff 100%);
    opacity: 0.8;

    .nav-btn {
      margin-left: -20px;
      margin-right: 2px;
      background: url("../assets/images/vectors/date_panel_left_arrow.svg") no-repeat center;
    }
  }

  .right {
    width: min-content;
    border-radius: 100px 0 0 100px;
    background-image: linear-gradient(to right, #efefef 0%, #ffffff 100%);
    opacity: 0.8;

    .nav-btn {
      margin-right: 5px;
      margin-left: 2px;
      background: url("../assets/images/vectors/date_panel_right_arrow.svg") no-repeat center;
      @media screen and (max-width: 830px) {
        margin-right: 0;
      }
    }
  }

  .active-text, .non-active-text {
    @include textGrayLabel;
    color: var(--main-black) !important;
    width: intrinsic;
    width: -moz-max-content;
    width: -webkit-max-content;
    min-width: 60px;
    text-align: center;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 4px;
  }

  .active-text {
    margin-left: 15px;
    @media screen and (max-width: 830px) {
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  .non-active-text {
    color: var(--natural-600);
    margin-right: 52px;

    &.last {
      margin-right: 20px;
    }
  }
}

.view-page {


  md-select.md-default-theme .md-select-value, md-select .md-select-value {
    border: none;
    background: none;
  }
}

md-select .md-select-value {
  @include textPurp;
}

.file_upload {
  position: relative;
  overflow: hidden;
  display: inline-block;
  cursor: pointer;
}

.file_upload input[type=file] {
  position: absolute;
  left: 0;
  top: -20px;
  width: 145px;
  height: 145px;
  opacity: 0;
  cursor: pointer;
}

.dots {
  cursor: pointer;
  margin-left: 32px;
  @media screen and (max-width: 1200px) {
    margin-left: 28px;
  }
  @media screen and (max-width: 1000px) {
    margin-left: 12px;
  }
  min-width: 6px;
  min-height: 22px;
  background: url("../assets/images/vectors/dots_v_3.svg") no-repeat center;

  &.supplement {
    margin-left: 16px;
  }
}

.select-new-coach-popup {
  @include shape_white_shadow_7px;
  overflow: hidden;
  min-width: 600px;
  max-height: 520px;
  padding: 30px 36px 0 41px;

  ::-webkit-scrollbar {
    width: 4px;
    height: 529px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 3px;
    background-color: #e7e7e7;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--primary-500);
    border-radius: 3px;
    width: 4px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: var(--warning-600);
  }

  &.for-supplement {
    padding-top: 12px;
    width: 588px;

    .coach-panel {
      padding-bottom: 0;
    }
  }

  .available-coaches-container {
    max-height: 300px;
    overflow-y: auto;
    @media all and (max-width: 950px) {
      overflow-y: unset;
    }

    md-radio-button {
      margin-left: 9px;
      margin-bottom: 0;
    }
  }

  .popup-title {
    margin-bottom: 16px !important;
  }

  .horizontal-separator {
    width: 510px;
    height: 1px;
    border-bottom: 1px solid #b2b3bc;
    padding: 0;
    opacity: 1;

    &.light {
      margin-left: 7px;
      opacity: 0.5;
    }
  }

  .coach-panel {
    padding-top: 17px;
    padding-bottom: 17px;
  }

  .table-title, .table-data {
    @include textRegular;
    color: var(--main-black);
    opacity: 0.6;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: -0.34px;
    text-align: inherit;
    max-height: 20px;
    margin-top: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .checkmark {
    margin-top: -23px;
  }

  .table-title {
    margin-left: -1px;
    color: var(--natural-600);

    &.coach-name {
      margin-left: 0;
    }
  }

  .sync-btn {
    @include textRegular;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: -0.34px;
    color: #6383e4;
    background: url("../assets/images/vectors/pos_success.svg") no-repeat left 3px;
    background-size: 14px 16px;
    padding-left: 20px;
    cursor: pointer;

    div {
      width: fit-content;
      border-bottom: 1px solid rgba(99, 131, 228, 0.5);
    }

    &.in-progress {
      cursor: initial;
      color: #FAA46F;
      background: url("../assets/images/vectors/pos_progress.gif") no-repeat left 3px;
      background-size: 14px 16px;

      div {
        border-bottom: 1px solid rgba(250, 164, 111, 0.3);
      }
    }
  }

  .table-data {
    &.coach-name {
      opacity: 1;
    }

    &.fail-reason {
      padding-left: 20px;
      padding-right: 10px;
      opacity: 1;
      min-height: max-content;
      background: url("../assets/images/vectors/pos_fail_icon.svg") no-repeat left 5px;
    }

    &.coach-email {
      opacity: 0.5;
    }

    &.light {
      opacity: 0.75;
    }
  }

  .btn-green, .btn-blue {
    width: 127px;
    margin-top: 20px;
    margin-bottom: 50px;
  }

  .btn-green {
    width: 172px;
    margin-left: 19px;
  }
}

.send-message-container-popup {
  @include shape_white_shadow_7px;
  position: absolute;
  left: calc(50% - 303px);
  top: calc(50% - 260px);
  width: 606px;
  min-height: 420px;
  padding: 30px 41px 0 41px;

  .popup-title {
    margin-bottom: 0 !important;
  }

  .switch-radio {
    margin: 30px 0 59px;
  }

  .btn-green, .btn-blue {
    width: 164px;
    margin-top: 0;
    margin-bottom: 37px;
  }

  .full-input {
    margin: 15px 0 20px 0;
  }

  .user-group {
    max-height: 50px;


    .btn-blue {
      height: 27px;
      width: 89px;
      border: 2px solid rgba(var(--main-button-border-rgb), 0.4);
      font-size: 14px;
      text-align: center;
      padding-top: 3px;
      margin-top: 12px;

      &.activate {
        border: 2px solid rgba(var(--default-rgb), 0.4);
        background-color: var(--primary-500);
        color: var(--white) !important;
      }

      &.activate:hover {
        opacity: 0.8;
        background-color: var(--primary-500) !important;
        color: var(--white) !important;
      }
    }
  }

  .btn-blue {
    margin-right: 13px;
  }

}

.details-container {
  max-width: 350px;
  padding: 0 32px 28px 32px;
  text-align: center;

  .popup-title {
    margin-top: 32px;
  }

  &__btn {
    width: 100%;
    padding: 0;
  }
}

.view-scan-data-container {
  padding: 0 48px 54px 48px;
  overflow: auto;
  min-height: 427px;

  .popup-title {
    @include textLatoBlack();
    font-weight: 700;
    font-size: 22px;
    text-align: center;
    margin-bottom: 22px !important;
    margin-top: 0 !important;
  }

  .table-border {
    border: 0.5px solid #d7d9e4;
    padding: 0;
  }

  .close_icon {
    margin-right: -29px;
  }

  .scan-data-value {
    @include textLatoBlack();
    color: var(--primary-500);
    width: 122px;
    padding-left: 40px;
  }

  .scan-data-label {
    @include textLatoBlack();
    color: var(--main-black);
    width: 208px;
    padding-left: 27px;
  }

  .scan-data-value, .scan-data-label {
    opacity: 0.8;
    font-size: 14px;
    font-weight: 900;
    letter-spacing: -0.34px;
    line-height: 37px;
    border: 0.5px solid #d7d9e4;

    .scan-date {
      font-weight: 400;
      margin: -6px 0 0 0;
      opacity: 0.6;
    }
  }
}

.select-ingredient-container {

  .add-food-total-goal {
    width: auto;

    .all-food-empty {
      min-height: 412px !important;
      max-height: 412px !important;
    }

    .meal-details-text {
      color: #7f7f7f;
      font-weight: 500;
    }
  }

  .all-food-content {
    max-height: 430px !important;
    min-height: 430px !important;
  }

  .all-food-list {
    max-height: 430px !important;
    min-height: 430px !important;
  }

  .close_icon {
    margin-right: 12px;
  }

  .all-food-search, .all-food-content {
    max-width: 471px;
    min-width: 282px;
  }

  .popup-title {
    text-align: center;
    margin: 0 !important;
  }

  .switch-radio {
    .food {
      width: 157px;
      text-align: center;
    }
  }
}

.save-score-dialog {
  width: 908px;
  padding: 33px 102px 45px 85px;
}

.saved-changes-dialog {
  width: auto;
  height: auto;
  box-shadow: 0 7px 22px rgba(96, 105, 157, 0.1);
  border-radius: 7px;
  text-align: center;
  padding-top: 16px;
  padding-right: 16px;

  .popup-title {
    @include textRegular;
    color: var(--main-black);
    font-size: 22px;
    font-weight: 900;
    line-height: 30px;
    width: 230px;
    margin: 16px 48px 32px 48px;
  }

  .close_icon {
    background: url("../assets/images/vectors/popup_close_icon.svg") no-repeat center;
    height: 17px;
    width: 18px;
    margin-top: 0;
    margin-right: -5px;
    margin-left: auto;
    cursor: pointer;
  }
}

.date-arrow {
  background: url("../assets/images/vectors/arrow_right.png") no-repeat center;
  height: 8px;
  width: 14px;
  margin-top: 22px;

  &.mobile {
    height: 10px;
    background: url("../assets/images/vectors/mobile_daterange.svg") no-repeat center;
  }
}

.filter-input {
  font-size: 14px;
  font-weight: 700;
  letter-spacing: -0.34px;
  border: 1px solid #C8C9D0;
  border-radius: 3px;
  height: 43px;
  padding-left: 15px;
}

.empty-search {
  position: relative;
  min-height: 400px;
  width: 100%;

  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &.leaderbord {
      top: 40%;
    }

    .nothing-found-img {
      background: var(--no-meal-report) no-repeat center;
      background-size: 153px 148px;
      width: 153px;
      height: 148px;
    }

    .nothing-found-food-img {
      background: var(--no-food) no-repeat right;
      background-size: 182px 146px;
      width: 189px;
      height: 146px;
    }

    .nothing-found-text {
      display: block;
      padding: 16px;
      text-align: center;
      color: var(--natural-600);
      font-family: "SF UI Display", sans-serif;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0.09px;

      &.small {
        @include textRegular;
        font-size: 14px !important;
        padding-right: 0;
      }
    }
  }
}

.container_checkbox {
  @include textPurp;
  cursor: pointer;
  display: block;
  position: relative;
  padding-left: 37px;
  margin-top: 26px;
  margin-bottom: 16px;

  &.first-ratio {
    margin-right: 75px;
  }

  &.popup {
    margin-bottom: 0 !important;
  }
}

.container_checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 23px;
  width: 23px;
  border-radius: 50%;
  border: 2px solid var(--info-300);
}

.container_checkbox input:checked ~ .checkmark {
  border: 2px solid var(--info-300);
  background-color: var(--info-300);
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container_checkbox input:checked ~ .checkmark:after {
  display: block;
}

.container_checkbox .checkmark:after {
  top: 5px;
  left: 5px;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: var(--white);
}

.panel-title {
  @include textPureHeavy;
  padding-left: 29px;
  padding-top: 14px !important;

  &.sales {
    padding-top: 8px !important;

    &.mobile {
      padding-left: 12px;
    }
  }

  &.corporate {
    padding: 34px 0 0 0 !important;
  }

  &.non-active {
    font-weight: 400;
    color: var(--light_storm_grey);
  }

  &.meal-time {
    @include textRegular;
    font-size: 14px !important;
    font-weight: 500 !important;
    letter-spacing: 0.2px !important;
    color: var(--waikawa_gray) !important;
    opacity: 0.9;
    margin: 0;
    padding-left: 18px;
    background: url("../assets/images/vectors/meal-info.svg") no-repeat left 18px;
    background-size: 11px 12px;
  }

  &.subscriptions {
    background-color: #f8f8fa;
    padding-bottom: 21px;
    border-radius: 7px 7px 0 0;
    border: 1px solid #dadeef;
  }

  &.with-background {
    background-color: #f8f8fa;
    border-bottom: 1px solid #dadeef;
    border-top: 1px solid #dadeef;
    padding-bottom: 17px;
    margin: 60px -17px 24px -17px;

    &.first {
      margin-top: 24px;
    }
  }
}

.search-container {
  margin: 25px 24px 19px 24px;

  &.dashboard {
    margin: 0 0 18px 0;

    .search-inner-page {
      padding-left: 15px;
      background: url("../assets/images/vectors/search_icon.svg") no-repeat 255px;
      background-size: 17px 17px;
    }
  }

  .search-inner-page {
    @include textLightInCircle;
    color: var(--main-black);
    font-size: 15px;
    max-width: 462px;
    width: 100%;
    height: 42px;
    border-radius: 3px;
    border: 1px solid var(--cloud);
    padding-left: 41px;
    padding-right: 10px;
    background: url("../assets/images/vectors/search_icon.svg") no-repeat 15px;
    background-size: 17px 17px;
  }

  ::-webkit-input-placeholder {
    color: var(--grey);
    letter-spacing: -0.34px;
  }

  ::-moz-placeholder {
    color: var(--grey);
    letter-spacing: -0.34px;
  }

  :-ms-input-placeholder {
    color: var(--grey);
    letter-spacing: -0.34px;
  }

  :-moz-placeholder {
    color: var(--grey);
    letter-spacing: -0.34px;
  }
}

.md-select-value > span:not(.md-select-icon) .md-text {
  @include textPurp;
}

.close_icon {
  background: url("../assets/images/vectors/popup_close_icon.svg") no-repeat center;
  height: 18px;
  width: 18px;
  margin-top: 20px;
  margin-right: -5px;
  margin-left: auto;
  cursor: pointer;

  &.goal {
    margin-right: 20px;
  }
}

.vertical-line {
  border: 0.5px solid var(--lynch);
  height: 40px;
  opacity: 0.1;
  margin-left: 46px;
  margin-right: 46px;
  margin-top: 4px;
  @media screen and (max-width: 1024px) {
    margin-left: 35px;
    margin-right: 35px;
  }
  @media screen and (max-width: 980px) {
    margin-left: 20px;
    margin-right: 20px;
  }


  &.workouts {
    margin: 0;
    padding: 0;
    height: auto;
  }

  &.small {
    height: 12px;
    border: 1px solid #8790a1;
    opacity: 0.3;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 7px;
  }

  &.dashboard-charts {
    height: 253px;
    width: 1px;
    border: 0.5px solid var(--athens_gray);
    margin-left: 26px;
    margin-right: 20px;
    opacity: 1;
  }
}

.result {
  @include textGreyLabel;
  font-size: 12px;
  padding-right: 12px;
  height: 14px;
  margin-left: 8px;
  margin-top: 2px;

  &.dashboard {
    margin-top: 12px;
    padding-left: 12px;

    &.down {
      color: #f66464;
      background: url("../assets/images/vectors/red_down_arrow.svg") no-repeat left bottom;
    }

    &.up {
      color: var(--success-300);
      background: url("../assets/images/vectors/green_up_arrow.svg") no-repeat left bottom;
    }
  }

  &.down {
    color: var(--success-300);
    background: url("../assets/images/vectors/green_down_arrow.svg") no-repeat right bottom;
  }

  &.up {
    color: #f66464;
    background: url("../assets/images/vectors/red_up_arrow.svg") no-repeat right bottom;

    &:before {
      content: '-';
    }
  }

  &.lbm-up {
    color: var(--success-300);
    background: url("../assets/images/vectors/green_up_arrow.svg") no-repeat right bottom;

    &:before {
      content: '-';
    }
  }

  &.lbm-down {
    color: #f66464;
    background: url("../assets/images/vectors/red_down_arrow.svg") no-repeat right bottom;
  }

  &.weight-up {
    background: url("../assets/images/vectors/grey_up_arrow.svg") no-repeat right bottom;

    &:before {
      content: '-';
    }
  }

  &.weight-down {
    background: url("../assets/images/vectors/grey_down_arrow.svg") no-repeat right bottom;
  }
}

.vertical-tab-panel {
  display: inline-block;
  background-color: rgba(var(--tabs-background-rgb), 0.3);
  max-width: 276px;
  margin-right: 11px;

  @media screen and (max-width: 1100px) {
    max-width: 210px;
  }
  @media screen and (max-width: 925px) {
    margin-left: 4px;
    margin-right: 4px;
  }
  @media screen and (max-width: 900px) {
    max-width: 150px;
  }

  &.nav-vertical {
    height: fit-content;
    padding-bottom: 26px;

    input {
      display: none;
    }

    input:checked + label {
      padding-left: 27px;
      background-color: var(--app-background);
      border-left: 6px solid var(--main-black);
      opacity: 1;
      @media screen and (max-width: 830px) {
        padding-left: 11px;
      }
    }

    label {
      @include textRegular;
      font-size: 18px;
      font-weight: 700;
      letter-spacing: -0.43px;
      min-height: 70px;
      width: 275px;
      padding-left: 35px;
      padding-top: 24px;
      border-bottom: 2px solid var(--left-panel-border);
      color: var(--main-black);
      opacity: 0.6;
      margin-bottom: 0;
      @media screen and (max-width: 1000px) {
        font-size: 15px;
      }
      @media screen and (max-width: 830px) {
        padding-left: 19px;
      }
    }

    label:hover {
      opacity: 0.8;
      cursor: pointer;
    }

    &.meal-tab {
      input:checked + label {
        padding-left: 17px;
      }

      label {
        padding-left: 22px;
        padding-top: 16px;
      }

      .completed {
        opacity: 0.6;
        background: url("../assets/images/vectors/completed_icon.svg") no-repeat 239px 17px;
      }

      .active {
        display: flex;
      }

      .non-active {
        display: none;
      }
    }
  }
}

.custom-layout-row {
  width: 100%;
  justify-content: space-between;


  &.leaderboard {
    justify-content: left;
  }

  .skipped-meal {
    @include textRegular;
    font-size: 16px;
    font-weight: 700;
    padding-left: 38px;
    margin-right: 54px;
    margin-top: 5px;
    width: 38px;
    height: 25px;
    background: url("../assets/images/vectors/skipped-button.svg") center 0;
  }

  .non-active-time {
    opacity: 0.5;
  }
}

.block-orientation {
  @media screen and (max-width: 1210px) {
    display: block !important;
  }
}

.inbody-details-panel .custom-layout-row {
  padding-right: 0;
}

.paginate-btns {
  width: 100%;
  justify-content: center;
  padding-bottom: 32px;

  &.custom-list {
    margin-top: 32px;
    justify-content: left;

    .page-number {
      width: 37px;
      height: 37px;
      border-radius: 4px;
      color: var(--main-black);
      background-color: transparent;
      margin-right: 8px;
      padding-top: 6px;
      text-align: center;
      cursor: pointer;

      &.active {
        color: white;
        background-color: var(--primary-500);
      }
    }
  }
}

.more-btn-dashboard {
  @include textLatoBlack();
  text-transform: initial;
  font-size: 16px;
  font-weight: 900;
  letter-spacing: -0.67px;
  color: var(--primary-500);
  width: auto;
  margin-top: 24px;
  margin-right: 10px;
  margin-left: 10px;
  opacity: 0.8;
  cursor: pointer;

}

.google-play {
  width: 154px;
  height: 53px;
  background-size: 154px 53px;
  background: url("../assets/images/vectors/google-play-badge.svg") no-repeat center;
}

.app-store {
  width: 154px;
  height: 53px;
  background-size: 154px 53px;
  margin-right: 20px;
  background: url("../assets/images/vectors/app-store-badge.svg") no-repeat center;
}

.sharing-container {
  padding: 38px 0 52px 0;

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 3px;
    background-color: #DADCE5;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--primary-500);
    border-radius: 3px;
    width: 4px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: var(--warning-600);
  }

  .meal-name, .meal-time {
    @include textBlackBold;
    color: var(--info-300) !important;
    font-size: 36px !important;

    &.mobile {
      font-size: 16px !important;
    }
  }

  .meal-time {
    padding-top: 12px;
    font-size: 24px !important;
    border-left: 1px solid #BEC0CB;

    &.mobile {
      padding-top: 2px;
      margin-left: 10px;
      font-size: 14px !important;
    }
  }

  .white-background-popup {
    width: auto;
    min-width: 203px;
    height: 462px;
    background-color: white;
    margin-top: -13px;

    &.mobile {
      height: max-content;
    }
  }

  .meal-header {
    position: relative;
    top: 29px;
    z-index: 100;
    min-height: 88px;
    width: auto;
    box-shadow: 0 7px 21px rgba(96, 105, 157, 0.19);
    border-radius: 4px 3px 3px 4px;
    background-color: var(--white);
    margin: 0 20px 0 20px;
    padding: 15px 80px 26px 30px;

    .header-meal-name {
      @include textBlackBold;
      font-size: 18px !important;
      color: var(--info-300) !important;
      font-weight: 700 !important;

      &.small {
        padding-top: 4px;
        font-size: 14px !important;
        border: none;
      }
    }

    .header-vertical-separ {
      margin: 8px 10px 0 10px !important;
      border-right: 1.5px solid rgba(135, 144, 161, 0.3);
      height: 11px;
      width: 1.5px;
    }
  }

  .meal-menu-container {
    overflow-y: hidden;
    position: relative;
    top: 13px;
    box-shadow: 0 2px 7px rgba(57, 70, 113, 0.07);
    border-radius: 4px 3px 3px 4px;
    background-color: rgba(214, 215, 222, 0.1);
    margin: 13px 20px 17px 20px;
    padding: 36px 15px 29px 11px;
    width: auto;
    min-height: 428px;
    max-height: 428px;

    &.mobile {
      max-height: max-content;
    }

    .menu-total {
      padding: 16px 8px 0 16px;

      .differences {
        .down {
          padding-left: 8px;
          color: #f66464;
          font-size: 12px;
          background: url("../assets/images/vectors/red_down_arrow.svg") no-repeat left center;
          background-size: 6px 4px;
        }

        .up {
          padding-left: 8px;
          color: var(--success-300);
          font-size: 12px;
          background: url("../assets/images/vectors/green_up_arrow.svg") no-repeat left center;
          background-size: 6px 4px;
        }
      }

      .total-header-text {
        @include textRegular;
        color: #7f8398 !important;
        font-size: 12px !important;
        font-weight: 700 !important;
        opacity: 0.8;
      }

      .total-main-text {
        @include textBlackBold;
        color: var(--info-300) !important;
        font-size: 14px !important;
        font-weight: 700 !important;
        letter-spacing: 0.07px !important;
      }

      .short-over-text {
        @include textLight;
        color: #7f8398 !important;
        opacity: 0.8;
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 0.06px;
        margin-bottom: 12px;

        .short {
          color: var(--success-300) !important;
        }

        .over {
          color: #ff6a68 !important;
        }
      }
    }

    .menu-meal {
      padding: 11px;
      border-bottom: 1px solid rgba(153, 156, 173, 0.3);
    }

    .menu-meal-name, .menu-meal-amount {
      @include textRegular;
      color: var(--info-300) !important;
      font-size: 14px !important;
      font-weight: 700 !important;
    }

    .menu-meal-amount {
      color: #7f8398;
      opacity: 0.6;
    }
  }

  .meal-menu-container:hover {
    overflow-y: scroll;
    padding-right: 11px;
  }

  .full-star, .half-full-star, .empty-star {
    height: 17px;
    width: 19px;
    margin-right: 2px;
    margin-left: 2px;
  }

  .full-star {
    background: url("../assets/images/vectors/score-star.svg") no-repeat center;

    &.dark {
      background: url("../assets/images/vectors/score-full-dark-star.svg") no-repeat center;
    }
  }

  .half-full-star {
    background: url("../assets/images/vectors/score-half-star.svg") no-repeat center;

    &.dark {
      background: url("../assets/images/vectors/score-half-dark-star.svg") no-repeat center;
    }
  }

  .empty-star {
    background: url("../assets/images/vectors/score-empty-star.svg") no-repeat center;

    &.dark {
      background: url("../assets/images/vectors/score-empty-dark-star.svg") no-repeat center;
    }
  }

  .food-image-container {

    img {
      width: 100%;
    }

    &.desktop {
      overflow-y: hidden;
      max-height: 540px;
      margin-top: 38px;
      padding-right: 4px;
    }
  }

  .sharing-default-image {
    width: 100%;
    height: 221px;
    box-shadow: 0 7px 22px rgba(96, 105, 157, 0.1);
    background: white url("../assets/images/vectors/sharing_default_image.svg") no-repeat center center;
  }

  .food-image-container.desktop:hover {
    overflow-y: scroll;
    padding-right: 0;
  }

  .main-score-container {
    width: auto;
    min-width: 203px;
    height: 540px;
    background-color: var(--info-300);
    margin-top: 38px;
    box-shadow: 0 7px 22px rgba(96, 105, 157, 0.1);
    padding: 0;

    &.meals {
      height: 78px;
    }

    &.mobile {
      margin-top: 5px;
    }

    .popup-container {
      overflow-y: hidden;
      max-height: 449px;
      position: relative;
      top: 60px;
      z-index: 100;
      margin: 0 32px 60px 32px;
      padding: 35px 25px 54px 25px;
      box-shadow: 0 24px 24px rgba(0, 0, 0, 0.3), 0 0 24px rgba(0, 0, 0, 0.22);
      border-radius: 6px;
      background-color: var(--white);
      text-align: center;

      .emoji-container {
        width: 120px;
        height: 120px;
        position: relative;
      }

      .score-popup-main-text {
        @include textBlackBold;
        color: var(--info-300) !important;
        font-size: 20px !important;
        font-weight: 700 !important;
        margin-top: 29px;
      }

      .score-popup-text {
        @include textRegular;
        color: var(--info-300) !important;
        font-size: 14px !important;
        font-weight: 400 !important;
        margin-top: 8px;

        &.light {
          opacity: 0.5;

          &.with-stars {
            margin-top: 0;
          }
        }

        &.bold {
          color: black;
          opacity: 0.87%;
          font-weight: 700 !important;
        }
      }

      .popup-separ {
        height: 1px;
        border-bottom: 1px solid var(--info-300);
        opacity: 0.1;
        margin-top: 23px;
        margin-bottom: 10px;
      }
    }
  }
}

.user-group-input {
  height: 14px;
  width: 14px;
  position: absolute;
  z-index: 100;
  right: 16px;
  top: 5px;
  cursor: pointer;
  background: url("../assets/images/vectors/remove-autocomplete.svg") no-repeat center;
}

@media (max-width: 860px) {
  .full-width-entity-shape.less-width-80 {
    width: 80% !important;
  }
}

.user-details-panel {
  margin-top: 38px;
  position: relative;
  height: max-content;
  justify-content: space-between;

  &.custom-layout-row {
    margin-bottom: 32px;
  }

  &.white-background {
    margin-top: -22px;
    padding: 38px 0 38px 0;
    background-color: white;
  }

  .text-scan-date {
    @include textRegular;
    font-size: 14px;
    font-weight: 700;
    color: var(--natural-600);
    letter-spacing: -0.34px;
  }

  .text-blue-link {
    @include textLightInCircle;
    color: var(--primary-500);
    border-bottom: 1px solid rgba(var(--default-rgb), 0.3);

    &.change-avatar {
      width: fit-content;
      cursor: pointer;
      margin-left: 21px;
    }

    &.log-out-text {
      min-width: 45px;
      margin-top: -5px;
      margin-right: 51px;
      cursor: pointer;
    }
  }

  .log-out {
    background-size: 19px 14px;
    height: 14px;
    width: 19px;
    background: url("../assets/images/vectors/log-out-icon.svg") no-repeat;
    margin-right: 7px;
  }

  .user-container {
    height: max-content;
    position: relative;

    @media (max-width: 922px) {
      flex-direction: column;
    }

    &.entity {
      height: 62px;
      width: 100%;
      padding-right: 20px;

      .textBlackBold {
        .qty-count {
          color: var(--main-black);
        }
      }
    }

    .avatar {
      position: relative;
      margin-left: 42px;
      @include textLatoBlack();
      color: var(--main-black);
      font-size: 32px;
      font-weight: 900;
      letter-spacing: -0.76px;

      @media (max-width: 922px) {
        margin-right: 30px;
      }

      img {
        border-radius: 50%;
        width: 64px;
        height: 64px;
        object-fit: cover;
      }

      &.sharing {
        margin-left: 0;
      }

      .score-value {
        @include textRegular;
        color: var(--main-black);
        font-size: 16px;
        background: url("../assets/images/vectors/score-star.svg") no-repeat center;
        background-size: 14px 13px;
        width: 30px;
        height: 22px;
        padding-left: 26px;
      }

      .circle-avatar {
        text-transform: uppercase;
        padding-top: 8px;
        position: absolute;
        width: 64px;
        height: 64px;
        text-align: center;
        background-size: 64px 64px;
        border-radius: 50%;
        background-color: var(--profile-avatar-background);

        @media (max-width: 922px) {
          & {
            transform: translateY(-15%);
          }
        }

        &.user {
          position: initial;
        }

        &.sharing {
          width: 72px;
          height: 72px;
          background-size: 72px 72px;
          padding-top: 12px;
        }

        &.mobile {
          margin-top: -10px;
        }
      }

      .user-description {
        margin-left: 110px;

        &.mobile {
          margin-left: 90px;
        }
      }

      .btn-green {
        font-size: 16px;
        position: absolute;
        width: 165px;
        height: 46px;
        right: 27px;
        top: 5px;
      }

      .btn-blue {
        font-size: 16px;
        position: absolute;
        width: 165px;
        height: 46px;
        right: 213px;
        top: 5px;
      }

      .textBlackBold {
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 700px;
        height: auto;
        margin-left: 21px;
        margin-bottom: 0;

        @media (max-width: 860px) {
          max-width: 256px;
        }
      }

      .content-width {
        min-width: 200px;
      }
    }

    @media (max-width: 922px) {
      .break-to-secondline {
        margin-top: 30px;
        padding: 0 40px;
        justify-content: space-between;
      }
    }

    .deactivated {
      padding: 0 4px 0 4px;
      height: 17px;
      margin-left: 5px;
      background: rgba(255, 108, 107, 0.4);
      color: #ff6c6b;
      border-radius: 3px;
      font-size: 12px;
      letter-spacing: normal;
    }

    .corporate {
      color: var(--primary-500);
      background: rgba(var(--default-rgb), 0.2);
      padding: 0 4px;
      height: 17px;
      font-size: 12px;
      margin-left: 5px;
      border-radius: 3px;
      letter-spacing: normal;
    }
  }
}

.md-select-menu-container {
  z-index: 92;
}

.md-scroll-mask {
  position: fixed;
  height: 100vh;
  //z-index: 90;
}

.addQuickFoodManually-container {
  max-width: 450px;
  max-height: 660px;
  min-height: 660px;
  width: 100%;
  padding: 50px;

  @media (max-height: 900px) {
    max-height: 75%;
    min-height: 75%;
  }

  form {
    min-height: 450px;
  }

  &.favorite {
    label {
      opacity: 1;
    }

    .checkmark {
      opacity: 1;
      border: 1px solid #717b90;
    }

    .container_checkbox input:checked ~ .checkmark {
      border: none;
      background: url("../assets/images/vectors/ratio-for-popup.svg") no-repeat center;
    }

    .container_checkbox .checkmark:after {
      background-color: transparent;
    }
  }

  &::-webkit-scrollbar {
    width: 4px;
    height: 529px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 3px;
    background-color: #e7e7e7;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--primary-500);
    border-radius: 3px;
    width: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: var(--warning-600);
  }


  h4.popup-title {
    text-align: center;
    margin-top: 0;
    color: var(--main-black);
    font-family: "SF UI Display", sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 35px;
  }

  .full-row-input {
    margin: 5px 0 0 0;
    width: 100%;
  }

  md-select {
    margin-bottom: 25px;
  }
}

.md-dialog-container {
  position: fixed;
  top: 0 !important;

  .btn-blue, .btn-green {
    width: 152px;
  }

  .scenario-label {
    font-size: 14px;
    margin-right: 3px;
  }

  .popup-main-text {
    @include textRegular;
    font-size: 16px;
    color: var(--main-black);
    opacity: 0.8;
    font-weight: 400;
    margin-top: -16px;
    margin-bottom: 36px;

    p {
      margin: 0;
    }
  }

  .popup-title {
    @include textRegular;
    font-size: 22px;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 39px;

    &.send-message {
      @include textBlack;;
      color: var(--main-black) !important;
      font-weight: 900 !important;
      font-size: 22px !important;
      letter-spacing: -0.53px;
    }
  }

  &.meal-plan-container {
    width: 574px;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);

    .close_icon {
      margin-right: 12px;
    }

    .meal-plan-title {
      @include textRegular;
      font-size: 22px !important;
      font-weight: 700 !important;
    }

    .meal-plan-data {
      @include textRegular;
      font-size: 16px !important;
      font-weight: 700 !important;

      &.with-margin {
        margin-left: 5px;
      }

      &.scenario {
        font-size: 18px !important;
      }
    }

    .scan-time {
      padding-left: 32px;
      background: var(--meal-time-active-icon) no-repeat left 8px;
    }

    .btn-green {
      width: 190px;
      margin-top: 10px;
      margin-bottom: 20px;

      &.review-anyway {
        width: 152px;
        margin-left: 20px;
        margin-top: 0;
      }
    }

    .meal-plan-illustration, .setup-meal-plan-illustration {
      background: url("../assets/images/vectors/change-meal-plan-pop-up-icon.svg") no-repeat center;
      height: 196px;
      width: 196px;
      margin-top: 20px;
      margin-left: 22%;
      margin-bottom: 16px;
    }

    .setup-meal-plan-illustration {
      background: var(--setup-meal-plan) no-repeat center;
      height: 161px;
      width: 200px;
    }

    .meal-plan-popup-title {
      @include textLight;
      font-size: 22px;
      font-weight: 700;
      line-height: 30px;
      margin-bottom: 0;

      &.small {
        font-size: 14px;
      }
    }

    .meal-plan-popup {
      @include textLight;
      color: var(--main-black);
      font-size: 16px;
      font-weight: 400;
      line-height: 25px;
      opacity: 0.8;
      margin-left: 50px;
      margin-right: 50px;
      margin-bottom: 24px;

      .scroll-scenario-container {
        overflow-y: auto;
      }
    }
  }
}

.current-page-container {
  width: 160px;
  justify-content: space-between;
  margin-top: 24px;
  margin-left: 32px;
  margin-bottom: 16px;

  md-icon[md-svg-icon="arrow-age"] {
    min-width: 24px;
    width: 24px;
  }
}

.page-number {
  @include textBlack;
  font-size: 18px;
  font-weight: 900;
}

.white-shape {
  display: inline-block;
  width: 100%;
  margin-right: 0 !important;
  border-radius: 7px;
  background-color: #ffffff;
  box-shadow: var(--panel-shadow);

  &.title-profile {
    margin-bottom: 16px;
    padding-bottom: 60px;

    .full-name {
      @include textBlackBold;
      margin-left: 84px;
      padding-top: 10px;
    }
  }

  &.discount {
    margin-top: 16px;
  }

  .text-info {
    @include textInfo;
    width: 174px;
    margin-left: 7px;

    &.coach-setup {
      width: 589px;
      margin-bottom: 27px;
      padding-left: 32px;
      background: url("../assets/images/vectors/warning.svg") no-repeat left center;
    }
  }

  &.leaderboard {
    margin-right: 25px !important;
    position: relative;
  }

  @media screen and (max-width: 1135px) {
    margin-right: 0;
  }

  .profile-fields {
    padding-right: 48px;
    padding-left: 44px;
    @media screen and (max-width: 950px) {
      padding-right: 10px;
    }

    &__inbody-description {
      color: var(--natural-600);
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0.17px;
      width: 174px;

      &:before {
        content: '';
        background: url("../assets/images/vectors/warning.svg") no-repeat 1px center;
        height: 13px;
        min-width: 16px;
        margin-left: 33px;
        margin-right: 7px;
        margin-top: 3px;
      }
    }

    &-header {
      padding-top: 30px;
    }

    .btn-deactivate {
      width: 151px;
      height: 45px;
      border-radius: 4px;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: -0.38px;
      margin: 0 7px 10px;
      border: 2px solid rgba(var(--main-button-border-rgb), 0.4);
      color: var(--main-black);
      background: #ffffff;

      &:hover {
        opacity: 0.8;
        background-color: var(--main-border-button-hover);
      }

      &--active {
        border-color: rgba(255, 108, 107, 0.4);
        color: #ff6c6b;

        &:hover {
          background-color: rgba(255, 108, 107, 0.2);
        }
      }
    }
  }


  .btn-green {
    width: 171px;
    height: 45px;
    margin: 32px 67px 53px 0;

    &.profile {
      margin: 0 16px;
      min-width: 171px;
    }
  }
}

/*HORIZON SEPARATOR*/
.separ_horizon_grey {
  opacity: 0.2;
  height: 0;
  border: 1px solid var(--lynch);
  margin-top: 21px;

  &.sales {
    border: 0.5px solid var(--lynch);
  }

  &.mobile {
    margin-top: 0;
  }
}

.horizontal-line {
  border: 1px solid #e5e6e9;

  &.reports {
    margin-top: 35px;
  }
}

/*BUTTON*/
.btn-green {
  @include textBold;
  color: #fff !important;
  border-radius: 4px;
  opacity: 1;
  background-color: var(--primary-500) !important;
  height: 46px;
  line-height: 46px;
  font-size: 16px;
  letter-spacing: -0.38px;
  margin: 0;
  text-align: center;
  text-transform: initial;

  &:hover {
    background-color: var(--primary-500) !important;
    opacity: 0.8 !important;
  }

  &[disabled] {
    opacity: 0.5;

    &:hover {
      background-color: var(--primary-500) !important;
      opacity: 0.5 !important;
    }
  }

  &.change-meal-plan {
    width: 191px;
    margin-top: 40px;
  }

  &.myNetDiary {
    height: 28px;
    min-width: 65px;
    max-width: 65px;
    margin-top: -5px;
    line-height: 20px;
    margin-left: 0 !important;
  }

  &.reset-password {
    margin-top: 93px;
    margin-left: auto;
    margin-right: auto;
  }

  &.payment {
    margin-bottom: 0;
    margin-right: 72px;
  }

  &.add-note {
    margin: 0 16px 0 0;
    width: 145px;
  }
}

.edit-workout-days-container {
  width: auto;
  height: auto;
  padding: 21px 31px 53px 31px;

  .explanation-text {
    color: var(--natural-600);
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.34px;
    text-align: center;
    margin-top: -50px;
  }

  .workout-days {
    margin-top: 24px;
    margin-bottom: 36px;

    md-checkbox {
      margin: 14px;
    }
  }

  .popup-title {
    text-align: center;
    margin-bottom: 36px;
  }

  .label-text {
    padding-left: 40px;
  }
}

.meal-day-container {
  width: 100%;
  max-width: 552px;
  min-height: 346px;
  box-shadow: 0 7px 22px rgba(96, 105, 157, 0.1);
  border-radius: 7px;
  background-color: #ffffff;
  padding: 20px 56px 49px 40px;

  .popup-title-table {
    color: var(--natural-600);
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.34px;
  }

  .btn-blue, .btn-green {
    width: 152px;
    margin-top: 55px;
  }

  .btn-green {
    margin-left: 15px;
  }

  .popup-title {
    text-align: center;
    margin: 0;
  }
}

.title-text {
  @include textRegular;
  color: var(--natural-600);
  font-size: 14px;
  font-weight: 700;
  letter-spacing: -0.34px;
}

.btn-blue {
  @include textBold;
  border: 2px solid var(--block-button-border);
  opacity: 1;
  height: 46px;
  border-radius: 4px;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.38px;
  margin: 0;
  color: var(--main-black) !important;
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
  background-color: transparent;

  &:hover {
    background-color: var(--main-border-button-hover) !important;
  }

  &.dashboard {
    width: 100px;
    text-align: center;
    @media all and (min-width: 1250px) and (max-width: 1330px) {
      width: 110px;
    }

    &.disabled {
      opacity: 0.5;
      cursor: initial;

      &:hover {
        opacity: 0.5;
        background-color: transparent !important;
      }
    }
  }

  &.pos-unlink {
    width: 137px;
    margin-top: 27px;
    margin-bottom: 16px;
  }

  &.new-serial-number {
    width: 189px;
  }

  &.sales-apply {
    max-height: 30px;
    line-height: 20px;
    margin-right: 45px;
    margin-left: 16px;

    &.mobile {
      margin-right: 15px;
      margin-left: 0;
    }
  }

  &.details {
    padding-left: 32px;
    padding-right: 6px;
    font-size: 16px;
    margin: 5px;
    max-height: 30px !important;
    line-height: 30px;
    border-radius: 5px;
    color: var(--main-black);
    background: var(--download-file) no-repeat 10px;
  }
}

.subscription-info-dialog {
  max-width: 572px;
  height: auto;
  padding-left: 38px;
  padding-right: 38px;
  padding-bottom: 32px;

  &.small {
    width: 560px;
    height: 254px;
  }

  hr {
    margin-top: 0;
    margin-bottom: 10px;
    margin-left: 5px;
    width: 440px;
    border-top: 1px solid #eee;
  }

  .popup-title {
    @include textBlack;
    color: var(--main-black);
    font-size: 22px;
    font-weight: 900;
    letter-spacing: -0.53px;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .popup-context {
    @include textRegular;
    color: var(--main-black);
    opacity: 0.6;
    font-size: 18px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: -0.43px;
    margin-top: 10px;

    &.dark {
      opacity: 1;
    }
  }
}

.content-meal-plan {
  padding-bottom: 20px;
  padding-left: 47px;
  padding-right: 35px;

  &.score-table {
    padding-bottom: 0;

    .score-star {
      width: 14px;
      height: 14px;
      margin: 4px 2px;

      &.full {
        background: url("../assets/images/vectors/score_table_star_full.svg") no-repeat center;
      }

      &.half {
        background: url("../assets/images/vectors/score_table_star_half.svg") no-repeat center;
      }

      &.empty {
        background: url("../assets/images/vectors/score_table_star_grey.svg") no-repeat center;
      }
    }
  }

  @media (max-width: 920px) {
    padding-right: 25px;
    padding-left: 25px;
  }

  .food-value {
    @include textRegular;
    font-weight: 600 !important;
    letter-spacing: -0.34px !important;
    font-size: 16px !important;
    word-break: break-word;

    &.score-table {
      margin-right: 0;

      &.value-with-error {
        color: var(--error-300);
      }

      .score-value {
        padding-left: 20px;
      }
    }

    &.protein, &.carbs, &.fat {
      padding-left: 16px;
    }

    &.protein {
      background: url("../assets/images/vectors/blue_oval.png") no-repeat left 7px;
    }

    &.carbs {
      background: url("../assets/images/vectors/red_oval.svg") no-repeat left 7px;
    }

    &.fat {
      background: url("../assets/images/vectors/yellow_oval.svg") no-repeat left 7px;
    }
  }

  &.total {
    padding-right: 0;
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  .header {
    font-size: 14px;
    margin-top: 27px;
    color: var(--natural-600);
    font-weight: 700;
  }

  .body {
    font-weight: 400;
    font-size: 16px;

    &.total {
      padding-top: 16px;
      margin-bottom: -8px;
    }

    .column-text {
      margin-top: 26px;
      margin-bottom: 14px;
      color: var(--natural-600);
      font-weight: 700;

      &.total {
        margin-top: 30px;
      }
    }

    .carbs {
      background: url("../assets/images/vectors/red_oval.svg") no-repeat left;
      padding-left: 14px;
    }

    .fat {
      background: url("../assets/images/vectors/yellow_oval.svg") no-repeat left;
      padding-left: 14px;
    }

    .protein {
      background: url("../assets/images/vectors/blue_oval.png") no-repeat left;
      padding-left: 14px;
    }

    .edit-container {
      margin-top: -15px;
      margin-bottom: 17px;
    }

    .edit-background {
      padding-left: 23px;
      width: 61px;

      &.carbs {
        background: url("../assets/images/vectors/edit_carbs_background.svg") no-repeat left;
      }

      &.protein {
        background: url("../assets/images/vectors/edit_prt_background.svg") no-repeat left;
      }

      &.fat {
        background: url("../assets/images/vectors/edit_fats_background.svg") no-repeat left;
      }

    }

  }

  .footer {
    @include textHeader;
    margin-top: 33px !important;
    margin-bottom: 10px;
    color: var(--main-black);

    .carbs {
      margin: 0 3px 3px 3px;
      padding-left: 18px;
      background: url("../assets/images/vectors/red_oval.svg") no-repeat left;
    }

    .fat {
      margin: 0 0 3px 3px;
      padding-left: 18px;
      background: url("../assets/images/vectors/yellow_oval.svg") no-repeat left;
    }

    .protein {
      margin: 0 3px 3px 3px;
      padding-left: 18px;
      background: url("../assets/images/vectors/blue_oval.png") no-repeat left;
    }
  }
}

.select_link_dash {
  @include textLatoBlack();
  color: var(--primary-500) !important;
  font-weight: 900;
  font-size: 28px;
  letter-spacing: -0.67px;
  border-bottom: 2px dashed var(--primary-500);
}

.transparent-page {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: lightgrey;
  opacity: 0.2;

  &.with-opacity {
    opacity: 0;
  }
}

.image-spinner {
  .treasure-overlay-spinner-container {
    border-radius: 50%;
  }
}

.overflow-x-scroll {
  overflow: auto;

  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--primary-500);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: var(--warning-600);
  }
}

/*BODY END*/

.md-open-menu-container {
  transform: scale(1) !important;
}

.block-info {
  md-icon[md-svg-icon="info"] {
    min-width: 16px;
    width: 16px;
    min-height: 16px;
    height: 16px;
    margin-left: 10px;
    cursor: pointer;

    &:active {
      color: #000;
    }
  }

  &__text {
    color: #1e233e;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -.38px;
    opacity: .7;
    margin-left: 10px;
  }
}

.foodsense-fluid {
  padding-top: 10px;
  min-width: auto;
}
