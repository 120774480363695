.box {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 100%;
}

.group {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  gap: 40px;
  justify-content: space-between;
  padding: 20px;

  .inputColumn {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
  }
}
