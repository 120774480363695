.scoreRow {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-left: 16px;
  margin-bottom: 21px;
  font: var(--title-16-bold);

  ::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 8px;
    height: 8px;
    content: '';
    background-color: var(--main-black);
    border-radius: 50%;
    transform: translateY(-50%);
  }

  .result {
    display: inline-flex;
    gap: 4px;
    align-items: center;

    svg {
      fill: var(--warning-300);
    }
  }
}
