.previewBox {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 264px;
  background-color: var(--main-black);
  border-radius: 16px;

  .chip {
    position: absolute;
    top: 16px;
    left: 20px;
    z-index: 1;
  }

  .deleteButton {
    position: absolute;
    top: 20px;
    right: 16px;
    z-index: 1;
  }
}

.havBar {
  justify-content: center;
}
