.box {
  margin-bottom: 16px;
}

.title {
  margin-bottom: 12px;
}

.buttonGroup {
  display: flex;
  column-gap: 16px;
  align-items: center;
  justify-content: start;
}
