.supplement-profile {
  max-width: 1376px;
  width: 100%;
  margin: 32px auto;

  .resend-email-btn {
    background: transparent;
    line-height: 24px;
    color: var(--main-black);
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    min-width: auto;
    padding: 0;
    height: 22px;
    border-radius: 0;
    margin-left: 50px;

    md-icon {
      color: var(--main-black);
      margin-left: -27px;
      margin-bottom: -10px;
      opacity: 1;
    }

    &.in-progress {
      opacity: 0.5;
    }

    &:disabled {
      background: transparent;
    }
  }

  .read-terms-message {
    font-size: 14px;
    font-weight: 700;
    padding: 0 4px 12px 4px;
    opacity: 0.9;
    justify-content: space-between;
    border-bottom: 1px solid rgba(var(--link-btns-rgb), .08 );

    p {
      opacity: 0.7;
    }
  }

  .title-profile {
    box-shadow: none;
    padding: 32px;

    &__avatar {
      text-transform: uppercase;
      background: var(--profile-avatar-background);
      color: var(--main-black);
      font-size: 32px;
      letter-spacing: -0.76px;
      font-weight: 900;
      border-radius: 50%;
      min-width: 64px;
      line-height: 64px;
      text-align: center;
      margin-right: 20px;
    }

    &__text {
      color: var(--main-black);
      font-size: 28px;
      letter-spacing: -0.67px;
      font-weight: 900;
      margin: 0;
    }
  }

  .subscriptions {
    padding: 25px;

    &__title {
      letter-spacing: -.34px;
      font-size: 14px;
      font-weight: 700;
      color: var(--natural-600);

      &:not(:first-child) {
        margin-top: 50px;
      }
    }

    .list {
      &-item {
        margin-right: 20px;
        margin-top: 20px;

        .subscription-card {
          width: 320px;
          background-image: linear-gradient(-80deg, #000000 0, #A0A0A0 100%);
          box-shadow: 0 7px 22px rgba(96, 105, 157, .1);
          border-radius: 7px;
          padding: 18px;

          &__text {
            font-size: 16px;
            font-weight: 700;
            letter-spacing: -.38px;
            color: #ffffff;

            &.light {
              letter-spacing: -.34px;
              font-size: 14px;
              opacity: .5;
              margin: 0;
            }

            &.price {
              white-space: nowrap;
            }
          }

          &.blue {
            background-image: linear-gradient(-80deg, #E17745 0, #FFBA53 100%);
          }

          &.expired {
            background-image: linear-gradient(-84deg, #828da3 0, #51596a 100%);
          }
        }

        &__notify-text {
          font-size: 10px;
          font-weight: 700;
          letter-spacing: -.24px;
          color: var(--light_storm_grey);
          margin-top: 5px;
        }
      }
    }

    &-actions {
      margin-top: 50px;

      button {
        margin-left: 10px;
      }
    }
  }

  .info {
    margin-top: 0;

    md-icon {
      margin: 0 15px 0 0;
      min-width: 16px;
      width: 16px;
      min-height: 16px;
      height: 16px;
    }

    &__text {
      color: #666666;
      font-size: 12px;
      font-weight: 300;
      line-height: 20px;
      letter-spacing: .17px;
      width: 589px;
      margin: 0;
    }
  }
}
