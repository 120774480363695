.contactCell {
  display: inline-flex;
  align-items: center;
  justify-self: end;
  width: auto;
  height: auto;
  color: inherit;
  background-color: transparent;

  &:hover {
    text-decoration: underline;
    background-color: transparent;
  }

  &:disabled {
    text-decoration: none;
    background-color: transparent;
  }

  span {
    flex-shrink: 0;
    width: 1px;
    height: 28px;
    margin: 0 14px;
    background-color: var(--natural-100);
  }
}
