.iconBtn {
  padding: 3px;
  background-color: var(--white);
  border: 1px solid rgb(0 0 0 / 8%);
  box-shadow: 0 2px 2px rgb(0 0 0 / 16%);

  svg {
    fill: var(--main-black);
  }

  &:hover {
    background-color: var(--main-black);

    svg {
      fill: var(--white);
    }
  }

  &:active {
    background-color: var(--white);
  }
}
