.promoCodesTable {
  display: grid;
  grid-template-columns: repeat(2, 172px) repeat(5, 140px) 200px 1fr;

  :global(.tableGridLayout) {
    grid-template-columns: subgrid;
    grid-column: 1 /-1;
    gap: 24px;
    place-items: center start;

    // need for sticky group
    overflow: visible;
  }
}
