.container {
  width: 100%;
}

.button {
  width: 100%;
  padding: 0 !important;
  margin: 0 !important;
  background: transparent !important;
  box-shadow: none !important;
}