.subscriptionOptionContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.notDisplayed {
  display: flex;
  gap: 8px;
  align-items: center;
  font: var(--body-14-medium);
  color: var(--natural-600);
}