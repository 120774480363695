.spinnerContainer {
  position: absolute;
  inset: 0;

  // We must use the z-index 2 to fix spinner position on self-setup flow.
  // If we use bigger z-index, the spinner will be behind the header.
  // Need to make special ruleset for z-index in the future.
  // https://lifebasellc.atlassian.net/browse/LMP-2742
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  background-color: rgb(255 255 255 / 85%);
  opacity: 0;
  transition-timing-function: ease-in;
  transition-duration: 500ms;
  transition-property: opacity, visibility;

  .spinner {
    display: none;
    width: 30px;
    height: 30px;
    border: 5px solid rgb(0 0 0 / 75%);
    border-top: 5px solid rgb(0 0 0 / 25%);
    border-radius: 50%;
    animation: rotate-forever 0.75s linear infinite;
  }

  &.loading {
    visibility: visible;
    opacity: 1;

    .spinner {
      display: block;
    }
  }

  &.global {
    position: fixed;
  }
}

@keyframes rotate-forever {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
