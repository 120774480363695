.infoRow {
  display: flex;
  gap: 16px;
  justify-content: space-between;
  margin-bottom: 12px;
  font: var(--body-14-bold);
}

.consumedCell {
  display: inline-flex;
  gap: 12px;
  align-items: center;

  .divider {
    width: 1px;
    height: 16px;
    background: var(--natural-400);
  }

  .description {
    font: var(--body-14-regular);
    color: var(--natural-600);
  }
}
