.infoBox {
  display: flex;
  gap: 12px;
  align-items: center;

  .idTitleBox {
    display: flex;
    gap: 8px;
    align-items: center;
  }
}
