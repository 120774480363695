.container {
  position: relative;

  .infoBox {
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font: var(--title-16-bold);
    backdrop-filter: blur(2px);

    p {
      padding: 10px;
      background-color: white;
    }
  }
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;
  font: var(--title-16-bold);

  form {
    max-width: 350px;
  }
}
