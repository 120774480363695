.infoCell {
  flex: 1 1 50%;
  text-align: center;

  .data {
    margin-bottom: 8px;
    font: var(--title-62-black);

    @media (width <= 1200px) {
      font: var(--title-48-black);
    }
  }

  .description {
    font: var(--title-16-medium);
    color: var(--natural-600);
  }
}
