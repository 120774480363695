.entity-setup-step-1 {
  button[type="submit"] {
    margin-top: 40px;
    margin-left: auto;
    display: block;
  }

  .md-switch {
    height: auto;
    width: auto;
    padding: 10px 24px;
    margin-right: 15px;
    border: 1px solid #EEEEF5;
    border-radius: 8px;
    background: #F8F8FA;

    .md-label {
      padding-right: 15px;
    }
  }

  .stripe-container {
    padding: 24px;

    p {
      margin: 0;
    }

    .secondary-text {
      font: 400 14px/24px "SF UI Display", sans-serif;
      color: #8B8B94;
    }

    .stripe-successful {
      &__text {
        padding-right: 12px;
        font: 700 16px/24px "SF UI Display", sans-serif;
        color: var(--success-300);
      }
      &__icon {
        margin-top: 3px;
      }
    }
  }

  .blue-btn {
    background-color: var(--info-300);

    &:disabled {
      opacity: 0.5;
    }
  }
}
