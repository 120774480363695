.entityRow {
  text-decoration: none;

  &:hover {
    text-decoration: none;
    box-shadow: var(--shadow-main-1);
    opacity: 1; // fix "a" style
  }
}

.ownerCell {
  font: var(--body-14-medium);
}

.businessNameCell {
  display: flex;
  gap: 10px;
  align-items: center;
}
