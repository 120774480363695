.box {
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: space-between;
  min-height: 100%;
}

.moduleBox {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
}
