.box {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 506px;
  padding: 32px 38px;

  @media (width <= 700px) {
    width: auto;
    padding: 32px 20px;
    background: transparent;
    box-shadow: none;
  }
}
