.tabTitleRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  font: var(--title-28-black);

  .titleBox {
    display: flex;
    gap: 16px;
  }
}
