@font-face {
  font-family: Lato;
  src: url('../assets/fonts/Lato-Black.woff2') format('woff2');
  font-weight: 900;
}

@font-face {
  font-family: Lato;
  src: url('../assets/fonts/Lato-Bold.woff2') format('woff2');
  font-weight: 700;
}

@font-face {
  font-family: Lato;
  src: url('../assets/fonts/Lato-Medium.woff2') format('woff2');
  font-weight: 500;
}

@font-face {
  font-family: Lato;
  src: url('../assets/fonts/Lato-Regular.woff2') format('woff2');
  font-weight: 400;
}

@font-face {
  font-family: Lato;
  src: url('../assets/fonts/Lato-Light.woff2') format('woff2');
  font-weight: 300;
}

@font-face {
  font-family: Lato;
  src: url('../assets/fonts/Lato-Hairline.woff2') format('woff2');
  font-weight: 200;
}

.textRegular {
  font-weight: 400;
}

.textLight {
  font-weight: 300;
}

.textHairline {
  font-weight: 200;
}

.textBold {
  @include textPurp;
  font-weight: 700;
}

.textBlack {
  @include textPurp;
  font-weight: 900;
}

.textLatoBlack {
  font-weight: 900;
}

.textInfo {
  @include textRegular;
  color: var(--natural-600);
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.17px;
}

.textPurp {
  color: var(--main-black);
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.38px;
}

.textLightInCircle {
  font-size: 14px;
  font-weight: 700;
  letter-spacing: -0.34px;
}

.textHeader {
  font-size: 16px;
  font-weight: 900;
  letter-spacing: -0.38px;
}

.textHeaderBlue {
  @include textHeader;
  color: var(--primary-500);
}

.textShadow {
  color: var(--shadow);
  font-weight: 700;
  letter-spacing: -0.38px;
}

.textHeaderBlack {
  @include textHeader;
  color: var(--main-black);
}

.textHeaderGrey {
  @include textHeader;
  opacity: 0.3;
  color: var(--main-black);
}

.textPurpBold {
  @include textBold;
  color: var(--main-black);
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.38px;
}

.textBlackBold {
  @include textBlack;
  color: var(--main-black);
  font-size: 28px;
  letter-spacing: -0.67px;
  font-weight: 900;

  &.sharing {
    color: var(--primary-500);
  }
}

.textPurpHeavy {
  @include textPurpBold;
  font-weight: 900;
}

.textGreyLabel {
  @include textLight;
  letter-spacing: -0.34px;
  font-size: 14px;
  font-weight: 700;
  color: var(--natural-600);
}

.textGrey {
  @include textGrayLabel;
  color: var(--natural-600);
}

.title_page {
  @include textBlack;
  color: var(--main-black) !important;
  font-size: 28px;
  font-weight: 900;
  letter-spacing: -0.67px;

  @media (max-width: 860px) {
    font-size: 26px;
  }

  &.dashboard {
    width: fit-content;
  }

  &.mobile {
    font-size: 20px;
  }

  &.revenue {
    @media all and (max-width: 860px) {
      margin-right: 0;
      width: 150px;
    }
  }

  &.reassigning-coaches {
    margin-bottom: 30px;
  }

  &.terms-and-cond {
    width: 90%;
    margin-left: auto;
  }
}

tags-input .tags .input {
  @include textPurp;
}

