.customer-profile-tab {
  md-input-container {
    margin: 15px 25px 5px;
  }

  .outline {
    &:hover {
      opacity: 0.5;
      background-color: transparent;
    }
  }

  .info {
    margin-left: 15px;

    md-icon {
      margin: 0 15px 0 0;
      min-width: 16px;
      width: 16px;
      min-height: 16px;
      height: 16px;
    }

    &__text {
      color: #666666;
      font-size: 12px;
      letter-spacing: .17px;
      width: 589px;
      margin: 0;
    }
  }

  .subscription-plans-container {
    .info {
      height: 50px;
      margin-bottom: 350px;
    }

    .info__text {
      width: 100%;
    }
  }

  .workout-type {
    padding: 25px;
  }

  .set-password {
    padding: 25px;
    margin-bottom: 0;

    .sub-title {
      margin: 0 0 25px 25px;
      color: var(--main-black);
      font: var(--title-18-black);
    }

    button[type="submit"] {
      margin-top: 52px;
    }
  }

  .subscriptions {
    padding: 25px;

    &__title {
      letter-spacing: -.34px;
      font-size: 14px;
      font-weight: 700;
      color: var(--natural-600);

      &:not(:first-child) {
        margin-top: 50px;
      }
    }

    .list {
      &-item {
        margin-right: 20px;
        margin-top: 20px;

        .subscription-card {
          width: 320px;
          background-image: linear-gradient(-80deg, #000000 0, #A0A0A0 100%);
          box-shadow: 0 7px 22px rgba(96, 105, 157, .1);
          border-radius: 7px;
          padding: 18px;

          &__text {
            font-size: 16px;
            font-weight: 700;
            letter-spacing: -.38px;
            color: #ffffff;

            &.light {
              letter-spacing: -.34px;
              font-size: 14px;
              opacity: .5;
              margin: 0;
            }

            &.price {
              white-space: nowrap;
            }
          }

          &.blue {
            background-image: linear-gradient(-80deg, #E17745 0, #FFBA53 100%);
          }

          &.expired {
            background-image: linear-gradient(-84deg, #828da3 0, #51596a 100%);
          }
        }

        &__notify-text {
          font-size: 10px;
          font-weight: 700;
          letter-spacing: -.24px;
          color: var(--light_storm_grey);
          margin-top: 5px;
        }
      }
    }

    &-actions {
      margin-top: 50px;

      button {
        margin-left: 10px;
      }
    }
  }
}
