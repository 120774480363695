.title {
  margin-bottom: 38px;
  text-align: center;

  h3 {
    margin-bottom: 16px;
    font: var(--title-36-bold);
    color: var(--white);

    span {
      color: var(--primary-500);
    }
  }

  p {
    font: var(--title-18-medium);
    color: var(--natural-200);
  }
}

.inputsBlock {
  margin-bottom: 24px;
}
