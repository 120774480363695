.nutrientTable {
  :global .tableGridLayout {
    grid-template-columns: 1.5fr repeat(5, 1fr);
    gap: 24px;
    place-items: center left;

    &:not(:last-child) {
      border-bottom: 1px solid var(--natural-300);
    }
  }
}
