.meal-info-popup {
  box-shadow: 0 7px 22px rgba(96, 105, 157, 0.1);
  border-radius: 7px;
  width: 1280px;
  max-height: 544px;
  padding: 16px 16px 47px 35px;

  .close_icon {
    margin: 0 0 0 auto;
  }

  .title-block {
    justify-content: space-between;
    padding: 19px 26px 22px 3px;
    border-bottom: 1px solid rgba(171, 174, 187, 0.2);

    &__title {
      color: var(--main-black);
      font-size: 22px;
      font-weight: 700;
      line-height: 30px;

      &.first {
        padding-right: 26px;
        border-right: 2px solid rgba(108, 113, 136, 0.5);
      }

      &.second {
        padding-left: 24px;
        text-transform: capitalize;
      }
    }

    &__text {
      font-size: 16px;
      font-weight: 700;
      letter-spacing: -0.38px;
      color: var(--main-black);

      &.light {
        color: var(--natural-600);
      }

      &.star-icon {
        margin-left: 5px;
        padding-left: 25px;
        background: url("../../assets/images/vectors/favorite_active.svg") no-repeat left top;
      }
    }
  }

  .header-block {
    padding: 17px 0px 20px 3px;
    border-bottom: 1px solid rgba(171, 174, 187, 0.2);

    &__text {
      font-size: 14px;
      font-weight: 700;
      letter-spacing: -0.34px;
      color: var(--natural-600);

      &.second-line {
        margin-top: 20px;
      }
    }

    &__values {
      padding-left: 5px;
      font-size: 16px;
      font-weight: 900;
      text-transform: uppercase;

      &.protein {
        color: var(--proteins-color);
      }

      &.carb {
        color: var(--carbs-color);
      }

      &.fat {
        color: var(--fats-color);
      }

      &.sugar {
        color: var(--sugar-color);
      }

      &.fiber {
        color: var(--fiber-color);
      }

      &.veggies {
        color: var(--veggies-color);
      }

      &.sodium {
        color: var(--neutral-700);
      }

      &.calories {
        color: var(--calories-color);
      }
    }
  }

  .main-block, .footer-block  {

    div {
      padding-bottom: 2px;
    }

    &__text {
      font-size: 16px;
      font-weight: 700;
      letter-spacing: -0.38px;
      color: var(--main-black);
    }

    &__values {
      font-size: 16px;
      font-weight: 700;
      letter-spacing: -0.38px;
      color:var(--main-black);

      &.scores {
        padding-left: 24px;

        &-small-padding {
          padding-left: 12px;
        }

        &-large-padding {
          padding-left: 36px;
        }
      }

      &.protein {

        &:before {
          content: '';
          width: 8px;
          height: 8px;
          display: block;
          background-color: var(--proteins-color);
          border-radius: 50%;
          margin-right: 5px;
          margin-top: 7px;
        }
      }

      &.carb {

        &:before {
          content: '';
          width: 8px;
          height: 8px;
          display: block;
          background-color: var(--carbs-color);
          border-radius: 50%;
          margin-left: 12px;
          margin-right: 5px;
          margin-top: 7px;
        }
      }

      &.fat {

        &:before {
          content: '';
          width: 8px;
          height: 8px;
          display: block;
          background-color: var(--fats-color);
          border-radius: 50%;
          margin-left: 12px;
          margin-right: 5px;
          margin-top: 7px;
        }
      }

      &.sugar {

        &:before {
          content: '';
          width: 8px;
          height: 8px;
          display: block;
          background-color: var(--sugar-color);
          border-radius: 50%;
          margin-left: 12px;
          margin-right: 5px;
          margin-top: 7px;
        }
      }

      &.fiber {

        &:before {
          content: '';
          width: 8px;
          height: 8px;
          display: block;
          background-color: var(--fiber-color);
          border-radius: 50%;
          margin-left: 12px;
          margin-right: 5px;
          margin-top: 7px;
        }
      }

      &.veggies {
        margin-left: 10px;

        &:before {
          content: '';
          width: 8px;
          height: 8px;
          display: block;
          background-color: var(--veggies-color);
          border-radius: 50%;
          margin-left: 12px;
          margin-right: 5px;
          margin-top: 7px;
        }
      }

      &.calories {

        &:before {
          content: '';
          width: 8px;
          height: 8px;
          display: block;
          background-color: var(--calories-color);
          border-radius: 50%;
          margin-left: 12px;
          margin-right: 5px;
          margin-top: 7px;
        }
      }

      &.sodium {
        margin-left: 32px;
      }
    }
  }

  .main-block {
    padding: 28px 0 30px 3px;
    border-bottom: 1px solid rgba(171, 174, 187, 0.2);
    max-height: 234px;
    overflow-y: auto;

    ::-webkit-scrollbar {
      width: 4px;
      height: 529px;
    }

    ::-webkit-scrollbar-track {
      border-radius: 3px;
      background-color: #e7e7e7;
    }

    ::-webkit-scrollbar-thumb {
      background-color: var(--primary-500);
      border-radius: 3px;
      width: 4px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background-color: var(--warning-600);
    }
  }

  .footer-block {
    padding: 19px 0 0 3px;
  }
}
