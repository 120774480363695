.row {
  display: grid;
  grid-template-columns:
    minmax(165px, 1fr) repeat(3, 1fr) minmax(165px, 1fr) minmax(
      180px,
      1fr
    );
  gap: 10px;
  align-items: center;
  min-height: 58px;
  padding: 10px 20px;
  border-radius: 4px;
}
