.topRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  .titleBox {
    display: flex;
    gap: 7px;
    align-items: baseline;
    justify-content: flex-start;

    h4 {
      font: var(--title-22-bold);
      color: var(--main-black);
    }

    p {
      font: var(--body-14-regular);
      color: var(--natural-400);
    }
  }

  .btnBox {
    display: flex;
    gap: 20px;
  }
}
