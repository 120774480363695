.customer-setup {
  max-width: 1053px;
  margin: 32px auto;

  .title-page {
    font-weight: 900;
    color: #000000;
    font-size: 28px;
    letter-spacing: -.67px;
    line-height: 45px;
    margin: 0;
  }
}
