.add-new-scan-dialog {
  box-shadow: 0 7px 22px rgba(96, 105, 157, 0.1);
  border-radius: 7px;
  padding: 25px 40px;
  width: 100%;
  max-width: 1050px;

  &.check-in {
    max-width: 1050px;
  }

  &__title {
    text-align: center;
    font-size: 22px;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 30px;
  }

  &__description {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    margin-bottom: 30px;
  }

  &__btn {
    width: 151px;
    height: 45px;
    border-radius: 4px;
    color: var(--white);
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.38px;
    border: none;
    margin: 0 7px;

    &:disabled {
      opacity: 0.5 !important;
    }

    &:hover {
      opacity: 0.8;
    }

    &-cancel {
      border: 2px solid rgba(var(--main-button-border-rgb), 0.4);
      color: var(--main-black);
      background: var(--white);

      &:hover {
        background-color: var(--main-border-button-hover);
      }
    }

    &-submit {
      background-color: var(--primary-500);
    }
  }

  .calculated-block {
    border-top: 2px dotted #e1e1e1;
    padding-top: 20px;
    margin-top: 30px;

    &-item {
      margin: 5px 15px;
      max-width: calc(25% - 30px);
      min-width: calc(25% - 30px);
      font-weight: 700;

      &__name {
        color: var(--light_storm_grey);
        font-size: 14px;
        letter-spacing: -0.34px;
        margin: 0;
      }

      &__count {
        font-size: 16px;
        letter-spacing: -0.38px;
        line-height: 24px;

        span {
          color: var(--natural-600);
          font-size: 16px;
          font-weight: 700;
          letter-spacing: -0.38px;
          line-height: 24px;
        }
      }
    }
  }

  md-dialog-actions {
    margin-top: 25px;
    border-top-style: none !important;

    .outline {
      margin-right: 10px;
    }
  }
}
