.add-recipe {
  max-width: 1088px;
  width: 100%;
  margin: 32px auto;

  .recipe-actions {
    margin: 32px 0;

    .outline {
      margin-right: 12px;
    }
  }

  .bookmark-star {
    position: relative;
    width: 25px;
    height: 23px;
    background-image: url("../../../assets/images/vectors/favourite_unactive.svg");
    cursor: pointer;
  }

  .bookmark-star.active {
    width: 24px;
    height: 21px;
    background-image: url("../../../assets/images/vectors/favorite_active.svg");
  }

  input#servingSize {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
      cursor: pointer;
      opacity: 1 !important;
      fill: var(--primary-500);
      background: url("../../../assets/images/material-icons/arrows.svg") no-repeat 50% 50%;
      width: 14px;
      height: 14px;
      padding: 4px;
      position: relative;
      right: 4px;
    }

    &::-webkit-outer-spin-button:hover {
      opacity: 0.7 !important;
    }

    &::-webkit-inner-spin-button:hover {
      opacity: 0.7 !important;
    }
  }

  .input-with-explanation {
    margin-top: -9px;

    p {
      color: rgb(102, 102, 102);
      font-size: 11px;
      font-weight: 700;
      margin: 0;
    }

    md-icon {
      cursor: pointer;
      margin-left: 8px;
      min-width: 12px;
      max-width: 12px;
      position: relative;
    }

    .servings-message-tooltip {
      z-index: 100;
      position: absolute;
      left: 85px;
      right: 0;
      width: 260px;
      margin-top: -16px;
      min-height: 250px;
      border-radius: 4px;
      filter: drop-shadow(0 7px 22px rgba(96, 105, 157, 0.2));

      span {
        padding: 10px;
        position: fixed;
        background-color: white;
        font-size: 11px;
        line-height: 16px;
        color: rgba(0, 0, 0, 0.6);
        font-weight: 900;
        text-align: left;
        border-radius: 4px;
        opacity: 1;
      }

      span:before {
        content: "";
        position: absolute;
        right: 100%;
        top: 16px;
        width: 0;
        height: 0;
        border-top: 9px solid transparent;
        border-right: 18px solid white;
        border-bottom: 9px solid transparent;
      }
    }
  }

  .food {
    &-item {
      position: relative;
      margin: 8px 15px 8px 0;
      width: 470px;
      min-height: 92px;
      box-shadow: 0 7px 22px rgba(96, 105, 157, 0.1);
      border-radius: 3px;
      padding: 10px;
    }

    &-name {
      margin: 0;
      color: var(--main-black);
      font-size: 18px;
      font-weight: 700;
      letter-spacing: 0.09px;
      padding-right: 44px;
    }

    &-description {
      margin-top: 5px;
      color: var(--natural-600);
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0.07px;
    }

    &-value {
      margin-right: 0 !important;
      margin-top: 5px;
      display: flex;
      color: #7f7f7f;
      font-weight: 500;
      flex-wrap: wrap;

      .protein-icon, .carbs-icon, .fat-icon, .sugar-icon, .sodium-icon, .fiber-icon, .veggies-icon, .calories-icon {
        display: flex;
        width: 14px;
        height: 12px;
        margin-right: 5px;
        background-size: contain;
      }

      .carbs-icon {
        width: 9px;
      }

      .protein-value, .carbs-value, .fat-value, .fiber-value, .sugar-value, .sodium-value, .veggies-value, .calories-value {
        display: flex;
        align-items: baseline;
        margin-right: 20px;
      }

      .protein-icon {
        background: url("../../../assets/images/vectors/colored_meal_plan_proteins.svg") no-repeat left;
        background-size: contain;
      }

      .carbs-icon {
        background: url("../../../assets/images/vectors/colored_meal_plan_carbs.svg") no-repeat left;
        background-size: contain;
      }

      .fat-icon {
        background: url("../../../assets/images/vectors/colored_meal_plan_fats.svg") no-repeat left;
        background-size: contain;
      }

      .sugar-icon {
        background: url("../../../assets/images/vectors/colored_meal_plan_sugar.svg") no-repeat left;
        background-size: contain;
      }

      .fiber-icon {
        background: url("../../../assets/images/vectors/colored_meal_plan_fiber.svg") no-repeat left;
        background-size: contain;
      }

      .sodium-icon {
        background: url("../../../assets/images/vectors/colored_sodium_icon.svg") no-repeat left;
        background-size: contain;
      }

      .veggies-icon {
        background: url("../../../assets/images/vectors/colored_meal_plan_veggies.svg") no-repeat left;
        background-size: contain;
      }

      .calories-icon {
        background: url("../../../assets/images/vectors/colored_calories_icon.svg") no-repeat left;
        background-size: contain;
      }
    }

    &-edit-btn {
      width: 27px;
      height: 27px;
      border-radius: 50%;
      background-color: #666666;
      opacity: 0.3;
      position: absolute;
      top: 7px;
      right: 10px;
      cursor: pointer;

      &:hover {
        opacity: 0.2;
      }

      md-icon {
        margin-left: 2px;
        padding: 0 4.5px;
        color: white;
      }
    }
  }

  .btn-add-new-ingredient {
    height: 35px;
  }

  .thumb {
    position: relative;
    display: block;
    overflow: hidden;
    height: 92px;
    width: 92px;
    border-radius: 5px;
    margin-bottom: 7px;
  }

  .thumb img {
    position: absolute;
    top: -100%;
    left: -100%;
    right: -100%;
    bottom: -100%;
    margin: auto;
    display: block;
    min-width: 100%;
    min-height: 100%;
    -ms-interpolation-mode: bicubic;
    background-color: #dddddd;
  }

  .full-input {
    padding-right: 28px;
  }

  .upload-recipe-image-btn {
    padding-left: 3px;
    color: var(--natural-600);
    font-size: 14px;
    font-weight: 700;
    letter-spacing: -0.34px;
    width: fit-content;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }

  .title-wrapper {
    margin: 0 0 20px;

    button.outline {
      margin-right: 10px;
    }
  }

  .title-page {
    font-weight: 900;
    color: #000000;
    font-size: 28px;
    letter-spacing: -.67px;
    line-height: 45px;
    margin: 0;
  }

  .input-nutrient {

    input {
      opacity: 0.9;
      padding-left: 12px;
      width: max-content;
      border: none;
      cursor: default;
      color: currentColor;
    }

    &:before {
      content: '•';
      position: absolute;
      left: 0;
      top: 5px;
      font-size: 20px;
    }

    &.proteins {
      color: var(--proteins-color);
    }

    &.carbs {
      color: var(--carbs-color);
    }

    &.fats {
      color: var(--fats-color);
    }

    &.sugar {
      color: var(--sugar-color);
    }

    &.fiber {
      color: var(--fiber-color);
    }

    &.veggies {
      color: var(--veggies-color);
    }

    &.sodium {
      color: var(--sodium-color);
    }

    &.calories {
      color: var(--calories-color);
    }
  }
}
