.button {
  position: absolute;
  bottom: 5px;
  left: 70px;
  z-index: 1;
  color: var(--white);
  background-color: var(--success-300);
  opacity: 0.9;

  &:hover {
    background-color: var(--success-300) !important;
    opacity: 1;
  }

  &:disabled {
    background-color: var(--natural-400);
  }
}
