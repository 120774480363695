$mobile: 425px;

.title {
  margin-bottom: 32px;
  font: var(--title-36-bold);
  color: var(--white);
  text-align: center;

  @media (width < $mobile) {
    font: var(--title-22-bold);
  }
}

.todoTitle {
  margin-bottom: 16px;
  font: var(--title-16-bold);
  text-align: center;
}

.todoBox {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.todoItem {
  display: flex;
  gap: 8px;
  align-items: center;
}

.buttonRow {
  display: flex;
  justify-content: end;
  margin: 32px 0;

  @media (width < $mobile) {
    button {
      width: 100%;
    }
  }
}
