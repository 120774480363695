.paginationBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding: 0 20px;
}

.outBox {
  background-color: var(--white);
  border-radius: 8px;
  box-shadow: var(--panel-shadow);
}

.inBox {
  border-top: 1px solid var(--natural-300);
}

.info {
  font: var(--body-14-medium);
}

.buttonList {
  display: inline-flex;
  gap: 16px;
  align-items: center;
  justify-content: center;
  font: var(--body-14-bold);
}
