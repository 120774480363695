.customer-setup-step-1 {
  .app-card {
    &.additional-user-info {
      .app-card-header {
        border: none;
      }

      .app-card-body {
        border-top: 2px solid rgba(120, 128, 162, 0.2);
      }
    }

    &-header {
      &.low-border {
        border-width: 1px;
      }

      md-icon[md-svg-icon="plus"], md-icon[md-svg-icon="minus"] {
        margin: 0;
        cursor: pointer;
      }
    }
  }

  .scan-button {
    border-bottom: 1px solid;
    height: auto;
    padding: 0;

    md-icon {
      margin-left: -19px;
    }
  }

  .message-loading {
    font-weight: 700;
    margin-left: 15px;
  }

  .message-error {
    font-weight: 700;
    color: #f66464;
    margin-left: 15px;
  }

  .info {
    margin-left: 15px;
    max-width: 210px;

    md-icon {
      margin: 0 15px 0 0;
      min-width: 16px;
      width: 16px;
      min-height: 16px;
      height: 16px;
    }

    &__text {
      color: #666666;
      font-size: 12px;
      letter-spacing: .17px;
      width: 589px;
      margin: 0;
    }
  }

  .calculated-block {
    border-top: 2px dotted #e1e1e1;
    padding-top: 20px;
    margin-top: 30px;

    &-item {
      margin: 5px 15px;
      max-width: calc(25% - 30px);
      min-width: calc(25% - 30px);
      font-weight: 700;

      &__name {
        color: var(--light_storm_grey);
        font-size: 14px;
        letter-spacing: -0.34px;
        margin: 0;
      }

      &__count {
        font-size: 16px;
        letter-spacing: -0.38px;
        line-height: 24px;

        span {
          color: var(--natural-600);
          font-size: 16px;
          font-weight: 700;
          letter-spacing: -0.38px;
          line-height: 24px;
        }
      }
    }
  }
}

