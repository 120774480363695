.date {
  display: inline-flex;
  color: var(--natural-600);

  p {
    margin-right: 4px;
    font: var(--body-14-regular);
  }

  h6 {
    margin-right: 16px;
    font: var(--body-14-bold);
  }
}
