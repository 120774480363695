.table {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 16px;
  place-items: center start;
  font: var(--body-14-medium);

  .nameCell {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  .amountCell {
    justify-self: end;
  }
}

.rectangle {
  width: 8px;
  height: 8px;
  border-radius: 2px;
}
