.container {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.form {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;

  @media (width <= 700px) {
    flex: 1;
  }
}
