.dropzoneBox {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: center;
  height: 264px;
  background-color: var(--background-50-light);
  border: 1px dashed var(--natural-400);
  border-radius: 16px;

  &.dragging {
    border-color: var(--primary-500);
  }

  &.error {
    color: var(--error-300);
    border-color: var(--error-300);
  }
}

.infoBox {
  text-align: center;

  h6 {
    margin-bottom: 8px;
    font: var(--title-18-bold);
  }

  p {
    font: var(--body-14-regular);

    :not(.error) {
      color: var(--natural-600);
    }
  }
}
