.paperBox {
  display: flex;
  flex-direction: column;

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 20px;

    h3 {
      font: var(--title-16-bold);
    }
  }

  .contentBox {
    flex-grow: 1;
    border-top: 1px solid var(--natural-200);
  }
}

.box {
  overflow: hidden;
  background-color: var(--white);
  border-radius: 8px;
  box-shadow: var(--panel-shadow);
}

.innerBox {
  border: 1px solid var(--natural-300);
  border-radius: 8px;
}

.margin {
  margin-bottom: 12px;
}

.contentPadding {
  padding: 16px 20px;
}
