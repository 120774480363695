.customer-profile {
  margin: 32px auto;
  max-width: 1376px;
  width: 100%;

  md-icon.spinning {
    animation: spin 1s infinite linear;
    -webkit-animation: spin2 1s infinite linear;
  }

  @keyframes spin {
    from {
      transform: scale(1) rotate(0deg);
    }
    to {
      transform: scale(1) rotate(360deg);
    }
  }

  @-webkit-keyframes spin2 {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
    }
  }

  .title-profile {

    &__avatar {
      text-transform: uppercase;
      background: var(--profile-avatar-background);
      color: var(--main-black);
      font-size: 32px;
      letter-spacing: -0.76px;
      font-weight: 900;
      border-radius: 50%;
      min-width: 64px;
      max-width: 64px;
      line-height: 64px;
      height: 64px;
      text-align: center;
      margin-right: 20px;
    }

    &__text {
      color: var(--main-black);
      font-size: 28px;
      letter-spacing: -0.67px;
      font-weight: 900;
      margin: 0;

      &-info {
        font-size: 14px;
        font-weight: 700;
        font-weight: 400;
        color: var(--natural-600);
      }

      span[class^="tag-"] {
        padding: 0 4px;
        height: 17px;
        font-size: 12px;
        margin-left: 5px;
        border-radius: 3px;
        letter-spacing: normal;
      }

      .tag-corporate {
        color: rgba(var(--corporate-tag-rgb), 1);
        background: rgba(var(--corporate-tag-rgb), .2);
      }

      .tag-deactivated {
        color: #ff6c6b;
        background: rgba(255, 108, 107, .4);
      }
    }
  }

  .title-actions {
    .outline {
      margin-right: 10px;

      &:disabled {
        opacity: 0.5;
      }
    }

    .text-scan-date {
      font-size: 14px;
      font-weight: 700;
      color: var(--natural-600);
      letter-spacing: -.34px;
      margin: 0;
    }

    .btn-toggle-message-menu {
      md-icon {
        color: #ffffff;
        min-width: 14px;
        width: 14px;
        min-height: 14px;
        height: 14px;
        margin-right: 5px;
      }
    }
  }

  .tabs {
    border-bottom: 2px solid var(--natural-100);
    margin: 40px 0;

    &-item {
      padding: 0 5px;
      margin: 8px 8px -2px 0;
      min-width: 218px;
      max-width: 100%;
      height: 70px;
      color: rgba(var(--tabs-text-rgb), .6);
      background-color: rgba(var(--tabs-background-rgb), .3);
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;

      &:last-of-type {
        margin-right: 0;
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: -2px;
        right: -2px;
        height: 6px;
        background: transparent;
        border-radius: 3px 3px 0 0;
      }

      &.active {
        border-left: 2px solid var(--natural-100);
        border-right: 2px solid var(--natural-100);
        border-radius: 3px 3px 0 0;
        color: #000000;
        background: var(--app-background);

        md-icon {
          fill: black;
          color: black;
        }

        &:before {
          background: var(--main-black);
        }
      }

      md-icon {
        min-width: 20px;
        width: 20px;
        min-height: 20px;
        height: 20px;
        margin: 0;
        font-size: 20px;
      }
    }
  }
}

