.cell {
  display: flex;
  gap: 8px;
  align-items: center;

  &.yellow {
    color: var(--warning-300);
  }

  &.red {
    color: var(--error-300);
  }

  &.green {
    color: var(--success-300);
  }
}
