.inputView {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: start;

  .label {
    font: var(--body-12-medium);
    color: var(--natural-600);
  }

  .value {
    display: inline-flex;
    gap: 8px;

    .font-input {
      font: var(--body-14-medium);
    }

    .font-inherit {
      font: inherit;
    }
  }

  .adornment {
    color: var(--natural-600);
  }

  .helperText {
    font: var(--body-12-regular);
    color: var(--natural-600);
  }
}
