.supBox {
  p {
    font: var(--body-14-bold);
    color: var(--main-black);
  }

  span {
    font: var(--body-12-medium);
    color: var(--natural-400);
  }
}
