.infoCell {
  width: 100%;

  h6 {
    margin-bottom: 5px;
    font: var(--body-12-medium);
    color: var(--natural-600);
  }

  p {
    font: var(--title-16-medium);
  }

  &.noInfo {
    color: var(--natural-400);

    h6 {
      color: var(--natural-400);
    }
  }
}
