.infoRow {
  display: flex;
  align-items: center;
  justify-content: center;
  font: var(--body-14-regular);
  color: var(--natural-500);

  .colorBox {
    position: relative;
    width: 24px;
    height: 24px;
    margin-right: 8px;
    border-radius: 4px;

    &.green {
      background-color: var(--success-50);
      border: 1px solid var(--success-300);
    }

    &.blue {
      background-color: var(--info-50);
      border: 1px solid var(--info-300);
    }
  }
}
