.paymentHistoryTable {
  :global(.tableGridLayout) {
    // static size from design, ask by designer
    grid-template-columns: 156px // name
      156px // subscription
      110px // total amount
      148px // status
      100px // lifebase fees
      100px // stripe fees
      110px // entity deposit
      92px // sales tax
      120px // saas tax
      100px // entity net
      100px // payout date
      58px; // actions

    column-gap: 24px;
    place-items: center start;
    width: fit-content;

    // need for sticky group
    overflow: visible;
  }
}

.searchInput {
  width: 240px;
}

.staticOnScroll {
  position: sticky;
  top: 0;
  left: 0;
}
