.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: space-between;
  height: 100%;
}

.answersBox {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 16px;
  justify-content: space-evenly;
}
