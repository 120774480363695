.coach-setup {
  max-width: 1053px;
  width: 100%;
  margin: 32px auto;

  .additional-params-container {
    md-checkbox {
      margin-left: 24px;
    }
  }

  .title-wrapper {
    margin-bottom: 20px;
  }

  .title-page {
    font-weight: 900;
    color: #000000;
    font-size: 28px;
    letter-spacing: -.67px;
    line-height: 45px;
    margin: 0;
  }

  .info {
    margin-left: 15px;
    margin-top: 20px;

    md-icon {
      margin: 0 15px 0 0;
      min-width: 16px;
      width: 16px;
      min-height: 16px;
      height: 16px;
    }

    &__text {
      color: #666666;
      font-size: 12px;
      letter-spacing: .17px;
      width: 589px;
      margin: 0;
    }
  }

  button[type="submit"] {
    margin-left: auto;
    display: block;
  }
}
