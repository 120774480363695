.customer-overview-tab {
  &__right-side {
    margin-left: 20px;
  }

  .kbm-list {
    &-item {
      font-size: 14px;
      font-weight: 700;
      border-bottom: 1px solid var(--athens_gray);

      &.with-margin-top {
        margin-top: 20px;
        border-top: 1px solid var(--athens_gray);
      }

      &:first-child {
        text-align: center;
      }

      &__label {
        letter-spacing: -.34px;
        color: var(--natural-600);
        margin: 0;
        padding: 8px;

        span {
          opacity: .6;
          display: block;
        }
      }

      &__text {
        padding: 8px;
        color: var(--main-black);
        letter-spacing: -.48px;
        border-left: 1px solid var(--athens_gray);
        margin: 0;
        text-align: center;

        span {
          font-weight: 300;
          letter-spacing: -.34px;
          color: var(--natural-600);
          font-size: 12px;
          margin-left: 8px;

          &.weight-up {

            &:after {
              content: '▴';
              margin-left: 5px;
            }
          }

          &.weight-down {
            &:after {
              content: '▾';
              margin-left: 5px;
            }
          }

          &.lbm-up {
            color: #58ca8c;
            &:after {
              content: '▴';
              margin-left: 5px;
            }
          }

          &.lbm-down {
            color: #f66464;
            &:after {
              content: '▾';
              margin-left: 5px;
            }
          }

          &.up {
            color: #f66464;
            &:after {
              content: '▴';
              margin-left: 5px;
            }
          }

          &.down {
            color: #58ca8c;

            &:after {
              content: '▾';
              margin-left: 5px;
            }
          }
        }
      }
    }
  }
}
