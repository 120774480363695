.insuranceCertificate {
  border-radius: 12px;

  .infoWrapper {
    padding: 4px 12px 12px;

    .fileName {
      margin-bottom: 4px;
      font: var(--body-14-regular);
    }

    .dateWrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h6 {
        font: var(--body-12-medium);
        color: var(--natural-600);
      }
    }
  }
}
