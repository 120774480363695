.privacy-overview {
  max-width: 1376px;
  width: 100%;
  margin: 32px auto;

  .title-page {
    font-weight: 900;
    color: #000000;
    font-size: 28px;
    letter-spacing: -.67px;
    line-height: 45px;
  }

  .app-card {
    padding: 32px;

    p {
      opacity: .75;
      color: #000000;
      font-weight: 700;
      letter-spacing: -.34px;
      line-height: 24px;

      &.paragraph {
        font-weight: 900;
        opacity: 1;

        &:before {
          content: '';
          min-width: 8px;
          height: 8px;
          background: var(--primary-500);
          border-radius: 50%;
          display: inline-block;
          margin-right: 8px;
        }
      }

      &.sub-paragraph {
        margin-left: 16px;
        padding-left: 16px;
        &:before {
          content: '';
          min-width: 8px;
          height: 8px;
          border: 1px solid var(--primary-500);
          border-radius: 50%;
          display: inline-block;
          margin-right: 8px;
          margin-left: -16px;
        }
      }

      &.notate {
        font-weight: 900;
        opacity: 1;
      }
    }
  }

  .title {
    font-weight: 900;
    color: #000000;
    font-size: 20px;
    letter-spacing: -.38px;
    margin-bottom: 12px;
    margin-top: 8px;
  }

  .subtitle {
    font-weight: 900;
    color: #000000;
    font-size: 16px;
    letter-spacing: -.38px;
    margin-top: 8px;
  }


}
