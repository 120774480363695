.scrollBox {
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--natural-300);
    border-radius: 4px;
  }
}

.withScrollIndent {
  &.outBox {
    margin-right: -30px;

    .contentWrapper {
      padding-right: 24px;
    }

    .scrollBox {
      padding: 8px 0;
      overflow: auto;
    }
  }

  &.inBox {
    padding: 8px 8px 8px 0;

    .contentWrapper {
      margin-right: -14px;
    }

    .scrollBox {
      overflow: hidden auto;
    }
  }
}

@media print {
  .scrollBox {
    max-height: 100vh !important;
  }
}
