button {
  margin: 0;
  border: none;
  min-width: 130px;
  padding: 0 10px;
  height: 45px;
  border-radius: 4px;
  background-color: #ff9900;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.38px;

  .compliance-coach-tooltip {
    display: none;
  }

  &:hover {
    background-color: rgba(255, 153, 0, 0.8);

    .compliance-coach-tooltip {
      display: block;
    }
  }

  &:disabled {
    cursor: initial;
    background-color: rgba(255, 153, 0, 0.5);
  }

  &.list-item__btn {
    height: 32px;
  }

  &.delete {
    background-color: var(--error-300);
  }

  &.outline {
    padding: 0 20px;
    font: var(--title-16-bold);
    color: var(--main-black);
    background: transparent;
    border: 2px solid #969696;

    md-icon {
      margin-right: 4px;
      color: #323232;
    }

    &:disabled {
      color: rgba(127, 127, 127, 0.6);
      border-color: rgba(127, 127, 127, 0.2);
      background: transparent !important;
      opacity: 1 !important;

      md-icon {
        opacity: 0.4;
      }
    }

    &:hover {
      background: rgba(127, 127, 127, 0.1);
    }

    &--red {
      border-color: rgba(255, 108, 107, 0.4);
      color: #ff6c6b;

      &:hover {
        background-color: rgba(255, 108, 107, 0.2);
      }

      &:disabled {
        color: rgba(255, 108, 107, 0.5);
        border-color: rgba(255, 108, 107, 0.3);
        background-color: transparent;
      }
    }
  }

  &.clear, &.clear-dark {
    background: transparent;
    color: #ff9900;
    display: flex;
    min-width: auto;
    border-radius: 0;

    md-icon {
      color: #ff9900;
      min-width: 14px;
      width: 14px;
      min-height: 14px;
      height: 14px;
      margin-right: 5px;

      &[md-svg-icon="communication"] {
        color: #000;
      }
    }
  }

  &.clear:disabled {
    opacity: 0.3;
  }

  &.clear-dark {
    color: #000000;
  }

  &.download {
    min-width: 80px;
    height: 36px;
    border-radius: 5px;
    padding: 0 5px;
    display: flex;
    align-items: center;

    &:before {
      content: '';
      border-right: 1px solid rgba(255, 255, 255, .2);
      margin-right: 5px;
      padding: 0 15px 0 12px;
      min-width: 15px;
      min-height: 15px;
      background: url("../../assets/images/material-icons/download-white.svg") no-repeat center;
    }

    &.outline {
      &:before {
        border-right: 1px solid rgba(0, 0, 0, .2);
        background: url("../../assets/images/material-icons/download-black.svg") no-repeat center;
      }


    }

    @media screen and (max-width: 768px) {
      min-width: 42px;
      &:before {
        border: none !important;
        margin: auto;
      }
      span {
        display: none;
      }
    }
  }

  &.filter__btn-date {
    width: 140px;
    height: 42px;
    border-radius: 4px;
    border: 1px solid rgba(145, 150, 179, 0.2);
    background: transparent;
    color: var(--main-black);
    font-size: 14px;
    font-weight: 700;
    letter-spacing: -0.34px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:before {
      content: '';
      background: var(--datepicker-calendar) no-repeat center;
      background-size: contain;
      margin-right: 9px;
      width: 18px;
      height: 22px;
    }
  }

  &.btn-with-icon {
    @extend .textPurpBold;
    width: 108px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-transform: capitalize;
    border-radius: 4px;
    border-color: #8E8E94;

    .icon {
      color: #000;
      margin: 0;
    }
  }

}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  &--flat {
    padding: 0;
    display: flex;
    min-width: auto;
    color: #ff9900;
    background: transparent;
    border-radius: 0;
    &:hover {
      background-color: transparent;
    }
    md-icon {
      color: #ff9900;
      margin-right: 5px;
    }
  }
  &--rotate-icon {
    &:hover {
      md-icon {
        transition: transform 0.4s linear;
        transform: rotate(90deg);
      }
    }
  }

  &.blue {
    background: var(--info-300);
    font: 700 16px/24px "SF UI Display", sans-serif;

    &:disabled {
      opacity: 0.3;
    }
  }
}

.btn-wrapper {
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
}
