.nutrientBox {
  display: inline-flex;
  gap: 5px;
  align-items: center;
}

.nutrientIcon {
  width: 8px;
  height: 8px;
  background-color: currentcolor;
  border-radius: 50%;
}

.proteins {
  color: var(--proteins-color);
}

.carbs {
  color: var(--carbs-color);
}

.fats {
  color: var(--fats-color);
}

.sugar {
  color: var(--sugar-color);
}

.fiber {
  color: var(--fiber-color);
}

.sodium {
  color: var(--sodium-color);
}

.calories {
  color: var(--calories-color);
}

.veggies {
  color: var(--veggies-color);
}

.water {
  color: var(--water-color);
}
