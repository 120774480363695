.container {
  display: flex;
  gap: 8px;
  justify-content: space-between;
}

.title {
  font: var(--body-14-regular);
  color: var(--natural-700);
}

.value {
  font: var(--body-14-regular);
  color: var(--main-black);
}
