.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: space-around;
  height: 100%;
}

.infoGroup {
  display: flex;
  flex-direction: column;
  gap: 4px;
  font: var(--body-14-medium);
}
