$cardHeaderHeight: 55px;
$border: 1px solid #e6e7e7;

.invoices {
  .title-row {
    margin-bottom: 30px;

    .title-box{
      display: inline-flex;
      align-items: center;
      padding-left: 10px;

      &__title {
        font: var(--title-22-black)
      }
    }

    .btn {
      margin: 0 70px 0 0;
      text-transform: none;
      color: var(--info-300);
      font: var(--title-16-regular);
      letter-spacing: inherit;

      &__icon {
        color: inherit;
      }
    }
  }

  .module-billing{
    position: relative;

    .arrow {
      font-size: 18px;
      position: absolute;
      top: 100%;
      left: 100%;
    }
  }

  .app-card {
    &-header {
      height: $cardHeaderHeight;
      .date {
        font: var(--title-16-black);
      }
    }
  }

  .table {
    height: calc(100% - #{$cardHeaderHeight});
    font: var(--title-16-bold);
    padding: 0 29px 15px;
    margin-bottom: 0;

    &__row {
      padding: 12px 0;
      box-sizing: content-box;
      border-top: $border;

      &:last-of-type {
        padding-top: 27px;
        font-size: 20px;

        .title {
          font-weight: 900;
        }
      }

      &_header {
        padding-top: 27px;
        font: 700 14px/24px Lato;
        color: var(--natural-600);
        border: none;
      }
    }

    .item {
      margin-left: 10px;
    }
  }

  .billing-date {
    width: 100%;
    margin-left: 20px;
  }

  .gray {
    color: #b5b5c2;
  }

  .orange {
    color: var(--primary-500);
  }

  .blue {
    color: var(--info-300);
  }
}
