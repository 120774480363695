md-dialog[aria-label="confirm-dialog"], md-dialog[aria-label="alert-dialog"], md-dialog[aria-label="delete-dialog"] {
  padding: 40px 23px 40px 23px;
  max-width: 380px;
  width: 380px;

  md-dialog-content, md-dialog-actions {
    padding: 0;
  }

  .md-dialog-content-body {
    p {
      margin-bottom: 39px;
      text-align: center;
    }
  }

  md-dialog-actions {
    justify-content: center;
  }

  .md-title {
    font-size: 22px;
    font-weight: 700;
    line-height: 30px;
    text-align: center;
  }

  .md-cancel-button {
    text-transform: capitalize;
    min-width: 130px;
    padding: 0 10px;
    height: 45px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.38px;

    color: #000000 !important;
    background: transparent;
    border: 2px solid rgba(178, 178, 178, 0.5);

    &:hover {
      opacity: 0.8;
    }
  }

  .md-confirm-button {
    text-transform: capitalize;
    border: none;
    min-width: 130px;
    padding: 0 10px;
    height: 45px;
    border-radius: 4px;
    background-color: #ff9900 !important;
    color: #ffffff !important;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.38px;

    &:hover {
      opacity: 0.8;
    }
  }
}

md-dialog[aria-label="delete-dialog"] {
  .md-confirm-button {
    background-color: var(--error-300) !important;
  }
}