.box {
  display: flex;
  gap: 8px;
  align-items: start;
}

.infoBox {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
