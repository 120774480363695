.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 240px;
  margin-bottom: 12px;
  border: 1px solid var(--natural-300);
  border-radius: 8px;
}

.img {
  margin-bottom: 16px;
}

.title {
  margin-bottom: 8px;
  font: var(--body-14-bold);
}

.description {
  font: var(--body-14-regular);
  color: var(--natural-600);
}
