.supplementItem {
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  padding: 8px 12px;
  cursor: pointer;
  border: 1px solid var(--natural-300);
  border-radius: 6px;

  &:hover {
    background-color: var(--natural-100);
  }

  h4 {
    font: var(--body-14-bold);
    color: var(--natural-600);
  }

  p {
    font: var(--body-10-regular);
    color: var(--natural-500);
  }
}

.inputBox {
  position: relative;
  margin-bottom: 32px;

  .addBtn {
    position: absolute;
    top: 50%;
    right: 16px;
    z-index: 10;
    min-width: 110px;
    height: 35px;
    font: var(--title-16-bold);
    background-color: var(--main-black);
    transform: translateY(-50%);

    &:hover {
      opacity: 0.9;
    }
  }
}
