.certificatePreview {
  display: block;
  height: 116px;
  margin: 4px 4px 8px;
  overflow: hidden;
  text-decoration: none;
  border-radius: 8px;

  &:hover,
  :focus {
    text-decoration: none;
  }

  img {
    width: 100%;
    height: 100%;
  }

  .fileExtensionBox {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background: var(--natural-200);
  }
}
