.certification-assigned {
  width: 388px;
  text-align: center;
  padding: 30px;

  &__image {
    width: 219px;
    margin-bottom: 15px;
  }

  &__title {
    color: #000000;
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 13px;
  }

  &__text {
    color: #000000;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 20px;
  }
}
