.supplement-setup-step-2 {

  .resend-email-btn {
    background: transparent;
    line-height: 24px;
    color: var(--main-black);
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    min-width: auto;
    padding: 0;
    height: 22px;
    border-radius: 0;
    margin-left: 50px;

    md-icon {
      color: var(--main-black);
      margin-left: -27px;
      margin-bottom: -10px;
      opacity: 1;
    }

    &.in-progress {
      opacity: 0.5;
    }

    &:disabled {
      background: transparent;
    }
  }

  .read-terms-message {
    font-size: 14px;
    font-weight: 700;
    padding: 0 4px;
    opacity: 0.9;
    justify-content: space-between;
    margin-bottom: 32px;

    p {
      opacity: 0.7;
    }
  }

  .app-card {
    &.inactive {
      opacity: 0.5;
    }
  }

  md-input-container {
    input[disabled] {
      border: none;
      color: #000000;
    }
  }

  .table-payment-detail {
    &__label {
      color: var(--natural-600);
      font-size: 14px;
      font-weight: 700;
      letter-spacing: -.34px;
      margin-top: 18px;
      min-height: 20px;

      &.total {
        font-weight: 900;
        font-size: 16px;
        color: var(--natural-600);
        margin-bottom: 0;
      }
    }

    &__text {
      color: var(--main-black);
      font-size: 16px;
      font-weight: 700;
      letter-spacing: -.38px;
      margin-top: 18px;

      &.total {
        font-weight: 900;
        font-size: 30px;
        color: var(--main-black);
        letter-spacing: -.72px;
        margin: 0;
      }
    }
  }

  &__submit {
    display: block;
    margin-right: 0;
    margin-left: auto;
  }

  .info {
    margin-top: -12px;

    md-icon {
      margin: 0 15px 0 0;
      min-width: 16px;
      width: 16px;
      min-height: 16px;
      height: 16px;
    }

    &__text {
      color: #666666;
      font-size: 12px;
      font-weight: 300;
      line-height: 20px;
      letter-spacing: .17px;
      width: 589px;
      margin: 0;
    }
  }
}
