.controlReports {
  display: flex;
  gap: 10px;
}

.datePicker {
  max-width: 148px;
}

@media print {
  .printBtn {
    display: none;
  }
}
