.add-food {
  max-width: 1053px;
  width: 100%;
  margin: 32px auto;

  .list-group {
    width: 210px;

    ul {
      margin-top: 12px;

      li {
        padding: 8px;
      }
    }
  }

  .title-wrapper {
    margin: 0 0 20px;

    button.outline {
      margin-right: 10px;
    }
  }

  .title-page {
    font-weight: 900;
    color: #000000;
    font-size: 28px;
    letter-spacing: -.67px;
    line-height: 45px;
    margin: 0;
  }

  .input-nutrient {
    padding-left: 13px;

    &:before {
      content: '•';
      position: absolute;
      left: 0;
      top: 5px;
      font-size: 20px;
    }

    &.proteins {
      &:before {
        color: var(--proteins-color);
      }
    }

    &.carbs {
      &:before {
        color: var(--carbs-color);
      }
    }

    &.fats {
      &:before {
        color: var(--fats-color);
      }
    }

    &.sugar {
      &:before {
        color: var(--sugar-color);
      }
    }

    &.fiber {
      &:before {
        color: var(--fiber-color);
      }
    }

    &.veggies {
      &:before {
        color: var(--veggies-color);
      }
    }

    &.sodium {
      &:before {
        color: var(--sodium-color);
      }
    }

    &.calories {
      &:before {
        color: var(--calories-color);
      }

      input {
        border: none;
        cursor: default;
      }
    }
  }

  .btn-add-new-nutrient {
    margin-left: 10px;
  }

  .food-details {
    md-input-container {
      width: 18%;

      &.product-field {
        width: 80%;
      }

      input::placeholder{
        color: rgba(0, 0, 0, 0.4)
      }
    }
  }

  .btn-wrapper {
    .button {
      margin-left: 15px;
    }
  }
}
