.errorInfo {
  display: flex;
  gap: 8px;
  padding: 8px 12px;
  font: var(--body-14-medium);
  background-color: var(--background-50-dark);
  border-bottom: solid 1px var(--natural-200);
}

.userInfo {
  padding: 11px 12px;
  font: var(--body-14-regular);
  border-bottom: solid 1px var(--natural-200);
}
