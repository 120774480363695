.summaryRow {
  display: flex;
  gap: 8px;
  justify-content: space-between;
  margin-bottom: 32px;
}

.mealDetails {
  display: grid;
  grid-template-columns: repeat(2, auto) 1fr minmax(100px, auto);
  gap: 16px;
}
