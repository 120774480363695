.container {
  display: flex;
  align-items: center;
}

.step {
  width: 43px;
  height: 5px;
  margin-left: 7px;
  background-color: var(--natural-100);
  border-radius: 15px;
}

.active {
  background-color: var(--success-300);
}
