a {
  font-weight: 700;
  font-size: 16px;
  color: var(--main-black) !important;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
}
