.box {
  display: flex;
  margin: 50px 0 80px;

  .submitRow {
    display: inline-flex;
    gap: 12px;
    margin-left: auto;
  }
}
