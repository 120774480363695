.emptyListBox {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: center;
  padding: 20px 32px;

  .description {
    font: var(--body-14-bold);
  }
}

.small {
  margin: 16px 0;
}

.medium {
  margin: 40px 0;
}
