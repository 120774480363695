//MealPlanMacros

.add-food-for-meal-container {
  width: 460px;
  height: auto;
  box-shadow: 0 24px 24px rgba(0, 0, 0, 0.3), 0 0 24px rgba(0, 0, 0, 0.22);
  border-radius: 6px;
  background-color: #ffffff;
  padding: 24px 56px 49px 49px;

  .ingregient-label {
    font-size: 11px;
    font-weight: 900;
    color:rgb(102, 102, 102);
    margin-bottom: 6px;
  }

  .full-input {
    margin-bottom: 0;
    &.select {
      margin-bottom: 12px;
    }
  }

  .switch-radio {
    margin-bottom: 24px;

    label {
      min-width: 50%;
    }
  }

  .add-food-text {
    @include textBlackBold;
    color: var(--main-black) !important;
    font-size: 20px !important;
    font-weight: 700 !important;
    text-align: center;
    width: 100%;
  }

  .btns-container {
    justify-content: space-between;
    padding-right: 10px;
    padding-left: 10px;
    margin-top: 32px;
  }

  .protein-value, .carbs-value, .fat-value, .fiber-value,
  .sugar-value, .sodium-value, .veggies-value, .calories-value {
    @include textLight;
    font-size: 16px;
    font-weight: 700;
    color: var(--light_storm_grey);
  }

  .protein-value {
    color: var(--proteins-color);
  }

  .carbs-value {
    color: var(--carbs-color);
  }

  .fat-value {
    color: var(--fats-color);
  }

  .fiber-value {
    color: var(--fiber-color);
  }

  .sugar-value {
    color: var(--sugar-color);
  }

  .sodium-value {
    color: var(--neutral-700);
  }

  .veggies-value {
    color: var(--veggies-color);
  }

  .calories-value {
    color: var(--calories-color);
  }

  .protein-icon {
    padding-left: 35px;
    background: url("../../assets/images/vectors/colored_meal_plan_proteins.svg") no-repeat left;
  }

  .carbs-icon {
    padding-left: 25px;
    background: url("../../assets/images/vectors/colored_meal_plan_carbs.svg") no-repeat left;
  }

  .fat-icon {
    padding-left: 35px;
    background: url("../../assets/images/vectors/colored_meal_plan_fats.svg") no-repeat left;
  }


  .sugar-icon {
    padding-left: 30px;
    background: url("../../assets/images/vectors/colored_meal_plan_sugar.svg") no-repeat left;
  }

  .fiber-icon {
    padding-left: 35px;
    background: url("../../assets/images/vectors/colored_meal_plan_fiber.svg") no-repeat left;
  }

  .veggies-icon {
    padding-left: 35px;
    background: url("../../assets/images/vectors/colored_meal_plan_veggies.svg") no-repeat left;
  }

  .sodium-icon {
    padding-left: 25px;
    background: url("../../assets/images/vectors/colored_sodium_icon.svg") no-repeat 5px;
  }

  .calories-icon {
    padding-left: 35px;
    background: url("../../assets/images/vectors/colored_calories_icon.svg") no-repeat left;
  }

  .outline--red {
    width: 152px;
  }
}

.meal-plan-filter, .total-goal-block {
  display: flex !important;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 40px;
}

.title-description {
  position: relative;

  h4.title_page {
    margin: 0;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: -35px;
    width: 1px;
    margin: -10px 0;
    height: 140%;
    background-color: #abaebb;
    opacity: 0.2;

    @media (max-width: 1200px) {
      height: 111%;
    }
  }
}

.total-goal-block {
  max-width: 1360px;
  padding: 40px 20px 40px 40px;

  .total-com-score {
    display: flex;

    .text-main-grey {
      @include textLight;
      margin-top: 7px;
      font-weight: 700;
      font-size: 16px;
      color: var(--light_storm_grey);
    }

    .text-main-blue {
      @include textBlack;
      margin-top: 7px;
      margin-left: 10px;
      font-weight: 900;
      font-size: 16px;
      color: var(--main-black) !important;
    }
  }

  .total-goal-points {
    &.row {
      margin: 0;
    }

    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 80%;

    @media (max-width: 1280px) {
      width: 76%;
      .margin-top-20 {
        margin-top: 20px;
        min-width: 120px;
      }
    }

    @media (max-width: 1170px) {
      justify-content: flex-start;
      width: 74%;
    }

    @media (max-width: 950px) {
      width: 68%;
      justify-content: flex-end;
    }

    @media (max-width: 800px) {
      .margin-top-20 {
        min-width: 115px;
      }
    }

    .text-column-grey {
      font-size: 14px;
      font-weight: 700;
      color: var(--natural-600);
      letter-spacing: -0.34px;
    }

    .total {
      margin-bottom: 10px;
    }

    .protein-icon, .carbs-icon, .fat-icon, .sugar-icon, .fiber-icon, .veggies-icon, .sodium-icon, .calories-icon, .water-icon {
      font-size: 20px;
      font-weight: 900;
    }

    .protein-icon {
      color: var(--proteins-color);
      padding-left: 35px;
      background: url("../../assets/images/vectors/meal_plan_proteins.svg") no-repeat left;
    }

    .carbs-icon {
      color: var(--carbs-color);
      padding-left: 25px;
      background: url("../../assets/images/vectors/meal_plan_carbs.svg") no-repeat left;
    }

    .fat-icon {
      color: var(--fats-color);
      padding-left: 35px;
      background: url("../../assets/images/vectors/meal_plan_fats.svg") no-repeat left;
    }

    .water-icon {
      color: var(--water-color);
      padding-left: 30px;
      background: url("../../assets/images/vectors/meal_plan_water.svg") no-repeat left;
    }

    .sugar-icon {
      color: var(--sugar-color);
      padding-left: 30px;
      background: url("../../assets/images/vectors/meal_plan_sugar.svg") no-repeat left;
    }

    .fiber-icon {
      color: var(--fiber-color);
      padding-left: 35px;
      background: url("../../assets/images/vectors/meal_plan_fiber.svg") no-repeat left;
    }

    .veggies-icon {
      color: var(--veggies-color);
      padding-left: 35px;
      background: url("../../assets/images/vectors/meal_plan_veggies.svg") no-repeat left;
    }

    .sodium-icon {
      color: var(--sodium-color);
      padding-left: 25px;
      background: url("../../assets/images/vectors/sodium_icon.svg") no-repeat left;
    }

    .calories-icon {
      color: var(--calories-color);
      padding-left: 35px;
      background: url("../../assets/images/vectors/calories_icon.svg") no-repeat left;
    }

    .text-column-total-default {
      padding-top: 6px;
      color: var(--main-black);
      font-size: 16px;
      font-weight: 900;
    }

    .default {
      color: var(--natural-600);
      font-size: 12px;
      font-weight: 700;
      letter-spacing: -0.29px;
      padding-left: 13px;

      md-icon {
        min-width: 16px;
        min-height: 16px;
        width: 16px;
        height: 16px;
      }
    }
  }
}

.meal-plan-blocks {
  .vertical-tab-panel {
    padding: 0;
    margin: 0 10px 0 0;

    @media (max-width: 1100px) {
      max-width: 276px !important;
    }

    li {
      list-style: none;
    }

    .vertical-tab-item {
      label {
        position: relative;
        padding-top: 15px !important;
        padding-left: 30px !important;
      }

      label.active {
        padding-left: 24px;
        background-color: var(--app-background);
        border-left: 6px solid var(--main-black);
        opacity: 1;
      }

      .eat-complete:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #eeeef5;
        opacity: 0.4;
        z-index: 2;
      }

      .eat-complete:after {
        content: '';
        position: absolute;
        top: 15px;
        right: 15px;
        width: 23px;
        height: 24px;
        background: url("../../assets/images/vectors/checkmark.svg") center / contain no-repeat;
      }

      .eat-skipped:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #eeeef5;
        opacity: 0.4;
        z-index: 2;
      }

      .eat-skipped:after {
        content: '';
        position: absolute;
        top: 8px;
        right: 8px;
        width: 38px;
        height: 38px;
        background: url("../../assets/images/vectors/skipped-button.svg") center / contain no-repeat;
      }

      label em {
        position: relative;
        padding-left: 14px;
        font-style: normal;
        color: var(--natural-600);
        font-size: 14px;
        font-weight: 700;
        letter-spacing: -0.34px;
      }

      em:after {
        content: '';
        position: absolute;
        top: 0;
        left: 7px;
        width: 1px;
        height: 100%;
        background-color: #8790a1;
        opacity: 0.3;
      }

      .eating-value {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        width: 78%;
      }

      label {
        .protein, .carb, .fat {
          font-weight: 500;
        }
      }

      label .protein {
        color: var(--proteins-color);
        padding-left: 12px;
        background: url("../../assets/images/vectors/blue_oval.png") no-repeat left;
      }

      label .carbs {
        color: var(--carbs-color);
        padding-left: 12px;
        background: url("../../assets/images/vectors/red_oval.svg") no-repeat left;
      }

      label .fat {
        color: var(--fats-color);
        padding-left: 12px;
        background: url("../../assets/images/vectors/yellow_oval.svg") no-repeat left;
      }
    }

    var {
      font-style: normal;
    }

    label .text-column-total-default {
      color: #9ca3b2;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.08px;
    }
  }
}

.add-food-total-goal {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0;

  @media (max-width: 1250px) {
    display: block;
    width: calc(100% - 285px);
  }

  .added-food {
    position: relative;
    max-width: 523px;
    width: 100%;
    margin-right: 10px !important;
    border-radius: 7px;
    background-color: #ffffff;

    @media (max-width: 1250px) {
      max-width: none;
      margin-bottom: 30px;
      margin-right: 0 !important;
    }


    .added-food-menu {
      position: relative;
      display: flex;
      justify-content: space-between;
      padding: 14px 20px;

      &:after {
        content: ' ';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        border: 1px solid var(--left-panel-border);
      }

      .added-food-title {
        margin-left: 10px;
        color: var(--main-black);
        font-size: 16px;
        font-weight: 900;
        letter-spacing: -0.38px;
      }

      button {
        height: 36px;
      }

      .btn-green-skip-sm {
        max-width: 88px;
        margin: 0 !important;
        border-radius: 4px;
        background-color: var(--primary-500);

        .main-text-in-button {
          text-transform: capitalize;
          color: #FFFFFF;
          font-size: 16px !important;
          font-weight: 700 !important;
          letter-spacing: -0.38px !important;
        }
      }
    }
  }

  //.bookmark-star-active {
  //  position: relative;
  //  width: 24px;
  //  height: 21px;
  //  margin-top: 6px;
  //  margin-right: 10px;
  //  background-image: url("../../assets/images/vectors/favourite_unactive.svg");
  //  &:after {
  //    content: '';
  //    position: absolute;
  //    top: 13px;
  //    right: -10px;
  //    width: 1.5px;
  //    margin: -10px 0;
  //    height: 100%;
  //    background-color: #abaebb;
  //    opacity: 0.2;
  //  }
  //}

  .added-food:after {
    content: '';
    position: absolute;
    top: 21%;
    right: -30px;
    width: 21px;
    height: 21px;
    background-image: url("../../assets/images/vectors/back_forth_arrows.svg");

    @media (max-width: 1250px) {
      right: -25px;
    }

    @media (max-width: 1199px) {
      top: calc(100% + 5px);
      left: 50%;
      transform: translateX(-50%) rotate(90deg);
    }
  }

  .food-name {
    margin: 0;
    color: var(--main-black);
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.09px;
    padding-right: 44px;
  }

  .food-description {
    margin-top: 5px;
    color: var(--natural-600);
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.07px;
  }

  .shadow_food_item {
    position: relative;
    padding: 10px;
    -webkit-box-shadow: 0 7px 22px rgba(96, 105, 157, 0.1);
    -moz-box-shadow: 0 7px 22px rgba(96, 105, 157, 0.1);
    box-shadow: 0 7px 22px rgba(96, 105, 157, 0.1);
    border-radius: 3px;
    background-color: #ffffff;
  }

  .food_item_add_btn, .food_item_remove_btn {
    content: '';
    position: absolute;
    top: 7px;
    right: 10px;
    width: 28px;
    height: 27px;
    background: url("../../assets/images/vectors/add-btn-icon.svg");
    cursor: pointer;

    &.done {
      opacity: 0.6;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  .food_item_remove_btn {
    background-image: url("../../assets/images/vectors/remove_btn_icon.svg");
  }


  .food-value {
    margin-right: 0 !important;
    margin-top: 5px;
    display: flex;
    flex-wrap: wrap;

    .protein-icon, .carbs-icon, .fat-icon, .sugar-icon, .sodium-icon, .fiber-icon, .veggies-icon, .calories-icon {
      display: flex;
      width: 14px;
      height: 12px;
      margin-right: 5px;
      background-size: contain;
    }

    .carbs-icon {
      width: 9px;
    }

    .protein-value, .carbs-value, .fat-value, .fiber-value, .sugar-value, .sodium-value, .veggies-value, .calories-value {
      display: flex;
      align-items: baseline;
      margin-right: 20px;
    }

    .protein-icon {
      background: url("../../assets/images/vectors/colored_meal_plan_proteins.svg") no-repeat left;
      background-size: contain;
    }

    .carbs-icon {
      background: url("../../assets/images/vectors/colored_meal_plan_carbs.svg") no-repeat left;
      background-size: contain;
    }

    .fat-icon {
      background: url("../../assets/images/vectors/colored_meal_plan_fats.svg") no-repeat left;
      background-size: contain;
    }

    .sugar-icon {
      background: url("../../assets/images/vectors/colored_meal_plan_sugar.svg") no-repeat left;
      background-size: contain;
    }

    .fiber-icon {
      background: url("../../assets/images/vectors/colored_meal_plan_fiber.svg") no-repeat left;
      background-size: contain;
    }

    .sodium-icon {
      background: url("../../assets/images/vectors/colored_sodium_icon.svg") no-repeat left;
      background-size: contain;
    }

    .veggies-icon {
      background: url("../../assets/images/vectors/colored_meal_plan_veggies.svg") no-repeat left;
      background-size: contain;
    }

    .calories-icon {
      background: url("../../assets/images/vectors/colored_calories_icon.svg") no-repeat left;
      background-size: contain;
    }
  }

  .bookmark-star {
    position: relative;
    width: 25px;
    height: 23px;
    margin-top: 6px;
    margin-right: 10px;
    background-image: url("../../assets/images/vectors/favourite_unactive.svg");
    cursor: pointer;

    &:after {
      content: '';
      position: absolute;
      top: 13px;
      right: -10px;
      width: 1.5px;
      margin: -10px 0;
      height: 100%;
      background-color: var(--main-black);
      opacity: 0.2;
    }
  }

  .bookmark-star.active {
    width: 24px;
    height: 21px;
    background-image: url("../../assets/images/vectors/favorite_active.svg");
  }

  .favorite-category {
    margin-top: 15px;
    max-height: 445px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 4px;
      height: 529px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 3px;
      background-color: #e7e7e7;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--primary-500);
      border-radius: 3px;
      width: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: var(--warning-600);
    }

    .favorite-food-list-header {
      display: flex;
      justify-content: space-between;
    }

    .md-button {
      margin: 0;
    }

    .remove-food-btn, .add-meal-food-btn {
      color: var(--natural-600);
      font-size: 14px;
      font-weight: 700;
      letter-spacing: -0.34px;
    }

    .add-meal-food-btn {
      text-transform: initial;
    }

    .favorite-food-title {
      margin-left: 10px;
      margin-bottom: 0;
      color: var(--natural-600);
      font-size: 14px;
      font-weight: 700;
      letter-spacing: -0.34px;
    }

    ul.favorite-food-list {
      padding: 8px;

      .food-item {
        margin-top: 15px;
        list-style: none;
      }
    }
  }

  .added-food-content {
    overflow-y: auto;
    min-height: 600px;
    max-height: 600px;
    padding: 20px 20px 0 20px;

    &::-webkit-scrollbar {
      width: 4px;
      height: 529px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 3px;
      background-color: #e7e7e7;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--primary-500);
      border-radius: 3px;
      width: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: var(--warning-600);
    }

    .added-food-list {
      padding: 0;
    }

    .added-food-list .food-item {
      list-style: none;
      background: none;
      margin-top: 15px;
    }
  }

  .added-food-empty {
    position: relative;
    min-height: 600px;
    max-height: 600px;

    .content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
    }

    .nothing-here-img {
      background: var(--no-product) no-repeat center;
      width: 179px;
      height: 143px;
    }

    .nothing-here-text {
      margin-top: 15px;
      margin-bottom: 5px;
      color: var(--natural-600);
      font-size: 18px;
      font-weight: 700;
      letter-spacing: 0.09px;
    }

    .add-product-text {
      opacity: 0.8;
      color: var(--natural-600);
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0.07px;
    }
  }

  .all-food {
    max-width: 523px;
    width: 100%;
    border-radius: 7px;
    background-color: #ffffff;
    margin-left: 10px !important;

    @media (max-width: 1250px) {
      max-width: none;
      margin-left: 0 !important;
    }
  }

  .all-food-menu {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 14px 20px;

    .all-food-title {
      color: var(--main-black);
      font-size: 16px;
      font-weight: 900;
      letter-spacing: -0.38px;
    }

    .btn-blue {
      width: 140px;
      height: 37px;
      padding: 0;
      color: var(--primary-500);
      font-size: 16px;
      font-weight: 700;
      letter-spacing: -0.38px;
      border-width: 1px;
    }
  }

  .all-food-menu:after {
    content: ' ';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    border: 1px solid var(--left-panel-border);
  }

  .all-food-body {
    min-height: 600px;
    max-height: 600px;
    padding: 20px 20px 0 20px;
  }

  .all-food-search {
    position: relative;
    margin-top: 15px;
    border-radius: 3px;
    border: 1px solid #D3D3D3;
    padding: 7px 10px;

    input {
      position: relative;
      padding-left: 30px;
      background: none;
      border: none;
      width: 100%;
      color: #000000;
    }

    input::placeholder {
      color: #D3D3D3;
    }

    &:after {
      content: '';
      position: absolute;
      left: 15px;
      top: 10px;
      width: 15px;
      height: 16px;
      background-image: url("../../assets/images/vectors/search_magnifying_glass.svg");
    }
  }

  .all-food-empty {
    position: relative;
    min-height: 463px !important;
    max-height: 463px;

    .content {
      position: absolute;
      top: 37.5%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      @media (max-width: 1199px) {
        top: 50%;
      }
    }

    .nothing-here-img {
      background: var(--no-product) no-repeat center;
      width: 179px;
      height: 143px;
    }

    .nothing-here-text {
      margin-top: 15px;
      margin-bottom: 5px;
      color: var(--natural-600);
      font-size: 18px;
      font-weight: 700;
      letter-spacing: 0.09px;
    }

    .add-product-text {
      opacity: 0.8;
      color: var(--natural-600);
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0.07px;
    }
  }

  .all-food-content {
    max-height: 480px;
    min-height: 480px;
    margin-top: 15px;

    .food-list-spinner {
      height: 100px;

      &--inactive {
        height: 0;
      }
    }
  }

  .dragged-items-result {
    color: #696c7e;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: -0.34px;
  }

  .all-food-list {
    max-height: 479px;
    min-height: 479px;
    overflow-y: auto;
    padding: 8px;
    margin: 0;
    background: none;

    &::-webkit-scrollbar {
      width: 4px;
      height: 529px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 3px;
      background-color: #e7e7e7;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--primary-500);
      border-radius: 3px;
      width: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: var(--warning-600);
    }
  }

  .all-food-list .food-item {
    list-style: none;
    background: none;
    margin-top: 15px;

    &:first-child {
      margin-top: 0;
    }
  }
}

.save-confirm-modal {
  max-width: 400px;
  text-align: center;
  border-radius: 8px;
  &__title {
    margin-bottom: 30px;
    padding: 30px 35px 0;
    @include textBlackBold;
    font-size: 20px;
  }
  &__btn {
    padding: 15px 35px;
    display: block;
    text-decoration: none;
    border-top: 1px solid #F0F0F0;
    &:hover {
      text-decoration: none;
    }
  }
  &__btn-title {
    display: block;
    @include textHeaderBlue;
  }
  &__btn-desc {
    @include textGrey;
    font-style: italic;
    font-size: 14px;
    font-weight: 400;
    color: #ACACAC;
  }
}
