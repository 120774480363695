.tabsBox {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: start;
}

.addMealButton {
  width: calc(100% - 24px);
  margin: 12px;
}
