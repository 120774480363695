.underConstructionFallback {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - var(--header-height));

  .fallbackTitle {
    margin-bottom: 8px;
    font: var(--title-36-bold);
  }

  .fallbackDescription {
    margin-bottom: 24px;
    font: var(--body-16-regular);
    color: var(--natural-600);
  }
}
