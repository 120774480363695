.box {
  font: var(--title-16-regular);
  text-align: center;

  .titleBox {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
    font: var(--title-28-bold);
  }

  .icon {
    color: var(--success-600);
  }

  .button {
    margin: 24px 0;
  }
}
