.titleRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;

  .title {
    font: var(--title-16-bold);
  }

  .controlBlock {
    display: grid;
    grid-template-columns: 1fr 1fr auto;
    gap: 12px;
    align-items: center;
  }
}
