.successRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 57px;
  padding: 0 32px;
  border-bottom: 1px solid var(--natural-200);

  p {
    font: var(--title-16-bold);
  }

  span {
    font: var(--title-16-regular);
    color: var(--natural-500);
  }
}
