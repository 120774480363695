.consumptionBetweenTable {
  :global .tableGridLayout {
    grid-auto-columns: 1fr;
    gap: 24px;
    place-items: center left;
  }

  & > * {
    border-top: 1px solid var(--natural-300);
  }

  .header {
    padding: 8px 20px;
    font: var(--body-14-bold);
  }
}
