.entityTable {
  display: grid;
  grid-template-columns: 1.5fr repeat(5, 1fr) auto;

  :global(.tableGridLayout) {
    grid-template-columns: subgrid;
    grid-column: 1 /-1;
    gap: 24px;
    place-items: center start;
  }
}
