.iconBox {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
}

.infoBox {
  margin-bottom: 32px;

  .allowsTitle {
    margin-bottom: 11px;
    font: var(--body-14-bold);
  }

  .allowsList {
    display: flex;
    flex-direction: column;
    gap: 11px;
  }

  .allowsItem {
    display: inline-flex;
    gap: 8px;
    align-items: center;
  }
}
