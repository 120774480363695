.labelRow {
  display: flex;
  justify-content: space-between;

  .label {
    font: var(--body-14-medium);
  }

  .value {
    font: var(--body-14-bold);
  }
}
