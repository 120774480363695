.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.text {
  margin: 0 0 0 10px;
  font: var(--body-14-regular);
}
