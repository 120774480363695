.infoCell {
  display: inline-flex;
  gap: 2px;
  color: var(--main-black);
}

.title {
  display: inline-block;
  font: var(--body-12-bold);
}

.info {
  display: inline-block;
  font: var(--body-12-medium);
}
