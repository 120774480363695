.box {
  position: relative;
  width: 688px;
  padding: 52px 38px 32px;
  margin: 63px auto 0;
  overflow: visible;

  .inputsBlock {
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-bottom: 24px;

    .subscriptionsBox {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
      gap: 16px;
    }
  }
}
