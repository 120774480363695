.group {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  gap: 8px;
}

.align-start {
  align-items: start;
}

.align-end {
  align-items: end;
}

.align-center {
  align-items: center;
}

.align-stretch {
  align-items: stretch;
}

.align-baseline {
  align-items: baseline;
}
