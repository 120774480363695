md-nav-bar {
  max-width: 100%;

  .md-nav-bar {
    border-width: 0;
  }

  &.navbar {
    background-color: var(--info-300);
    height: 74px;
    margin: 0;
    color: var(--white);
    //z-index: 10000;
  }

  &.open-menu {
    margin-right: 250px;
    background-color: rgba(57, 72, 101, 0.95);
  }
}

.block-divider {
  height: 1px;
  border: 0.5px solid var(--lynch);
  opacity: 0.2;
}

.btn-container {
  width: 100%;
  padding: 0 40px;
  @media screen and (max-width: 920px) {
    padding: 0;
  }
}

md-menu-bar {
  padding: 0;
  @media screen and (max-width: 920px) {
    padding: 0;
    width: max-content;
  }

  .md-menu {
    @media screen and (max-width: 920px) {
      display: flex;
    }
  }
}

.nav-container {
  width: 100%;

  .fs-nav-btn {
    @include textLight;
    opacity: 0.8;
    color: #fefefe !important;
    font-weight: 700;
    letter-spacing: -0.38px;
    padding-left: 28px;
    padding-right: 18px;
    padding-bottom: 22px;
    margin-top: 50px;

    @media all and (max-width: 845px) {
      margin-right: 0.01px;
    }

    @media screen and (max-width: 920px) {
      width: 108px;
      margin-top: 20px !important;
      padding-left: 0;
      padding-bottom: 0;
      padding-right: 0;
    }

    &.active {
      opacity: 1;
      position: relative;

      .active-line {
        display: block;
      }
    }
  }

  @media (max-width: 920px) {
    .fs-nav-btn.small-item-less {
      width: 85px;
    }
  }

  @media (max-width: 1140px) {
    .fs-nav-btn.less-padding-10, .menu-item {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  @media (max-width: 921px) {
    .less-padding-10.fs-nav-btn {
      width: 88px;
    }
    .fs-nav-btn.less-padding-10 {
      padding-left: 0;
      padding-right: 0;
    }
  }

  @media (max-width: 1024px) and (min-width: 921px) {
    .less-padding-10 .active-line {
      margin-left: -34px;
    }
  }

  @media (max-width: 921px) {
    .less-padding-10 .active-line {
      margin-left: -16px;
    }
  }

  @media all and (max-width: 845px) {
    .margin-left-40 {
      margin-left: 40px;
    }
  }

  .fs-nav-btn:hover {
    opacity: 1;
  }

  .fs-nav-btn:focus {
    opacity: 1;
    background: transparent;
  }

  .active-line {
    display: none;
    height: 5px;
    border-radius: 4px 4px 0 0;
    background-color: #48cb78;
    margin-top: 19px;
    margin-left: -28px;
    margin-right: -28px;
    position: absolute;
    bottom: -2px;
    left: 35px;
    width: 100%;

    @media screen and (max-width: 920px) {
      margin-top: 12px;
      bottom: -14.1px;
      left: 8px;
    }
  }

  .menu-item {
    margin-top: 20px;
    font-size: 16px;
  }

  @media (max-width: 921px) {
    .long-text-menu-item {
      width: 150px !important;

      & > div {
        width: 100%;
      }
    }

    .long-text-menu-item2 {
      width: 135px !important;
    }
  }
}

.logo {
  text-transform: uppercase;
  font-size: 20px;
  padding-top: 5px;
  padding-left: 75px;
  background: url("../assets/images/vectors/logo.svg") no-repeat center left;
  background-size: 69px 27px;
  height: 52px;
  cursor: pointer;
  @media screen and (max-width: 845px) {
    margin-left: 0;
  }

  &.sharing {
    margin: 8px 0 0 16px;
  }
}

.mobile-navbar {
  max-width: 100%;
  background-color: var(--info-300);
  height: 74px;
  margin: 0;

  .logo.sharing {
    margin: 0;
  }
}

.top-sharing-mobile-panel {
  background: white;
  height: 80px;
  width: 100%;
  padding-top: 22px;

  .mobile-logo {
    width: 41px;
    height: 41px;
    padding-left: 49px;
    background: url("../assets/images/vectors/mobile-logo.svg") no-repeat center;
  }

  .main-text, .detail-text {
    @include textRegular;
    color: #373739;
    font-size: 16px;
    font-weight: 500;
  }

  .detail-text {
    font-size: 13px;
  }

  .btn-green {
    height: 37px;
    width: 98px;
  }
}

.home-header {
  @media screen and (max-width: 1170px) {
    margin-left: 0;
  }
  cursor: pointer;
}

.md-menu {
  @media screen and (max-width: 845px) {
    margin-right: -40px;
  }
}

.dropdown {
  cursor: pointer;
  max-height: 50px;
  background: url("../assets/images/vectors/dropdown.svg") no-repeat right 10px !important;
  padding-right: 28px !important;
  @media (max-width: 920px) {
    padding-right: 0 !important;
    &.sales {
      width: 60px;
      min-width: 60px;
    }

    &.stats {
      width: 94px !important;
      min-width: 87px !important;
      margin-right: 32px !important;
    }
  }
}

.dropdown-exit {
  cursor: pointer;
  max-height: 50px;
  padding-right: 18px;
  background: url("../assets/images/vectors/dropdown.svg") no-repeat right !important;
  background-position-y: 10px !important;

  &.without-text {
    min-height: 26px;
  }

  img {
    position: absolute;
    margin-left: -40px;
    margin-top: -7px;
  }
}

.email {
  margin-right: 62px;
  position: relative;
  width: 23px;
  height: 18px;
  background-size: 23px 18px;
  background: url("../assets/images/vectors/mail.svg") no-repeat;

  .email-alert {
    top: -7px;
    right: -7px;
    position: absolute;
    width: 15px;
    height: 15px;
    background-size: 15px 15px;
    background: url("../assets/images/vectors/alert-oval.svg") no-repeat;
  }
}

.md-open-menu-container.md-active, .md-open-menu-container {
  z-index: 1060;
}

.navbar-nav {
  @media screen and (max-width: 845px) {
    display: flex;
    margin-right: 20px;
  }
  @media screen and (max-width: 768px) {
    margin: 0;
  }
}

.navbar-fixed-top {
  z-index: 89;
}
