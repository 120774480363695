.textBox {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: space-between;
  margin-bottom: 16px;
}

.titleBox {
  display: inline-flex;
  gap: 12px;
  align-items: center;
  justify-content: start;
}

.title {
  font: var(--body-14-bold);
}

.description {
  font: var(--body-14-regular);
  color: var(--natural-700);
}
