.foodScoreTable {
  display: grid;
  grid-template-columns: repeat(14, auto);

  :global(.tableGridLayout) {
    grid-template-columns: subgrid;
    grid-column: 1 /-1;
    gap: 24px;
    place-items: center start;
  }
}

.pagination {
  margin: 40px 0;
}
