.sales-deposits {
  max-width: 1376px;
  width: 100%;
  margin: 32px auto;

  .title-page {
    font-weight: 900;
    color: #000000;
    font-size: 28px;
    letter-spacing: -.67px;
    line-height: 45px;
    margin: 0;
  }

  .app-card {
    margin-top: 20px;

    &-body {
      padding: 15px 30px;
    }
  }

  .deposits-list {
    &-item {
      padding: 20px 0;

      &:first-child {

        .deposits-list-item__text {
          font-weight: 400;
          color: var(--natural-600);
        }
      }

      &__text {
        font-weight: 700;
        color: #000000;
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;

        span {
          color: var(--natural-600);
        }

        &:last-child {
          font-weight: 900;
        }
      }

      &__status {

        md-icon {
          padding: 2px;
          min-width: 20px;
          width: 20px;
          height: 20px;
          min-height: 20px;
          background: #58ca8b;
          border-radius: 50%;
          display: block;
          margin: 0;
          color: #ffffff;

          &[md-svg-icon="warning"] {
            background: #fa6f6f;
            padding: 2px 4px 4px;
          }

          &[md-svg-icon="refund"] {
            padding: 0;
          }

          &[md-svg-icon="pos-status"] {
            background: #FAA46F;
            animation: rotating 2s linear infinite;
          }

          &[md-svg-icon="close"] {
            padding: 0px;
            background: #d5d7dc;
          }

          @keyframes rotating {
            from {
              transform: rotate(0deg);
            }
            to {
              transform: rotate(360deg);
            }
          }
        }

        span {
          font-weight: 700;
          color: #000000;
          margin-left: 5px;
          text-transform: capitalize;
        }
      }

      button {
        margin: 5px 10px 5px 0;
      }
    }
  }
}
