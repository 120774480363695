.privacyPolicy {
  h3 {
    margin: 24px 0;
    font: var(--title-18-bold);
    letter-spacing: -0.6px;
  }

  h4 {
    margin: 14px 0;
    font: var(--title-18-bold);
    letter-spacing: -0.6px;
  }

  p {
    margin-bottom: 10px;
    font: var(--title-16-regular);
    letter-spacing: -0.1px;
  }

  a {
    padding: 0 5px;
    font-weight: 500;
  }
}
