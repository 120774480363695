@mixin spaceBetween-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin textRegular {
  font-weight: 400;
}

@mixin textLatoBlack() {
  font-weight: 900;
}

@mixin textLatoBold() {
  font-weight: 700;
}

@mixin textHairLine {
  font-weight: 200;
}

@mixin textLight {
  font-weight: 700;
}

@mixin textPurp {
  color: var(--main-black);
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.38px;
}

@mixin textPurpBold {
  color: var(--main-black);
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.38px;
}

@mixin textGrayLabel {
  letter-spacing: -0.34px;
  font-size: 14px;
  font-weight: 700;
  color: var(--natural-600);
}

@mixin textBlack {
  color: var(--main-black);
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.38px;
}

@mixin textPureHeavy {
  color: var(--main-black);
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.38px;
}

@mixin textLightInCircle {
  font-size: 14px;
  font-weight: 700;
  letter-spacing: -0.34px;
}

@mixin textGreyLabel {
  letter-spacing: -0.34px;
  font-size: 14px;
  font-weight: 700;
  color: var(--natural-600);
}

@mixin textHeader {
  font-weight: 900;
  font-size: 16px;
  letter-spacing: -0.38px
}

@mixin textInfo {
  color: var(--natural-600);
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.17px;
}

@mixin textBold {
  color: var(--main-black);
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.38px;
}

@mixin textShadow {
  color: var(--shadow);
  font-weight: 700;
  letter-spacing: -0.38px;
}

@mixin shape_white_shadow_7px {
  background-color: #fff;
  border-radius: 7px;
  box-shadow: 0 7px 22px rgba(96, 105, 157, 0.1);
}

@mixin shape_white_shadow_3px {
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 7px 22px rgba(96, 105, 157, 0.1);
  font-weight: 400;
}

@mixin textHeaderGrey {
  opacity: 0.3;
  color: var(--main-black);
  font-size: 16px;
  font-weight: 900;
  letter-spacing: -0.38px;
}

@mixin textHeaderBlack {
  font-size: 16px;
  font-weight: 900;
  letter-spacing: -0.38px;
  color: var(--main-black);
}

@mixin textLightGrey {
  font-size: 14px;
  font-weight: 700;
  color: var(--grizzly);
  letter-spacing: -0.34px;
}

@mixin textHeaderBlue {
  color: var(--primary-500);
  font-size: 16px;
  font-weight: 900;
  letter-spacing: -0.38px;
}

@mixin textGreyLabel {
  letter-spacing: -0.34px;
  font-size: 14px;
  font-weight: 700;
  color: var(--natural-600);
}

@mixin textGrey {
  letter-spacing: -0.34px;
  font-size: 14px;
  font-weight: 700;
  color: var(--natural-600);
}

@mixin textInfo {
  color: var(--natural-600);
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.17px;
}

@mixin textBlackBold {
  color: var(--main-black);
  font-size: 28px;
  letter-spacing: -0.67px;
  font-weight: 900;
}

@mixin body {
  @include textShadow;
  font-size: 16px;
  .column-text {
    margin-top: 25px;
    opacity: 0.6;

    &.total {
      margin-top: 30px;
    }
  }

  .carbs {
    display: flex;
    align-items: center;
    border-radius: 5px;
    padding: 5px;

    &:before {
      content: '';
      border-radius: 50%;
      width: 8px;
      height: 8px;
      background: var(--carbs-color);
      display: block;
      margin-right: 10px;
    }
  }

  .protein {
    display: flex;
    align-items: center;
    border-radius: 5px;
    padding: 5px;

    &:before {
      content: '';
      border-radius: 50%;
      width: 8px;
      height: 8px;
      background: var(--proteins-color);
      display: block;
      margin-right: 10px;
    }
  }

  .fat {
    display: flex;
    align-items: center;
    border-radius: 5px;
    padding: 5px;

    &:before {
      content: '';
      border-radius: 50%;
      width: 8px;
      height: 8px;
      background: var(--fats-color);
      margin-right: 10px;
    }
  }

  .edit-container {
    margin-top: -15px;
    margin-bottom: 17px;
  }

  .edit-background {
    padding-left: 23px;
    width: 61px;

    &.carbs {
      background: rgba(255, 108, 107, 0.1);
    }

    &.protein {
      background: rgba(69, 119, 255, 0.1);
    }

    &.fat {
      background: rgba(238, 176, 43, 0.1);
    }

  }
}

@mixin greenBtn {
  font-weight: 700;
  color: #fff !important;
  border-radius: 4px;
  opacity: 1;
  background-color: var(--primary-500) !important;
  height: 46px;
  line-height: 46px;
  font-size: 16px;
  letter-spacing: -0.38px;
  margin: 0;
  text-align: center;
  text-transform: initial;

  &:hover {
    background-color: var(--primary-500) !important;
    opacity: 0.8 !important;
  }

  .btn-green[disabled] {
    opacity: 0.5;
  }

  .btn-green[disabled]:hover {
    background-color: var(--primary-500) !important;
    opacity: 0.5 !important;
  }

  .btn-green.change-meal-plan {
    width: 191px;
    margin-top: 40px;
  }

  .btn-green.myNetDiary {
    height: 32px;
    max-width: 52px;
    margin-top: -5px;
    line-height: 20px;
    margin-left: 27%;
  }

  .btn-green.reset-password {
    margin-top: 93px;
    margin-left: auto;
    margin-right: auto;
  }
}

@mixin blueBtn {
  border: 2px solid rgba(var(--main-button-border-rgb), 0.3);
  opacity: 1;
  height: 46px;
  border-radius: 4px;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.38px;
  margin: 0;
  color: var(--main-black) !important;
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
  background-color: transparent;


  &:hover {
    background-color: var(--main-border-button-hover) !important;
  }

  .btn-blue.dashboard {
    width: 140px;
    text-align: center;
    padding-top: 8px;
  }

  @media all and (min-width: 1250px) and (max-width: 1330px) {
    .btn-blue.dashboard {
      width: 110px;
    }
  }

  .btn-blue.dashboard.disabled {
    opacity: 0.5;
    cursor: initial;
  }

  .btn-blue.dashboard.disabled:hover {
    opacity: 0.5;
    background-color: transparent !important;
  }

  .btn-blue.new-serial-number {
    width: 189px;
  }

  .btn-blue.sales-apply {
    max-height: 30px;
    line-height: 20px;
    margin-right: 45px;
    margin-left: 16px;
  }

  .btn-blue.sales-apply.mobile {
    margin-right: 15px;
    margin-left: 0;
  }
}

@mixin feesTable {
  &-header {
    padding: 0 17px;

    &__text {
      color: #6c7188;
      font-weight: 700;
      letter-spacing: -.34px;
      min-width: 140px;

      &:first-child {
        min-width: 210px;
      }
    }
  }

  .header-range {
    p {
      &:nth-of-type(1) {
        min-width: 208px;
      }

      &:nth-of-type(2) {
        min-width: 180px;
      }
    }
  }

  .bundle-item {
    padding-top: 5px;

    .dash {
      font: var(--title-16-bold);
    }
  }

  md-input-container {
    margin-top: 10px;
    margin-bottom: 0;
  }

  .outline {
    margin-top: 28px;
    margin-bottom: 16px;
    margin-left: 15px;
    height: 35px;
  }

  .dollar-icon {
    padding-left: 20px;
    margin-right: 0;

    & + md-input-container {
      margin-left: 0;
    }
  }

  .md-icon-button {
    background: none;

    .trash {
      width: 14px;
      height: 14px;
      min-width: 14px;
      min-height: 14px;
    }
  }
}
