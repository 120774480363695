.container {
  padding: 16px;
  cursor: pointer;
  background-color: var(--white);
  border: 1px solid var(--white);
}

@media (hover) {
  .container:hover {
    border: 1px solid var(--primary-500);
  }
}

.checkedContainer {
  border: 1px solid var(--primary-500);
}
