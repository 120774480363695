.container {
  display: flex;
  gap: 8px;
  align-items: center;
}

.title {
  font-family: var(--body-14-bold);
  color: var(--main-black);
}

.subtitle {
  font-family: var(--body-14-regular);
  color: var(--natural-600);
}