.new-members-added {
  .list {
    &-item {
      padding: 10px;

      &:not(:first-child) {
        border-bottom: 1px solid #e4e6ec;
        min-height: 53px;
      }

      &:first-child {
        padding: 0 10px;

        .list-item__text {
          color: var(--natural-600);
        }
      }

      &:last-child {
        border: none;
      }

      &__text {
        font-weight: 700;
        letter-spacing: -.34px;
        font-size: 14px;
        color: #000000;
        margin: 0;
        padding-right: 8px;
      }

      &.bold {
        font-size: 16px;
        letter-spacing: -.38px;
        font-weight: 900;
      }
    }

    &:first-child {
      .list-item:not(:first-child) {
        border-right: 1px solid #e4e6ec;
      }
    }
  }
}
