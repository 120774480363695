md-checkbox {
  margin: 0;
  min-width: 23px;
  height: 23px;
  &.md-checked {
    .md-icon {
      background-color: var(--main-black);

      &:after {
        left: 7px;
        top: 1px;
        width: 7px;
        height: 14px;
        border-color: #ffffff;
      }
    }
  }

  .md-container {
    width: 23px;
    height: 23px;
  }

  .md-icon {
    border-radius: 3px;
    border-width: 1px;
    width: 23px;
    height: 23px;
  }

  .md-label {
    font-size: 16px;
    font-weight: 700;
    color: var(--main-black);
    margin-bottom: 2px;
    line-height: 23px;
  }
}

.not-rec {
  .md-label {
    margin-left: 0;
  }

  .md-icon {
    display: none;
  }
}

@mixin md-checkbox {
  margin: 0;
  min-width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  &.md-checked {
    .md-icon {
      background-color: var(--primary-500);
      border-color: transparent!important;
    }
  }

  &[disabled] {
    .md-label {
      color: inherit;
    }
  }

  .md-container {
    width: 18px;
    height: 18px;

    .md-icon {
      border-radius: 3px;
      border-width: 2px;
      width: 18px;
      height: 18px;
      border-color: #323232;

      &:after {
        left: 4px;
        top: -1px;
        width: 7px;
        height: 12px;
        border-color: #ffffff;
      }
    }
  }
}

.md-checkbox-primary {
 @include md-checkbox;

  &.md-checked {
    &[disabled] .md-icon {
      background-color: var(--primary-500);
      opacity: 0.3;
    }
  }
}

.md-checkbox-secondary {
  @include md-checkbox;

  &.md-checked {
    .md-icon {
      background-color: var(--main-black);
    }

    &[disabled] .md-icon {
      background-color: #8B8B94;
    }
  }
}

.md-checkbox-green {
  @include md-checkbox;

  .md-container > .md-icon {
    border-color: #B6B6C2;
  }

  &.md-checked {
    .md-icon {
      background-color: var(--success-300);
    }
  }
}
