.description {
  margin-bottom: 14px;
  font: var(--body-14-regular);
}

.stripeBlock {
  margin-bottom: 24px;
}

.linkTitle {
  margin-bottom: 8px;
  font: var(--body-14-bold);
}
