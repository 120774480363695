.actionBar {
  button {
    width: auto;
    height: auto;
  }
}

.digitalClockSectionItem {
  border-radius: 4px;

  &:global {
    &.Mui-selected:hover {
      background-color: var(--primary-500);
    }
  }

  &:hover {
    background-color: var(--natural-100);
  }
}
