.titleBox {
  padding: 0 20px;

  .titleRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 76px;

    .title {
      font: var(--title-28-bold);
    }
  }
}

.marginBottom {
  margin-bottom: 8px;
}

.marginTop {
  margin-top: 24px;
}
