.entity-profile {
  max-width: 1376px;
  width: 100%;
  margin: 32px auto;

  .app-card-body.serial-number {
    md-input-container {
      margin-top: 32px;
    }
  }

  .add-item-btn {
    width: 220px;
    height: 75px;
    margin: 0 0 15px 15px;
    border-radius: 7px;
    padding-top: 12px;
    border: 2px dashed rgba(136, 140, 158, 0.36);
    background-color: rgba(75, 90, 120, 0.04);
    color: var(--natural-600);
    font-size: 12px;
    font-weight: 700;
    letter-spacing: -0.29px;
    text-align: center;
    cursor: pointer;

    span {
      width: 50%;
      margin: 0 auto;
    }

    .add-icon {
      height: 28px;
      width: 28px;
      margin: 2px auto;
      border-radius: 50%;
      background: rgba(203, 205, 213, 0.5);
      -webkit-text-fill-color: var(--natural-600);

      &:before {
        content: '+';
        font-size: 20px;
        margin: auto;
      }
    }
  }

  .subscription-loading-message {
    font-size: 14px;
    font-weight: 700;
    padding: 0 4px 12px 4px;
    opacity: 0.9;
    justify-content: space-between;
    border-bottom: 1px solid rgba(var(--link-btns-rgb), .08 );

    p {
      opacity: 0.7;
    }
  }


  .title-page {
    font-weight: 900;
    color: #000000;
    font-size: 28px;
    letter-spacing: -.67px;
    line-height: 45px;
    margin: 0;
  }

  .available-corporate-accounts {
    font-weight: 900;
    color: var(--main-black);
    font-size: 28px;
    letter-spacing: -.67px;
    margin: 0;

    span {
      color: var(--primary-500);
    }
  }

  button[type="button"].outline {
    margin-right: 10px;
  }

  button.delete-contact-number {
    border-radius: 0;
    padding: 0;
    font-weight: 900;
    border-bottom: 1px solid var(--primary-500);
    height: 19px;
  }

  button.button-connect-light-speed {
    width: 171px;
    height: 45px;
    background: url("../../../assets/images/vectors/connect_to_lightspeed.png") no-repeat center;
    background-size: contain;
  }

  .info {
    margin-left: 15px;

    md-icon {
      margin: 0 15px 0 0;
      min-width: 16px;
      width: 16px;
      min-height: 16px;
      height: 16px;
    }

    &__text {
      color: #666666;
      font-size: 12px;
      letter-spacing: .17px;
      width: 589px;
      margin: 0;
    }
  }

  .text-connected-light-speed {
    color: #000000;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.38px;
    display: flex;
    align-items: center;
    margin-bottom: 25px;

    span {
      color: var(--primary-500);
    }

    &:before {
      content: '';
      width: 17px;
      height: 17px;
      background-color: var(--primary-500);
      border-radius: 50%;
      margin-right: 5px;
      background-image: url("../../../assets/images/material-icons/done.svg");
      background-size: 13px;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .table-allow-supplement-users {
    &-header {
      padding: 0 48px;

      &__text {
        color: #6c7188;
        font-weight: 700;
        letter-spacing: -.34px;
        min-width: 130px;

        &:first-child {
          min-width: 238px;
        }
      }
    }
    md-input-container {
      margin-top: 10px;
      margin-bottom: 0;
    }
    .outline {
      margin-top: 28px;
      margin-bottom: 16px;
      height: 35px;
    }
  }

  .table-certificates {
    &-header {
      padding: 0 17px;

      &__text {
        color: #6c7188;
        font-weight: 700;
        letter-spacing: -.34px;
        min-width: 130px;

        &:first-child {
          min-width: 238px;
        }
      }
    }

    .outline {
      margin-top: 28px;
      margin-bottom: 16px;
      margin-left: 15px;
      height: 35px;
    }

    md-input-container {
      margin-top: 10px;
      margin-bottom: 0;
    }

    input:disabled {
      color: black;
      border: none;
    }
  }

  .plan-options {
    &-actions {
      button {
        min-width: 35px;
        height: 35px;
        margin-left: 10px;
        padding: 0;
        display: flex;

        md-icon {
          min-width: 14px;
          min-height: 14px;
          width: 14px;
          height: 14px;
          color: #ffffff;
        }

        &.outline {
          md-icon {
            color: #000000;
          }
        }
      }
    }

    .corporate-plan-card {
      width: 250px;
      height: 267px;
      box-shadow: 0 7px 20px rgba(96, 105, 157, 0.19);
      border-radius: 7px;
      background-color: #ffffff;
      padding: 25px;
      margin-right: 15px;

      &.selected {
        border: 3px solid var(--success-300);
      }

      &__image {
        width: 146px;
        height: 131px;
        margin: auto;
        position: relative;

        &.blue {
          background: url("../../../assets/images/vectors/corporate-plan-blue.svg") no-repeat left center;
        }

        &.green {
          background: url("../../../assets/images/vectors/corporate-plan-green.svg") no-repeat left center;
        }

        &.red {
          background: url("../../../assets/images/vectors/corporate-plan-red.svg") no-repeat left center;
        }

        &.purple {
          background: url("../../../assets/images/vectors/corporate-plan-purple.svg") no-repeat left center;
        }

        &.lightgreen {
          background: url("../../../assets/images/vectors/corporate-plan-lightgreen.svg") no-repeat left center;
        }

        &.seablue {
          background: url("../../../assets/images/vectors/corporate-plan-seablue.svg") no-repeat left center;
        }

        &.yellow {
          background: url("../../../assets/images/vectors/corporate-plan-yellow.svg") no-repeat left center;
        }

        &.orange {
          background: url("../../../assets/images/vectors/corporate-plan-orange.svg") no-repeat left center;
        }

        &:before {
          content: attr(data-qty);
          color: #000000;
          font-size: 12px;
          font-weight: 900;
          letter-spacing: -0.31px;
          position: absolute;
          left: 0;
          right: 28px;
          top: 5px;
          bottom: 0;
          margin: auto;
          height: 18px;
          width: 30px;
          text-align: center;
        }
      }

      &__text {
        color: #000000;
        font-size: 18px;
        font-weight: 700;
        margin: 0;

        span {
          display: block;
          font-size: 12px;
          letter-spacing: -0.29px;
          opacity: 0.5;
        }
      }
    }
  }

  .members-inputs-wrapper {
    border-top: 1px solid rgba(120, 128, 162, 0.2);
    margin: 30px 0;
    padding-top: 20px;
  }

  .change-password {
    padding: 25px;

    &__title {
      font-weight: 900;
      color: var(--main-black);
      font-size: 20px;
      letter-spacing: -0.48px;
      margin-left: 15px;
      margin-bottom: 25px;
    }

    button[type="submit"] {
      margin-top: 15px;
      margin-left: 15px;
      display: block;
    }
  }

  .customer-pricing {
    &__header-input-container {
      font-weight: 900;
      font-size: 28px;
    }

    &__supplement-subscription-indicator {
      min-width: 220px;
      margin: 24px 0 5px 13px !important;
    }

    &__select-status {
      &.md-icon-right {
        md-icon {
          top: 10px;
          height: 14px;
          width: 14px;
          min-height: 14px;
          min-width: 14px;
        }

        md-icon[md-svg-icon="active-status"] {
          height: 20px;
          width: 20px;
        }

        md-icon[md-svg-icon="arrow-down"] {
          top: 0;
        }
      }

      .md-select-value > span:not(.md-select-icon) .md-text {
        color: #F66464;
      }

      &.active {
        .md-select-value > span:not(.md-select-icon) .md-text {
          color: #4dcb8a;
        }
      }
    }

    md-icon[md-svg-icon="arrow-age"] {
      min-width: 14px;
      width: 14px;
      min-height: 14px;
      height: 14px;
      margin: auto 14px;
      @media screen and (max-width: 1279px) {
        margin: 0 auto 20px;
        transform: rotate(90deg);
      }
    }

    button.outline {
      margin-top: 20px;
    }

    button[type="submit"] {
      margin-top: 20px;
      margin-left: 10px;
      display: block;
    }

    .app-card {
      &-subscription {
        padding: 12px 10px 0 10px;
        box-shadow: 0 3px 22px 4px rgba(96, 105, 157, .1);
        margin-bottom: 16px;
        border-radius: 5px;
      }

      &-header {
        border: 1px solid rgba(120, 128, 162, 0.2);
        background-color: #f8f8fa;
        border-radius: 7px 7px 0 0;
      }

      &-footer {
        &__title {
          color: var(--light_storm_grey);
          font-size: 14px;
          font-weight: 700;
          letter-spacing: -.34px;
        }

        &__price {
          font-size: 27px;
          margin: 0;
          letter-spacing: -.72px;
          color: var(--main-black);
          font-weight: 900;

          &.small {
            font-size: 20px;
          }

          span {
            color: var(--main-black);
            font-size: 14px;
            letter-spacing: -.34px;
            margin-left: 8px;
          }
        }
      }

      &.with-margin-left {
        margin-left: 18px;
        @media screen and (max-width: 1279px) {
          margin-left: 0;
        }
      }

      &.with-margin-right {
        margin-left: 0;
        @media screen and (max-width: 1279px) {
          margin-right: 18px;
        }
      }

      md-input-container {
        margin: 15px 13px 5px;
      }
    }

  }

  .subscriptions {
    padding: 25px;

    &__title {
      letter-spacing: -.34px;
      font-size: 14px;
      font-weight: 700;
      color: var(--natural-600);

      &:not(:first-child) {
        margin-top: 50px;
      }
    }

    .list {
      &-item {
        margin-right: 20px;
        margin-top: 20px;

        .subscription-card {
          width: 320px;
          background-image: linear-gradient(-80deg, #000000 0, #A0A0A0 100%);
          box-shadow: 0 7px 22px rgba(96, 105, 157, .1);
          border-radius: 7px;
          padding: 18px;

          &__text {
            font-size: 16px;
            font-weight: 700;
            letter-spacing: -.38px;
            color: #ffffff;

            &.light {
              letter-spacing: -.34px;
              font-size: 14px;
              opacity: .5;
              margin: 0;
            }
          }

          &.blue {
            background-image: linear-gradient(-80deg, #E17745 0, #FFBA53 100%);
          }

          &.expired {
            background-image: linear-gradient(-84deg, #828da3 0, #51596a 100%);
          }
        }

        &__notify-text {
          font-size: 10px;
          font-weight: 700;
          letter-spacing: -.24px;
          color: var(--light_storm_grey);
          margin-top: 5px;
        }
      }
    }

    &-actions {
      margin-top: 50px;

      button {
        margin-left: 10px;
      }
    }
  }

  .field-container {
    padding-left: 16px;
    height: 200px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    md-checkbox {
      margin: 12px 0;
    }
  }

  .field-container-inbody-types {
    padding-left: 16px;

    md-checkbox {
      margin: 12px 0;
      min-width: 200px;
    }
  }

  .input-container {
    &--zip-code {
      height: 58px;
    }
  }
}
