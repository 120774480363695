.customer-setup-step-3 {
  .app-card {
    &:first-child {
      .app-card-header {
        border-width: 1px;
      }
    }

    &-body {
      padding-left: 32px;
    }

    &-footer {
      &__text {
        margin: 0;
        font-weight: 700;
        letter-spacing: -.34px;
        color: #000000;
        opacity: .3;
        text-align: center;

        md-icon {
          min-width: 20px;
          min-height: 20px;
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }
      }
    }
  }

  .border-left {
    border-left: 1px solid rgba(120, 128, 162, 0.2);
  }

  .info {
    text-align: center;
    color: #000000;
    opacity: .3;
    margin: 74px 0;

    md-icon {
      margin: 3px 10px 0 0;
      min-width: 16px;
      width: 16px;
      min-height: 16px;
      height: 16px;
    }

    &__text {
      margin: 0;
      font-weight: 700;
      letter-spacing: -.34px;
      text-align: center;
    }
  }

  .post-pre-workout-table {
    margin-top: 26px;

    &__text {
      font-size: 14px;
      font-weight: 700;
      letter-spacing: -.34px;
      opacity: .5;
      white-space: nowrap;
    }

    md-input-container {
      margin: 0 10px 0 0;
    }
  }

  md-radio-group {
    margin: 12px 0 24px 0;

    md-radio-button {
      margin-right: 50px;
    }
  }

  .video-under-construction-block {
    width: 100%;
    text-align: center;
    padding: 30px 0;

    &__text {
      font-size: 16px;
      font-weight: 700;
      letter-spacing: -0.39px;
      color: #000000;
      opacity: .5;
    }
  }

  .under-construction {
    text-align: center;
    padding: 30px 0;
    color: #000000;
    font-weight: 700;
    letter-spacing: 0.09px;

    &__description {
      margin-top: 20px;
    }
  }

}
