.container {
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 10px 16px;
  background-color: #1F1F1FA3;
  border: 1px solid #FFF3;
  border-radius: 9px;
}

.timeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.time {
  font: var(--title-22-bold);
  color: var(--background-50-light);
}

.divider {
  align-self: flex-start;
  font: var(--title-22-bold);
  color: var(--natural-400);
}

.description {
  font: var(--body-12-medium);
  color: var(--natural-400);
}