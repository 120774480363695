.seatsCount {
  font: var(--title-16-medium);
  color: var(--shades-900);
}

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.formContainer {
  display: flex;
  flex-direction: row;
  gap: 16px;
}