.container {
  display: flex;
  gap: 16px;
  align-items: center;
  padding: 20px 24px;
  margin-bottom: 16px;
}

.avatarBox {
  position: relative;
}

.infoBox {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.name {
  font: var(--title-28-bold);
}

.descriptionBox {
  display: flex;
  gap: 12px;
  align-items: center;
  font: var(--body-14-medium);

  .inbodyInfo {
    color: var(--natural-700);

    & > *:not(:first-child)::before {
      padding: 0 8px;
      content: '|';
    }
  }
}

.extraOptionsBox {
  margin-left: auto;
}
