.btnBox {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  width: 100%;

  .applyBtn {
    flex-grow: 1;
  }
}
