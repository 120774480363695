.empty-list-placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px 0;
  background: var(--no-compliance-board) no-repeat center;
  color: var(--nothing-found-text);
  font-weight: 700;
  letter-spacing: 0.09px;
  background-size: 124px 153px;

  &__description {
    margin-top: 200px;
  }
}
