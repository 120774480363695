.infoBlock {
  display: flex;
  flex-direction: column;
  gap: 24px;
  color: var(--natural-700);
  text-align: center;

  img {
    max-width: 280px;
    margin: 0 auto;
  }

  .infoText {
    font: var(--title-16-medium);
  }

  .buttonBlock {
    display: flex;
    flex-wrap: wrap;
    gap: 16px 12px;

    a {
      flex-grow: 1;
    }
  }

  .footerText {
    font: var(--body-14-medium);
  }
}
