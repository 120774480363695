.coach-list {
  max-width: 1376px;
  width: 100%;
  margin: 32px auto;

  .link-panel {
    font-size: 14px;
    text-decoration: none;
    opacity: 1;
  }

  .title-page {
    font-weight: 900;
    color: #000000;
    font-size: 28px;
    letter-spacing: -.67px;
    line-height: 45px;
    margin: 0;
  }

  .filter {
    margin-top: 40px;
    background-color: #fefefe;
    padding: 17px;

    &-list-input {
      margin-right: 16px;

      &.large {
        min-width: 18%;
      }

      &.small {
        max-width: 7%;
      }
    }

    &-list__text, &-list__label {
      color: #000000;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: -.34px;
      padding: 0 10px 0 0;
      margin-bottom: 0;
    }

    &-list__label {
      color: var(--natural-600);
    }
  }

  .list {
    &-item {
      box-shadow: 0 7px 22px rgba(96, 105, 157, 0.07);
      background-color: #fefefe;
      margin: 10px 0;
      padding: 0 17px;
      z-index: 2;
      position: relative;

      &:hover {
        box-shadow: 0 4px 15px rgba(96, 105, 157, 0.22);
      }

      md-menu {
        margin-left: auto;
      }

      &__text {
        padding: 17px 0;
        margin: 0 10px 0 0;
        color: #000000;
        font-weight: 700;
        font-size: 14px;
        letter-spacing: -0.34px;
        overflow: hidden;
        text-overflow: ellipsis;

        &.large {
          min-width: 18%;
        }

        &.small {
          max-width: 7%;
        }

        md-icon {
          cursor: pointer;
          margin-left: 16px;
        }

        .email {
          display: block;
          opacity: 0.5;
          text-overflow: ellipsis;
          width: 100%;
          overflow: hidden;
        }

        &:not(:first-child) {
          opacity: .8;
        }

        &:first-child {
          cursor: pointer;
        }

        &.red {
          color: #f66464;
        }
      }
    }
  }

  &__submit {
    position: absolute;
    visibility: hidden;
  }
}
