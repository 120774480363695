.checkReleases {
  display: flex;
  align-items: center;
  height: 32px;
  background: var(--success-300);

  .linkBtn {
    flex-grow: 1;
    font: var(--body-12-bold);
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  button {
    color: var(--white);

    &:hover {
      color: var(--main-black);
      background-color: transparent;
    }
  }
}

@media print {
  .checkReleases {
    display: none;
  }
}
