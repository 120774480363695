.widgetBox {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10;

  .titleBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;

    .titleInfo {
      h5 {
        font: var(--title-16-bold);
      }

      p {
        font: var(--body-14-medium);
        color: var(--natural-600);
      }
    }
  }
}
