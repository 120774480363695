.box {
  padding: 40px 20px;
  margin-top: 141px;
  font: var(--title-16-regular);
  text-align: center;

  .contentBox {
    display: grid;
    grid-auto-flow: row;
    gap: 24px;
    max-width: 512px;
    margin: 0 auto;

    .titleBox {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      align-items: center;
      justify-content: center;

      h1 {
        font: var(--title-28-bold);
      }
    }

    .infoBox {
      display: grid;
      grid-auto-flow: row;
      gap: 16px;

      .description {
        font: var(--title-16-bold);
      }
    }
  }
}
