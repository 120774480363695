.cardHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  padding: 0 32px;
  border-bottom: 1px solid var(--natural-300);

  p {
    font: var(--title-16-black);
  }

  .btnBox {
    display: flex;
    gap: 8px;
  }
}
