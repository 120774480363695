.customer-meal-plan-macros {
  #vertical-container {
    height: 292px;
    width: 100%;
    max-width: 400px;
  }

  .md-virtual-repeat-container .md-virtual-repeat-offsetter div {
    padding-left: 16px;
  }
}