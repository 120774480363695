.row {
  min-height: 56px;
  padding: 12px;
  cursor: pointer;
  border: 1px solid var(--white);
}

.checkedRow {
  border: 1px solid var(--primary-500);
}

@media (hover) {
  .row:hover {
    border: 1px solid var(--primary-500);
  }
}
