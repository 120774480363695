.box {
  display: flex;

  &.column {
    flex-direction: column;
  }

  &.row {
    flex-direction: row;
  }
}

.gap {
  gap: 4px;
}

.body-14-bold {
  font: var(--body-14-bold);
}

.title-16-bold {
  font: var(--title-16-bold);
}

.title-22-bold {
  font: var(--title-22-bold);
}

.inherit {
  font: inherit;
}

.description {
  font: var(--body-14-regular);
}

.natural-600 {
  color: var(--natural-600);
}

.natural-700 {
  color: var(--natural-700);
}

.main-black {
  color: var(--main-black);
}

.fullWidth {
  width: 100%;
}
