.leaderboard {
  .list {
    &-item {
      padding: 10px;

      &:not(:first-child) {
        border-bottom: 1px solid #e4e6ec;
        min-height: 60px;

        &:before {
          content: attr(data-index);
          font-weight: 900;
          min-width: 19px;
          line-height: 19px;
          border-radius: 50%;
          background-color: #ffca1d;
          color: #fff;
          font-size: 10px;
          letter-spacing: -.24px;
          text-align: center;
          margin-right: 11px;
        }
      }

      &:first-child {
        padding: 0 10px;

        .list-item__text {
          color: var(--natural-600);

          &:first-child {
            padding-left: 30px;
          }
        }
      }

      &:last-child {
        border: none;
      }

      &:nth-child(2) {
        &:before {
          content: '';
          min-width: 26px;
          height: 20px;
          border-radius: 0;
          background: url("../../../assets/images/vectors/leaderboard_crown.svg") no-repeat left;
          margin-right: 5px;
        }
      }

      &__text {
        padding-right: 8px;
        font-weight: 700;
        letter-spacing: -.34px;
        font-size: 14px;
        color: #000000;
        margin: 0;
      }

      &.bold {
        font-size: 16px;
        letter-spacing: -.38px;
        font-weight: 900;
      }
    }

    &.overview-with-goal {
      height: 370px;
      overflow-y: hidden;
      margin-bottom: 20px;
    }
    &.overview-without-goal {
      height: 555px;
      overflow-y: hidden;
      margin-bottom: 20px;
    }
  }
}
