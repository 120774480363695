.cancel-subscription-modal {
  padding: 34px;
  background: #FBFBFB;

  .header {
    margin-bottom: 15px;
    &__title {
      margin-bottom: 7px;
      font: var(--title-28-black);
    }
    &__text {
      font: var(--title-16-regular);
      color: var(--natural-500);
    }
  }

  .main {
    display: flex;
    justify-content: space-between;

    .header {
      margin-bottom: 15px;
      font: var(--title-18-bold);
    }
  }

  .statistic {
    margin-right: 24px;

    &__card {
      margin-bottom: 12px;
    }

    .card {
      width: 470px;
      padding: 20px;
      border: #fff 1px solid;
      border-radius: 8px;
      background: #fff;
      box-shadow: 0 7px 22px rgba(96, 105, 157, 0.01);

      &__icon {
        img {
          width: 42px;
        }
        margin-right: 14px;
      }
    }
  }

  .list {
    &__item {
      margin-bottom: 12px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .item {
    &__text {
      font: var(--body-14-regular);
    }

    &__value {
      font: var(--body-14-bold);

      &_green {
        color: var(--success-300);
      }

      &_blue {
        color: var(--info-300);
      }

      &_red {
        color: var(--error-300);
      }

      &_yellow {
        color: var(--primary-500);
      }
    }
  }

  .feedback {
    padding-left: 10px;

    &__subtitle {
      padding-bottom: 15px;
      font: var(--title-16-regular);

      ~ .feedback__subtitle {
        padding: 20px 0 15px;
      }
    }

    .md-checkbox-green {
      margin-bottom: 10px;

      .md-label {
        font: var(--body-14-regular);
      }
    }

    &__description {
      width: 100%;
      height: 220px;
      margin: 0;
      padding: 7px 12px;
      border-color: #B6B6C2;
      border-radius: 8px;
      resize: none;

      &::placeholder {
        font: var(--body-14-regular);
        color: var(--natural-300);
      }
    }
  }

  .btn-wrapper {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;

    .button {
      width: 165px;
      margin-left: 20px;
    }
  }

  .couch-img {
    width: 307px;
    position: absolute;
    left: 0;
    bottom: 0;
  }
}