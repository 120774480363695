@import '../../../../src/sass/reset';

.child-entity-list {
  &__title {
    padding: 30px 0 0 0;
    font: var(--title-28-black);
  }

  &__card-list {
    display: flex;
    flex-wrap: wrap;
    padding: 30px 0 0 0;
  }

  .card-list {
    &__card {
      width: 330px;
      height: 200px;
      margin: 0 24px 24px 0;
      padding: 24px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .card {
    background: #fff;
    box-shadow: 0 7px 22px rgba(96, 105, 157, 0.01);
    border-radius: 16px;
    color: var(--main-black);

    &__bottom-row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__title {
      font: var(--title-22-black);
      margin: 0 0 10px 0;
    }

    &__subtitle {
      text-align: start;
      font: var(--title-16-regular);
    }

    &__text {
      font: var(--body-14-bold);
      padding: 0 14px;
      color: var(--primary-500);

      &:first-of-type {
        padding-left: 0;
        border-right: 1px solid var(--primary-500);
      }
    }

    &__btn {
      display: none;

      md-icon {
        color: inherit;
      }
    }
  }

  .selected {
    cursor: default;
    background: var(--primary-500);
    color: #fff;

    .card__text {
      color: #fff;
      border-color: #fff;
    }

    .card__btn {
      display: block;
    }
  }
}
