.app-card {
  background-color: #fff;
  border-radius: 7px;
  box-shadow: 0 7px 22px rgba(96, 105, 157, .1);
  margin-bottom: 20px;

  &.with-margin-left {
    margin-left: 20px;
  }

  &-header {
    padding: 15px 24px 15px 32px;
    border-bottom: 2px solid transparentize(#7880a2, .8);

    &__title {
      font-weight: 900;
      color: var(--main-black);
      font-size: 16px;
      letter-spacing: -0.38px;
      margin: 0;
    }

    &__sub-title {
      color: rgba(30, 35, 62, 0.4);
      font-size: 16px;
      font-weight: 700;
      margin-left: 16px;
      margin-bottom: 0;
    }

    &.closed {
      border-bottom: none;
    }
  }

  &-body {
    padding: 15px;
  }

  &-footer {
    border-top: 1px solid transparentize(#7880a2, .8);
    padding: 15px;
  }
}
