.supplementRow {
  display: grid;
  grid-template-rows: 44px;
  grid-template-columns: 3fr 1.85fr 1.85fr 1.4fr;
  font: var(--body-14-regular);
  border-bottom: 1px solid var(--natural-200);

  &:last-of-type {
    border-bottom: none;
  }
}
