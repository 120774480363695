.container {
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: 0.75fr 1fr;
  width: 100%;
  min-height: 100vh;

  @media (width <= 768px) {
    grid-template-columns: 1fr;
  }
}