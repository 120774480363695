.box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;
  margin-bottom: 8px;
}

.mealPlanName {
  font: var(--title-28-bold);
}

.mealEditActions {
  display: flex;
  gap: 12px;
  align-self: start;
}
