.rowTemplate {
  display: grid;
  grid-template-rows: 56px;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  padding: 0 32px;
  border-bottom: 1px solid var(--background-50-dark);

  div:nth-child(1) {
    justify-self: start;
  }

  div:nth-child(2) {
    justify-self: center;
  }

  div:nth-child(3) {
    justify-self: end;
  }
}
