.location-metrics {

  .canvas-wrapper {
    width: 560px;
    height: 200px;
    margin: 22px auto;
  }

  &__total {
    color: var(--main-black);
    font-size: 26px;
    font-weight: 700;
    letter-spacing: -.62px;
    margin: 0 0 0 20px;
  }

  &__chart-signature {
    color: var(--natural-600);
    font-weight: 700;
    letter-spacing: -.38px;
    margin: 0 0 0 20px;
  }

  &__result {
    letter-spacing: -.34px;
    font-weight: 700;
    color: var(--natural-600);
    font-size: 12px;
    margin: 10px 0 0 10px;

    &:before {
      content: '';
      width: 0;
      height: 0;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      display: inline-block;
      margin-bottom: 1px;
    }

    &.up {
      color: #f66464;

      &:before {
        border-top: 5px solid #f66464;
      }
    }

    &.down {
      color: #58ca8c;

      &:before {
        border-bottom: 5px solid #58ca8c;
      }
    }
  }
}
