.titleRow {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 30px;
  padding: 16px 32px;
  margin-bottom: 1px;
  font: var(--body-14-bold);
  color: #666;
  background-color: var(--white);
  border-radius: 8px 8px 0 0;
}

.formBox {
  border-radius: 0 0 8px 8px;
}
