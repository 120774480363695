.textContainer {
  display: flex;
  gap: 2px;
  align-items: center;
  font: var(--body-14-bold);

  .rateIco {
    color: var(--warning-400);
  }
}
