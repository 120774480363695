.testBox {
  margin-bottom: 16px;

  .cardHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 56px;
    padding: 0 32px;
    border-bottom: 1px solid var(--natural-300);

    input {
      font: var(--title-16-black);
      background-color: transparent;
      border: none;
    }
  }

  .cardFooter {
    border-top: 1px solid var(--natural-300);
  }

  .addTestResultBtn {
    margin: 16px 32px;
  }
}
