.answerInfoRow {
  display: grid;
  grid-template-columns: 1fr 2.5fr;
  gap: 16px;
  font: var(--body-14-medium);
}

.infoTitle {
  font: var(--body-14-bold);
}
