.row {
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: center;
  height: 32px;
  font: var(--body-14-bold);
  color: var(--info-300);
  background-color: #edf2ff;
}
