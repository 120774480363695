.bgGroup {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: space-between;
}

.bgCard {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: start;
  cursor: pointer;

  .bgImg {
    width: 116px;
    aspect-ratio: 1;
    object-fit: cover;
    border: transparent 1px solid;
    border-radius: 8px;
  }

  &:hover .bgImg {
    border: 2px solid var(--primary-500);
  }

  &:has(input[type='radio']:checked) .bgImg {
    border: 2px solid var(--primary-500);
  }
}
