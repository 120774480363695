.compliance-coach-list {
  border-radius: 7px;
  background: #fff;
  width: 100%;
  padding-bottom: 24px;

  &-search {
    padding: 14px 24px 24px 24px;
  }

  &-header {
    padding: 10px 26px 10px 26px;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: -0.34px;
    color: var(--natural-600);
    border-top: 1px solid #e4e6ec;
    border-bottom: 1px solid #e4e6ec;
  }

  &-item-row {
    padding: 17px 26px 4px 26px;
    color: #000000;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.38px;
  }

  &-delete-link {
    p {
      cursor: pointer;
      max-height: 24px;
      color: #f66464;
      border-bottom: 1px solid rgba(246, 100, 100, 0.15);
    }

    p:hover {
      opacity: 0.7;
    }

    md-icon {
      margin: 4px 8px 0 0;
      color: #f66464 !important;
      min-height: 16px;
      max-height: 16px;
      min-width: 16px;
      max-width: 16px;
    }
  }

  &__status {

    md-icon {
      padding: 2px;
      min-width: 20px;
      width: 20px;
      height: 20px;
      min-height: 20px;
      background: #58ca8b;
      border-radius: 50%;
      display: block;
      margin: 0;
      color: #ffffff;

      &[md-svg-icon="pos-status"] {
        background: #FAA46F;
        animation: rotating 2s linear infinite;
      }

      &[md-svg-icon="close"] {
        padding: 0;
        background: #d5d7dc;
      }

      @keyframes rotating {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
    }

    span {
      font-weight: 700;
      color: #000000;
      margin-left: 5px;
      text-transform: capitalize;
    }
  }
}

md-autocomplete-wrap {
  border: 1px solid #a4a6b1;
  border-radius: 3px;
  box-shadow: none;
  opacity: 0.5;
  font-weight: 700;
}

.md-whiteframe-z1 {
  box-shadow: none;
}

md-autocomplete#compliance-coach-list-template {
  min-width: 394px;
  max-width: 394px;
  background: url("../../../assets/images/material-icons/search.svg") no-repeat 365px;
  background-size: 18px;
}

.md-virtual-repeat-container.md-autocomplete-suggestions-container {
  margin-top: 4px;
  border-radius: 7px;
  box-shadow: 0 2px 6px rgba(57, 72, 101, 0.3);
  font-size: 16px;
  font-weight: 700;
  padding-right: 30px;
}

.autocomplete-compliance-coach-list-template li {
  height: auto;
  margin: 3px 3px;
  border-radius: 4px;
  padding: 14px 12px 6px 12px;
  white-space: normal;

  &:hover {
    background-color: rgba(255, 165, 20, 0.12);
  }
}

.autocomplete-compliance-coach-list-template .item-title {
  display: block;
  line-height: 1;
}
