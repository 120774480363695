.justify-start {
  justify-self: start;
}

.justify-end {
  justify-self: end;
}

.justify-center {
  justify-self: center;
  text-align: center;
}

.justify-stretch {
  justify-self: stretch;
}

.justify-auto {
  justify-self: auto;
}

.title {
  font: var(--body-14-bold);
}

.cell {
  font: inherit;
}
