.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
  background-color: black;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 28px;
  padding: 0 48px;
  margin-top: 48px;

  @media (width <= 768px) {
    padding: 0;
  }
}
