.recipe-list-container {
  max-width: 1376px;
  width: 100%;
  margin: 32px auto;

  .title-page {
    font-weight: 900;
    color: #000000;
    font-size: 28px;
    letter-spacing: -.67px;
    line-height: 45px;
    margin: 0;
  }
}

.recipe-list-tab-container {
  width: 100%;
  margin: 32px auto;

  .title-page {
    font-weight: 900;
    color: #000000;
    font-size: 24px;
    letter-spacing: -.67px;
    margin: 0;
  }

  .recipe-list {
    margin: 0 16px;
  }
}

.recipe-list {

  .title-text {
    margin: 32px 26px 16px 26px;
  }

  .item-row {
    font-size: 14px;
    text-decoration: none;
    padding: 6px 0;
    opacity: 1;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }

    &.disabled {
      pointer-events: none;

      md-switch {
        pointer-events: auto;
      }
    }
  }

  .list {
    margin-top: 20px;
    min-height: 100px;

    &-header {
      margin-bottom: 10px;
      padding: 0 17px;

      .list-item__text {
        font-size: 14px;
        color: var(--natural-600);
      }
    }

    &-item {
      box-shadow: 0 7px 22px rgba(96, 105, 157, 0.07);
      background-color: #ffffff;
      margin-bottom: 10px;
      min-height: 60px;
      padding: 0 17px;

      .thumb {
        position: relative;
        display: block;
        overflow: hidden;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin-right: 20px;
      }

      .thumb img {
        position: absolute;
        top:-100%; left:-100%; right: -100%; bottom:-100%;
        margin: auto;
        display: block;
        min-width: 100%;
        min-height: 100%;
        -ms-interpolation-mode: bicubic;
      }

      &.editable {
        &:hover {
          box-shadow: 0 4px 15px rgba(96, 105, 157, 0.22);

          a {
            cursor: pointer;
          }
        }
      }

      &.non-active {
        opacity: 0.5;
      }

      &__text {
        font-weight: 700;
        font-size: 16px;
        margin: 0;
        color: #000000;
        opacity: 1;
        padding-right: 24px;
        line-height: 30px;

        &-description {
          margin: 0;
          padding-right: 86px;
          overflow: hidden;
          text-overflow: ellipsis;
          line-height: 30px;
          font-weight: 500;
          color: #898989;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
        }
      }
    }
  }
}
