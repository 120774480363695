md-menu {
  .md-button:not(.md-icon-button) {
    margin: 0;
    color: var(--primary-500);
    text-transform: capitalize;
    font-weight: 700;
    font-size: 16px;
    display: flex;
    background: transparent !important;
    height: 40px;

    &:disabled {
      opacity: 0.5;
    }

    md-icon {
      color: var(--primary-500) !important;
      min-width: 14px;
      width: 14px;
      min-height: 14px;
      height: 14px;
      margin-right: 5px;

      &[md-svg-icon="communication"] {
        color: #000 !important;
      }
    }
  }

  .md-icon-button {
    &:disabled {
      opacity: 0.5;
    }

    md-icon {
      color: var(--main-black);
    }

    &.non-active {
      opacity: 0.5;
    }
  }
}


md-menu-content {
  padding: 4px !important;

  md-menu-item {
    min-height: 41px !important;
    height: 41px !important;
    padding: 0;
    border-radius: 3px;

    .md-button {
      min-height: 41px !important;
      color: #000000;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: -0.34px;
      padding: 0 11px;
      transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
      background-color: transparent !important;

    }

    &.selected {
      a, .md-button, md-icon {
        color: var(--primary-500) !important;
      }
    }

    &:not(.inactive):hover {
      border-radius: 3px;
      background-color: rgba(var(--default-rgb), 0.1) !important;
      color: var(--primary-500) !important;

      a, .md-button, md-icon {
        color: var(--primary-500) !important;
      }
    }

    md-icon {
      color: #575a6f;;
      min-width: 13px;
      width: 13px;
      margin-right: 6px !important;
      transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    }
  }

  md-divider {
    border-color: #f1f1f1;
    height: 1px !important;
    margin: 0 5px;
  }
}
