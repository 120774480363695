.block {
  display: flex;
  gap: 12px;
  align-items: center;
  margin: 0 16px 0 auto;

  .separationLine {
    width: 1px;
    height: 19px;
    background-color: var(--natural-700);
  }
}
