.container {
  position: sticky;
  top: 0;
  z-index: 2;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 24px;
  align-items: center;
  padding: 12px 14px;
  margin-bottom: 8px;
}

.mobileLayout {
  grid-template-columns: 1fr;
  grid-auto-flow: row;
  gap: 16px;
}
