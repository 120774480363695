.button {
  position: relative;
  display: flex;
  align-items: center;
  align-self: stretch;
  font: var(--title-16-bold);
  color: var(--natural-400);
  white-space: nowrap;

  &.active {
    color: var(--white);

    &::after {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: 3px;
      content: '';
      background-color: var(--primary-500);
      border-radius: 2px 2px 0 0;
    }
  }

  &:hover,
  &:focus {
    color: var(--white);
  }
}

.menuBox {
  :global .MuiMenu-paper {
    // 176 is width for longest item 'Active calendar users'
    min-width: 176px;
    margin-top: -10px;
  }
}
