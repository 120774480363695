.customerRow {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 10px;
  align-items: center;
  padding: 20px;
  margin-bottom: 8px;
  font: var(--body-14-bold);

  .inventoryBox {
    > div:nth-child(odd) {
      background-color: var(--background-50-light);
    }

    .inventoryRow {
      padding-right: 0;
    }
  }
}
