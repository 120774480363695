.pin-code {
  .app-card {
    max-width: 924px;
    width: 95%;
    padding: 54px 41px 54px 87px;
    margin: calc((100vh - 618px) / 2) auto;
  }

  &-input-container {
    margin-top: 45px;
    margin-bottom: 26px;
    margin-left: 0;
    width: 369px;

    input {
      background: url("../../../assets/images/vectors/pincode-icon.svg") no-repeat right;
    }
  }

  &-numbers-container {
    width: 369px;
    text-align: end;

    button {
      background-color: var(--white);
      color: var(--main-black);
      min-width: 115px;
      margin: 3px;
      font-size: 22px;
      font-weight: 700;
      letter-spacing: -.53px;
      text-align: center;
      box-shadow: 0 3px 5px rgba(0, 0, 0, .18);
      min-height: 50px;

      &:hover {
        background-color: rgba(151, 151, 151, 0.1);
      }

      &.remove-pin-number {
        background: transparent;
        box-shadow: none;

        &:hover {
          background: transparent;
        }
      }
    }
  }

  &__title {
    color: var(--main-black);
    opacity: .8;
    font-size: 28px;
    font-weight: 900;
    letter-spacing: -.67px;
    margin-bottom: 10px;
    margin-top: 8px;
  }

  &__subtitle {
    opacity: .66;
    color: var(--main-black);
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -.38px;
    line-height: 29px;
  }

  &__image {
    max-width: 370px;
    width: 50%;
    margin-left: 20px;
  }
}
