.certificates {
  max-width: 1376px;
  width: 100%;
  margin: 32px auto;

  .outline:disabled {
    opacity: 0.5;
  }

  .tooltip-container {
    position: absolute;
    width: 130px;
    height: 45px;
    margin-top: -45px;
  }

  a {
    text-decoration: none;
  }

  .title-page {
    font-weight: 900;
    color: #000000;
    font-size: 28px;
    letter-spacing: -.67px;
    line-height: 45px;
    margin: 0;
  }

  &-payment {
    &-item {
      &:not(:first-child) {
        margin-left: 40px;
      }
    }

    &__label {
      color: var(--natural-600);
      font-size: 14px;
      font-weight: 700;
    }

    &__text {
      margin: 0 10px 0 0;
      color: #000000;
      font-size: 18px;
      font-weight: 700;
      letter-spacing: -0.34px;

      span {
        font-size: 14px;
        opacity: .5;
      }
    }

    button {
      height: 35px;
      min-width: 95px;
    }
  }

  .filter {
    margin-top: 40px;
    background-color: #fefefe;
    padding: 17px;

    &-list-input {
      margin-right: 10px;
      padding-right: 32px;
    }

    &-list__text, &-list__label {
      color: #000000;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: -.34px;
      margin: 0 10px 0 0;
    }

    &-list__label {
      color: var(--natural-600);
    }
    
    &__submit{
      visibility: hidden;
      position: absolute;
    }
  }

  .list {
    &-item {
      box-shadow: 0 4px 15px rgba(96, 105, 157, 0.07);
      background-color: #ffffff;
      margin: 10px 0;
      padding: 17px;

      &:hover {
        box-shadow: 0 4px 15px rgba(96, 105, 157, 0.22);
      }

      md-menu {
        margin-left: auto;
      }

      &__text {
        margin: 0 10px 0 0;
        color: #000000;
        font-weight: 700;
        letter-spacing: -0.34px;
        overflow: hidden;
        text-overflow: ellipsis;

        &-email {
          display: block;
          opacity: 0.5;
          text-overflow: ellipsis;
          width: 100%;
          overflow: hidden;
        }

        &:not(:first-child) {
          opacity: .8;
        }

        &.in-progress {
          md-icon {
            border-radius: 50%;
            background: #FAA46F;
            color: #ffffff;
            animation: rotating 2s linear infinite;
            padding: 3px;
            min-width: 18px;
            width: 18px;
            min-height: 18px;
            height: 18px;
          }
        }
      }

      &-text-wrapper {
        margin-right: 10px;

        .list-item__text {
          margin: 0;
        }
      }
    }
  }
}
