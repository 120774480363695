.block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 528px;
  min-height: calc(100% - var(--header-height));
  margin: 0 auto;

  .scholasticSetNewPassword {
    padding: 32px 38px;
    margin: 30px 0;
  }
}

@media (width <= 528px) {
  .block {
    padding: 0;

    .scholasticSetNewPassword {
      flex-grow: 1;
      padding-bottom: 47px;
      margin: 0;
      box-shadow: none;
    }
  }
}
