.autocomplete-search {
  $self: &;
  position: relative;
  &[disabled] {
    input,
    label,
    #{$self}__icon {
      opacity: 0.5;
    }
  }
  &:focus-within {
    #{$self}__icon {
      color: var(--primary-500);
    }
  }
  &__icon {
    position: absolute;
    top: 5px;
    right: 0;
    z-index: 1;
    color: var(--block-button-border);
  }
  &__loading {
    bottom: 20px;
  }
  md-autocomplete-wrap {
    border: none;
    opacity: 1;
  }
  md-input-container {
    margin-left: 0;
    margin-right: 0;
    &.md-input-invalid {
      label.md-required:after {
        color: var(--error-300);
      }
    }
    input:not(:focus) {
      color: rgba(0,0,0,0.87);
    }
  }
}