.row:hover {
  cursor: pointer;
  box-shadow: var(--shadow-main-1);
}

.historyIsOpen {
  margin-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.infoBox {
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: space-between;
  justify-self: end;

  button {
    margin-right: 6px;
  }
}
