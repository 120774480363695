.title {
  margin-bottom: 4px;
  font: var(--body-14-medium);
}

.total {
  font: var(--body-14-bold);
}

.valueBox {
  display: inline-flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.value {
  font: var(--title-28-bold);
}
