.green {
  &.selected {
    background-color: var(--success-300) !important;
  }

  &.goodDay {
    background-color: var(--success-50);
  }
}

.blue {
  &.selected {
    background-color: var(--info-300) !important;
  }

  &.goodDay {
    background-color: var(--info-50);
  }
}
