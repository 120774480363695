.supplementBox {
  margin-bottom: 14px;

  h6 {
    margin-bottom: 4px;
    font: var(--body-14-bold);
  }

  p {
    font: var(--body-12-bold);
    color: var(--natural-400);
  }
}
