.container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px 24px;
  align-items: center;
  justify-content: space-between;
}

.infoBlock {
  flex: 1;
  min-width: fit-content;
}
