.switch-radio {
  display: flex;
  input {
    display: none
  }

  label {
    border-left: 1px solid rgba(102, 102, 102, 0.7);
    border-top: 1px solid rgba(102, 102, 102, 0.7);
    border-bottom: 1px solid rgba(102, 102, 102, 0.7);
    color: rgba(102, 102, 102, 0.7);
    min-width: 87px;
    line-height: 30px;
    min-height: 33px;
    max-height: 33px;
    border-radius: 0;
    background-color: var(--white);
    font-size: 16px;
    letter-spacing: -0.38px;
    margin: 0;
    text-align: center;
    cursor: pointer;
    padding: 0 5px;
    white-space: nowrap;
    &:nth-child(2) {
      border-radius: 5px 0 0 5px;
    }

    &:last-child {
      border-radius: 0 5px 5px 0;
      border-right: 1px solid rgba(102, 102, 102, 0.7);
    }
  }

  .workout {
    min-width: 180px;
  }

  .food {
    min-width: 94px;
  }

  input:checked + label {
    background-color: var(--main-black);
    color: var(--white);
    border-color: transparent;
  }
}
