.modalBox {
  position: relative;
  width: 400px;
  padding: 51px 44px 44px;
  text-align: center;

  img {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  h4 {
    margin-bottom: 8px;
    font: var(--title-28-bold);
  }

  p {
    margin-bottom: 60px;
    font: var(--title-16-regular);
  }
}
