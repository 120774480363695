.row {
  font: var(--body-14-bold);
}

.fiberCell {
  display: inline-flex;
  gap: 8px;

  .value {
    min-width: 44px;
  }

  .description {
    color: var(--natural-600);
  }
}
