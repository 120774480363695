.row {
  display: flex;
  gap: 16px;
  justify-content: space-between;
  font: var(--body-14-medium);
}

.meal {
  color: var(--natural-600);
}
