$light-blue: #f4f7ff;

.str-chat {
  &.messaging {
    background-color: $light-blue;
  }

  &__channel-list-messenger {
    background-color: $light-blue;
  }

  &__channel-preview-messenger--active {
    color: var(--white);
    background-color: var(--info-200);
  }

  &__input-flat {
    .str-chat__textarea {
      > textarea {
        background-color: $light-blue;
      }
    }
  }

  &__send-button {
    display: initial;
  }
}

.MuiModal-root {
  .str-chat {
    &__channel-preview-messenger--active {
      &:hover {
        background-color: var(--info-200);
      }
    }

    &__message-text-inner {
      background: $light-blue;
    }
  }
}

.str-chat-channel-list {
  padding-bottom: 40px;
  overflow-y: auto;
}
