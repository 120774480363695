.table {
  color: var(--background-50-light);

  td {
    padding: 24px 0;
    vertical-align: top;
  }
}

.regularRow {
  font: var(--body-14-bold);
}

.totalRow {
  font: var(--title-18-bold);
  border-top: 1px solid var(--natural-600);
}

.nestedTable {
  margin-top: 10px;
  font: var(--body-14-regular);
  color: var(--natural-300);
  border-spacing: 24px 0;
  border-collapse: separate;

  td {
    padding: 6px 0;
  }
}
