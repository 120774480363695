.box {
  position: relative;
  display: flex;
  justify-content: center;
  margin: 160px 0;

  .text {
    position: absolute;
    top: 77px;
    width: 267px;
    font: var(--title-18-bold);
    text-align: center;
    text-transform: capitalize;
  }
}
