.box {
  position: fixed;
  bottom: var(--footer-desktop-height);
  left: 0;
  z-index: 3;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 368px;
  padding: 20px;
}
