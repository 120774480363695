.box {
  padding-top: 5px;
  margin-bottom: 8px;
  border-top: 1px solid var(--natural-300);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.filterRow {
  justify-content: space-between;

  .formBox {
    display: flex;
    gap: 12px;
    align-items: center;
  }
}
