.container {
  display: grid;
  grid-template-rows: subgrid;
  grid-row: span 2;
  grid-auto-flow: row;
  justify-content: space-between;

  @media (width <= 700px) {
    display: flex;
    flex-direction: row-reverse;
  }
}

.placeholder {
  display: block;

  @media (width <= 700px) {
    display: none;
  }
}

.title {
  font: var(--body-14-regular);
  color: var(--natural-700);
  text-align: end;
}

@media (width <= 700px) {
  .title::after {
    content: ":";
  }
}

.description {
  font: var(--body-16-regular);
  color: var(--main-black);

}

.pricesContainer {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-end;

  @media (width <= 700px) {
    flex-direction: column-reverse;
    gap: 4px;
    align-items: start;
    min-width: 125px;
  }
}

.price {
  font: var(--title-16-bold);
  color: var(--main-black);

  @media (width <= 700px) {
    font: var(--body-14-bold);
  }
}

.oldPrice {
  font: var(--title-16-bold);
  color: var(--natural-600);
  text-decoration: line-through;

  @media (width <= 700px) {
    font: var(--body-14-bold);
  }
}
