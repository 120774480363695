.tabBox {
  display: flex;
  align-items: flex-end;

  button {
    height: 41px;
    font: var(--title-16-bold);
    color: var(--natural-500);
    background-color: var(--natural-200);
    border-radius: 0;

    &:first-child {
      border-radius: 8px 0 0;
    }

    &:last-child {
      border-radius: 0 8px 0 0;
    }

    &[aria-selected='true'] {
      height: 52px;
      color: var(--main-black);
      background-color: var(--white);
      border-radius: 8px 8px 0 0;
    }
  }
}
