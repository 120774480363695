.customer-meal-plan-tab {
  max-width: 1080px;
  margin: auto;
  min-height: 200px;

  .content-meal-plan {
    .header {
      margin-top: 0;
    }
  }

  .title-page-wrapper {
    margin-bottom: 20px;
  }

  .title-page {
    font-weight: 900;
    color: #000000;
    font-size: 28px;
    letter-spacing: -.67px;
    line-height: 45px;
    margin: 0;
  }

  .info {
    margin-left: 32px;

    md-icon {
      margin: 0 8px 0 0;
      min-width: 16px;
      width: 16px;
      min-height: 16px;
      height: 16px;
    }

    &__text {
      color: #666666;
      font-size: 12px;
      letter-spacing: .17px;
      width: 589px;
      margin: 0;
    }
  }

  .list {
    border-bottom: 2px solid rgba(120, 128, 162, 0.2);
    padding: 10px 30px 32px;

    &-item {
      position: relative;
      font-size: 16px;
      font-weight: 700;

      &-header {
        font-size: 14px;
      }

      md-checkbox {
        margin: 14px 0 10px 0;
      }

      .explanation-tooltip {
        z-index: 100;
        position: absolute;
        top: 3px;
        right: -125px;
        width: 260px;
        min-height: 250px;
        border-radius: 4px;
        filter: drop-shadow(0 7px 22px rgba(96, 105, 157, 0.3));

        span {
          padding: 10px;
          position: fixed;
          background-color: white;
          font-size: 12px;
          line-height: 16px;
          color: rgba(0, 0, 0, 0.6);
          font-weight: 900;
          text-align: left;
          border-radius: 4px;
          opacity: 1;
        }

        span:before {
          content: "";
          position: absolute;
          right: 100%;
          top: 8px;
          width: 0;
          height: 0;
          border-top: 9px solid transparent;
          border-right: 18px solid white;
          border-bottom: 9px solid transparent;
        }
      }

      &-total {
        border: none;
        letter-spacing: -0.38px;
        font-weight: 900;
        font-size: 16px;

        .list-item__label {
          color: var(--main-black);
        }

        .list-item__text {
          border-bottom: none;
        }
      }

      &-calories {
        letter-spacing: -0.38px;
        font-weight: 900;
        font-size: 16px;
        margin-top: 15px;

        .list-item__label {
          color: var(--main-black);
        }

        .list-item__text {
          border: none;
        }
      }

      &__label {
        letter-spacing: -.34px;
        color: #000000;
        opacity: .7;
        margin: 0;
        padding: 10px;
        display: flex;
        align-items: center;

        &:not(:last-child) {
          padding-left: 32px;
        }

        &:first-child {
          margin-right: 0;
          padding-left: 0;
        }

        md-icon[md-svg-icon="info"] {
          cursor: pointer;
          margin-left: 8px;
          min-width: 16px;
          max-width: 16px;
          position: relative;
        }
      }

      &__text {
        color: var(--main-black);
        letter-spacing: -.48px;
        border-left: 1px solid var(--athens_gray);
        border-bottom: 1px solid var(--athens_gray);
        margin: 0;
        padding: 10px 10px 10px 32px;
        display: flex;
        align-items: center;

        &.protein {
          border-left: none;

          &:before {
            content: '•';
            font-size: 20px;
            margin-right: 5px;
            color: var(--proteins-color);
            font-weight: 400;
          }
        }

        &.carbs {
          &:before {
            content: '•';
            font-size: 20px;
            margin-right: 5px;
            color: var(--carbs-color);
            font-weight: 400;
          }
        }

        &.fat {
          &:before {
            content: '•';
            font-size: 20px;
            margin-right: 5px;
            color: var(--fats-color);
            font-weight: 400;
          }
        }

        &.calories {
          &:before {
            content: '•';
            font-size: 20px;
            margin-right: 5px;
            color: var(--calories-color);
            font-weight: 400;
          }
        }
      }
    }

    &.edit-list {
      padding-top: 24px;
      padding-bottom: 24px;

      .app-card {
        height: 690px;
      }

      .list-item-header{
        .list-item__label {
          padding-left: 10px;
        }

        .list-item__label:first-child {
          padding-left: 0;
        }

        .list-item__label.meal-name {
          padding-left: 0;
        }
      }

      .list-item-total {
        .list-item__text {
          padding-left: 16px;
        }

        &.suggested {
          .list-item__text {
            color: var(--natural-600);
          }
        }
      }

      .list-item {
        border: none;

        &__text {
          border: none;
        }

        &-total {
          .list-item__label {
            margin: 0;
          }
        }

        &.suggested {
          input {
            border: none !important;
            border-radius: 4px;
          }
        }
      }

      md-input-container {
        margin-left: 0;
        margin-top: 0;
        margin-bottom: 0;
        padding: 8px;

        input {
          height: 33px;
        }

        &.protein {
          &:before {
            content: '•';
            font-size: 20px;
            margin-left: 7px;
            color: var(--proteins-color);
            font-weight: 400;
            position: absolute;
            left: 10px;
            top: 12px;
          }

          input {
            padding-left: 24px;
          }

          &.different {
            input {
              color: var(--proteins-color);
              background: rgba(69, 119, 255, 0.1);
            }
          }
        }

        &.carbs {
          &:before {
            content: '•';
            font-size: 20px;
            margin-left: 7px;
            color: var(--carbs-color);
            font-weight: 400;
            position: absolute;
            left: 10px;
            top: 12px;
          }

          input {
            padding-left: 24px;
          }

          &.different {
            input {
              color: var(--carbs-color);
              background: rgba(255, 108, 107, 0.1);
            }
          }
        }

        &.fat {
          &:before {
            content: '•';
            font-size: 20px;
            margin-left: 7px;
            color: var(--fats-color);
            font-weight: 400;
            position: absolute;
            left: 10px;
            top: 12px;
          }

          input {
            padding-left: 24px;
          }

          &.different {
            input {
              color: var(--fats-color);
              background: rgba(238, 176, 43, 0.1);
            }
          }
        }

        &.calories {
          &:before {
            content: '•';
            font-size: 20px;
            margin-left: 7px;
            color: var(--calories-color);
            font-weight: 400;
            position: absolute;
            left: 10px;
            top: 12px;
          }

          input {
            padding-left: 24px;
          }

          &.different {
            input {
              color: var(--calories-color);
              background: rgba(170, 216, 72, 0.1);
            }
          }
        }

        .md-errors-spacer {
          display: none;
        }
      }

      &.input-with-background {
        md-input-container {
          &.protein {
            input {
              background: rgba(69, 119, 255, 0.1);
            }
          }

          &.carbs {
            input {
              background: rgba(255, 108, 107, 0.1);
            }
          }

          &.fat {
            input {
              background: rgba(255, 201, 84, 0.1);
            }
          }
        }
      }

      .meal-plan-name-input-field {
        padding: 0;
        width: 310px;
      }
    }

    &.last-block {
      border: none;
      margin-right: 24px;
      padding-top: 24px;

      .list-item {
        border: none;

        &.suggested {
          input {
            border: none !important;
            border-radius: 4px;
            padding-top: 10px;
          }
        }
      }

      .list-item__label {
        padding: 0 0 16px 0;
      }

      md-input-container {
        margin: 0;

        &:before {
          display: none;
        }

        input {
          padding-left: 0;
        }
      }
    }

    &.workouts {
      border: none;

      .list-item__label {
        padding-left: 0;

        &.disabled {
          opacity: 0.5;
        }
      }

      .last {
        border-bottom: none;
      }
    }
  }

  .app-card {
    margin-bottom: 0;
    min-height: 620px;

    &-header {
      &:before {
        content: attr(data-number);
        width: 22px;
        line-height: 22px;
        font-weight: 700;
        text-align: center;
        background-color: rgba(0, 0, 0, .1);
        border-radius: 50%;
        margin-right: 20px;
        letter-spacing: 1px;
      }
    }
  }

  .input-container {
    margin: 0 13px;

    &__text {
      font-weight: 700;
      color: var(--natural-600);
      font-size: 16px;
      letter-spacing: -.38px;
    }

    md-input-container {
      margin-bottom: 0;
    }
  }

  .edit-meal-error {
    color: #f66464;
    margin: 20px 0 0;
  }

  .within-tab-panel {
    margin: 24px;
  }

  .meal-plan-tabs {
    max-height: 500px;
    overflow: auto;

    label {
      min-height: 80px;
    }

    label.disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }

    &::-webkit-scrollbar {
      width: 4px;
      height: 500px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 3px;
      background-color: #e7e7e7;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #cecece;
      border-radius: 3px;
      width: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #c1c1c1;
    }
  }

  .save-macro-plan-btns {
    margin-top: 32px;

    .outline {
      margin-right: 12px;
    }
  }

  .meal-plan-value {
    display: flex;
    font-size: 14px;
    width: 78%;
    opacity: 0.7;
  }

  .with-remove {
    position: relative;
  }

  .tag-remove {
    position: absolute;
    right: 10px;
    top: 28px;
    width: 18px;
    height: 18px;
  }

  .tag-remove:before, .tag-remove:after {
    position: absolute;
    left: 5px;
    content: ' ';
    height: 16px;
    width: 2px;
    background-color: black;
  }

  .tag-remove:before {
    transform: rotate(45deg);
  }

  .tag-remove:after {
    transform: rotate(-45deg);
  }

  .switch-radio {
    label {
      padding: 0 24px;
    }
  }
}
