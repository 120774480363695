.without-goals {
  text-align: center;
  width: 164px;
  margin: 146px auto 0;

  &__description {
    color: var(--natural-600);
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.39px;
    margin-top: 23px;
  }

  &__button {
    margin-top: 58px;
  }

  &__img {
    background: var(--no-goal) no-repeat center;
    background-size: 110px 121px;
    width: 170px;
    height: 121px;
  }
}

.form-goal {
  max-width: 1047px;
  margin: 0 auto 50px;

  &__title {
    color:var(--main-black);
    font-size: 28px;
    font-weight: 900;
    letter-spacing: -0.67px;
  }

  &-block {
    width: 100%;
    box-shadow: 0 7px 22px rgba(96, 105, 157, 0.1);
    border-radius: 7px;
    background-color: var(--white);
    margin: 21px 0;

    &__header {
      padding: 13px 28px;
      border-bottom: 1px solid rgba(120, 128, 162, 0.2);
    }

    &__body {
      padding: 20px 28px;
    }

    &__title {
      color:var(--main-black);
      font-size: 16px;
      font-weight: 900;
      letter-spacing: -0.38px;
    }

    &__second-line {
      padding: 20px 28px;
      margin-top: 0;
    }
  }

  &-actions {
    margin-top: 40px;
  }

  &-field {
    margin-right: 100px;
    margin-bottom: 20px;
    align-items: flex-start;

    &__label {
      color: var(--natural-600);
      font-size: 14px;
      font-weight: 700;
      letter-spacing: -0.34px;
      line-height: 30px;
      min-width: 145px;
      margin: 0;

      &--required {
        &:after {
          content: ' *';
          font-size: 13px;
          vertical-align: top;
        }
      }
    }

    md-select {
      margin: 0;

      &-value {
        padding-right: 20px;
      }
    }

    md-input-container {
      margin: 0;
      width: 90px;
      padding: 0;

      .form-goal-field__input {
        margin-top: -3px;
        width: 90px;
      }

      .form-setup-goal-field__input {
        margin-top: -3px;
        width: 90px;
      }

      .form-goal-name-field__input {
        width: 300px;
      }
    }

    &__text {
      width: 90px;
      color:var(--main-black);
      font-size: 16px;
      font-weight: 700;
      letter-spacing: -0.38px;
      line-height: 30px;
      margin: 0;
    }

    &-date {
      .form-goal-field__label {
        min-width: 90px;
      }

      md-input-container {
        width: 173px;

        .form-goal-field__input {
          width: 173px;
          padding-right: 22px;
        }
      }

      &:after {
        content: '';
        background: url("../../../assets/images/vectors/calendar.svg");
        display: block;
        width: 18px;
        height: 19px;
        margin-left: -16px;
        margin-top: 4px;
      }
    }


  }

  &__btn-cancel {
    width: 164px;
    height: 45px;
    border-radius: 4px;
    border: 2px solid rgba(var(--main-button-border-rgb), 0.4);
    background-color: transparent;
    color: var(--main-black);
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.38px;
    margin-right: 22px;

    &:hover {
      opacity: .8;
      background-color: var(--main-border-button-hover);
    }
  }

  &__btn-save {
    width: 164px;
    height: 45px;
    border-radius: 4px;
    border: none;
    background-color: var(--primary-500);
    color: var(--white);
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.38px;

    &:hover {
      opacity: .8;
    }

    &:disabled {
      opacity: 0.6;
    }
  }
}

.add-goal-button {
  width: 164px;
  height: 45px;
  border-radius: 4px;
  border: 2px solid rgba(var(--main-button-border-rgb), .4);
  color: var(--main-black);
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.38px;
  background-color: transparent;

  &:hover {
    opacity: 0.8;
    background-color: var(--main-border-button-hover) !important;
  }
}

.goals-list {
  max-width: 1349px;
  margin: 0 auto 50px;

  md-menu{
    .md-icon-button:disabled {
      opacity: 1;
    }
  }
}

.goals-category {
  margin: 24px 0;

  &__name {
    margin: 0;
    color:var(--main-black);
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;
  }
}

.goals {
  box-shadow: 0 7px 22px rgba(96, 105, 157, 0.1);
  border-radius: 7px;
  background-color: var(--white);
  margin-bottom: 18px;
  height: 82px;
  overflow: hidden;

  &--open {
    height: fit-content;
    min-height: 470px;

    .goals-header {
      &__toggle-btn {
        transform: scale(-1);
      }
    }
  }

  &-completed {
    .goals-header {
      .goals-progress {

        &__value {
          color: var(--success-300);
        }

        &__bar {
          background-color: var(--success-300);
        }
      }
    }
  }

  &__name {
    color:var(--main-black);
    font-size: 28px;
    font-weight: 900;
    letter-spacing: -0.67px;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    max-width: 500px;
    text-overflow: ellipsis;

    &.small {
      font-size: 16px;
      padding-top: -8px;
    }
  }

  &__date {
    color: var(--natural-600);
    font-size: 14px;
    font-weight: 700;
    letter-spacing: -0.34px;
    margin: 0;

    span {
      margin-right: 14px;
      color:var(--main-black);
    }
  }

  &-header {
    border-bottom: 1px solid var(--cloud);
    padding: 9px 0 22px 32px;

    md-icon {
      color: black;
      height: 32px;
      margin: 0 12px 0 0;
    }

    md-icon[md-svg-icon="more-vert"] {
      margin-top: -4px;
      opacity: 0.4;
    }

    .completed-icon {
      background: black;
      border-radius: 50%;
      width: 26px;
      height: 26px;
      margin-right: 8px;
      margin-top: 3px;

      md-icon {
        color: white;
        min-width: 20px;
        min-height: 20px;
        width: 20px;
        height: 20px;
        margin-top: 2px;
        margin-left: 2px;
      }
    }

    .goals-progress {
      display: flex;
      align-items: center;
      padding-right: 29px;

      width: 379px;

      &__value {
        margin-right: 5px;
      }

      &__bg {
        height: 21px;
      }
    }

    &__toggle-btn {
      border: none;
      padding: 0;

      md-icon {
        min-width: 24px;
        min-height: 24px;
        width: 24px;
        height: 24px;
      }
    }

    &__divider {
      height: 25px;
      border-left: 1px solid #eeeff1;
      margin-left: 29px;

      &.transparent {
        border-left: 1px solid transparent;
      }
    }
  }

  &-footer {
    border-top: 1px solid var(--cloud);
    height: 49px;

    &__button {
      color: black!important;
      padding: 0;
      margin: 0;
      background: transparent;
    }
  }

  &-top-table {
    font-weight: 700;
    letter-spacing: -0.34px;
    padding: 28px;

    &-row {
      height: 48px;
    }

    &-col {
      color:var(--main-black);
      font-size: 16px;
      letter-spacing: -0.38px;
      line-height: 24px;

    }

    &-row:first-child &-col, &-col:first-child {
      color: var(--natural-600);
      font-size: 14px;
    }

    @for $i from 0 through 100 {
      .goals-progress progress[value="#{$i}"] {
        & + .goals-progress__value:before {
          margin-left: calc(#{$i * 1%} - 15px);
        }
      }
    }

    .goals-progress {
      width: 347px;

      &__value {
        margin-right: 5px;
      }
    }
  }

  &-bottom-table {
    font-weight: 700;
    margin-top: 55px;
    max-height: 325px;
    overflow: hidden;

    &--open {
      max-height: fit-content;
    }

    &-row {
      height: 54px;
      color:var(--main-black);
      font-size: 16px;
      letter-spacing: -0.38px;

      &:first-child {
        color: var(--natural-600);
        font-size: 14px;
        letter-spacing: -0.34px;
        border-top: 1px solid var(--cloud);
        border-bottom: 1px solid var(--cloud);
      }
    }

    &-col {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      &:not(:first-child) {
        border-left: 1px solid var(--cloud);
      }

    }

    &__green-value {
      color: var(--success-300);
    }

    &__red-value {
      color: #f66464;
    }

    &__sub {
      letter-spacing: -0.29px;
      display: inline-flex;
      align-items: center;
      width: 0;
      //text-indent: 5px;

      &:after {
        content: '';
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;
        margin-left: 5px;
      }

      &--red {
        color: #f66464;

        &:after {
          border-top: 4px solid #f66464;
        }
      }

      &--green {
        color: var(--success-300);

        &:after {
          border-bottom: 4px solid var(--success-300);
        }
      }
    }
  }
}

@for $i from 0 through 100 {
  .goals-progress progress[value="#{$i}"] {
    & + .goals-progress__value:before {
      content: '#{$i}%'
    }

    & ~ .goals-progress__bg .goals-progress__bar {
      width: $i * 1%
    }
  }
}

.goals-progress {
  progress {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
  }

  &__value {
    color: var(--primary-500);
    font-size: 12px;
    font-weight: 700;
    letter-spacing: -0.29px;
  }

  &__bg {
    background: #f0f0f5;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    width: 100%;
    height: 9px;
  }

  &__bar {
    box-shadow: 0 7px 22px rgba(96, 105, 157, 0.1);
    overflow: hidden;
    background: var(--primary-500);
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    transition: width 1s linear;
    border-radius: 4px;
  }

  &__confines {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--light_storm_grey);
    font-size: 12px;
    font-weight: 700;
    letter-spacing: -0.29px;
  }

  &--green {
    .goals-progress__value {
      color: var(--success-300);
    }

    .goals-progress__bar {
      background-color: var(--success-300);
    }
  }

  &--red {
    .goals-progress__value {
      color: #f66464;
    }

    .goals-progress__bar {
      background-color: #f66464;
    }
  }

  &--black {
    .goals-progress__value {
      color: black;
    }

    .goals-progress__bar {
      background-color: black;
    }
  }
}
