.container {
  display: flex;
  justify-content: space-between;
  padding: 20px;

  .mealInfo {
    display: flex;
    gap: 10px;
    align-items: center;
    font: var(--title-22-bold);
    text-transform: capitalize;
  }
}
