.container {
  position: sticky;
  top: 0;
  z-index: 2;
  padding: 12px 14px;
  margin-bottom: 8px;
}

.mobileLayout {
  grid-auto-flow: row;
  gap: 16px;
}
