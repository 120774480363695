.infoCell {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.title {
  font: var(--body-14-regular);
}

.description {
  font: var(--body-14-bold);
}
