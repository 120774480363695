.descriptionRow {
  padding: 0 10px;
  margin-bottom: 16px;
  font: var(--body-14-bold);

  h4 {
    margin-bottom: 4px;
    color: var(--natural-600);
  }
}

.formBox {
  display: flex;
  gap: 25px;
  justify-content: space-between;
  padding: 0 10px;
  margin-bottom: 16px;
  overflow: auto;

  > div {
    flex-shrink: 0;
  }

  & :global .MuiFormGroup-root {
    display: grid;
    grid-template-rows: repeat(12, 1fr);
    grid-auto-flow: column;
  }
}

.btnRow {
  padding: 0 10px;

  button {
    max-width: 124px;
    height: 39px;
  }
}
