.filesContainer {
  width: 100%;
  max-height: 160px;
  overflow: auto;
}

.uploadBtn {
  margin: 20px 0 0;
  white-space: nowrap;
  border: 2px solid var(--natural-600);

  input {
    display: none;
  }
}

.chip {
  color: var(--main-black);
  background-color: transparent;

  & :global .MuiSvgIcon-root {
    fill: var(--main-black);
  }
}
