.calculatedRow {
  display: grid;
  grid-template-columns: subgrid;
  grid-column: 1 / -1;
  gap: inherit;
  padding: 16px 0;
  border-top: 1px dashed var(--natural-300);
}

.measureInfo {
  text-transform: lowercase;
}
