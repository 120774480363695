.chatBtn {
  position: fixed;
  right: 16px;
  bottom: 16px;
  z-index: 4;

  @media print {
    display: none;
  }
}

.modal {
  right: 0;
  left: auto;
}

.chatWrapper {
  position: relative;
  width: 910px;
  background-color: #fff;

  button {
    min-width: auto;
    height: auto;
    line-height: normal;

    &:hover {
      background-color: transparent;
    }
  }

  label {
    margin-bottom: 0;
  }
}
