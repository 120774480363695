.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.title {
  margin-bottom: 9px;
  font: var(--title-22-bold);
  color: var(--main-black);
}

.subTitle {
  margin-bottom: 16px;
  font: var(--body-14-regular);
  color: var(--natural-600);
}

.video {
  width: 100%;
  min-height: 210px;
}
