.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.titleRow {
  display: inline-flex;
  gap: 8px;
  align-items: center;
}
