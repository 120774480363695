.groupLabel {
  margin-bottom: 6px;
  font: var(--body-12-bold);
  color: var(--natural-600);
}

.optionBox {
  gap: 12px 20px;
}

.rowFullWidth {
  justify-content: space-between;
}

.helperText {
  margin: 0;
}
