.historyBox {
  font: var(--body-14-regular);

  > div:nth-child(even) {
    background-color: var(--background-50-light);
  }
}

.info {
  padding: 0 0 20px;
  font: var(--body-14-bold);
  text-align: center;
}
