.dropzoneOpenCard {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 186px;
  padding: 0 16px;
  cursor: pointer;
}

.textContainer {
  text-align: center;

  .uploadIcon {
    margin-bottom: 19px;
  }

  p {
    margin-bottom: 8px;
    font: var(--body-14-medium);
  }

  h6 {
    font: var(--body-12-medium);
    color: var(--natural-600);
  }
}
