.subscriptionTable {
  :global(.tableGridLayout) {
    // static size from design, ask by designer
    grid-template-columns:
      42px 176px 114px 96px 114px 148px repeat(2, 114px) repeat(2, 148px)
      66px;
    column-gap: 24px;
    place-items: center stretch;
    width: fit-content;

    // need for sticky group
    overflow: visible;
  }
}

.staticOnScroll {
  position: sticky;
  top: 0;
  left: 0;
}
