.userInfo {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: start;
  justify-self: start;
  font: var(--body-14-bold);
  word-break: break-all;

  .nameRow {
    display: inline-flex;
    gap: 10px;
    align-items: center;
  }

  p {
    font: var(--body-14-regular);
    color: var(--natural-600);
  }
}
