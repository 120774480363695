.calendarRow {
  display: flex;
  flex-direction: column;
  border-right: 1px solid var(--natural-300);

  .infoBox {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    padding: 0 32px 32px;
  }
}
