.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 570px;
  padding: 100px 0;
  margin: 0 auto;

  img {
    margin-bottom: 70px;
  }

  .textBox {
    margin-bottom: 30px;
    font: var(--title-16-regular);
    text-align: center;

    a {
      color: var(--primary-500) !important;
      text-decoration: none;
    }
  }
}
