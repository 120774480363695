.underlineRow {
  display: flex;
  align-items: center;

  .line {
    flex-grow: 1;
    height: 1px;
    margin: 6.5px 0;
    background-color: var(--natural-100);
  }

  .btn {
    display: flex;
    align-items: center;
    margin: 0 11px;
    font: var(--body-14-bold);
    color: var(--primary-500);
    cursor: pointer;

    &:hover {
      text-decoration: underline dashed;
    }
  }
}
