.container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.table {
  border-spacing: 0 12px;
  border-collapse: separate;

  th {
    width: 60%;
    font-family: var(--body-14-regular);
    color: var(--natural-700);
    vertical-align: top;
  }

  td {
    font-family: var(--body-14-regular);
    color: var(--main-black);
    text-align: end;
    vertical-align: top;
  }
}

.closeButton {
  width: 100%;
}