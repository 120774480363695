.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0 100px;

  img {
    max-width: 420px;
    margin-bottom: 30px;
  }

  .textBox {
    margin-bottom: 60px;
    text-align: center;

    h3 {
      margin-bottom: 10px;
      font-size: 40px;
    }

    p {
      margin-bottom: 5px;
      font: var(--title-16-regular);
    }
  }
}
