.title {
  margin-bottom: 5px;
  font: var(--title-28-black);
  letter-spacing: -0.8px;
}

.subTitle {
  font: var(--title-18-bold);
  color: var(--natural-500);
  letter-spacing: -0.6px;
}

.termListContainer {
  margin-top: 40px;
}
