md-radio-group {
  md-radio-button.md-primary {
    .md-off {
      width: 22px;
      height: 22px;
      border-color: #000000;
    }

    &:not([disabled]).md-checked .md-off {
      border-width: 7px;
    }

    .md-on {
      width: 22px;
      height: 22px;
      background-color: transparent !important;
    }

    .md-container {
      width: 22px;
      height: 22px;
    }
  }

  .md-label {
    font-weight: 900;
    font-size: 14px;
    letter-spacing: -.38px;
    color: var(--main-black);
  }
}
