.box {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.titleRow {
  flex-grow: 1;
  margin-bottom: 24px;
}

.paymentInfoRow {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  margin-bottom: 20px;
}

.subscriptionNotPaid {
  font: var(--body-14-bold);
}
