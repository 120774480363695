.mealNameCell {
  :global(.MuiFormControlLabel-label) {
    font: var(--body-14-bold);
  }
}

.mealRow:hover {
  cursor: pointer;
  box-shadow: var(--shadow-main-1);
}

.mealInfoIsOpen {
  margin-bottom: 0;
  border-bottom: 1px solid var(--natural-200);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.mealInfoBox {
  margin-bottom: 8px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
