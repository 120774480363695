.box {
  display: flex;
  flex-direction: column;
  gap: 25px;
  align-items: center;
  justify-content: center;
  margin: 160px 0;

  .text {
    width: 209px;
    font: var(--title-18-bold);
    text-align: center;
    text-transform: capitalize;
  }

  img {
    width: 306px;
    height: 210px;
  }
}
