.cell {
  padding: 0 16px 0 0;
  border-right: 1px solid var(--natural-200);

  .title {
    margin-bottom: 4px;
    font: var(--body-12-medium);
    color: var(--natural-700);
  }

  .info {
    font: var(--title-16-medium);
    color: var(--main-black);
  }

  .adornment {
    color: var(--natural-700);
  }
}
