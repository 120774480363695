.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.title {
  font: var(--title-16-regular);
  color: var(--natural-400);
}

