.box {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.titleBlock {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
}
