// need to get tag created by angular, replace to footer class if star use in react
* > :has(> .footer) {
  position: sticky;
  bottom: 0;
  z-index: 2;
  width: 100%;
}

.footer {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  height: var(--footer-desktop-height);
  padding: 2px 80px 2px 16px;
  background-color: var(--app-background);
  border-top: 1px solid var(--natural-200);

  @media (width < 768px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: start;
    height: var(--footer-mobile-height);
  }

  @media print {
    display: none;
  }
}
