.reached-goal-dialog {
  box-shadow: 0 7px 22px rgba(96, 105, 157, 0.1);
  border-radius: 7px;
  font-weight: 400;
  max-width: 414px;
  text-align: center;
  padding: 35px;

  &__title {
    color:var(--main-black);
    font-size: 22px;
    font-weight: 700;
    line-height: 30px;
    margin-top: 27px;
    margin-bottom: 14px;
  }

  &__description {
    color:var(--main-black);
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    margin-bottom: 30px;
    width: 90%;
  }

  &__btn {
    width: 165px;
    height: 45px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.38px;
    margin: 11px 10px;
    border: none;

    &-cancel {
      border: 2px solid rgba(var(--main-button-border-rgb), 0.4);
      color: var(--main-black);
      background: var(--white);
    }

    &-add {
      background-color: var(--primary-500);
      color: var(--white);
    }
  }
}
