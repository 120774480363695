.row {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .rowLeft {
    display: grid;
    gap: 4px;

    .title {
      font: var(--body-14-bold);
    }

    .description {
      max-width: 532px;
      font: var(--body-14-medium);
      color: var(--natural-700);
    }
  }

  .button {
    min-width: fit-content;
  }
}

.card {
  margin-bottom: 8px;
}

.showPinCodeContainer {
  display: flex;
  align-items: center;

  .pinCode {
    min-width: 36px;
    margin: 0 8px;
    font: var(--body-14-bold);
  }
}
