.statusCellWithDate {
  display: flex;
  flex-direction: column;
  gap: 2px;

  span {
    font: var(--body-12-medium);
    color: var(--natural-600);
  }
}
