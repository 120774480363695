.dailyMealTable {
  :global .tableGridLayout {
    grid-template-columns: repeat(10, 1fr);
    gap: 10px;
    place-items: center start;
  }

  & > * {
    border-bottom: 1px solid var(--natural-300);
  }

  & > :last-child {
    border-bottom: none;
  }
}

@media print {
  .dailyMealTable {
    border: 1px solid var(--natural-300);
    break-inside: avoid;
  }
}
