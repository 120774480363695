.container {
  display: flex;

  @media (width <= 700px) {
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}

.title {
  font: var(--body-14-medium);
  color: var(--natural-700);
}

.priceContainer {
  display: flex;
  gap: 4px;

  @media (width <= 700px) {
    flex-direction: column-reverse;
    min-width: 125px;
  }
}

.price {
  font: var(--body-14-bold);
  color: var(--main-black);
}

.oldPrice {
  font: var(--body-14-bold);
  color: var(--natural-700);
  text-decoration: line-through;
}

.description {
  font: var(--body-14-medium);
  color: var(--main-black);
}
