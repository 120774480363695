.sortButtons {
  display: inline-flex;
  gap: 8px;
  align-items: center;

  .arrowBox {
    display: inline-flex;
    flex-direction: column;

    button {
      width: 14px;
      height: 14px;
    }
  }
}
