.row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
}

.column {
  display: flex;
  flex-direction: column;
}

.moduleFormBlock {
  flex-grow: 1;
}
