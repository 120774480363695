.info {
  margin-bottom: 24px;
  text-align: center;

  .title {
    margin-bottom: 8px;
    font: var(--title-28-black);
  }

  .text {
    font: var(--title-16-medium);
    color: var(--natural-700);
  }
}
