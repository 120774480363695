.filtersBox {
  padding: 20px;
  margin-top: 44px;
  margin-bottom: 12px;

  form {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 10px;
    align-items: center;

    .userInfoBox {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;
      align-items: center;
    }

    .inventoryInputsBox {
      min-height: 0;
      padding: 0;
    }
  }
}
