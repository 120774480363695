.titleBlock {
  margin-bottom: 28px;

  .title {
    margin-bottom: 12px;
    font: var(--body-14-medium);
  }

  .total {
    margin-bottom: 8px;
    font: var(--title-28-bold);
  }

  .description {
    font: var(--body-12-medium);
    color: var(--natural-600);
  }
}
