.contentContainer {
  position: relative;
  overflow-x: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.contentContainer::-webkit-scrollbar {
  display: none;
}

.scrollBar {
  display: flex;
  align-items: center;
  height: 28px;
  overflow: hidden;

  &.outBox {
    background-color: var(--white);
    border-radius: 8px;
    box-shadow: var(--panel-shadow);
  }

  &.inBox {
    border-top: 1px solid var(--natural-300);
  }
}

.scrollThumb {
  position: absolute;
  height: 4px;
  background-color: var(--natural-300);
  border-radius: 4px;
}

.scrollThumb:hover {
  height: 6px;
  background-color: var(--natural-600);
}

.scrollThumbDragging {
  height: 6px;
  background-color: var(--natural-600);
}
