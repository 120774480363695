.box {
  margin-bottom: 20px;

  .checkboxContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    padding: 0 15px;
    background-color: var(--background-50-light);
    border-radius: 5px;

    .dayCheckbox {
      margin-bottom: 0;
      text-transform: capitalize;

      span {
        font: var(--body-14-bold);
      }
    }

    .cancelDayBtn {
      font: var(--body-14-medium);
      color: var(--natural-400);
      cursor: pointer;
    }
  }

  .error {
    color: var(--error-300);
    text-align: center;
  }
}
