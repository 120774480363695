.row {
  min-height: 56px;
  padding: 12px;
  margin-bottom: 8px;
  cursor: pointer;
  border: 1px solid var(--white);
}

.checkedRow {
  border: 1px solid var(--primary-500);
}

@media (hover) {
  .row:hover {
    border: 1px solid var(--primary-500);
  }
}

.coachCell {
  display: flex;
  gap: 8px;
  align-items: center;
  font: var(--body-14-bold);
}
