.iconBox {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  color: var(--main-black);
  background-color: var(--natural-300);
  border-radius: 50%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.small {
  width: 34px;
  height: 34px;
  font: var(--body-14-bold);
}

.large {
  width: 64px;
  height: 64px;
  font: var(--title-28-bold);
}
