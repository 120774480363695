.recommended-steps {
  max-width: 1376px;
  width: 100%;
  margin: 32px auto;

  .title-page {
    font-weight: 900;
    color: #000000;
    font-size: 28px;
    letter-spacing: -.67px;
    line-height: 45px;
    margin: 0 0 20px;
  }

  &__actions {
    button.outline {
      margin-right: 10px;
    }
  }

  .app-card {
    margin-left: 65px;
    position: relative;

    &:before {
      content: '';
      width: 30px;
      height: 30px;
      border-radius: 50%;
      position: absolute;
      left: -60px;
      top: 21px;
      border: 1px solid rgba(0, 0, 0, .4);
      background: var(--app-background);
      z-index: 1;
    }

    &:after {
      content: '';
      height: 100%;
      width: 1px;
      background: rgba(0, 0, 0, .4);
      position: absolute;
      left: -46px;
      top: 50px;
    }

    &-header {
      cursor: pointer;
      border: none;
      position: relative;
      padding: 25px 25px 25px 43px;

      md-icon[md-svg-icon="arrow-down"] {
        fill: black;
        margin: 0;
        min-width: 20px;
        min-height: 20px;
        width: 20px;
        height: 20px;
      }

      &:before {
        content: '';
        width: 3px;
        height: 56px;
        background-color: rgba(0, 0, 0, .4);
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }

    &-body {
      display: none;
      padding: 25px 25px 25px 43px;

      .checkbox-wrapper {
        margin-bottom: 30px;
      }

      .water-description {
        md-icon[md-svg-icon="water"] {
          margin: 0 15px 0 0;
          min-width: 32px;
          width: 32px;
          min-height: 32px;
          height: 32px;

          svg:not(:root) {
            overflow: visible;
          }
        }

        color: var(--main-black);
        font-size: 16px;
        font-weight: 400;
        line-height: 25px;
      }

      .water-input-description {
        color: var(--natural-600);
        font-size: 14px;
        font-weight: 700;
        letter-spacing: -.34px;
      }

    }

    &-footer {
      display: none;
      padding: 25px 25px 25px 43px;
    }

    &.opened {

      .app-card-header {
        border-bottom: 2px solid rgba(120, 128, 162, 0.2);

        md-icon[md-svg-icon="arrow-down"] {
          transform: scale(-1);
        }
      }

      .app-card-body {
        display: block;

        &.video-card {
          display: flex;
          flex-wrap: wrap;
        }
      }

      .app-card-footer {
        display: block;
      }
    }

    &.important {
      text-align: center;

      &:before {
        content: '!';
        line-height: 28px;
        font-weight: 700;
        color: #666666;
      }
    }

    &.last {
      &:after {
        display: none;
      }
    }

    &.completed {
      .app-card-header {
        &:before {
          background-color: #000000;
        }
      }

      &:before {
        content: '';
        background: #000000 url("../../../assets/images/material-icons/done-white.svg") no-repeat center;
        background-size: 20px;
      }
    }

  }

  .switch-radio label {
    min-width: 180px;
  }
}
