.chip {
  display: inline-flex;
  gap: 8px;
  align-items: center;
  max-height: 34px; // size of small button
  padding: 16px 9px;
  font: var(--body-14-bold);
  background-color: var(--natural-100);
  border-radius: 4px;
}
