.timeRow {
  display: grid;
  grid-template-columns: 100px 1fr;
  padding-top: 23px;
  font: var(--body-14-bold);

  .time {
    text-align: center;
  }

  .scheduleBox {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    padding-right: 60px;
  }
}
