.userInfoBox {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  align-items: center;

  .userInfo {
    font: var(--body-14-bold);
    word-break: break-all;

    h6 {
      margin-bottom: 3px;
    }

    p {
      color: var(--natural-400);
    }
  }
}
