.userInfoBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: var(--white);

  .userName {
    font: var(--title-16-bold);
  }

  .userRole {
    font: var(--body-14-medium);
    color: var(--natural-400);
  }
}
