$box-side-padding: 20px;

.paymentDetailsTableView {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 16px $box-side-padding;

  :global(.tableGridLayout) {
    grid-template-columns: 1fr 220px;
    place-items: start left;
  }
}

.billInfoRow {
  margin-bottom: auto;
}

.divider {
  margin: 16px (-$box-side-padding);
}

.billingDatesRow {
  margin-bottom: 20px;
}

.actionRow {
  display: flex;
  gap: 12px;
}
