.customer-inbody-indicators-tab {
  &-header {
    padding-bottom: 20px;
    margin-bottom: 40px;
    border-bottom: 2px solid rgba(120, 128, 162, 0.2);

    button.outline {
      md-icon{
        min-width: 18px;
        width: 18px;
        min-height: 18px;
        height: 18px;
        margin-right: 5px;
      }
    }
  }

  .inbody-details-table {
    &-item {
      border-right: 1px solid rgba(120, 128, 162, .1);
      margin-right: 45px;
      padding-right: 45px;

      &__text {
        font-weight: 700;
        color: var(--main-black);
        font-size: 16px;
        letter-spacing: -.38px;
        margin: 0;

        &:first-child {
          color: var(--natural-600);
          font-size: 14px;
        }
      }

      md-input-container {
        margin: 0;
        width: 135px;
      }
    }
  }

  @media print {
    .inbody-details-table-item:last-child {
      border-right: none;
    }
  }
}
