.divider {
  padding-bottom: 12px;
  border-bottom: 1px solid var(--natural-300);
}

.labelRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2px;

  .label {
    font: var(--body-14-medium);
  }

  .value {
    font: var(--body-14-bold);
  }
}

.descriptionRow {
  font: var(--body-12-medium);
  color: var(--natural-600);
  text-align: right;
}
