@import "../../../../sass/mixins";

.subscriptions-tab {
  $grayFont: #808288;
  color: $grayFont;
  display: grid;
  grid-template-columns: 1fr 1fr;

  p {
    margin: 0;
  }

  .app-card {
    padding: 36px;
    margin-bottom: 24px;

    &:nth-of-type(odd) {
      margin-right: 24px;
    }
  }

  .header {
    &__row {
      height: 50px;
      @include spaceBetween-center;
      margin-bottom: 17px;
    }

    &__subscription-type {
      width: auto;
      padding: 0;
      margin: 0;
      font: 600 16px/19px Lato;

      md-select {
        .md-text {
          color: var(--primary-500);
        }

        .md-select-value {
          padding-right: 30px;
        }

        &[disabled] {
          padding: 8px 16px;
          background: rgba(255, 153, 0, 0.08);
          border: 1px solid #FFF3E0;
          border-radius: 4px;

          & + md-icon {
            display: none;
          }

          .md-select-value {
            padding-right: 0;
            background: none;
          }
        }
      }


    }

    .switch-wrapper {
      @include spaceBetween-center;

      &__text {
        padding-right: 8px;
        font: 500 14px/18px Lato;
      }

      .md-switch {
        margin: 0;

        &.ng-empty{
          .md-bar {
            background: red;
          }
        }
      }
    }

    &__subscription-title {
      width: 100%;
      height: 66px;
      overflow-y: auto;
      margin: 0;
      color: var(--main-black);

      textarea {
        font: 900 22px/28px Lato;
      }

      textarea[disabled] {
        color: var(--main-black);
        background: none;
      }

      .md-errors-spacer {
        display: none;
      }
    }
  }

  .settings {
    margin: 40px 0 32px;

    &__item {
      @include spaceBetween-center;
      margin-bottom: 6px;
    }

    .item {
      &__key {
        font: 400 14px/18px Lato, sans-serif;
        color: inherit;
      }

      md-input-container {
        margin: 0 0 0 14px;
        position: relative;
        color: var(--main-black);

        input {
          border-width: 1px;
          background: none;
          padding: 8px 40px 8px 12px;
          border-radius: 4px;

          &[disabled] {
            border: none;
            background: #F8F8FA;

            &~.overlay {
              &.days {
                color: #808288;
              }
            }
          }
        }

        md-select {
          border: 1px solid #D8D8E6;
          border-radius: 4px;

          &[disabled] {
            background-image: none;
          }

          .md-text {
            padding: 12px;
          }
        }

        .md-select-icon {
          right: 15px;
        }

        .md-errors-spacer {
          display: none;
        }

        .overlay {
          position: absolute;

          &.dollar {
            left: 15px;
            top: 5px;
            color: var(--main-black);

            + .currency {
              padding-left: 30px;
            }
          }

          &.days {
            top: 4px;
            right: 8px;
            color: var(--primary-500);
          }
        }
      }
    }
  }

  fieldset[disabled] {
    color: #808288;

    .settings {
      input {
        border: none;
        background: #F8F8FA;
      }

      md-select {
        border: none;
        background-color: #F8F8FA;

        .md-select-value {
          background: none;
        }

        .md-text {
          color: inherit;
        }
      }

      .md-select-icon {
        display: none;
      }

      .overlay {
        &.days, &.dollar {
          color: #808288;
        }
      }
    }
  }

   .footer {
     @include spaceBetween-center;

     .app-card {
       padding: 0;
       margin-right: initial;
     }

     .total-card {
       width: 100%;
       margin-left: 14px;
       border-radius: 3px;
       &:nth-of-type(odd) {
         margin-left: 0;
       }
       &__header {
         border-radius: 3px 3px 0 0;
         padding: 16px 19px;
         background: #F2F2F7;
         font: 900 16px/20px Lato, sans-serif;
         color: var(--main-black);
       }

       .total {
         font: var(--title-16-bold);
         &__item {
           margin: 0 19px;
           padding: 13px 0;
           @include spaceBetween-center;
           border-bottom: 1px solid #E2E2E9;

           &_last {
             border-bottom: none;
           }
         }
         .item {
           &__tax {
             font-size: 12px;
           }

           &__price_last {
             font-weight: 800;
             color: var(--primary-500);

             &.no-active {
               color: #65656B;
             }
           }
         }
       }
     }
   }

  .no-active {
    color: $grayFont;

    md-select[disabled] {
      background: #F2F2F7;

      .md-text {
        color: inherit;
      }
    }
  }

  & + .btn-wrapper {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }
}