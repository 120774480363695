.customer-setup-step-6 {

  .plan-container {
    height: 700px;
    margin-bottom: 20px;

    .vertical-tab-panel.nav-vertical {
        height: 100%;
    }

    .app-card {
      height: 100%;
      margin-bottom: 0;
    }

    .app-card-body {
      padding: 15px 0;
    }
  }

  .list {
    border-bottom: 2px solid rgba(120, 128, 162, 0.2);
    margin-bottom: 20px;
    padding: 10px 30px 20px;

    md-input-container {
      position: relative;

      &:before {
        content: '•';
        position: absolute;
        left: 0;
        top: 0;
        font-size: 18px;
        line-height: 30px;
      }

      input {
        padding-left: 15px;
      }

      &:not(:last-child) {
        margin-right: 45px;
      }

      &.protein {
        color: var(--proteins-color);
      }

      &.carbs {
        color: var(--carbs-color);
      }

      &.fat {
        color: var(--fats-color);
      }

      &.calories {
        color: var(--calories-color);
      }
    }

    &-item {
      position: relative;

      &.header {
        margin-bottom: 20px;

        .list-item__text {
          font-size: 14px;
        }
      }

      .explanation-tooltip {
        z-index: 100;
        position: absolute;
        top: 3px;
        right: -125px;
        width: 260px;
        min-height: 250px;
        border-radius: 4px;
        filter: drop-shadow(0 7px 22px rgba(96, 105, 157, 0.3));

        span {
          padding: 10px;
          position: fixed;
          background-color: white;
          font-size: 12px;
          line-height: 16px;
          color: rgba(0, 0, 0, 0.6);
          font-weight: 900;
          text-align: left;
          border-radius: 4px;
          opacity: 1;
        }

        span:before {
          content: "";
          position: absolute;
          right: 100%;
          top: 8px;
          width: 0;
          height: 0;
          border-top: 9px solid transparent;
          border-right: 18px solid white;
          border-bottom: 9px solid transparent;
        }
      }

      &__text {
        font-size: 14px;
        color: #000000;
        opacity: .7;
        font-weight: 700;

        &:not(:last-child) {
          margin-right: 45px;
        }

        &:first-child {
          margin-right: 0;
        }

        md-icon[md-svg-icon="info"] {
          cursor: pointer;
          margin-left: 8px;
          min-width: 16px;
          max-width: 16px;
          position: relative;
        }
      }

      md-input-container {
        margin: 0;
        padding: 0;
      }

      &.total {
        margin-top: 12px;

        .list-item__text {
          opacity: 1;

          &:first-child {
            font-weight: 900;
            letter-spacing: -0.38px;
          }

          &:not(:last-child) {
            margin-right: 45px;
          }

          &:first-child {
            margin-right: 0;
          }

          &:not(:first-child) {
            position: relative;
            padding-left: 15px;

            &:before {
              content: '•';
              position: absolute;
              left: 0;
              top: -4px;
              font-size: 18px;
            }

            &.protein {
              &:before {
                color: var(--proteins-color);
              }
            }

            &.carbs {
              &:before {
                color: var(--carbs-color);
              }

            }

            &.fat {
              &:before {
                color: var(--fats-color);
              }
            }

            &.calories {
              &:before {
                color: var(--calories-color);
              }
            }
          }
        }
      }
    }

    &.height-more-size{
      padding-bottom: 74px;
    }

    &.last-block {
      border: none;
      margin-right: 24px;

      md-input-container {
        &:before {
          display: none;
        }

        input {
          padding-left: 0;
        }
      }
    }

    &.workouts {
      border: none;
    }
  }
}