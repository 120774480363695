.titleRow {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;

  .title {
    font: var(--title-36-bold);
    color: var(--white);

    @media (width < 768px) {
      font: var(--title-22-bold);
    }
  }

  .description {
    font: var(--body-14-medium);
    color: var(--natural-400);
  }
}
