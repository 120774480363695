md-datepicker {
  padding: 0 !important;
  margin: 0 !important;
  width: 0 !important;

}

.md-datepicker-input {
  display: none;
}

.md-datepicker-input-mask {
  display: none;
}

.md-datepicker-calendar-pane {
  z-index: 12000;
}

.md-default-theme .md-calendar-day-header, .md-calendar-day-header {
  background: white;
  color: var(--waikawa_gray);
  opacity: 0.5;
}
