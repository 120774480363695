// Style from icon button
.icon {
  &:hover {
    color: var(--primary-500);
  }
}

.textBox {
  text-align: center;
}
