$box-width: 924px;

.terms-and-conditions {
  max-width: $box-width;
  width: 100%;
  height: 860px;
  margin: 62px auto;
  padding-top: 79px;
  box-shadow: 0 7px 22px rgba(96, 105, 157, 0.1);
  border-radius: 7px;

  @media (width <= $box-width) {
    margin: 0;
    padding-top: 24px;
    word-break: break-word;
  }

  &.full {
    height: fit-content;
  }

  &-title {
    color: var(--main-black);
    font-size: 28px;
    font-weight: 900;
    letter-spacing: -0.67px;
    padding: 0 24px 0 78px;
  }

  &.full &-title {
    @media (width <= $box-width) {
      padding: 0 24px;
    }
  }

  &-content {
    padding: 0 64px 0 78px;
    margin-right: 24px;
    max-height: 650px;
    overflow: auto;
    color: var(--main-black);
    font-size: 14px;
    font-weight: 700;
    letter-spacing: -0.34px;
    line-height: 27px;
    margin-top: 35px;

    &.full {
      max-height: fit-content;

      @media (width <= $box-width) {
        margin-right: 0;
        padding: 0 24px;
      }
    }

    &::-webkit-scrollbar {
      width: 4px;
      height: 529px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 3px;
      background-color: #e7e7e7;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--primary-500);
      border-radius: 3px;
      width: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: var(--warning-600);
    }


    h2 {
      color: var(--main-black);
      font-family: Roboto;
      font-size: 20px;
      font-weight: 700;
      line-height: 28px;
      text-transform: uppercase;

      &:first-child {
        margin-top: 0;
      }
    }

    b {
      font-weight: 900;
    }

    &:after {
      content: '';
      background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, var(--white) 100%);
      position: sticky;
      display: block;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 190px;
      margin-top: -100px;
    }
  }

  &-actions {
    padding: 20px 42px 20px 78px;
  }

  &__checkbox {
    color: var(--info-300);
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.08px;
  }

  button.outline {
    margin-right: 10px;
  }
}
