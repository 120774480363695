.dropzone {
  position: absolute;
  inset: 0;
  z-index: 1;
  overflow: hidden;
  border-radius: 10px;
}

.background {
  position: absolute;
  inset: 0;
  background-color: var(--natural-700);
  opacity: 0.16;
}

.imgBox {
  position: relative;
  z-index: 1;
  width: 480px;
  margin: 60px auto;
  text-align: center;

  .chooseFileImage {
    margin-bottom: 46px;
  }

  .chooseFileText {
    font: var(--title-22-bold);
  }
}
