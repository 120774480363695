.tax {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

  .container {
    width: 450px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font: var(--title-16-regular);
    color: #65656B;
  }

  .title {
    font: var(--title-22-black);

    span {
      color: var(--error-300);
    }
  }

  .text {
    width: 330px;
    text-align: center;
  }

  .form {
    display: flex;
    margin-top: 10px;

    .input-container {
      font: inherit;
      &__label {
        font: inherit;
      }
    }
  }
}