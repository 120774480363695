.bar {
  display: flex;
  gap: 4px;
  align-items: center;
  height: 16px;
  margin-bottom: 32px;
}

.segment {
  height: 100%;
  border-radius: 4px;
}
