.customer-meal-time-tab {

  .app-card {
    margin-left: 65px;
    position: relative;

    &.last-card {
      margin-bottom: 65px;
    }

    &-header {
      border-bottom: none;
      position: relative;
      cursor: pointer;
      padding: 25px 25px 25px 43px;

      md-icon[md-svg-icon="arrow-down"] {
        fill: black;
        margin: 0;
        min-width: 20px;
        min-height: 20px;
        width: 20px;
        height: 20px;
      }
    }

    &-body {
      display: none;
      padding: 25px 25px 25px 43px;

      &.meal-times {
        padding: 0;
      }
    }

    &.opened {
      .app-card-header {
        border-bottom: 2px solid rgba(120, 128, 162, 0.2);

        md-icon[md-svg-icon="arrow-down"] {
          transform: scale(-1);
        }
      }

      .app-card-body {
        display: block;
      }
    }

    button.tab {
      background-color: #eeeff3;
      border-radius: 0;
      color: #000000;
      border-top: 5px solid transparent;
      min-height: 54px;

      &.active {
        background: transparent;
        border-color: #000000;
      }
    }
  }

  .meal-plan-select-container {
    margin-left: -16px;

    md-input-container {
      max-width: 120px;
    }
  }

  .meal-times-list {
    padding: 25px 47px;
    margin-top: 15px;
    z-index: 1;
    position: relative;

    &-item {

      &:first-child {
        margin-bottom: 16px;

        .meal-times-list-item__text:last-child {
          text-align: center;
        }
      }

      md-checkbox {
        margin-bottom: 16px;
        min-width: 100px;

        .md-label {
          color: var(--natural-600);
        }
      }

      &__text {
        letter-spacing: -.34px;
        font-size: 14px;
        font-weight: 700;
        color: var(--natural-600);
        border-radius: 5px;
        min-width: 100px;
      }

      md-input-container {
        width: 100px;
        margin: 0;

        &.non-active-time {
          opacity: .5;
        }
      }

      &__button-wrapper {
        margin-top: -16px;
        width: 100px;

        button.clear {
          margin: auto;
          color: var(--main-black);
          width: auto;
          height: auto;
          padding: 0;
          border-bottom: 1px solid;
          border-radius: 0;

          md-icon {
            color: var(--main-black);
            margin-left: -19px;
          }
        }
      }
    }

    button[type="submit"] {
      margin-top: 20px;
      margin-left: 10px;
    }

    button.outline {
      margin-top: 20px;
      md-icon[md-svg-icon="options-gear"] {
        min-width: 20px;
        width: 20px;
        min-height: 20px;
        height: 20px;
        color: #000000;
        margin-right: 5px;
      }
    }
  }
}
