.add-sales-reps {
  margin: 32px auto;
  max-width: 1376px;
  width: 100%;

  &__form {
  }

  &__title {
    margin: 0 0 20px;
    font-weight: 900;
    color: #000000;
    font-size: 28px;
    letter-spacing: -.67px;
    line-height: 45px;
  }

  &__btn-group {
    margin-left: auto;
  }
  &__btn {
    height: auto;
  }
}