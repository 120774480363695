.meal-time-container {
  width: 400px;
  height: auto;
  padding: 21px 31px 21px 31px;

  .popup-title {
    text-align: center;
    margin: 15px;
  }
}

.consumption-between-scans {
  min-height: 289px;
  margin: 0;

  .consumption-title {
    justify-content: space-between;
    padding-right: 16px;
  }

  .default {
    @include textLight;
    color: #9ca3b2;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: -0.29px;
    margin-left: 18px;
  }

  .text-column-total {
    @include textBlack;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: -0.48px;
    color: var(--shadow);

    &.reports {
      margin-bottom: 21px;
    }

    &.sugar {
      color: var(--sugar-color);
      padding-left: 18px;
      background: url("../assets/images/vectors/violet_oval.svg") no-repeat left;
    }

    &.fiber {
      color: var(--fiber-color);
      padding-left: 18px;
      background: url("../assets/images/vectors/green_oval.svg") no-repeat left;
    }

    &.veggies {
      color: var(--veggies-color);
      padding-left: 18px;
      background: url("../assets/images/vectors/orange_oval.svg") no-repeat left;
    }

    &.calories {
      color: var(--calories-color);
      padding-left: 18px;
      background: url("../assets/images/vectors/lightgreen_oval.svg") no-repeat left;
    }

    &.water {
      color: var(--water-color);
      padding-left: 18px;
      background: url("../assets/images/vectors/lightblue_oval.svg") no-repeat left;
    }

    &.carbs {
      color: var(--carbs-color);
      padding-left: 18px;
      background: url("../assets/images/vectors/red_oval.svg") no-repeat left center;
    }

    &.fat {
      color: var(--fats-color);
      padding-left: 18px;
      background: url("../assets/images/vectors/yellow_oval.svg") no-repeat left center;
    }

    &.protein {
      color: var(--proteins-color);
      padding-left: 18px;
      background: url("../assets/images/vectors/blue_oval.png") no-repeat left center;
    }
  }

  .text-column-total-default {
    @include textBlack;
    color: var(--main-black);
    font-size: 16px;
    letter-spacing: -0.48px;
    margin-top: 5px;
    margin-left: 18px;

    &.reports {
      font-size: 14px;
      margin-bottom: 22px;
    }
  }

  .text-column-grey {
    @include textRegular;
    font-size: 14px;
    font-weight: 700;
    color: var(--natural-600);
    letter-spacing: -0.34px;
    margin: 0 50px 39px 0;

    &.goal {
      margin: 0;
    }

    &.title {
      margin: 0;
    }

    &.total {
      margin: 0;
      height: 38px;

      &:last-child {
        width: 50px;
      }

      div {
        padding-top: 22px;
      }
    }
  }
}

.customer-profile {
  min-height: 300px;
}

.customer-profile {
  &.sharing {
    max-width: 100%;
  }

  &.mobile {
    padding-top: 0;
  }

  treasure-overlay-spinner {
    justify-content: center;
  }

  // recommended steps
  .vertical-line-with-steps, .additional-line {
    position: absolute;
    top: 0;
    left: -75px;
    border-right: 1px solid rgba(0, 0, 0, 0.4);
    height: 100%;

    &.top {
      top: 24px;
      bottom: 0;
    }

    &.bottom {
      height: 50%;
    }
  }

  .additional-line {
    top: -24px;
    height: 24px;
  }

  .recommended-steps-container {
    margin: 38px 0 33px 0;


    .workout-days-fields {
      @media (max-width: 1200px) {
        display: flex;
        flex-wrap: wrap;
      }
    }

    .workout-days-fields > div {
      @media (max-width: 1200px) {
        width: 25%;
        max-width: 25%;
        margin-bottom: 15px;
      }
    }

    .recommended-meal-time {
      .header {
        @media (max-width: 1200px) {
          margin-left: 110px;
        }
      }

      @media (max-width: 960px) {
        .content-meal-time {
          min-width: 770px;
        }
        overflow-x: scroll;

        &::-webkit-scrollbar {
          position: relative;
          bottom: -20px;
          width: 4px;
          height: 6px;
        }

        &::-webkit-scrollbar-track {
          border-radius: 3px;
          background-color: #DADCE5;
        }

        &::-webkit-scrollbar-thumb {
          background-color: var(--primary-500);
          border-radius: 3px;
          width: 4px;
        }

        &::-webkit-scrollbar-thumb:hover {
          background-color: var(--warning-600);
        }
      }
    }
  }

  .shape_white_shadow {
    position: relative;
    background-color: var(--white);
    border-radius: 3px;
    box-shadow: 0 7px 22px rgba(96, 105, 157, 0.1);
    min-height: 74px;
    margin-bottom: 24px;
    padding-top: 10px;

    .change-password-block {
      padding: 36px 0 0 47px;
      justify-content: space-between;


    }

    .video-panel {
      display: flex;
      flex-wrap: wrap;
      -webkit-flex-wrap: wrap;
      margin: 38px 45px 0 45px;
      padding-bottom: 45px;
    }

    .video-container {
      margin-top: 8px;
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }

      cursor: pointer !important;

      &.recommended {
        margin-right: 23px;
      }

      .preview {
        max-width: 238px;
        width: 100%;
        height: auto;
        flex-wrap: wrap;
        opacity: 0.8;
        border-radius: 7px;

        &.recommended {
          max-width: 222px;
        }

        @media (max-width: 1250px) {
          max-width: 195px;
        }
      }

      .preview:hover {
        opacity: 1;
      }
    }

    .video-frame {
      width: 241px;
      height: 176px;
      border-radius: 7px;
      box-shadow: 0 7px 22px rgba(96, 105, 157, 0.1);
      margin-bottom: 6px;
      opacity: 0.8;

      &.benefits {
        background: url("../assets/images/vectors/video_benefits.png") no-repeat center / contain;
      }

      &.pre-workout-recommendations {
        background: url("../assets/images/vectors/video_pre_recommendations.png") no-repeat contain / center;
      }

      &.post-workout-recommendations {
        background: url("../assets/images/vectors/video_post_recommendations.png") no-repeat contain/ center;
      }
    }

    .video-frame:hover {
      opacity: 1;
    }

    .video-title {
      @include textLight;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: -0.34px;
      color: var(--natural-600);
      width: 160px;
      max-height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-top: 8px;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }

    .video-container-popup {
      max-width: 951px;
      width: 100%;
      height: auto;
      max-height: 648px;
      padding-top: 30px;

      .videoframe-container {
        margin: 0 auto;
        width: 90%;
      }

      &.for-two-videos {
        min-height: 433px;
      }

      .btn-blue {
        width: 171px;
        margin-bottom: 35px;
        margin-right: 35px;
      }
    }

    iframe {
      border: none;
      padding-bottom: 10px;
    }

    .videoframe-container {
      max-width: 850px;
      height: 469px;
      padding: 0;
      margin-top: 36px;
      margin-left: 23px;
      box-shadow: 0 7px 22px rgba(96, 105, 157, 0.1);
      border-radius: 10px;
      overflow: hidden;
      display: block;

      &.small {
        max-width: 427px;
        min-height: 235px;
        margin-top: 23px;

        .video_wrapper {
          width: 100%;
          max-width: 427px;
          min-height: 235px;
        }
      }
    }

    .workout-days-fields {
      margin: 30px 0 33px 46px;
      width: 85%;
    }

    .panel-content {
      border-left: 3px solid rgba(98, 105, 147, 0.7);
      justify-content: space-between;
      padding-right: 30px;
      min-height: 56px;
      cursor: pointer;

      .panel-title {
        padding-top: 13px;
        padding-left: 46px;
      }

      .dropdown-panel {
        width: 20px;
        height: 56px;
        background: url("../assets/images/vectors/panel-dropdown-down.svg") no-repeat center;

        &.up {
          background: url("../assets/images/vectors/panel-dropdown-up.svg") no-repeat center;
        }
      }

      &.active {
        border-left: 3px solid #6d8eee;;
      }
    }

    .completed-recommended-step,
    .uncompleted-recommended-step,
    .important-recommended-step {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      margin: 10px 31px;
      position: absolute;
      left: -121px;
      border: 1px solid #000000;
      background: #fbfbfb;
    }

    .completed-recommended-step {
      background: #000000 url("../assets/images/material-icons/done.svg") no-repeat center;
      background-size: 20px;
    }

    .important-recommended-step {
      text-align: center;
      &:before {
        content: '!';
        line-height: 28px;
        font-weight: 700;
      }
    }

    .water-description-block {
      padding: 30px 46px;
      color: var(--main-black);
      @include textRegular;
      font-size: 16px;
      font-weight: 400;
      line-height: 25px;
      display: flex;


      &:before {
        content: '';
        min-width: 32px;
        height: 32px;
        background: url("../assets/images/material-icons/water.svg");
        margin-right: 13px;
      }
    }

    .water-field-block {
      border-top: 1px solid var(--athens_gray);
      padding: 16px 30px 16px 46px;
      color: var(--natural-600);
      @include textRegular;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: -0.34px;

      .water-field-label {
        margin: 0;
      }


      .btn-green {
        min-width: 171px;
      }
    }
  }

  .btn-blue {
    &.recommended-steps {
      min-width: 150px;
      margin-right: 16px;
      margin-bottom: 40px;
    }
  }

  .btn-green {
    &.message {
      width: 165px;
      padding-left: 41px;
      text-align: left;
      margin-left: 21px;
      margin-right: 16px;
    }

    &.edit-meals {
      min-width: 165px;
      margin-top: 36px;
    }

    &.recommended-steps {
      min-width: 150px;
      margin-right: 16px;
      margin-bottom: 40px;

      &.block {
        margin-right: 30px;
        min-width: 171px;
      }
    }
  }

  .edit-workout-days-container {
    width: auto;
    height: auto;
    padding: 21px 31px 53px 31px;

    .explanation-text {
      @include textRegular;
      color: var(--light_storm_grey);
      font-size: 14px;
      font-weight: 400;
      letter-spacing: -0.34px;
      text-align: center;
      margin-top: -50px;
    }

    .workout-days {
      margin-top: 24px;
      margin-bottom: 36px;

    }

    .popup-title {
      text-align: center;
      margin-bottom: 0;
    }

    .label-text {
      padding-left: 40px;
    }
  }

  .edit-workout-days {
    justify-content: flex-end;
    margin-top: 45px;

    .btn-blue {
      width: 205px;
      padding-left: 48px;
      background: url("../assets/images/vectors/options-gear.svg") no-repeat 25px;
    }

    .btn-green {
      margin-top: 0 !important;
    }
  }

  .meal-details-text {
    @include textRegular;
    color: var(--light_storm_grey);
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.07px;

    &.small {
      margin-top: 2px;
      font-size: 12px;
    }

    &.weight {
      margin-top: 3px;
    }

    &.protein {
      padding-left: 14px;
      background: url("../assets/images/vectors/blue_oval.png") no-repeat left;

      &.tab {
        color: var(--proteins-color);
      }

      &.icon {
        padding-left: 18px;
        padding-right: 2px;
        background: url("../assets/images/vectors/meal_proteins.svg") no-repeat left;
      }
    }

    &.carbs {
      padding-left: 14px;
      background: url("../assets/images/vectors/red_oval.svg") no-repeat left;

      &.tab {
        color: var(--carbs-color);
      }

      &.icon {
        padding-left: 18px;
        padding-right: 2px;
        background: url("../assets/images/vectors/meal_carbs.svg") no-repeat left;
      }
    }

    &.fats {
      padding-left: 14px;
      background: url("../assets/images/vectors/yellow_oval.svg") no-repeat left;

      &.tab {
        color: var(--fats-color);
      }

      &.icon {
        padding-left: 18px;
        padding-right: 2px;
        background: url("../assets/images/vectors/meal_fats.svg") no-repeat left;
      }
    }
  }

  .shape_white_shadow_food {
    @include shape_white_shadow_7px;
    max-width: 523px;
    height: 670px;

    .panel-title {
      @include textBlack;
      color: var(--main-black);
      font-size: 16px;
      font-weight: 900;
      letter-spacing: -0.38px;
    }

    .info-message {
      @include textLight;
      color: #696c7e;
      font-size: 15px;
      font-weight: 700;
      letter-spacing: -0.34px;
      margin-left: 24px;
    }

    ::-webkit-scrollbar {
      width: 4px;
      height: 529px;
    }

    ::-webkit-scrollbar-track {
      border-radius: 3px;
      background-color: #DADCE5;
    }

    ::-webkit-scrollbar-thumb {
      background-color: var(--primary-500);
      border-radius: 3px;
      width: 4px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background-color: var(--warning-600);
    }

    ul[dnd-list] > li.dndPlaceholder {
      border-radius: 3px;
      background-color: #f6f8f9;
      width: 462px;
      height: 80px;
    }

    ul[dnd-list] > li.dndDraggingSource {
      border-radius: 3px;
      background-color: #f6f8f9;
      max-width: 462px;
      height: 80px;
      opacity: 0.7;
    }

    ul[dnd-list] > li.dndDragging {
      border-left: 6px solid #000000;
    }

    .meal-main-text {
      @include textRegular;
      color: var(--info-300);
      font-size: 18px;
      font-weight: 700;
      letter-spacing: 0.09px;
    }

    .entities-container {
      cursor: grab;
      padding-left: 24px;
      margin-right: 7px;
      margin-top: 9px;
      height: 528px;

      &.meal {
        height: 445px;
      }

      &.added-meal {
        margin-top: 20px;
        height: 570px;
      }

      &.with-scroll {
        overflow-y: scroll;
      }

      .entity-card {
        @include shape_white_shadow_3px;
        background: url("../assets/images/vectors/drag-drop-icon.svg") no-repeat 427px 16px;
        max-width: 462px;
        height: 80px;
        margin-bottom: 7px;

        &.meal {
          height: 82px;
        }

        .card-content {
          @include textHeader;
          padding-top: 17px;
          padding-left: 28px;

          .text-entity-address {
            @include textLightInCircle;
            color: var(--grey);
          }

          .text-entity-member {
            @include textLightInCircle;
            color: var(--grey);
            background: url("../assets/images/vectors/grey_point.svg") no-repeat left;
            background-size: 5px 5px;
            padding-left: 12px;
            margin-left: 7px;
          }
        }
      }
    }
  }

  .adjust-padding {
    li:nth-child(1), li:nth-child(2), li:nth-child(3) {
      margin-bottom: 0 !important;
      @media screen and (max-width: 1250px) {
        margin-bottom: 8px;
      }
    }
  }

  .back-forth-arrows {
    background: url("../assets/images/vectors/back_forth_arrows.svg") no-repeat center;
    background-size: 19px 19px;
    height: 19px;
    width: 19px;
    margin-top: 92px;
    margin-left: 7px;
    margin-right: 7px;
  }

  .select_link_dash {
    min-width: max-content;
    min-width: -moz-max-content; /* Firefox/Gecko */
    min-width: -webkit-max-content; /* Chrome */
    font-size: 16px;
    letter-spacing: -0.38px;
    border-bottom: 1px dashed var(--primary-500);
    height: 24px;
    margin-top: 2px;
    margin-left: 8px;
    cursor: pointer;
  }

  .date-dropdown {
    fill: var(--primary-500);
    min-height: 12px;
    min-width: 12px;
    height: 12px;
    width: 12px;
    margin-top: 8px;
    margin-left: 5px;
  }

  .dropdown-entity {
    width: 16px;
    height: 8px;
    margin-left: 6px;
    margin-top: 10px;
    background: url("../assets/images/vectors/dropdown_blue.svg") no-repeat top;
  }

  .subtitle {
    @include textBlack;
    color: var(--main-black);
    font-size: 20px;
    font-weight: 900;
    letter-spacing: -0.48px;
    margin-top: 50px;
    margin-left: 60px;
  }

  .active-tab {
    position: relative;
    bottom: -2px;
  }

  .customer-profile-container {

    .page-container {
      max-width: 1360px;
      margin: 0 auto;
      background-color: unset;
    }

    .customer-details-tabs {
      padding: 0 0 0 40px;
      border-bottom-width: 2px;

      @media all and (max-width: 1209px) {
        padding: 0 150px;
        li:nth-child(2) {
          margin-bottom: 8px !important;
        }
        li:nth-child(3) {
          margin-bottom: 8px !important;
        }
      }

      li:nth-child(1) {
        @media (max-width: 1440px) {
          margin-bottom: 8px !important;
        }
      }

      @media all and (max-width: 1100px) {
        padding: 0 100px;
      }

      @media all and (max-width: 930px) {
        padding: 0;
      }

      .customer-detail-tab.active {
        border-left-width: 2px;
        border-right-width: 2px;
      }
    }

    .customer-profile .adjust-padding li:nth-child(1) {
      margin-bottom: unset !important;
    }

    .vertical-tab-panel.nav-vertical input[checked="checked"] + label {
      padding-left: 27px;
      background-color: #f9f9f9;
      border-left: 6px solid var(--main-black);
      opacity: 1;
    }

    .vertical-tab-panel {
      margin-left: 0;
    }

    h4.subtitle {
      margin-top: 0;
      margin-left: 15px;
      margin-bottom: 40px;
      font-size: 18px;
    }

    .user-details-block, .change-password-block {
      margin-top: 38px;
      margin-bottom: 40px;
    }


    .push-notification-block {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      width: fit-content;

      label {
        font-size: 20px;
        font-weight: 900;
        letter-spacing: -0.48px;
        color: var(--main-black);
      }

      md-switch {
        margin-top: 0;
        margin-left: 32px;
      }
    }

    .add-card-button {
      @media all and (max-width: 850px) {
        max-width: 270px;
        width: 100% !important;
        min-height: 151px;
      }
    }

    .card {
      min-height: 151px;
    }
  }

  .payment-popup-container {
    width: 100%;
    min-height: 465px;

    .btn-green {
      margin-top: 80px;
    }
  }

  .textHeader {
    &.popup {
      margin-top: 22px;
      margin-bottom: 22px;
    }
  }

  .close_icon {
    margin-left: 465px;
  }

  .meal-day-container {
    max-width: 552px;
    width: 100%;
    min-height: 346px;
    box-shadow: 0 7px 22px rgba(96, 105, 157, 0.1);
    border-radius: 7px;
    background-color: var(--white);
    padding: 20px 56px 49px 40px;

    .popup-title-table {
      @include textRegular;
      color: var(--natural-600);
      font-size: 14px;
      font-weight: 400;
      letter-spacing: -0.34px;
    }

    .btn-blue, .btn-green {
      width: 152px;
      margin-top: 55px;
    }

    .btn-green {
      margin-left: 15px;
    }

    .popup-title {
      text-align: center;
      margin: 0;
    }
  }

  .md-dialog-container {

    .btn-blue, .btn-green {
      width: 152px;
    }

    .popup-title {
      @include textRegular;
      font-size: 22px !important;
      font-weight: 900 !important;
      line-height: 30px;
      margin-bottom: 39px;

      &.message {
        margin-bottom: 0 !important;
      }
    }

    &.meal-plan-container {
      min-width: 554px;
      min-height: 360px;
      text-align: center;

      .btn-green {
        width: 190px;
        margin-top: 10px;
        margin-bottom: 20px;
      }

      .meal-plan-illustration, .setup-meal-plan-illustration {
        background: url("../assets/images/vectors/change-meal-plan-pop-up-icon.svg") no-repeat center;
        height: 191px;
        width: 196px;
        margin-top: 20px;
        margin-left: 22%;
        margin-bottom: 16px;
      }

      .setup-meal-plan-illustration {
        background: var(--setup-meal-plan) no-repeat center;
        height: 161px;
        width: 200px;
      }

      .meal-plan-popup-title {
        @include textLight;
        font-size: 22px;
        font-weight: 700;
        line-height: 30px;
        margin-bottom: 0;
      }

      .meal-plan-popup {
        @include textLight;
        color: var(--main-black);
        font-size: 16px;
        font-weight: 400;
        line-height: 25px;
        opacity: 0.8;
        margin-left: 50px;
        margin-right: 50px;
        margin-bottom: 24px;
      }
    }
  }

  .meal-plan-panel {
    max-width: 1082px;
    width: 100%;
    margin: 0 auto 117px auto;

    .suggested-body {
      @include body;
      margin-bottom: 7px;
    }

    .shape_white_shadow_meal_plan.first-shape.margin-r-reset {
      @media (max-width: 1100px) {
        margin-right: 0;
      }
    }

    .title_page {
      margin-top: 15px;
      margin-bottom: 24px;
      @include textBlackBold;
      @media screen and (max-width: 860px) {
        text-align: center;
      }
    }

    @media (max-width: 880px) {
      .column-direction {
        flex-direction: column;

        & > div {
          margin: 0 auto 30px auto !important;
        }
      }
    }

    @media (max-width: 880px) {
      .btn-cust-cont-right {
        max-width: 530px;
      }
    }

    .horizontal-line {
      border: 1px solid #686e97;
      opacity: 0.1;
      margin-top: 48px;
      margin-bottom: 34px;
    }

    .edit-meal-plan-button {
      @include blueBtn;
      width: 178px;
      margin-left: 13px !important;
    }

    .shape_white_shadow_meal_plan {
      @include shape_white_shadow_7px;
      max-width: 530px;
      width: 100%;
      height: auto;
      margin-top: 15px;
      padding-bottom: 32px;

      &.first-shape {
        margin-right: 21px;

        &:last-child {
          margin-right: 0;
        }
      }

      .meal-plan-number {
        width: 22px;
        height: 22px;
        margin: 15px -10px 0 25px;
        @include textRegular;
        font-size: 14px !important;
        font-weight: 700 !important;
        color: black !important;
        text-align: center;
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 50%;
      }
    }

    .edit-meal-explanation {
      @include textRegular;
      opacity: 0.8;
      color: red !important;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.2px;
      margin-top: 28px;
      margin-bottom: 13px;

      &.without-margin {
        margin-bottom: 2px;
      }
    }

    .text-total-suggested {
      @include textBlack;
      margin-top: 10px;
      opacity: 0.5;
    }

    .column-main-cell, .column-cell,
    .suggested-column-cell {
      margin-top: 0;
      border-top: 1px solid var(--athens_gray);
      border-right: 1px solid var(--athens_gray);

      &.no-borders {
        &.top {
          border-top: 1px solid transparent;
        }

        &.right {
          border-right: 1px solid transparent;
        }

        &.top-right {
          border-top: 1px solid transparent;
          border-right: 1px solid transparent;
        }
      }
    }

    .header {
      margin-bottom: 12px;
    }

    .column-cell {
      max-width: 150px;
      padding-top: 0;
      margin-top: -10px;

      &.view-plan {
        margin-top: 0;
      }
    }

    .column-main-cell {
      padding-top: 12px;
      margin-top: -10px;

      &.view-plan {
        margin-top: 0;
      }
    }

    .suggested-column-cell {
      max-width: 150px;
      margin-top: -5px;
      padding-top: 2px;
    }

    .meal-type-text {
      @include textRegular;
      font-size: 16px;
      color: var(--natural-600);
      margin-top: 15px;
      padding-bottom: 12px;
      font-weight: 700;
    }

    .text-footer, .text-footer-no-table, .suggested-text-column, .suggested-text-footer {
      @include textRegular;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: -0.38px;
      color: var(--shadow);
      margin-left: 12px;
      margin-top: 5px;
      margin-bottom: 15px;
      @media screen and (max-width: 1100px) {
        margin-left: 10px;
      }
      @media screen and (max-width: 860px) {
        margin-left: 2px;
      }

      &.meal-plan-table {
        font-size: 20px;
        letter-spacing: -0.48px;
        margin: 32px 0 0 47px;

        .column-text {
          color: var(--natural-600);
        }
      }

      &.percents {
        margin-left: 0;
        margin-top: 20px;
        margin-bottom: 0;
      }

      &.edit-table {
        margin-left: 7px;
      }

      &.carbs {
        overflow: hidden;
        text-overflow: ellipsis;

        &.different {
          color: var(--carbs-color);
          background: rgba(255, 108, 107, 0.1);
        }
      }

      &.fat {
        overflow: hidden;
        text-overflow: ellipsis;

        &.different {
          color: var(--fats-color);
          background: rgba(238, 176, 43, 0.1);
        }
      }

      &.protein {
        overflow: hidden;
        text-overflow: ellipsis;

        &.different {
          color: var(--proteins-color);
          background: rgba(69, 119, 255, 0.1);
        }
      }
    }

    .suggested-text-column {
      @include textLight;
      color: var(--slate_grey);
      font-weight: 700;

      &.different {
        @include textRegular;
        color: var(--main-black);
      }
    }

    .suggested-text-footer {
      @include textBlack;
      font-weight: 900;
      color: var(--slate_grey);
    }

    .text-footer {
      @include textBlack;
      font-weight: 900;
    }

    .text-footer-no-table {
      @include textBlack;
      font-weight: 900;
      margin: 0;
    }
  }

  .detail-panel {
    position: relative;
    margin-top: 35px;
    margin-left: 37px;
  }

  .meal-time-details-panel {
    justify-content: space-between;
    margin-top: 35px;
    margin-left: 16px;
    margin-right: 22px;

    &.recommended {
      margin-top: 0;
      margin-left: 5px;

      .meal-time-tabs {
        .meal-time-tab {
          margin-left: 0;
          padding-left: 20px;
          height: 54px;
          border-radius: 0;
        }
      }

      .blue-line {
        margin-left: 0;
        border-radius: 0;
      }
    }

    @media screen and (max-width: 1400px) {
      //padding-right: 40px;
    }
    @media screen and (max-width: 900px) {
      padding-right: 0;
    }
  }

  .filter-select {
    @include textHeaderBlue;
    background: url("../assets/images/vectors/small-filter-dropdown.svg") no-repeat right top;
    background-size: 10px 20px;
    padding-right: 19px;
    margin-top: 7px;
    padding-bottom: 2px;
    height: 23px;
    border-bottom: 1px dashed #6d8eee;
  }

  .content-meal-time {
    padding-bottom: 20px;
    padding-left: 31px;
    @media screen and (max-width: 1000px) {
      padding-left: 10px;
    }
    width: 96%;

    .custom-layout-row {
      padding-right: 0;
    }

    .header {
      margin-bottom: 2px;
      margin-top: 15px;
      padding-left: 170px;
      width: 100%;
      @media screen and (max-width: 1300px) {
        margin-left: 134px;
      }
      @media screen and (max-width: 970px) {
        margin-left: 95px;
      }
      @media screen and (max-width: 850px) {
        margin-left: 85px;
      }

      .header-text {
        @include textGrey;
        width: 75px;
        text-align: center;

        &.days {
          margin-left: 10px;
          @media all and (max-width: 850px) {
            margin-left: 5px;
          }
        }

        &.active-day {
          background-color: rgba(109, 142, 238, 0.1);
          color: #6d8eee;
          border-radius: 4.5px;
        }

        &.hide-element {
          opacity: 0;
        }
      }
    }

    .content {
      margin-top: 35px;

      &.meal-type-text {
        width: 100px;
        padding-right: 0;
        @media screen and (max-width: 1000px) {
          padding-right: 0;
          width: 102px;
        }
        @media screen and (max-width: 800px) {
          width: 92px;
        }
      }

      &.edit-text {
        @include textLight;
        color: var(--info-300);
        font-size: 15px;
        font-weight: 700;
        letter-spacing: -0.36px;
        background: url("../assets/images/vectors/edit_pen.svg") no-repeat left;
        padding-left: 18px;
        cursor: pointer;

        &.non-active {
          opacity: 0.6;
          cursor: default;
        }

        &.hide-element {
          opacity: 0;
          cursor: default;
        }
      }
    }


    .btn-green {
      width: 171px;
      margin: 45px 0 33px 33px;

    }
  }

  .print-button {
    @include blueBtn;
    margin-top: 5px;
    margin-left: 15px;
    padding-right: 15px;
  }

  .print-text {
    background: var(--printer-profile) no-repeat left center;
    padding-left: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .width-right-part {
    width: 67%;

    @media screen and (max-width: 1100px) {
      width: 71%;
    }

    .custom-layout-row {
      @media (max-width: 930px) {
        justify-content: flex-start;
      }
    }
  }

  .msg-info {
    height: 13px;
    width: 16px;
    margin-top: 5px;
    margin-left: 33px;
    background: url("../assets/images/vectors/warning.svg") no-repeat 1px center;
  }

  .text-info {
    @include textInfo;
    width: 174px;
    margin-left: 7px;

    &.profile {
      margin-top: 8px;
    }
  }

  .msg-error {
    color: red;
  }

  .white-shape-shadow {
    @include shape_white_shadow_7px;
    display: inline-block;
    height: auto;
    max-width: 100%;
    width: 97%;

    &.meal-time, &.recommended-meal-time {
      position: relative;
      top: -10px;
      margin: 21px 0 36px 22px;
      @media screen and (max-width: 1200px) {
        margin-left: 0;
        width: 100%;
      }
    }

    &.recommended-meal-time {
      width: 100%;
      box-shadow: none;
      border-radius: 0;
      margin-bottom: 0;
      margin-left: 0;
      margin-right: 0;

      .content-meal-time {
        padding-bottom: 0;
      }
    }
  }

  .btn-cust-cont-right {
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 1085px;
    @media (max-width: 1100px) {
      max-width: 821px;
    }
    @media (max-width: 860px) {
      max-width: 721px;
    }
  }

  .btn-cust-cont {
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    max-width: 1081px;
    @media (max-width: 1100px) {
      max-width: 821px;
    }
    @media (max-width: 860px) {
      max-width: 721px;
    }
  }

  .overview {
    margin: auto;
    width: fit-content;
    padding-left: 27px;
  }

  .customer-detail-tab .customer-profile {
    margin: auto;
    width: fit-content;
    padding-left: 30px;
  }

  .micro-macro-plan {
    margin: auto;
    width: fit-content;
    padding-left: 31px;
  }

  .meal-timess {
    @media screen and (max-width: 890px) {
      padding-left: 32px !important;
    }
  }

  .meal-time {
    margin: auto;
    width: fit-content;
    padding-left: 32px;
    @media screen and (max-width: 890px) {
      padding-left: 28px;
    }
    @media screen and (max-width: 850px) {
      padding-left: 10px;
    }
  }

  .food-list-tab {
    position: relative;
    text-align: center;
    padding-left: 35px;

    &:before {
      content: '';
      position: absolute;
      top: 2px;
      left: 50px;
      width: 20px;
      height: 17px;
      background: url("../assets/images/vectors/meal_reports.svg");
    }
  }

  .videos {
    position: relative;
    text-align: center;
    padding-left: 40px;

    &:before {
      content: '';
      position: absolute;
      top: 3px;
      left: 60px;
      width: 20px;
      height: 15px;
      background: url("../assets/images/vectors/shape_video_icon.svg");
    }
  }

  .help-info {
    position: relative;
    text-align: center;
    padding-left: 25px;

    &:before {
      content: '';
      position: absolute;
      top: 2px;
      left: 62px;
      width: 20px;
      height: 20px;
      background: url("../assets/images/vectors/help_info_icon.svg");
    }
  }

  .meal-reports {
    margin: auto;
    width: fit-content;
    padding-left: 27px;
  }

  .inbody-indicators {
    margin: auto;
    width: fit-content;
    padding-left: 30px;
  }

  .goal-tab {
    margin: auto;
    width: fit-content;
    padding-left: 28px;
  }

  .custom-layout-extra {
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 40px;
  }

  .treasure-overlay-spinner-content .custom-layout-extra {
    -webkit-flex-wrap: wrap;
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: 980px) {
      padding-left: 0;
      justify-content: center;
    }
  }

  .count-meal-time > div {
    &:nth-child(9) {
      display: none;
    }
  }

  .joke-height {
    @media screen and (max-width: 1411px) {
      height: 7.1%;
    }
  }

  .customer-details-tabs {
    border-bottom: 2px solid var(--natural-100);
    min-height: 71px;
    margin: 38px 32px 0 32px;
    display: flex;
    flex-wrap: wrap;
    padding-left: 8px;

    &:after {
      content: '';
      display: block;
      min-width: 10px;
      height: 10px;
    }

    &.coach-profile {
      .customer-detail-tab {
        width: 161px;
      }
    }

    @media screen and (min-width: 900px) and (max-width: 1410px) {
      padding: 0 40px;
    }

    li {
      margin-top: 8px;
      margin-right: 4px;
      margin-left: 4px;
    }

    @media screen and (max-width: 1300px) {
      &:first-child {
        margin-bottom: 20px;
      }
      &:nth-child(2) {
        margin-bottom: 20px;
      }
      &:nth-child(3) {
        margin-bottom: 20px;
      }
    }
    @media screen and (max-width: 1209px) {
      padding-left: 180px;
      padding-right: 180px;
      justify-content: center;
      li:nth-child(3) {
        .customer-detail-tab {
          margin-right: 0;
        }
      }
      li:nth-child(6) {
        .customer-detail-tab {
          margin-right: 0;
        }
      }
    }
    @media screen and (max-width: 1120px) {
      padding-left: 100px;
      padding-right: 100px;
    }
    @media screen and (max-width: 944px) {
      padding-left: 0;
      padding-right: 0;
    }

    .tab-bottom-suggest {
      margin-bottom: -1px;
    }

    .blue-line {
      background-color: var(--info-300);
      border-radius: 3px 3px 0 0;
      height: 6px;
      max-width: 223px;
    }

    .customer-detail-tab {
      cursor: pointer;
      width: 186px;
      padding: 23px 0;
      border-radius: 3px 3px 0 0;

      &.user-profile {
        width: 180px;
      }

      &.superuser {
        width: 218px;
      }

      &.non-active {
        @include textPurp;
        color: rgba(var(--tabs-text-rgb), 0.6);
        height: 71px;
        background-color: rgba(var(--tabs-background-rgb), 0.3);

        .overview {
          background: var(--overview-icon) no-repeat left;
        }

        .customer-profile {
          background: var(--customer-profile-icon) no-repeat left;
        }

        .micro-macro-plan {
          background: var(--micro-macro-plan-icon) no-repeat left;
        }

        .meal-time {
          background: var(--meal-time-icon) no-repeat left;
        }

        .meal-reports {
          background: var(--meal-reports-icon) no-repeat left;
        }

        .inbody-indicators {
          background: var(--inbody-indicators-icon) no-repeat left;
        }

        .goal-tab {
          background: var(--goal-icon) no-repeat left;
        }
      }

      &.active {
        @include textPurp;
        height: 65px;
        border-left: 2px solid var(--natural-100);
        border-right: 2px solid var(--natural-100);
        outline: 1px solid var(--app-background) !important;
        background-color: var(--app-background);

        .overview {
          background: var(--overview-active-icon) no-repeat left;
        }

        .customer-profile {
          background: var(--customer-profile-active-icon) no-repeat left;
        }

        .micro-macro-plan {
          background: var(--micro-macro-plan-active-icon) no-repeat left;
        }

        .meal-time {
          background: var(--meal-time-active-icon) no-repeat left;
        }

        .meal-reports {
          background: var(--meal-reports-active-icon) no-repeat left;
        }

        .inbody-indicators {
          background: var(--inbody-indicators-active-icon) no-repeat left;
        }

        .goal-tab {
          background: var(--goal-active-icon) no-repeat left;
        }
      }
    }
  }

  .meal-time-tabs {
    @include textRegular;
    display: flex;
    font-size: 16px !important;
    font-weight: 700 !important;
    color: rgba(var(--tabs-text-rgb), 0.6) !important;
    letter-spacing: -0.38px;
    height: 61px;
    margin-bottom: -50px;
    border-bottom: none;

    .blue-line {
      margin-left: 6px;
      background-color: var(--main-black);
      border-radius: 3px 3px 0 0;
      height: 5px;
      width: calc(100% - 6px);
    }

    .meal-time-tab {
      cursor: pointer;
      width: max-content;
      margin-left: 6px;
      padding: 15px 20px 26px 24px;
      border-radius: 5px 5px 0 0;
      background-color: #eeeff3;
      word-break: break-word;

      @media (max-width: 820px) {
        padding-left: 15px;
        padding-right: 15px;
        font-size: 13px;
      }

      &.active {
        background-color: #ffffff;
        padding-top: 10px;
        color: var(--main-black) !important;
      }
    }

    @media (max-width: 820px) {
      .blue-line {
        width: calc(100% - 6px);

        &.right {
          max-width: 259px;
          width: calc(100% - 6px);
        }

        &.expired {
          width: calc(100% - 6px);
        }
      }
    }
  }

  .adjust-padding {
    li:nth-child(1), li:nth-child(2), li:nth-child(3) {
      margin-bottom: 0 !important;
      @media screen and (max-width: 1250px) {
        margin-bottom: 8px;
      }
    }
  }

  .food-adding-panel {
    margin-bottom: 22px;
    margin-top: -28px;

    .vertical-tab-panel {
      background: url("../assets/images/vectors/vertical-tab-panel-long.svg") no-repeat center;
      height: auto !important;
      margin-right: 21px;
    }
  }

  .vertical-tab-panel {
    height: auto;
  }

  .inbody-diagrams-panel {
    margin: 23px 32px 45px 32px;

    .blue-title-text {
      @include textBlack;
      font-weight: 900;
      font-size: 16px;
      color: var(--main-black);
      margin: 20px 0 20px 36px;
    }

    .vertical-tab-panel {
      height: auto !important;
    }

    .white-shape {
      height: min-content;
      width: 100%;
      margin: 0 auto;

      &.charts {
        margin-top: 16px;
      }

      & > section {
        padding-right: 10px;
        overflow: hidden;
        @media screen and (max-width: 950px) {
          padding-right: 0;
        }
      }
    }

    .muscle_fat_analysis_diagrams {
      height: 246px;
      max-width: 1033px;
      margin: 28px 16px 41px 30px;

      .muscle-chart-max-width {
        max-width: 850px;
        @media screen and (max-width: 1410px) {
          max-width: 800px;
        }
        @media screen and (max-width: 1325px) {
          max-width: 700px;
        }
        @media screen and (max-width: 1260px) {
          max-width: 600px;
        }
        @media screen and (max-width: 1160px) {
          max-width: 500px;
        }
        @media screen and (max-width: 835px) {
          max-width: 440px;
        }
      }
    }

    .lastline-firstpart {
      max-width: 280px;
      @media screen and (max-width: 1410px) {
        max-width: 260px;
      }
      @media screen and (max-width: 1325px) {
        max-width: 200px;
      }
      @media screen and (max-width: 1260px) {
        max-width: 170px;
      }
      @media screen and (max-width: 1160px) {
        max-width: 140px;
      }
    }

    .lastline-secondpart {
      position: relative;
      left: -34px;
      max-width: 610px;
      @media screen and (max-width: 1410px) {
        max-width: 560px;
      }
      @media screen and (max-width: 1325px) {
        max-width: 500px;
      }
      @media screen and (max-width: 1260px) {
        max-width: 430px;
      }
      @media screen and (max-width: 1160px) {
        max-width: 360px;
      }
      @media screen and (max-width: 835px) {
        left: -34px;
        max-width: 330px;
      }
    }

    .segmental_lean_analysis_diagrams {
      height: 398px;
      max-width: 1033px;
      margin: 28px 16px 20px 30px;

      .seg-chart-respons {
        max-width: 850px;
        @media screen and (max-width: 1410px) {
          max-width: 800px;
        }
        @media screen and (max-width: 1325px) {
          max-width: 700px;
        }
        @media screen and (max-width: 1260px) {
          max-width: 600px;
        }
        @media screen and (max-width: 1160px) {
          max-width: 540px;
        }
        @media screen and (max-width: 835px) {
          max-width: 440px;
        }
      }
    }

    .body_composition_history_chart {
      height: auto;
      margin: 25px 10px 25px 10px;
      padding: 0;
      position: relative;

      .transparent-panel-for-chart {
        position: absolute;
        width: 100%;
        height: 240px;
      }
    }

    .body_health_diagram {
      height: 370px;
      margin: 25px 50px 35px 50px;
      max-width: 960px;
      @media screen and (max-width: 1400px) {
        max-width: 900px;
      }
      @media screen and (max-width: 1300px) {
        max-width: 800px;
      }
      @media screen and (max-width: 1275px) {
        max-width: 740px;
      }
      @media screen and (max-width: 1200px) {
        max-width: 670px;
      }
      @media screen and (max-width: 1060px) {
        max-width: 520px;
      }

      @media screen and (max-width: 830px) {
        margin-left: 0;
        max-width: 530px;
        margin-right: 0;
      }
    }

    .body_composition_table {
      margin: 0 26px 20px 26px;
    }

    .obesity_analysis_diagrams {
      height: 170px;
      max-width: 1033px;
      margin: 28px 17px 55px 30px;

      .obesity-max-width {
        max-width: 850px;
        @media screen and (max-width: 1410px) {
          max-width: 800px;
        }
        @media screen and (max-width: 1330px) {
          max-width: 700px;
        }
        @media screen and (max-width: 1260px) {
          max-width: 600px;
        }
        @media screen and (max-width: 1160px) {
          max-width: 500px;
        }
        @media screen and (max-width: 835px) {
          max-width: 440px;
        }
      }
    }

    .ob-fl-l {
      position: relative;
      left: -90px;
      max-width: 530px;
      @media screen and (max-width: 1430px) {
        max-width: 480px;
      }
      @media screen and (max-width: 1350px) {
        max-width: 430px;
      }
      @media screen and (max-width: 1290px) {
        max-width: 350px;
      }
      @media screen and (max-width: 1180px) {
        max-width: 290px;
        left: -90px;
      }
    }

    .ob-fl-t {
      position: relative;
      left: -59.8px;
      max-width: 110px;
      @media screen and (max-width: 1350px) {
      }
      @media screen and (max-width: 1180px) {
        max-width: 70px;
      }
    }

    .ob-fl-s {
      position: relative;
      left: -29.8px;
      max-width: 190px;
      @media screen and (max-width: 1350px) {
        max-width: 160px;
      }
      @media screen and (max-width: 1180px) {
        max-width: 140px;
      }
      @media screen and (max-width: 835px) {
        max-width: 110px;
      }
    }

    .ob-fl-f {
      max-width: 110px;
      @media screen and (max-width: 1350px) {
        max-width: 80px;
      }
      @media screen and (max-width: 1180px) {
        max-width: 70px;
      }
    }

    .date-panel {
      .non-active-text {
        @media all and (max-width: 1400px) {
          margin-right: 42px;
        }
        @media all and (max-width: 1300px) {
          margin-right: 28px;
        }
        @media all and (max-width: 1275px) {
          margin-right: 20px;
        }
        @media all and (max-width: 1200px) {
          margin-right: 10px;
        }
        @media all and (max-width: 1060px) {
          min-width: 37px;
          margin-left: 23px;
        }
        @media all and (max-width: 830px) {
          margin-left: 20px;
        }
      }
    }
  }

  .chart-container {
    height: 75px;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--athens_gray);
    border-left: 1px solid var(--athens_gray);

    &.no-left-border {
      border-left: 1px solid transparent;
    }
  }

  .darker-cell {
    background-color: #d6d7e2;
    height: 20px;
    width: 145px;
    @media screen and (max-width: 1000px) {
      width: 100px;
    }

    &.icon {
      width: 175px;
      background: #d6d7e2 url("../assets/images/vectors/minus.svg") no-repeat center;
    }
  }

  .light-cell {
    height: 20px;

    &.down {
      width: 192px;
      background: #e7e8ee url("../assets/images/vectors/down.png") no-repeat center;
    }

    &.up {
      width: 525px;
      background: #e7e8ee url("../assets/images/vectors/up.png") no-repeat center;
    }
  }

  .chart-label {
    @include textGrayLabel;
    color: var(--waikawa_gray);
    opacity: 0.5;
    padding-top: 18px;
    padding-left: 9px;
    width: 145px;
    @media screen and (max-width: 1000px) {
      width: 100px;
    }
  }

  .inbody-title-cell, .inbody-cell {
    @include textGrayLabel;
    width: 155px;
    height: 62px;
    padding-top: 17px;
    padding-left: 2px;
    border-top: 1px solid var(--athens_gray);
    border-right: 1px solid var(--athens_gray);

    &.no-borders {
      &.top {
        border-top: 1px solid transparent;
      }

      &.right {
        border-right: 1px solid transparent;
      }

      &.top-right {
        border-top: 1px solid transparent;
        border-right: 1px solid transparent;
      }
    }
  }

  .inbody-cell {
    height: 50px;
    width: 115px;
    text-align: center;
    @media screen and (max-width: 800px) {
      width: 95px;
    }

    &.table-text {
      height: 62px;
      color: var(--waikawa_gray);
    }
  }
}

.inbody-details-panel {
  justify-content: space-between;
  margin-right: 38px;
  margin-left: 38px;
  margin-top: 35px;
  @media screen and (max-width: 850px) {
    margin: 35px 0 0 0;
  }

  .details {
    max-width: 100%;
    @media screen and (max-width: 840px) {
      padding-left: 40px;
      padding-right: 30px !important;
    }
  }

  .btn-blue {
    margin: 0;
  }
}

.details-title-text {
  @include textRegular;
  color: var(--natural-600);
  font-weight: 700;

  &.report {
    margin-top: 3px;
  }
}

@import "preCustomer/view_videos_tab";
