.container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2vw;
}

.title {
  font: var(--body-14-medium);
  color: var(--natural-600);
}

.info {
  font: var(--title-16-bold);
}
