.container {
  position: sticky;
  bottom: 0;
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
  font: var(--body-14-bold);
  color: var(--primary-500);
  background: var(--main-black);

  @media (width >= 700px) {
    display: none;
  }
}

.icon {
  animation: down 1.5s infinite;
}

@keyframes down {
  0% {
    transform: translate(0);
  }

  20% {
    transform: translateY(6px);
  }

  40% {
    transform: translate(0);
  }
}

@keyframes down {
  0% {
    transform: translate(0);
  }

  20% {
    transform: translateY(6px);
  }

  40% {
    transform: translate(0);
  }
}