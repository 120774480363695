.customerParam {
  display: grid;
  gap: 4px;
  padding-right: 40px;

  .title {
    font: var(--body-12-medium);
    color: var(--natural-600);
  }

  .value {
    font: var(--title-16-medium);
  }
}
