.textInfo {
  display: inline-flex;
  gap: 8px;
  margin-bottom: 16px;
  font: var(--body-14-medium);

  .label {
    color: var(--natural-500);
  }
}
