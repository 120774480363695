.videoModal {
  max-width: 972px;
  padding: 15px;
  margin: 0 auto;

  .videoBox {
    position: relative;
    width: 100%;

    & iframe {
      width: 100%;
      aspect-ratio: 16/9;
    }

    .closeBtn {
      position: absolute;
      top: 0;
      left: 100%;
      margin-right: 24px;
    }
  }
}
