.blackBg {
  position: absolute;
  top: var(--header-height);
  right: 0;
  left: 0;
  z-index: 0;
  background-color: var(--main-black);
}

.container {
  position: relative;
  z-index: 1;
}
