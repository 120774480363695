.agreement {
  h2 {
    margin: 24px 0;
    font: var(--title-18-bold);
    letter-spacing: -0.6px;
  }

  p {
    margin-bottom: 10px;
    font: var(--title-16-regular);
    letter-spacing: -0.1px;
  }

  ul {
    margin-left: 30px;

    li {
      padding-bottom: 10px;
      font: var(--title-16-regular);
    }

    li::before {
      display: inline-block; /* Needed to add space between the bullet and the text */
      width: 1em; /* Also needed for space (tweak if needed) */
      margin-left: -1em; /* Also needed for space (tweak if needed) */
      font-weight: bold; /* If you want it to be bold */
      color: var(--primary-500); /* Change the color */
      content: '\2022'; /* Add content: \2022 is the CSS Code/unicode for a bullet */
    }
  }

  a {
    padding: 0 5px;
    font-weight: 500;
  }
}
