.customers-dashboard {
  max-width: 1376px;
  width: 100%;
  margin: 32px auto;

  md-tabs {
    padding: 0 22px;
    width: calc(100% + 44px);
    visibility: visible;
    margin-left: -22px;
    .md-ripple-container {
      display: none;
    }

    md-tabs-wrapper{
      position: static;
      margin-bottom: 17px;
    }
    
    md-pagination-wrapper {
      height: auto;
      position: static;
      justify-content: center;
      flex-wrap: wrap;
      margin: 0 -10px;
    }

    md-tabs-canvas {
      position: static;
      height: auto;
      overflow: visible;
    }

    .md-tab {
      text-align: left;
      text-transform: none;
      background: #ffffff;
      border-radius: 7px;
      border: 2px solid transparent;
      display: flex;
      align-items: flex-start;
      padding: 14px 15px;
      max-width: 33.33%;
      width: 30%;
      flex-grow: 1;
      box-shadow: 0 7px 22px rgba(96, 105, 157, 0.1);
      margin: 0 10px 20px 10px;

      &:nth-child(-n + 3) {
        // important fix bug https://lifebasellc.atlassian.net/browse/LMP-1122 
        max-width: none !important; 
      }

      &[disabled] md-icon {
        color: var(--tab-icons);

        svg path {
          stroke: var(--main-black);
        }
      }

      md-icon {
        min-width: 43px;
        height: 43px;
        border-radius: 50px;
        color: var(--tab-icons);
        background-color: var(--coach-tab-icon-background);
        margin: 0 10px 0 0;
        display: flex;
        justify-content: center;
        align-items: center;

        svg path {
          stroke: var(--tab-icons);
        }
      }
      .too_big_svg{
        svg {
          width: 24px;
          height: 24px;
        }
      }

      &.md-active {
        border-radius: 7px;
        border: 2px solid var(--main-black);
        box-shadow: 0 7px 22px rgba(96, 105, 157, 0.1);

        md-icon {
          color: var(--main-black);

          svg path {
            stroke: var(--main-black);
          }

          background-color: var(--coach-tab-icon-background);
        }
      }

      &-text-wrapper {
        overflow: hidden;
      }

      &__title {
        margin: 0;
        text-overflow: ellipsis;
        overflow: hidden;
        @include textLatoBlack;
        color: var(--main-black);
        font-size: 16px;
        font-weight: 900;
        letter-spacing: -0.38px;
      }

      &__sub-title {
        margin: 0;
        text-overflow: ellipsis;
        overflow: hidden;
        @include textRegular;
        color: var(--natural-600);
        font-size: 14px;
        font-weight: 700;
        letter-spacing: -0.34px;
      }

    }

    md-tabs-content-wrapper {
      overflow: hidden !important;
      position: static;
    }

    md-tab-content {
      transition: none;
      position: static;

      .md-card-under-construction {
        background-color: transparent;
        padding-top: 60px;
        margin: 0;

        img {
          width: 178px;
        }

        &__text {
          margin-top: 20px;
          color: var(--nothing-found-text);
          font-weight: 700;
          letter-spacing: 0.09px;
        }
      }
    }
  }
}
