.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.updateInfoContainer {
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 10px;
  color: var(--success-300);
}

.active {
  color: var(--success-300);
}
