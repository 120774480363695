.food-lists {
  .food-list-title {
    width: 100%;
    justify-content: space-between;
    margin: 40px 0;

    .btn-green {
      margin-left: 20px;
      width: 150px;
    }

    .md-select-value {
      span {
        color: rgb(109, 142, 238);
        font-size: 28px;
      }
    }

    .dropdown-entity {
      width: 19px;
      height: 26px;
      margin-left: 10px;
      margin-top: 10px;
      background: url("../../assets/images/vectors/dropdown_blue.svg") center / contain no-repeat;
    }
  }

  .food-lists-select {
    margin-left: 15px;
  }

  .all-food-search {
    position: relative;
    margin-top: 15px;
    border-radius: 3px;
    border: 1px solid rgba(164, 166, 177, 0.3);
    padding: 7px 10px;

    input {
      position: relative;
      width: 100%;
      padding-left: 30px;
      background: none;
      border: none;
    }

    input::placeholder {
      color: rgba(164, 166, 177, 0.5);
    }

    &:after {
      content: '';
      position: absolute;
      left: 15px;
      top: 10px;
      width: 15px;
      height: 16px;
      background-image: url("../../assets/images/vectors/search_magnifying_glass.svg");
    }
  }

  .title-text {
    margin-bottom: 13px;
  }

  .list-content .md-container {
    width: 66px;
  }

  .recommended {
    padding: 5px 10px;
    border-radius: 4px;
  }

  .recommended.protein-color {
    color: var(--proteins-color);
    background-color: rgba(109, 142, 238, 0.2);
  }

  .recommended.carbs-color {
    color: var(--carbs-color);
    background-color: rgba(255, 108, 107, 0.2);
  }

  .recommended.fats-color {
    color: var(--fats-color);
    background-color: rgba(255, 201, 84, 0.2);
  }

  .details-text {
    @include textRegular;
    color: var(--main-black);
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.38px;
    padding-left: 18px;

    &.carbs {
      background: url("../../assets/images/vectors/red_oval.svg") no-repeat left;
    }

    &.fats {
      background: url("../../assets/images/vectors/yellow_oval.svg") no-repeat left;
    }

    &.proteins {
      background: url("../../assets/images/vectors/blue_oval.png") no-repeat left;
    }
  }

  .food-card .padding-l-reset {
    padding-left: 0;
    word-break: break-all;
    margin-right: 5px;
  }

  .edit-food-point {
    display: none;
    width: 50%;
    margin: 0;
    padding: 0;
    height: 27px;
    color: inherit;

    input {
      color: var(--shadow);
      font-size: 16px;
      font-weight: 700;
      letter-spacing: -0.38px;
    }
  }

  .active.details-text {
    padding-left: 0;

    .edit-food-point {
      display: block;

      input {
        padding-left: 18px;
        min-width: 55px;
      }
    }
  }

  .product-text {
    @include textRegular;
    color: var(--main-black);
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.09px;
    padding-right: 16px;
    margin-right: 16px;
  }

  .list-content {
    padding-top: 30px;
    padding-bottom: 56px;

    .custom-tag-clmn-title {
      @media (max-width: 1200px) {
        text-align: center;
      }
    }

    .food-card {
      align-items: center;
      height: auto;
      box-shadow: 0 7px 21px rgba(96, 105, 157, 0.1);
      border-radius: 4px 3px 3px 4px;
      background-color: #ffffff;
      margin-bottom: 6px;
      padding: 22px 26px;
      opacity: 1;

      md-checkbox[disabled] {
        .md-label {
          font-size: 16px;
          white-space: nowrap;
          color: var(--main-black);
        }

        &.md-checked .md-icon {
          background-color: var(--main-black)
        }
      }

      &.non-active {
        opacity: 0.5;
      }

      .wrap-container {
        flex-wrap: wrap;
        margin-right: 10px;
      }

      .custom-tag {
        height: auto;
        margin-bottom: 5px;
        margin-top: 5px;
        @media (max-width: 1200px) {
          margin: 5px auto 5px auto;
        }
      }

      button.myNetDiary.btn-green {
        margin-left: 20px;
        @media (max-width: 900px) {
          margin-left: 0;
        }
      }
    }
  }

  .active-menu {
    position: relative;
  }
}

