.row {
  display: grid;
  grid-template-columns: 46% 46%;
  justify-content: space-between;
}

.box {
  display: flex;
  gap: 40px;
  align-items: stretch;
  justify-content: space-between;

  .info {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: start;
    justify-content: space-between;
  }

  .icon {
    flex: 80px 0 0;
  }
}
