.scoreNotExist {
  color: var(--main-black);
}

.badScore {
  color: var(--error-300);
}

.goodScore {
  color: var(--info-300);
}
