.stepperInfoRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;

  .title {
    font: var(--title-18-bold);
    color: var(--primary-500);
  }

  .stepInfo {
    font: var(--body-14-medium);
    color: var(--white);
  }
}

.linearProgress {
  margin-bottom: 40px;

  &:global(.MuiLinearProgress-root) {
    height: 7px;
    background-color: var(--natural-600);
    border-radius: 8px;
  }

  @media (width < 768px) {
    margin-bottom: 32px;
  }
}

.stepTitleRow {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;

  .title {
    font: var(--title-36-bold);
    color: var(--white);

    @media (width < 768px) {
      font: var(--title-22-bold);
    }
  }

  .description {
    font: var(--body-14-medium);
    color: var(--natural-400);
  }
}
