.entity-stats {
  max-width: 1376px;
  width: 100%;
  margin: 32px auto;

  .table-wrapper {
    margin: 20px 0 70px;
    box-shadow: 0 7px 22px rgba(96, 105, 157, .1);
  }

  table {
    background-color: #ffffff;
    border-radius: 7px;
    overflow: auto;

    tr {
      &:last-child {
        border-top: 1px solid #e9ebef;
      }

      &:nth-child(2) {
        th {
          padding: 4px 15px 9px;
        }
      }
    }

    th {
      color: var(--natural-600);
      letter-spacing: -.34px;
      font-size: 14px;
      font-weight: 700;
      text-align: center;
      padding: 15px;
      vertical-align: top;
      border-bottom: 1px solid #e9ebef;

      &:not(:first-child) {
        border-left: 1px solid #e9ebef;
      }

      &:nth-child(1) {
        width: 100px;
      }
    }

    td {
      color: #000000;
      text-align: center;
      font-weight: 900;
      font-size: 16px;
      letter-spacing: -.38px;
      padding: 15px;

      &:not(:first-child) {
        border-left: 1px solid #e9ebef;
      }
    }
  }

  &__entity-name {
    font-weight: 900;
    color: #000000;
    font-size: 16px;
    letter-spacing: -.38px;
    margin-top: 25px;

    a {
      margin-left: 10px;
    }
  }

  .button-entities-container {
    font-weight: 900;
    border-bottom: dashed 2px var(--primary-500);
    font-size: 28px;
    border-radius: 0;
    padding: 0;
    height: 38px;
    md-icon {
      margin-right: -25px;
      margin-top: 14px;
      margin-left: 5px;
      min-width: 20px;
      width: 20px;
      min-height: 20px;
      height: 20px;
    }
  }
}
