.operator-profile {
  max-width: 1376px;
  width: 100%;
  margin: 32px auto;

  .title-profile {
    padding: 0 15px;

    &__avatar {
      text-transform: uppercase;
      background: var(--profile-avatar-background);
      color: var(--main-black);
      font-size: 32px;
      letter-spacing: -0.76px;
      font-weight: 900;
      border-radius: 50%;
      min-width: 64px;
      line-height: 64px;
      text-align: center;
      margin-right: 20px;
    }

    &__text {
      color: var(--main-black);
      font-size: 28px;
      letter-spacing: -0.67px;
      font-weight: 900;
      margin: 0;
    }
  }

  .app-card {
    padding: 25px;
    margin-bottom: 0;

    &.profile-header {
      box-shadow: none;
      margin-bottom: 16px;
    }

    .sub-title {
      margin: 50px 15px 25px;
      color: var(--main-black);
      font-weight: 900;
      font-size: 22px;
      letter-spacing: -0.53px;
    }

    button[type="submit"] {
      margin-top: 52px;
      margin-left: auto;
      display: block;
    }
  }
}
