.box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.generalInfoBox {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  margin-bottom: 16px;
}

.chartBox {
  height: 191px;
}
