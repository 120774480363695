@import "../../../../sass/mixins";

.life-base-fees {
  p {
    margin: 0;
  }

  .app-card {
    &-header {
      &__title {
        padding-right: 15px;
        text-transform: capitalize;
      }
    }
    &-body {
      padding-left: 26px;

      &__col {
        width: 49%;
      }

      &__title {
        padding-bottom: 15px;
      }

      .col {
        &__header {
          width: 100%;
          font: var(--body-14-regular);
          color: #969696;
        }

        &__item {
          height: 55px;
          padding: 10px 20px;
          &:nth-of-type(even) {
            background: var(--background-50-light);
            border-radius: 8px;
          }

          .hidden-checkbox {
            .md-container {
              display: none;
            }
            .md-label {
              margin-left: 0;
            }
          }

          .grey {
            color: #B6B6C2;
          }

          .not-included {
            width: 105px;
            font: var(--title-16-bold);
            color: #B6B6C2;
          }

          .md-checkbox-primary {
            .md-label {
              font-weight: 500;
            }
          }

          md-input-container {
            margin: 0;
          }
        }
      }
    }
  }

  .fee-schedule {
    padding: 32px 26px 44px 26px;
  }

  .one-time-payment {
    padding: 28px 32px;
    &__item {
      width: 260px;
      height: 55px;
      padding: 10px 20px;
      background: var(--background-50-light);
      border-radius: 8px;
      font: 500 16px/22px Lato;

      &_only-value {
        width: auto;
      }

      md-input-container.no-message {
        width: 100px;
        margin: 0;
      }
    }

    &__description {
      margin: 40px 0 0 0;
      width: 100%;
    }
  }

  md-switch {
    margin: 0;
  }

 .table {
    @include feesTable;
 }

  .cert-group-title {
    margin: 50px 0 15px;
    font: 900 20px/24px 'Lato';
  }

  .payment-label {
    color: #666666;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: -0.38px;
  }

  .switch-radio {
    margin-right: 15px;
  }

  .btn-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
    text-align: end;
    button {
      margin-left: 8px;
    }
  }

  md-input-container.no-message {
    width: auto;
    margin: 0 15px;
  }
}
