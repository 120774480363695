.new-members-added-page {
  max-width: 1376px;
  width: 100%;
  margin: 32px auto;

  .title-page {
    font-weight: 900;
    color: #000000;
    font-size: 28px;
    letter-spacing: -.67px;
    line-height: 45px;
    margin: 0;
  }

  button.outline {
    margin-left: 10px;
  }

  .filter-list-input {
    padding-right: 45px;
  }

  .list {
    margin-top: 14px;

    &-item {
      background-color: #fefefe;
      min-height: 80px;
      padding: 0 25px;

      &:not(:first-child) {
        margin-top: 8px;
      }

      &:first-child {
        .list-item__text {
          color: var(--natural-600);
        }
      }

      &__text {
        font-weight: 700;
        letter-spacing: -.34px;
        font-size: 14px;
        color: #000000;
        margin: 0;
      }
    }
  }
}
