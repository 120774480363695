.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-top: 150px;

  .placeholderBox {
    display: flex;
    gap: 25px;
    align-items: center;
    justify-content: space-between;
    width: 560px;

    h4 {
      font: var(--title-22-bold);
    }
  }
}
