.dateRangeRow {
  display: flex;
  gap: 16px;
  align-items: center;

  .label {
    flex: 1 1 25%;
    font: var(--body-14-bold);
  }

  .dateRangePicker {
    flex-grow: 1;
  }
}
