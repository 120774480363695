.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80vh;
}

.titleContainer {
  background-image: url("../../../../assets/images/line.png");
  background-repeat: no-repeat;
  background-position: 0 23px;
}

.title {
  font: var(--title-28-black);
}

.description {
  width: 335px;
  margin-top: 12px;
  font: var(--title-16-regular);
  text-align: center;
}

.followUs {
  margin: 90px 0 10px;
  font: var(--body-12-bold);
}

.icon {
  padding: 5px;
  margin: 0 5px;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
}
