.notes {
  padding: 25px 0 !important;

  .spinner-container {
    min-height: 100px;
    padding-top: 16px;
  }

  .add-note {
    padding: 0 25px;

    &__input-label {
      letter-spacing: -0.38px;
      font-size: 15px;
      font-weight: 500;
      color: var(--label-text);
      margin: 0;

      &:not(:first-child) {
        margin-top: 20px;
      }
    }
  }

  md-input-container {
    margin: 0;
    width: 100%;

    textarea {
      margin-top: 5px;
      border: 1px solid var(--note-border);
      border-radius: 6px;
      padding: 8px !important;
      min-height: 26px;

      &:focus {
        border: 1px solid var(--primary-500) !important;
      }
    }
  }

  .tags {
    background-color: var(--white);
    box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.075);
    transition: none;
    margin-top: 5px;
    border: 1px solid var(--note-border);
    border-radius: 6px;
    padding: 8px;

    .tag-item {
      color: var(--natural-600);
      font-weight: 700;
      letter-spacing: -.34px;
      border-radius: 5px;
      border: 1px solid var(--note-tag-background);
      background: var(--note-tag-background);
      padding: 0 10px;
      height: 31px;
      line-height: 30px;

      .remove-button {
        color: var(--natural-600) !important;
        margin-left: 25px;
        text-decoration: none;
      }
    }

    &.focused {
      box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.075);
      border: 1px solid var(--primary-500);
    }
  }

  .autocomplete {
    padding: 4px;

    ::-webkit-scrollbar {
      width: 4px;
      height: 529px;
    }

    ::-webkit-scrollbar-track {
      border-radius: 3px;
      background-color: #e7e7e7;
    }

    ::-webkit-scrollbar-thumb {
      background-color: var(--primary-500);
      border-radius: 3px;
      width: 4px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background-color: var(--warning-600);
    }

    .suggestion-item {
      color: var(--main-black) !important;
      font-weight: 700;
      letter-spacing: -0.34px;
      background-color: white;
      height: 56px;

      img {
        border-radius: 50%;
      }

    }

    .suggestion-item.selected {
      background-color: var(--select-item-background);
      border-radius: 2px;
    }
  }

  button[type="submit"] {
    margin-top: 0 !important;
    margin-left: auto;
    display: block;
  }

  .note-item {
    font-weight: 700;
    padding: 25px;
    border-top: 1px solid var(--note-border);

    &:nth-child(2){
      margin-top: 25px;
    }

    &-date-wrapper {
      padding-right: 25px;
      border-right: 1px solid #e1e1e9;
      min-width: 90px;
      height: 60px;
      text-align: center;
    }

    &__day {
      font-size: 28px;
      color: var(--natural-600);
    }

    &__month {
      font-size: 14px;
      color: var(--main-black);
      opacity: .75;
      font-weight: 400;
    }

    &-info {
      margin-left: 25px;

      &__text {
        font-size: 16px;
        font-weight: 400;
        letter-spacing: -.38px;
        line-height: 25px;
        color: var(--main-black);
        opacity: .8;
        word-break: break-all;
      }

      &__tag {
        color: var(--natural-600);
        background-color: var(--note-tag-background);
        border-radius: 5px;
        padding: 3px 17px;
        margin: 10px 10px 0 0;
      }

      button.clear {
        color: var(--main-black);
        border-bottom: 1px solid rgba(var(--link-btns-rgb), .3);
        border-radius: 0;
        padding: 0;
        height: auto;
        margin-right: 10px;
        font-size: 14px;
        margin-top: 20px;
      }
    }
  }
}

.user-tag-avatar-circle {
  background-color: var(--primary-500);
  border-radius: 50%;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  height: 35px;
  width: 35px;
  text-align: center;
  padding-top: 7px;
}

.left-panel {
  margin-top: 5px;
  float: left;

  img {
    width: 35px;
    height: 35px;
  }
}

.right-panel {
  float: left;
  margin-top: 5px;
  margin-left: 10px;
  width: calc(100% - 50px);

  p {
    margin: 0;

    &:first-child {
      font-size: 16px;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &:last-child {
      font-size: 14px;
      opacity: 0.5;
      font-weight: 400;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.prev-page-arrow, .next-page-arrow {
  cursor: pointer;

  md-icon {
    margin: 0;
  }

  &.non-active {
    opacity: 0.5;
    cursor: initial;
  }
}

.prev-page-arrow {
  transform: scale(-1);
}
