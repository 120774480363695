.sales-reps {
  &__autocomplete {
    margin-top: 0;

    .autocomplete-search[disabled] label {
      opacity: 1;
    }

    label {
      margin-bottom: 2px;
    }
  }

  button.delete-item {
    border-radius: 0;
    padding: 0;
    font-weight: 300;
    border-bottom: 1px solid var(--primary-500);
    height: 19px;
  }
}


