.analyticsBox {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;
  height: 100%;

  .separator {
    height: 74px;
  }
}
