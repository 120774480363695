.box {
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 12px 20px;
  font: var(--body-14-medium);
  color: var(--white);
  background-color: var(--error-300);
}

.link {
  color: inherit !important;
  text-decoration-color: currentcolor;
}
