$padding-for-different-cell-sizes: min(calc(100% - 120px), 30px);

.serving {
  display: flex;
  align-items: center;
  padding-right: $padding-for-different-cell-sizes;

  button {
    margin-left: auto;

    svg {
      fill: var(--natural-300);
    }
  }
}
