.container {
  display: flex;
  justify-content: space-between;
}

.title {
  font: var(--title-16-black);
}

.helper {
  display: flex;
  align-items: center;
  padding: 0 20px;
  background: rgb(101 219 155 / 16%);
  border: 1px solid rgb(101 219 155 / 16%);
  border-radius: 8px;
}

.helperTitle {
  font: var(--body-14-bold);
  color: var(--success-300);
}
