.customer-food-list-tab {
  .quick-search {
    margin-right: 10px;
  }

  .filter-list-input {
    margin-top: 40px;

    md-icon {
      margin-right: -30px;
      min-height: 16px;
      height: 16px;
    }

    md-icon[md-svg-icon="close"] {
      width: 24px;
      height: 24px;
      min-width: 24px;
      min-height: 24px;
    }

    input {
      background: transparent;
      padding-left: 34px;
    }
  }

  .list {
    margin-top: 20px;
    min-height: 100px;

    .custom-tag-clmn-title {
      text-align: center;
    }

    &-item {
      box-shadow: 0 7px 21px rgba(96, 105, 157, 0.1);
      background-color: #ffffff;
      margin-bottom: 10px;
      padding: 6px 17px;
      min-height: 74px;

      &:first-child {
        box-shadow: none;
        background: transparent;

        .list-item__text {
          font-size: 14px;
          color: var(--natural-600);
        }
      }

      md-checkbox[disabled] {
        .md-label {
          font-size: 16px;
          white-space: nowrap;
          color: var(--main-black);
        }

        &.md-checked .md-icon {
          background-color: var(--main-black)
        }
      }

      &.non-active {
        opacity: 0.5;
      }

      .tag {
        width: auto;
        border-radius: 12px;
        color: var(--white);
        font-size: 14px;
        font-weight: 700;
        letter-spacing: -.34px;
        padding: 3px 8px 3px 8px;
        height: min-content;
        margin: 5px 0;
        border: 1px solid #c9cbd4;
        background: #c9cbd4;

        &-Protein {
          border-color: var(--proteins-color);
          background: var(--proteins-color);
        }

        &-Carbohydrate {
          border-color: var(--carbs-color);
          background: var(--carbs-color);
        }

        &-Fat {
          border-color: var(--fats-color);
          background: var(--fats-color);
        }

        &-Condiments {
          border-color: var(--condiments-color);
          background: var(--condiments-color);
        }

        &-Fruit, &-Veggie {
          border-color: var(--veggies-color);
          background: var(--veggies-color);
        }

        @media (max-width: 1200px) {
          margin: 5px auto 5px auto;
        }
      }

      &__text {
        font-weight: 700;
        font-size: 16px;
        margin: 0;
        color: #000000;
        opacity: 1;
      }

      p[class^="icon-"] {
        &:before {
          content: '•';
          margin-right: 7px;
          font-size: 20px;
        }
      }

      .icon-protein {
        &:before {
          color: var(--proteins-color);
        }
      }

      .icon-carbs {
        &:before {
          color: var(--carbs-color);
        }
      }

      .icon-fats {
        &:before {
          color: var(--fats-color);
        }
      }
    }

    button.add-button {
      height: 36px;
      min-width: 65px;
    }
  }

  .empty-list-placeholder {
    background: var(--no-food) no-repeat center;
    background-size: 190px 146px;
  }
}
