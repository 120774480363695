.paper {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 688px;
  padding: 34px 38px 38px;
  margin: 67px auto 0;

  .stars {
    position: absolute;
    top: 0;
    left: 50%;
    width: 296px;
    transform: translate(-50%, -50%);
  }

  .infoBox {
    text-align: center;

    h3 {
      font: var(--title-22-black);
    }

    p {
      margin-top: 8px;
      font: var(--title-16-regular);
      color: var(--natural-700);
    }
  }
}
