.radio {
  padding: 0;
}

.icon {
  margin: 0 5px;
  color: var(--natural-300);
}

.headerContent {
  display: flex;
  align-items: center;
}

.headerTitle {
  margin-left: 14px;
  font: var(--title-16-black);
}

.headerDay {
  font: var(--title-16-black);
  color: var(--natural-600);
}

.contentContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.contentInfoTitle {
  font: var(--body-12-medium);
  color: var(--natural-600);
}

.contentInfoData {
  font: var(--title-16-bold);
}
