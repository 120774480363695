.buttonGroup {
  display: flex;
  grid-column: span 2;
  column-gap: 24px;
  align-items: center;
  justify-content: start;
}

.scanData {
  grid-column: 1 / -1;
}
