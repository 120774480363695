.printable {
  display: none !important;
}

@media print {
  html {
    background-color: white !important;
  }
  body {
    margin: 0 !important;
    padding: 0 !important;
    width: 285mm !important;
  }
  @page {
    size: A4;
    margin: 10mm auto;
  }

  md-toolbar, footer {
    display: none;
  }

  .inbody-details-panel {
    md-input-container.title-dropdown md-select-value {
      border-bottom: none !important;
    }

    md-icon {
      display: none;
    }
  }

  .customer-profile {
    padding-top: 0 !important;
    background-color: white !important;

    .print-button, .user-details-panel, .back-page, .customer-details-tabs, .tabs, .inbody-details-panel, .title-actions {
      display: none;
    }

    .title-profile__avatar {
      opacity: 0;
      margin-right: -20px !important;
    }

    .white-shape {
      background-color: #f5f5f5 !important;
    }

    .blue-title-text {
      color: black !important;
    }

    .details-title-text {
      color: var(--light_storm_grey) !important;
    }
  }
  .app-body {
    background-color: white !important;
  }
  .navbar-fixed-bottom {
    display: none;
  }

  .printable {
    display: block !important;
  }
}
