.complianceInfoModal {
  max-width: 1200px;
  margin: 0 auto;
}

.container {
  width: 100%;
}

@media screen and (width <= 1200px) {
  .complianceInfoModal {
    margin: 0 15px;
  }
}
