.titleBlock {
  margin-bottom: 24px;
  text-align: center;

  h3 {
    margin-bottom: 4px;
    font: var(--title-28-bold);
  }

  p {
    font: var(--title-16-medium);
    color: var(--natural-700);
  }

  img {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
