.listBox {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.listItem {
  display: flex;
  gap: 8px;
  align-items: center;
  font: var(--body-14-medium);
}
