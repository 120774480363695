.selectInfoRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 24px;
  margin-bottom: 8px;
  font: var(--title-16-medium);
  background-color: var(--warning-50);

  .optionsBox {
    display: flex;
    gap: 10px;
  }
}

.subGridRow {
  grid-column: 1 /-1;
}
