.testRow {
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: space-between;

  .resultDateCell {
    display: flex;
    gap: 10px;
    align-items: center;
    width: 100%;
  }

  .addNewBtnContainer {
    min-width: 145px;
  }
}
