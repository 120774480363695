.todayRow {
  background-color: var(--background-50-light);
}

.score {
  gap: 4px;
  justify-content: flex-end;
  padding-right: 55px;

  svg {
    fill: var(--warning-300);
  }
}
