.mealTable {
  :global(.tableGridLayout) {
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    gap: 24px;
    place-items: center start;
  }

  margin-bottom: 8px;
}
