.container {
  display: flex;
  flex-direction: row;
  gap: 4px 8px;
  align-items: start;
  width: 100%;
  padding: 16px 12px;
  border: var(--natural-300) 1px solid;
  border-radius: 8px;

  @media (width <= 700px) {
    flex-direction: column;
    padding: 12px 12px 16px;
  }
}

@media (hover) {
  .container:hover {
    border: 1px solid var(--primary-500);
  }
}

.checkedContainer {
  border: 1px solid var(--primary-500);
}

.subscriptionInfoContainer {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 4px;
  place-content: start space-between;
  width: 100%;

  @media (width <= 700px) {
    grid-template-columns: 1fr;
    gap: 12px;
  }
}

.titleContainer {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;

  @media (width <= 700px) {
    flex-direction: column;
    gap: 4px;
    align-items: start;
  }
}

.title {
  font: var(--title-16-bold);
  color: var(--main-black);
}
