.complianceInfoTable {
  width: 100%;

  :global .tableGridLayout {
    grid-template-columns: 180px 1fr 1.5fr repeat(8, 1fr);
    gap: 10px;
    place-items: center start;
  }

  & > * {
    border-bottom: 1px solid var(--natural-300);
  }

  & > :last-child {
    border-bottom: none;
  }
}

@media print {
  .complianceInfoTable {
    border: 1px solid var(--natural-300);
    break-inside: avoid;
  }
}

.foodTitle {
  display: flex;
  flex-direction: column;
  gap: 2px;

  .label {
    font: var(--body-12-medium);
    color: var(--natural-600);
  }
}
