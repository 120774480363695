[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
    display: none !important;
}

.floatRight {
    float: right;
    margin-right: 18px;
}

.has-error, .error-message, .delete-button {
    color: red;
}

.delete-button {
    width: 30px;
}

.error-message {
    font-weight: bold;
    font-size: medium;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    margin: 0 20px 0 20px;
}

.form-container {
    background-color: white;
    /*padding: 20px 20px 0 0;*/
}

.emoticon-container {
    background-color: white;
}

.generic-container {
    width: 80%;
    margin-left: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 20px;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 0 30px black;
}

.custom-width {
    width: 80px !important;
}

.ext-content {
    min-width: 840px;
    padding: 50px;
}


.pad-right-15 {
    padding-right: 15px;
}

.pad-left {
    padding-left: 50px;
}

.screen-fit-100 {
    height: 100vh;
}

.screen-fit {
    height: 90vh;
}

.appPreview {
    height: 250px;
}

.about {
    height: 250px;
}

.contact, .waiver-link-cell {
    text-align: center;
}

.login-dialog {
    max-width: 90%;
    width: 500px;
}

.edit-help-dialog {
    width: 700px;
    max-width: 90%;
    max-height: 80%;
}

.edit-help-large-field {
    width: 300px;
    margin: 20px 20px 0 20px;
}
.edit-help-small-field {
    width: 150px;
    margin: 20px 20px 0 20px;
}
.edit-help-textarea-field {
    width: 450px;
    margin: 20px 20px 0 20px;
}

.grade-dialog {
    width: 590px;
    max-width: 90%;
    max-height: 80%;
}

.grade-side {
    margin: 10px 10px 10px 25px;
}

.grade-side-right {
    margin: 10px 10px 10px 40px;
}

.grade-label {
    width: 220px;
    font-size: medium;
}

.grade-value {
    width: 20px;
    font-weight: bold;
}

.food-admin-table {
    max-height: 500px;
    overflow: auto;
}

.food-admin-select-all {
    margin-left: 13px !important;
    margin-top: 10px;
}

.food-admin-select {
    margin-left: 10px !important;
    margin-top: 10px;
}

.food-admin-align {
}

.food-admin-loading {
    clear: both;
    margin: auto;
    width: 10%;
    display: block;
    overflow: hidden;
}

.food-admin-no-data {
    clear: both;
}

.food-admin-center-align {
    text-align: center !important;
    vertical-align: middle;
}

table.clone thead tr:last-child th {
    border-bottom: 1px rgba(0, 0, 0, 0.12) solid;
}

table.clone + md-table-container table tbody tr:first-child td {
    border-top: none;
}

.food-admin-table-container {
    max-height: 500px;
}

.grade-nutrient-column {
    width: 20px !important;
}

#verticalContainer {
    height: 500px;
}

/* This is needed for the $mdSticky hack */
.sticky-placeholder {
    display: none !important;
}

/* When my md-table was inside an md-card or other elements with margin, the sticky clone was given incorrect margins so I reset them to 0. */
.md-table.md-sticky-clone {
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .16);
    margin: 0 !important;
}

.table-container {
    height: 500px
}

.food-admin-table {
    height: 500px
}

.core-food-list {
    height: 100%;
    overflow-y: scroll;
}

.entity-header {
    width: 100%;
    margin: 0 20px 0 20px;
}

md-toolbar.md-default-theme:not(.md-menu-toolbar), md-toolbar:not(.md-menu-toolbar) {
    background-color: red;
    color: white;
    font-weight: 900;
    font-size: x-large;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.margin-below {
    margin-bottom: 20px;
}

.admin-button {
    height: 50px;
    width: 200px;
    margin: 10px 10px 10px 10px;
    border-radius: 25px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    text-transform: none;
}

.core-food-button {
    position: absolute;
    bottom: 0;
    height: 50px;
    width: 200px;
    margin: 10px 10px 10px 10px;
    border-radius: 25px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    text-transform: none;
}

.red-button {
    background-color: red;
    color: white;
}

.verydark-gray-button {
    background-color: #333333;
    color: white;
}

.red-button:not([disabled]):hover {
    background-color: #bd0000;
}

.red-button:not([disabled]):active {
    background-color: #ff8080;
}

.red-button[disabled] {
    background-color: lightgray;
    color: dimgray;
    text-transform: none;
}

.blue-button {
    background-color: #303BA6;
    color: white;
}

.blue-button:not([disabled]):hover {
    background-color: #2f3a7c;
}

.blue-button[disabled] {
    background-color: lightgray;
    color: dimgray;
    text-transform: none;
}

.green-button {
    background-color: #20cb94;
    color: white;
}

.green-button:not([disabled]):hover {
    background-color: #189a71;
}

.green-button:not([disabled]):active {
    background-color: #7beac7;
}

.green-button[disabled] {
    background-color: lightgray;
    color: dimgray;
    text-transform: none;
}

.purple-button {
    background-color: #a58afc;
    color: white;
}

.purple-button:not([disabled]):hover {
    background-color: #6538fa;
}

.purple-button:not([disabled]):active {
    background-color: #c5b4fd;
}

.purple-button[disabled] {
    background-color: lightgray;
    color: dimgray;
    text-transform: none;
}

.SlimMdInputContainer:not(.md-input-invalid) {
    margin: 0 0 0 0;
    padding: 0 10px 0 10px;
}

.time-entry {
    width: 95px;
}

.time-select-all, .time-select-all-entry {
    width: 30px;
    font-size: medium;
    font-weight: 700;
}

.time-select-all-entry, .time-entry {
    padding: 20px 0 20px 0;
}

.mealtype-column {
    width: 95px;
    font-size: medium;
    font-weight: 700;
}

.mealtype-row-header {
    width: 140px;
    padding: 0 15px 0 0;
    font-size: medium;
    font-weight: 700;
}

.hint {
    font-size: x-small;
}

.SlimMdInputContainer.md-input-invalid {
    margin: 0 0 0 0;
    padding: 0 0 0 0;
}

.SlimMdInputContainer:not(.md-input-invalid) input {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    height: 25px;
    min-height: 20px;
}

.md-button.md-small {
    min-width: 1%;
}

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
    display: none !important;
}

.noline-bottom {
    padding-top: 10px;
}

.hline-bottom {
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid lightgray;
}

.meal-contents-header {
    width: 90%;
    padding: 10px 0 0 30px;
}

.meal-contents {
    border: 2px solid lightgray;
    box-sizing: border-box;
    background: #eff3f4;
    width: 95%;
    border-radius: 10px;
    margin: 5px 0 20px 0;
    min-height: 50px;
    max-height: 215px;
}

.edit-list-button {
    color: #0F61C1;
    padding: 5px 30px 5px 30px;
}

.meal-choice-content {
    background-color: white;
    min-height: 50px;
}

.food-item-separator {
    border-bottom: 1px solid lightgray;
    margin: 0 15px 0 15px;
}

.meal-details-tab-body {
    background-color: white;
}

.meal-contents::-webkit-scrollbar {
    width: 12px;
}

.meal-contents::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}

.meal-contents::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.protein-font, .fat-font, .carb-font, .total-font {
    font-size: medium;
    font-weight: bold;
}

.protein-font {
    color: #20cb94;
}

.carb-font {
    color: #a58afc;
}

.fat-font {
    color: #fc4667;
}

.fiber-font {
    color: #20cb94;
}

.sugar-font {
    color: black;
}

.sugar-percent-font {
    color: white;
    font-weight: 800;
    font-size: x-large;
}

.total-font {
    color: white;
}

.nutrient-name-font {
    color: #dddee0;
    font-weight: bold;
    font-size: large;
}

.daily-nutrient-name-font {
    color: white;
    font-size: large;
    font-weight: 700;
    font-family: Roboto, "Helvetica Neue", sans-serif;

}

.plus-in-button {
    font-weight: 900;
    font-size: xx-large;
    padding-right: 10px;
}

.icon-in-button {
    color: white;
    font-weight: 900;
    padding-right: 10px;
}

.main-text-in-button.message {
    background: url(../images/vectors/message_pen.svg) no-repeat left;
    padding-left: 20px;
}

.multi-line-button-text {
    color: white;
    font-weight: bold;
    font-size: large;
    text-align: center;
    vertical-align: middle;
    line-height: 20px;
    margin-bottom: 5px;
}

.sub-text-in-button {
    color: whitesmoke;
    font-weight: normal;
    font-size: medium;
}

.nutrient-per-item {
    padding: 5px 5px 5px 5px;
}

.meal-chart {
    background-color: #3d464b;
    height: 120px;
    min-height: 120px;
    max-height: 120px;
}

.daily-summary {
    background-color: #3d464b;
}

.circular-progress-bar-container {
    display: block;
    position: relative;
    padding-left: 20px;
}

.circular-progress-bar {
    position: absolute;
    top: 0;
    left: 20px;
}

.circular-progress-bar-inner {
    position: absolute;
    top: 4px;
    left: 24px;
}

.circular-progress-bar-big-font {
    color: white;
    font-weight: 900;
    font-size: x-large;
    margin-bottom: 5px;
}

.big-circular-progress-bar-container {
    display: block;
    position: relative;
    margin: 5px 10px 0 10px;
    width: 120px;
}

.big-circular-progress-bar {
    position: absolute;
    top: 0;
    left: 10px;
}

.big-circular-progress-bar-inner {
    position: absolute;
    top: 4px;
    left: 14px;
}

.big-circular-progress-bar-center {
    margin-top: 20px;
}

.big-circular-progress-bar-below {
    margin-top: 30px;
    color: white;
}

.food-score-button {
    border-radius: 10px 10px 0 0;
    margin-top: 30px;
    padding: 5px 0 10px 0;
    background-color: #4E5B63;
    opacity: 1;
    width: 90%;
}

.food-score-summary-section {
    border-radius: 10px 10px 10px 10px;
    margin: 30px 0 30px 0;
    padding: 5px 0 10px 0;
    background-color: #4E5B63;
    width: 90%;
}

.food-score-font {
    color: white;
    font-size: x-large;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    margin-right: 30px;
}

.summary-spacing {
    margin: 15px 30px 15px 30px;
}

.compliance-quality-score-font {
    color: white;
    font-size: large;
    font-weight: 700;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    width: 120px;
}

.meal-score-font {
    color: white;
    font-size: medium;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    margin-left: 30px;
    margin-right: 30px;
    width: 120px;
}

.fiber-indicator-container, .sugar-indicator-container {
    padding-left: 20px;
}

.big-sugar-container {
    margin-right: 30px;
    width: 80%;
}

.big-fiber-indicator-container {
    margin-left: 20px;
}

.foodsense-page-header, .foodsense-page-header-meal {
    background-color: red;
    color: white;
}

.foodsense-page-header-meal {
    min-height: 72px;
    max-height: 72px;
}

.header-menu-content {
    padding: 0 0 0 0 !important;
}

.meal-item-header {
    font-weight: 900;
    font-size: medium;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.meal-item {
    font-weight: 500;
    font-size: medium;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    padding-left: 15px;
}

.meal-item-sub, .meal-goal-values-sub, .meal-item-nutrient {
    font-weight: normal;
    font-size: small;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.meal-item-sub {
    padding-left: 15px;
}

.meal-goal-sub {
    width: 60px;
    color: #4E5B63;
    font-weight: normal;
    font-size: small;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.meal-goal-values-sub, .meal-item-nutrient {
    width: 60px;
}

.meal-goal-values-sub {
    color: black;
}

.meal-item-nutrient {
    color: #4E5B63;
}

.whole-meal-sub {
    color: #20cb94;
    font-weight: 700;
    font-size: medium;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    text-transform: none;
    margin-right: 30px;
    padding-left: 15px;
}

.md-tab.md-active {
    background-color: white;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    font-weight: 600;
}

.md-tab {
    background-color: #eff3f4;
}

.md-ink-bar {
    height: 0;
}

.page-container {
    background-color: #eff3f4;
}

.tab-contents {
    background-color: white;
}

.food-search-input {
    height: 40px;
    padding: 0 40px 0 10px;
    width: 100%;
    border-radius: 20px;
}

.outer-food-search-container {
    padding: 10px 30px 10px 30px;
    background-color: #eff3f4;
    min-height: 60px;
    max-height: 60px;
}

.food-search-input-container {
    position: relative;
}

.food-search-input-icon {
    position: absolute;
    bottom: 8px;
    right: 10px;
    width: 24px;
    height: 24px;
}

.meal-header {
    padding: 0 0 10px 30px;
}

.list-type-header-container {
    min-height: 35px;
    height: 35px;
}

.list-type-header {
    padding: 0 0 5px 30px;
}

.search-food-item {
    padding: 5px 0 5px 30px;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

.md-tabs.md-no-animation md-tab-content {
    transition: none;
}

.meal-item-dialog {
    width: 500px;
}

.choose-meal-plan-dialog {
    width: 440px;
}

.waiver-dialog {
    width: 500px;
}

.background-bar {
    background-color: lightgray;
}

.protein-bar {
    background-color: #20cb94;
    height: 10px;
    width: 50%
}

.carb-bar {
    background-color: #a58afc;
    height: 10px;
    width: 50%
}

.fat-bar {
    background-color: #fc4667;
    height: 10px;
    width: 50%
}

.fiber-bar {
    background-color: black;
    height: 10px;
    width: 50%
}

.sugar-bar {
    background-color: black;
    height: 10px;
    width: 50%
}

.meal-separator {
    border-bottom: 15px solid #eff3f4;
    margin: 0 15px 0 15px;
}

.meal-item-separator {
    border-bottom: 1px solid lightgray;
    margin: 0 15px 0 15px;
}

.meal-summary-rating-spacing {
    padding: 5px 20px 5px 20px;
}

.time-adjust-icon {
    color: #20cb94;
    font-weight: bold;
}

.icon-white {
    color: white;
}

.icon-large {
    font-size: 48px;
    width: 48px;
    align-content: center;
    vertical-align: center;
    padding-bottom: 45px;
}

.icon-gray {
    color: #353D43;
}

.add-remove-icon {
    padding: 0 45px 0 15px;
}

.icon-green {
    color: #20cb94;
}

.icon-green:not([disabled]):hover {
    color: #189a71;
}

.icon-green:not([disabled]):active {
    color: #7beac7;
}

.icon-green[disabled] {
    color: dimgray;
}

.icon-equal {
    color: black;
}

.icon-under {
    color: #20cb94;
}

.icon-over {
    color: #fc4667;
}

.icon-button {
    margin: 0 15px 0 15px;
}

.increment-icon {
    font-size: 36px;
    padding: 0 30px 0 30px;
}

.meal-date {
    color: white;
    font-size: 36px;
    font-weight: 700;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    width: 300px;
    text-align: center;
}

.meal-title {
    color: black;
    font-size: x-large;
    font-weight: 700;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    width: 250px;
}

.meal-title-super-script {
    color: black;
    font-size: x-small;
    font-weight: 400;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    vertical-align: 8px;
}

.view-meal-header {
    border: 1px solid lightgray;
    box-sizing: border-box;
    background: #F7F9FA;
    padding: 5px 10px 5px 10px;
}

.view-meal-contents {
    border: 1px solid lightgray;
    border-top-width: 0;
    box-sizing: border-box;
    background: white;
    padding: 5px 10px 5px 10px;
}

.view-meal-list {
    background: #ECF0F1;
}

.grey-background {
    background: #ECF0F1;

}

.star {
    font-size: x-large;
    width: 30px;
    display: inline-block;
    color: #353D43;
}

.star:last-child {
    margin-right: 0;
}

.star:before {
    content: '\2605';
}

.star.on {
    color: #20cb94;
}

/*noinspection CssFloatPxLength*/
.star.half:after {
    content: '\2605';
    color: #20cb94;
    position: absolute;
    margin-left: -24px;
    width: 12.5px;
    overflow: hidden;
}

.star.half.halfwhite:after, .star.white {
    color: white
}

.daily-chart {
    background-color: #3d464b;
}

.daily-chart-header-text {
    background-color: #3d464b;
    color: white;
    font-size: x-large;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    text-align: center;
    padding: 10px 0 10px 0;
}

.daily-chart-header-text-actual {
    padding-right: 5px;
    font-weight: 900;
}

.meal-summary-rating-row {
    padding-top: 5px;
}

.meal-summary-quality-column {
    margin-left: 30px;
}

.nutrient-row {
    margin-right: 40px;
}

.customer-setup-section {
    border: 2px solid lightgray;
    box-sizing: border-box;
    background: #eff3f4;
    width: 95%;
    border-radius: 10px;
    margin: 20px 20px 20px 20px;
    padding: 20px 20px 20px 20px;
}

.customer-setup-inbodyId-field {
    width: 250px;
    margin: 20px 20px 0 20px;
}

.customer-gender-field {
    width: 75px;
    margin: 20px 20px 0 20px;
}

.customer-password-field {
    width: 180px;
    margin: 20px 20px 0 20px;
}

.customer-workout-field {
    width: 180px;
    margin: 10px 20px 10px 20px;
}

.customer-setup-select-field {
    width: 150px;
    margin: 20px 20px 0 20px;
}

.customer-setup-address-line-field {
    width: 300px;
    margin: 20px 20px 10px 20px;
}

.selectedAgent {
    background: darkgray;
}

.agent-setup-field {
    width: 240px;
    margin: 20px 20px 10px 20px;
}

.location-field-row {
    width: 100%;
}

.location-setup-checkbox {
    margin-left: 20px;
}

.location-setup-field {
    width: 100%;
    margin: 20px 20px 10px 20px;
}

.location-setup-select-field {
    width: 100%;
    margin: 0 20px 10px 20px;
}

.customer-setup-payment-field {
    width: 250px;
    margin: 20px 0 20px 0;
}

.email-address-field {
    width: 350px;
    margin: 30px 0 10px 0;
}

.summary-hline-bottom {
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid #3d464b;
}

.score-summary-tooltip {
    width: 200px;
    height: 150px;
    background: #286090 !important;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    font-size: small;
    border: 1px solid white;
    opacity: 1 !important;
}

.food-desc-tooltip {
    font-family: Roboto, "Helvetica Neue", sans-serif;
    font-size: medium;
    border: 1px solid white;
    opacity: 1 !important;
    -ms-transition: none;
    -o-transition: none;
    -webkit-transition: none;
    transition: none;
    z-index: 500;
}

.score-summary-tooltip-label {
    font-weight: 700;
    width: 80%;
}

.score-summary-tooltip-title {
    font-size: small;
    font-weight: 700;
    border-bottom: 1px solid white;
}

.grade-tooltip {
    width: 100px;
    min-height: 25px;
    height: auto;
    max-height: 200px;
    background: #ce8483 !important;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    font-size: small;
    border: 1px solid white;
    opacity: 1 !important;
}

.grade-tooltip-label {
    font-weight: 700;
    width: 80%;
}

.meal-grade-description {
    padding-bottom: 15px;

}

.meal-grade-improvement-message {
    padding-bottom: 15px;
}

.meal-plan-nutrient-input {
    padding: 0 10px 0 10px;
}

.meal-plan-section {
    padding-bottom: 20px
}

.bs-loading-container {

}

.hide-v-scroll {
}

.add-item-icon {
    margin: 0 10px 10px 0;
    font-size: 36px;
}

.add-waiver-icon {
    margin: 0 30px 10px 0;
    font-size: 36px;
    color: white;
}

.pdf-icon {
    margin: 0 30px 10px 0;
    font-size: 28px;
    color: red;
}

.help-edit-icon {
    font-size: 20px;
    color: red;
}

.help-icon-cell {
    width: 30px;
}

.help-grid-align-center {
    text-align: center;
}

.home-header {
    font-size: medium;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    margin: 0 20px 0 20px;
}

.home-icon {
    margin: 0 10px 0 0;
}

.home-header:focus {
    outline: 0;
}

.customer-filter-container {
    width: 100%;
    padding: 10px 15px 10px 15px;
}

.customer-filter {
    width: 100%;
    height: 40px;
    padding-left: 20px;
    border: solid darkgray 2px;
    border-radius: 20px;
    color: black;
}

.customer-section {
    border: 1px lightgrey solid;
    padding: 5px 5px 5px 5px;
    margin: 5px 0px 5px 5px;
}

.customer-section-header {
    font-size: large;
    font-weight: 700;
}

.customer-detail {
    margin: 5px 5px 0 5px;
}

.customer-label {
    font-size: medium;
    font-weight: 700;
    width: 50px;
    margin: 10px 10px 0 10px;
}

.customer-value {
    font-size: medium;
    padding-left: 10px;
    margin: 10px 10px 0 10px;
}

.customer-value-n-line {
    font-size: medium;
    padding-left: 10px;
    margin: 0 10px 0 10px;
}

*:focus {
    outline: none !important;
}

.qty-label {
    font-size: large;
    padding-right: 15px;
}

.material-icons.md-36 {
    font-size: 36px;
    height: 36px;
    width: 36px;
}

.arrow-icon {
    color: #20cb94;
    align-self: center;
}

.arrow-icon-filler {
    height: 36px;
}

.picker-container {
    background-color: white;
    padding-right: 5px;
    padding-left: 5px;
}

.picker-value {
    font-size: large;
    color: black;
    border-top: 1px solid #3d464b;
    border-bottom: 1px solid #3d464b;
}

.picker-next-value {
    font-size: large;
    color: darkgray;
}

.tooltip .tooltip-inner {
    color: white;
    background-color: #3d464b;
    border-radius: 5px;
    font-size: medium;
    max-width: 500px;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.custom-popup-wrapper {
    position: absolute;
    top: 100%;
    left: 0;
    width: 800px;
    z-index: 1000;
    display: none;
    background-color: white;
}

.food-admin-button {
    bottom: 0;
    height: 50px;
    width: 200px;
    margin: 10px 10px 10px 10px;
    border-radius: 25px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    text-transform: none;
}

.add-icon {
    -webkit-text-fill-color: white;
}

.header-button {
    cursor: pointer;
}

.error-text {
    color: red;
    font-weight: bold;
}

.warning-text {
    color: #ffdd00;
    font-weight: bold;
}

.success-text {
    color: green;
    font-weight: bold;
}

.add-custom-food-sub {
    color: #20cb94;
    font-weight: 700;
    font-size: medium;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    text-transform: none;
    margin-right: 30px;
    padding-left: 15px;
    text-align: right;
}

.edit-custom-food-icon {
    color: #00bd77;
    margin-top: 20px;
    font-size: 36px;
    padding: 0 30px 0 30px;
}

.center {
    text-align: center;
}

.help-body-text {
    font-size: 16px;
    margin: 20px;
}

.help-video {
    text-align: center;
}

.admin-home-table {
    height: 300px
}

.table-title {
    color: black;
    font-size: x-large;
    font-weight: 700;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    height: 47px;
    text-align: center;
    margin-top: 14px;
}

.table-icon-button {
    min-width: 30px;
    margin: 0px;
    padding: 0px;
}

.table-button {
    padding: 0;
    text-transform: none;
}

.tbl-icon-btn {
    margin: 0px;
}

.btn-icon {
    color: white;
    margin: 0px 5px 0px 0px;
}

.admin-home-title-btn {
    width: 350px;
}

.table-title-button {
    width: 200px;
    margin: 10px 10px 10px 10px;
    border-radius: 25px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    text-transform: none;
}

.table-title-button.md-focused {
    background-color: red !important;
}

.multi-line-button-text-admin {
    color: white;
    font-weight: bold;
    font-size: small;
    text-align: center;
    vertical-align: middle;
    line-height: 15px;
    margin-bottom: 3px;
}

.admin-dashboard-date {
    color: black;
    font-size: 24px;
    font-weight: 700;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    text-align: center;
    margin-left: 25px;
}

.admin-button-right {
    margin-right: 20px;
}

.admin-calendar-icon {
    margin-left: 10px;
    font-color: black;
}

.notify-modal-width {
    max-width: 80%;
    margin-left: 10%;
}

.notify-recipient-row {
    margin-left: 13%;
}


.modal-title {
    background-color: red;
    color: white;
}

.notify-modal-title {
    background-color: red;
    color: white;
}

.recipient-label-text {
    font-weight: bold;
}

.email-recipient-btn {
    background-color: lightgrey;
    height: 30px;
    font-size: 16px;
    border-radius: 8px;
    margin: 4px;
    padding-top: 4px;
    padding-right: 8px;
    padding-bottom: 4px;
    padding-left: 8px;
    display: inline-block;

}

.notify-clear-icon {
    font-size: 20px;
}

.notify-modal-email-text {
    font-size: 16px;
    margin: 4px;
}

.btn-raised {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
}

md-table-container {
    max-height: 300px;
}

table.md-table td.md-cell:last-child, table.md-table th.md-column:last-child {
    padding: 0 12px 0 0;
}

table.md-table:not(.md-row-select) td.md-cell:nth-child(n+2):nth-last-child(n+2), table.md-table:not(.md-row-select) th.md-column:nth-child(n+2):nth-last-child(n+2) {
    padding: 0 12px 0 0;
}

.reset-password-rules {
    margin-top: 10px;
    margin-left: 15px;
}

.font-bold {
    font-weight: bold;
}

.password-rules-text {
    margin-left: 75px;
}

.coaching-mode-text {
    background-color: #20cb94;
    color: white;
    font-size: x-large;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    text-align: center;
    padding: 10px 0 10px 0;
}
