$table-left-padding: 20px;
$half-table-gap: 12px;

.stickyGroup {
  position: sticky;
  left: $table-left-padding;
  z-index: 1;
  display: grid;
  grid-template-columns: subgrid;
  place-items: inherit;
}

.ground {
  position: absolute;
  left: -$table-left-padding;
  z-index: -1;
  width: calc(100% + $table-left-padding + $half-table-gap);
  background-color: var(--white);
  border-right: 1px solid var(--natural-300);
  border-radius: 8px 0 0 8px; // 8px is the border-radius of row in table
}
