.entity-setup-step-4 {
  width: 480px;
  margin: 70px auto;
  padding: 40px 44px 40px;
  position: relative;
  box-shadow: 0 7px 22px rgba(96, 105, 157, 0.1);
  border-radius: 7px;
  background-color: var(--white);

  .stars-img {
    $starImgWidth: 234px;
    $starImgHeight: 94px;
    top: calc(0px - #{$starImgHeight} / 2);
    left: calc(50% - #{$starImgWidth} / 2);
    position: absolute;
  }

  .header {
    padding-bottom: 60px;
    text-align: center;
    &__title {
      margin-bottom: 10px;
      font: var(--title-28-black);
    }
    &__text {
      font: var(--title-16-regular);
      span {
        font-weight: 700;
        color: var(--primary-500);
      }
    }
  }

  .form {
    &__title {
      color: var(--main-black);
      opacity: .8;
      font-size: 28px;
      font-weight: 900;
      letter-spacing: -.67px;
      margin-bottom: 60px;
    }

    md-input-container {
      margin: 0 0 30px;
      md-icon {
        border-left: none!important;
      }
    }

    &__email {
      margin-bottom: 4px;
      position: relative;

      .reset {
        min-height: auto;
        height: auto;
        min-width: auto;
        padding: 0;
        margin: 0;
        position: absolute;
        right: 0;
        top: -5px;
        font: var(--title-16-bold);
        line-height: 36px;
        color: #B6B6C2;
        text-transform: capitalize;
      }

      input {
        padding-right: 45px;
      }
    }

    &__password {
      margin-bottom: 0;

      md-icon {
        color: #808288;
        cursor: pointer;
      }
    }


    &__submit {
      width: 100%;
      height: 45px;
      border-radius: 4px;
      background-color: var(--primary-500);
      color: var(--white);
      font-size: 16px;
      font-weight: 700;
      letter-spacing: -0.38px;
      text-transform: capitalize;
      margin-left: 0;

      &:hover {
        background-color: var(--primary-500);
        opacity: 0.8;
      }

      &[disabled] {
        opacity: 0.5;
      }
    }
  }
}
