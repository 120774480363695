@import "../../../../sass/mixins";

.entity-inbody {
  @include textBold;
  font-size: 15px;

  p {
    margin: 0;
  }

  .app-card-header {
    &__title {
      font: inherit;
    }
  }

  .app-card-body {
    padding: 15px 32px;

    md-input-container {
      font: inherit;
    }

    .fields-group {
      margin: 15px 0 25px;

      &:nth-of-type(2) {
        margin-bottom: 0;
      }

      .title-container {
        display: flex;
        align-items: baseline;

        md-input-container {
          width: auto;
          font: inherit;

          md-select {
            font: inherit;

            md-select-value {
              font: inherit;
            }
          }

          .md-text {
            font: inherit;
            font-weight: 600;
            color: #B6B6C2;
          }
        }
      }
    }
  }

  .field-container-inbody-types {
    display: grid;
    gap: 12px;
    align-items: center;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));

    md-checkbox {
      margin: 12px 0;
      min-width: auto;

      .md-label {
        font: inherit;
      }
    }
  }

  &-field-container {
    display: flex;
    flex-wrap: wrap;

    md-checkbox {
      width: 210px;
      margin: 12px 30px 12px 0;

      .md-label {
        font: inherit;
      }
    }
  }

  button.outline {
    margin-top: 20px;
  }

  button[type="button"] {
    margin-top: 20px;
    margin-left: 10px;
    display: block;
  }
}
