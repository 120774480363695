.revenue-report {
  .list {
    &-item {
      padding: 10px;

      &:first-child {
        padding: 0 10px;

        .list-item__text {
          color: var(--natural-600);
        }
      }

      &__text {
        font-weight: 700;
        letter-spacing: -.34px;
        font-size: 14px;
        color: #000000;
        margin: 0;
        padding-right: 8px;
      }

      &:not(:first-child) {
        min-height: 60px;
        border-bottom: 1px solid #e4e6ec;
      }

      &:last-child {
        border: none;
      }
    }
  }
}
