.superuser-dashboard {
  max-width: 1376px;
  width: 100%;
  margin: 32px auto;

  .title-wrapper {
    .title-page {
      font-weight: 900;
      color: #000000;
      font-size: 28px;
      letter-spacing: -.67px;
      line-height: 45px;
      margin: 0;
    }

    button.outline {
      margin-right: 10px;
    }

    .btn-download {
      margin: 0;
      min-width: 130px;
      padding: 0 10px;
      height: 45px;
      line-height: 42px;
      border-radius: 4px;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: -0.38px;
      color: #000000;
      background: transparent;
      border: 2px solid rgba(178, 178, 178, 0.5);

      input {
        display: none;
      }
    }
  }

  &-body {
    margin-top: 20px;

    .app-card {
      min-height: 410px;
      display: flex;
      flex-direction: column;

      &-header {
        min-height: 65px;
      }

      &-body {
        flex: 1;
      }
    }

    > * {
      width: calc(50% - 10px);

      &:nth-child(odd) {
        margin-right: 20px;
      }

      @media screen and (max-width: 1290px) {
        margin-right: 0 !important;
        width: 100%;
      }
    }
  }

  .summary-info {
    .list {
      &-item {
        padding: 10px;

        &:first-child {
          padding: 0 10px;

          .list-item__text {
            color: var(--natural-600);
          }
        }

        &__text {
          font-weight: 700;
          letter-spacing: -.34px;
          font-size: 14px;
          color: #000000;
          margin: 0;
        }

        &__link {
          text-decoration: none;
          color: var(--link-btns-rgb) !important;
          border-bottom: 1px solid rgba(var(--link-btns-rgb), 0.3) !important;
          margin-left: auto;
        }

        &:not(:first-child) {
          min-height: 60px;
          border-bottom: 1px solid #e4e6ec;
        }
      }
    }
  }

  .amount-of-users-billed {
    .canvas-wrapper {
      width: 560px;
      height: 200px;
      margin: 22px auto;
    }
  }

  .title-dropdown{
    margin-right: 0;
  }
}
