.box {
  line-height: 0; // This is to remove the space between the circle and the valueBox
}

.circleBox {
  position: relative;
  display: inline-block;
  color: var(--background-50-dark);

  .circle {
    position: absolute;
    top: 0;
    left: 0;

    :global(.MuiCircularProgress-circle) {
      stroke-linecap: round;
    }
  }

  .valueBox {
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--main-black);
  }
}
