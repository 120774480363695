.container {
  display: flex;
  flex-direction: column;

  .option {
    padding: 16px;
    text-align: center;
    cursor: pointer;
    border-top: 1px solid #e0e0e0;

    &:last-child {
      border-bottom: 1px solid #e0e0e0;
    }

    &:hover {
      opacity: 0.7;
    }

    &Title {
      margin: 0;
      font-size: 18px;
      font-weight: bold;
      color: var(--primary-800);
    }

    &Description {
      margin: 8px 0 0;
      font-size: 14px;
      color: var(--natural-600);
    }
  }
}
