.sending-message-dialog {
  box-shadow: 0 7px 22px rgba(96, 105, 157, 0.1);
  border-radius: 7px;
  min-width: 370px;
  max-width: 518px;
  min-height: 342px;

  .figure {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__gif {
      margin-bottom: 20px;
      width: 200px;
      height: 200px;
      background: var(--send-message-gif) no-repeat center;
    }

    &__image {
      margin-bottom: 20px;
      width: 200px;
      height: 200px;
      background: var(--send-message-img) no-repeat center;
    }

    &__title {
      color:var(--main-black);
      font-size: 22px;
      font-weight: 700;
      line-height: 30px;
      margin: 0;
    }

    &__text {
      color:var(--main-black);
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
      margin: 0;
      span{
        font-size: 22px;
        font-weight: 700;
        display:block;
      }
    }
  }

  &__cancel {
    width: 165px;
    height: 45px;
    border-radius: 4px;
    background-color: var(--primary-500);
    color: var(--white);
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.38px;
    text-transform: uppercase;
    margin: 34px;
    border: none;
  }

  .loading-block {
    min-height: 326px;
  }

  .success-block {
    margin-top: 20px;
  }

  .errors-block {
    text-align: center;

    &__title {
      color:var(--main-black);
      font-size: 22px;
      font-weight: 700;
      line-height: 30px;
      margin: 0;
    }

    &__text {
      color: var(--main-black);
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
      width: 69%;
      margin: auto;
    }

    &-actions {
      margin-bottom: 15px;
    }

    &__btn {
      width: 165px;
      height: 45px;
      border-radius: 4px;
      background-color:var(--white);
      color: var(--white);
      font-size: 16px;
      font-weight: 700;
      letter-spacing: -0.38px;
      border: none;
      margin: 9px;
    }

    &__btn-cancel {

    }

    &__btn-resend-email {
           margin-left:10px;
    }

    &__btn-resend-sms {
    margin-left:10px;
    }

    .table {
      font-weight: 700;
      letter-spacing: -0.34px;
      font-size: 14px;
      text-align: left;
      margin: 30px 0 40px;

      &-header {
        color: var(--natural-600);
      }

      &-body {
        color: var(--main-black);
        max-height: 200px;
        overflow: auto;
      }

      &__row {
        padding: 7px 25px;

        &:not(:first-child) {
          border-top: 1px solid rgba(108, 113, 136, 0.1);
        }
      }

      &__col {
        margin-right: 20px;

        &-error {

          &:before {
            content: '';
            background-image: url("../../assets/images/vectors/sending-message-error-icon.svg");
            background-size: cover;
            display: block;
            min-width: 13px;
            height: 12px;
            margin-right: 6px;
            margin-left: -19px;
          }
        }
      }
    }
  }

  ::-webkit-scrollbar {
    width: 4px;
    height: 529px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 3px;
    background-color: #DADCE5;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--primary-500);
    border-radius: 3px;
    width: 4px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: var(--warning-600);
  }
}
