.measurementUnitRow {
  display: flex;
  gap: 40px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.select {
  width: 238px;
}
