.cardsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
}

.box {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
}

.cardTitle {
  font: var(--title-16-bold);
  text-align: center;

  @media (width < 425px) {
    font: var(--body-14-bold);
  }
}
