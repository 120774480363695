.revenue-report-page {
  max-width: 1376px;
  width: 100%;
  margin: 32px auto;

  .filter-list-input {
    padding-right: 32px;
  }

  .title-page {
    font-weight: 900;
    color: #000000;
    font-size: 28px;
    letter-spacing: -.67px;
    line-height: 45px;
    margin: 0;
  }

  button.outline {
    margin-left: 10px;
  }

  button.print {
    padding: 0;
    height: fit-content;
    border-bottom: 1px solid;
    border-radius: 0;
    margin-left: 50px;

    md-icon {
      min-width: 20px;
      width: 20px;
      min-height: 20px;
      height: 20px;
      margin-right: 5px;
      margin-left: -25px;
    }
  }

  .list {
    &-item {
      box-shadow: 0 7px 22px rgba(96, 105, 157, 0.1);
      background-color: #ffffff;
      margin: 10px 0;
      padding: 17px;
      min-height: 74px;

      &:first-child {
        margin-top: 40px;

        .list-item__text {
          color: #666666;
          font-size: 14px;
          font-weight: 700;
          letter-spacing: -.34px;
          opacity: 1;
        }
      }

      &__text {
        margin: 0;
        color: #000000;
        font-weight: 700;
        letter-spacing: -0.34px;
        overflow: hidden;
        text-overflow: ellipsis;

        &:not(:first-child) {
          opacity: .8;
        }
      }
    }
  }
}
