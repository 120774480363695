.inventoryCell {
  display: inline-flex;
  gap: 7px;
  align-items: center;

  .green {
    fill: var(--success-200);
  }

  .red {
    fill: var(--error-300);
  }

  .grey {
    fill: var(--grey-2);
  }
}
