.entity-dashboard {
  max-width: 1376px;
  width: 100%;
  margin: 32px auto;

  .title-page {
    font-weight: 900;
    color: #000000;
    font-size: 28px;
    letter-spacing: -.67px;
    line-height: 45px;
    margin: 0;
  }

  &__panel-pos-status {
    position: relative;
    width: 100%;

    .pos-message-tooltip {
      position: absolute;
      left: 288px;
      right: 0;
      width: auto;
      margin-top: -20px;
      min-height: 250px;
      border-radius: 4px;
      filter: drop-shadow(0 7px 22px rgba(96, 105, 157, 0.2));

      span {
        padding: 10px;
        position: fixed;
        background-color: white;
        font-size: 13px;
        color: rgba(0, 0, 0, 0.6);
        font-weight: 900;
        text-align: left;
        border-radius: 4px;
        opacity: 1;
      }

      span:before {
        content: "";
        position: absolute;
        right: 100%;
        top: 16px;
        width: 0;
        height: 0;
        border-top: 9px solid transparent;
        border-right: 18px solid white;
        border-bottom: 9px solid transparent;
      }
    }

    md-icon {
      cursor: pointer;
      margin-left: 8px;
      min-width: 18px;
      max-width: 18px;
    }

    span {
      font-size: 12px;
      opacity: 0.6;
      margin: 7px 0 0 16px;
    }
  }

  &__btn-pos-status {
    background: transparent;
    color: var(--main-black);
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    min-width: auto;
    padding: 0;
    height: 22px;
    border-radius: 0;
    margin-left: 50px;

    &:hover {
      background-color: transparent;
      opacity: 0.7;
    }

    md-icon {
      color: var(--main-black);
      margin-left: -27px;
      padding: 2px;
    }

    &.failed {
      color: #f66464;
      border-bottom: 1px solid rgba(246, 100, 100, 0.3);

      md-icon {
        color: #f66464;
      }
    }

    &.in-progress {
      color: #FAA46F;
      border-bottom: 1px solid rgba(250, 164, 111, 0.3);

      md-icon {
        color: #FAA46F;
        animation: rotating 2s linear infinite;
      }
    }

    &:disabled {
      background: transparent;
    }
  }

  .table-wrapper {
    margin: 20px 0 40px;
    box-shadow: 0 7px 22px rgba(96, 105, 157, .1);
  }

  table {
    background-color: #ffffff;
    border-radius: 7px;
    overflow: auto;

    tr {
      &:last-child {
        border-top: 1px solid #e9ebef;
      }

      &:nth-child(2) {
        th {
          padding: 4px 15px 9px;
        }
      }
    }

    th {
      color: var(--natural-600);
      letter-spacing: -.34px;
      font-size: 14px;
      font-weight: 700;
      text-align: center;
      padding: 15px;
      vertical-align: top;
      border-bottom: 1px solid #e9ebef;

      &:not(:first-child) {
        border-left: 1px solid #e9ebef;
      }

      &:nth-child(1) {
        width: 100px;
      }
    }

    td {
      color: #000000;
      text-align: center;
      font-weight: 900;
      font-size: 16px;
      letter-spacing: -.38px;
      padding: 15px;

      &:not(:first-child) {
        border-left: 1px solid #e9ebef;
      }
    }
  }

  &__link {
    color: #000000 !important;
  }

  @keyframes rotating {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
