.tableHeader {
  padding: 16px 20px;
  font: var(--body-14-bold);
  border-bottom: 1px solid var(--natural-200);
}

.infoCell {
  display: flex;
  gap: 2px;
  align-items: center;
}
