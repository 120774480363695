.row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 24px;
  align-items: center;

  &:first-child {
    margin-bottom: 12px;
  }
}
