.buy-certificates-dialog {
  width: 368px;

  md-input-container {
    width: 290px;
    margin: 0 23px 38px;
  }

  button.outline {
    margin-right: 10px;
  }
}

