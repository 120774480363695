.title {
  margin-bottom: 16px;
}

.consumptionContainer {
  position: relative;
  margin-bottom: 16px;

  .infoBox {
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font: var(--title-16-bold);
    backdrop-filter: blur(2px);

    p {
      padding: 10px;
      background-color: white;
    }
  }
}

.listBox {
  display: flex;
  gap: 16px;
  justify-content: space-between;
}
