.pre-check-in {

  .communication-filter {
    &-header {
      margin: 60px 0 0;

      &__title {
        margin: 0;
        align-self: start;
      }

      .delete-all {
        margin: 0 0 0 20px;
      }

      .bg-red {
        background: var(--error-300);
      }
    }
  }

  .filter {
    padding: 17px;
    margin: 25px 0;
    border-radius: 8px;
    background-color: #fefefe;

    md-checkbox {
      margin-right: 17px;
      width: 23px;
    }

    &-list-input {
      margin-right: 10px;
    }

    &-date-container {
      margin-bottom: 20px;
    }
  }

  a {
    text-decoration: none;
    font-size: 14px;
    min-height: 80px;
    vertical-align: middle;

    &:hover  {
      opacity: 1;
    }
  }

  .list {
    &-item {
      height: 100px;
      box-shadow: 0 7px 22px rgba(96, 105, 157, 0.07);
      background-color: #ffffff;
      margin: 10px 0;
      padding: 0 17px;
      opacity: 0.7;
      border-radius: 8px;

      &:hover {
        box-shadow: 0 4px 15px rgba(96, 105, 157, 0.22);
      }

      &.active {
        opacity: 1;
      }

      md-checkbox {
        display: flex;
        justify-content: center;
        flex-direction: column;

        margin-top: 8px;
        margin-right: 17px;
        opacity: 1;
        pointer-events: all;
        width: 23px;
        min-height: 80px;
      }

      &__text {
        display: flex;
        align-items: center;
        justify-content: space-between;

        margin: 0 10px 0 0;
        color: #000000;
        font-weight: 700;
        font-size: 16px;
        letter-spacing: -0.34px;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;

        &--email {
          opacity: .5;
          font-weight: 400;
        }

        &_centered {
          text-align: center;
        }
      }

    }

    .emerald {
      color: var(--success-300);
      border-color: var(--success-300);
    }

    .yellow {
      color: #FFC256;
    }
  }

  .red {
    color: var(--error-300)!important;
    border-color: var(--error-300);
  }
}
