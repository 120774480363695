.supplement-list {
  max-width: 1376px;
  width: 100%;
  margin: 32px auto;

  .title-page {
    font-weight: 900;
    color: #000000;
    font-size: 28px;
    letter-spacing: -.67px;
    line-height: 45px;
  }

  .clear {
    span {
      margin: auto;
    }
  }

  a {
    font-size: 14px;
    text-decoration: none;
    min-height: 80px;
    vertical-align: middle;

    &:hover {
      opacity: 1;
    }
  }

  .contact-btn {
    min-height: 80px;

    span {
      color: var(--main-black) !important;
      text-decoration: underline;
      text-decoration-color: rgba(0, 0, 0, 0.3)
    }

    .clear {
      span, md-icon {
        min-height: 80px;
      }
    }

    span, md-icon, md-menu {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }

    md-icon {
      min-height: 28px !important;
      min-width: 28px !important;
    }

    md-menu {
      padding-top: 3px;
    }
  }

  .filter {
    margin-top: 40px;
    background-color: #fefefe;
    padding: 17px;

    md-checkbox {
      margin-right: 17px;
      width: 23px;
    }

    &-list-input {
      margin-right: 10px;
    }

    button.outline {
      min-width: 92.63px;

      &.ADMIN {
        min-width: 120px;
        margin-left: 45px;
      }
    }
  }

  .list {
    &-item {
      box-shadow: 0 4px 15px rgba(96, 105, 157, 0.07);
      background-color: #ffffff;
      margin: 10px 0;
      padding: 0 17px;
      opacity: 0.7;
      min-height: 80px;

      &:hover {
        box-shadow: 0 4px 15px rgba(96, 105, 157, 0.22);
      }

      &.active {
        opacity: 1;
      }

      md-checkbox {
        display: flex;
        justify-content: center;
        flex-direction: column;

        margin-right: 17px;
        opacity: 1;
        width: 23px;
        min-height: 80px;
      }

      &__text {
        display: flex;
        justify-content: center;
        flex-direction: column;

        margin: 0 10px 0 0;
        color: #000000;
        font-weight: 700;
        letter-spacing: -0.34px;
        overflow: hidden;
        text-overflow: ellipsis;

        &:not(:nth-child(2)) {
          opacity: .8;
        }
      }
      p {
        margin-bottom: 0;
      }
      .valid-subscription {
        background: url("../../../assets/images/vectors/subscription_active_status.svg") no-repeat left 3px;
        padding-left: 24px;
      }

      .not-valid-subscription {
        background: url("../../../assets/images/vectors/subscription_inactive_status.svg") no-repeat left 3px;
        padding-left: 24px;
      }

      .uncompleted-subscription {
        background: url("../../../assets/images/vectors/subscription_uncompleted_status.svg") no-repeat left 3px;
        padding-left: 24px;
      }

      .subscription-expire-date {
        font-weight: 400;
        opacity: .5;
        font-size: 12px;
        display: block;
      }
    }
  }
}
