.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 12px 20px;
  background: url("../../../../../../../assets/images/bg-timer.png") 100% 50% / cover;
  border-radius: 8px;
}

.title {
  max-width: 200px;
  font: var(--title-16-medium);
  color: var(--white);
}