.modalPaper {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 688px;
  padding: 34px 38px 38px;
  overflow-wrap: break-word;

  .modalInfo {
    text-align: center;

    h3 {
      font: var(--title-22-black);
    }

    p {
      margin-top: 8px;
      font: var(--title-16-regular);
      color: var(--natural-700);
    }
  }
}
