.container {
  display: flex;
  padding: 20px;

  &:not(:last-child) {
    border-bottom: 1px solid var(--natural-200);
  }
}

.title {
  font: var(--title-16-bold);
  color: var(--main-black);
}

.answer {
  margin-top: 4px;
  font: var(--body-14-medium);
  color: var(--main-black);
}