.row {
  margin-bottom: 8px;

  .title {
    padding: 21px 20px;
    font: var(--title-28-bold);
    border-bottom: 1px solid var(--natural-200);
  }

  .header {
    padding: 16px 20px;
    font: var(--body-14-bold);
  }
}
