.box {
  max-width: 1048px;
  padding-top: 34px;
  margin: 0 auto;
}

.typography {
  margin-bottom: 25px;
  font-size: 28px;
  font-weight: 900;
}
