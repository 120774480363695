.cellBox {
  align-items: center;
}

.goalNotExist {
  color: var(--main-black);
}

.badGoal {
  color: var(--error-300);
}

.goodGoal {
  color: var(--info-300);
}
